import React from "react";
import {post} from "./js/helpers/rest";
import {getBackendUrl} from "./js/helpers/functions";

class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = {error: null, errorInfo: null};
	}

	componentDidCatch(error, errorInfo) {
		this.setState({
			error: error,
			errorInfo: errorInfo
		});
	}

	render() {
		if (!this.state.error) {
			try {
				return this.props.children;
			} catch (e) {
				this.track(e, {});
				this.redirect();
			}
		} else {
			this.track(this.state.error, this.state.errorInfo);
			this.redirect();
		}
	}

	redirect() {
		if (process.env.NODE_ENV !== 'development') {
			localStorage.removeItem('token');
			localStorage.removeItem('state');
			localStorage.removeItem('id_hint');
			window.location.href = '/prets';
		}
	}

	track(e, errorInfo) {
		let state = localStorage.getItem('state');
		if (state) {
			try {
				state = JSON.parse(state);
				state.config.banks && delete state.config.banks;
				state.config.insurers && delete state.config.insurers;
				state.config.professions && delete state.config.professions;
				state.config.project_types && delete state.config.project_types;
				state.config.risky_professions && delete state.config.risky_professions;
			} catch (parse) {
				console.log('State was not an objetc', parse.message);
			}
		}
		post({
			error: {
				stack: e.stack,
				message: e.message
			}, reactError: errorInfo,
			state: state ? JSON.stringify(state) : '',
		}, getBackendUrl() + "/config/fe_errors").catch(error => {
			console.warn('Cannot track the error!', error.message);
		});
		console.log('To track after endpoint deploy!', e.message);
	}
}

export default ErrorBoundary;
