import React from 'react';
import {connect} from 'react-redux';
import Shell from '../../components/Shell';
import {Row, Col} from 'react-grid-system'
import FinalOffer from '../../components/FinalOffer';

import md5 from "js-md5";

class Offer extends React.Component {

	getStep() {
		return 'step8';
	}
	componentWillMount() {
		if (window.parent.location.pathname === '/sante') {
			window.parent.location = '/offre';
		}
	}
	render() {
		return (<div>
			<img src={`https://www.affinilead.com/p/c/a/fef130fb1bc531b5bfe44ae63c4f3ced93a15486/205/p.gif?order_id=${md5(this.props.borrower.email)}`} width="1" height="1"
			     alt=""/>
			<Row justify='center' style={{textAlign: 'center'}}>
				<Col md={12}>
					<FinalOffer
						borrower={this.props.borrower}
						coborrower={this.props.coborrower}
						updateConfig={this.props.updateConfig}
						config={this.props.config}
						updateProgress={this.props.updateProgress}
						updateLoan={this.props.updateLoan}
						updateBorrower={this.props.updateBorrower}
						updateCustomer={this.props.updateCustomer}
						updatedPricing={this.props.updatedPricing}
						finalCalculation={this.props.finalCalculation}
						documents={this.props.documents}
						translations={this.props.translations}
						afiEsca={this.props.afiEsca}
						loan={this.props.loan}
						sign={this.props.sign}
					/>
				</Col>
			</Row>

		</div>)

	}
}

export default connect()(Shell(Offer));
