import React from 'react';
import style from './style.module.css';
import {createMuiTheme} from '@material-ui/core'
import {MuiThemeProvider} from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateValidator from '../DateField'
import NumberValidator from '../NumberField'
import {TextValidator, SelectValidator} from 'react-material-ui-form-validator';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Translate from "components/CustomTranslate";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import "moment/locale/fr";

import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';

import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import {ValidatorComponent} from 'react-material-ui-form-validator';

moment.locale("fr");

class SelectFieldMultiple extends ValidatorComponent {
	render() {
		let time = new Date().getTime();

		let renderValue = (selected) => {
			let named = selected ? selected.map(s => {
				let entry = this.props.options.find(o => o.key === s);
				return entry ? entry.name : false;
			}).filter(n => !!n) : [];

			return named.length > 0 ? named.join(', ') : null;
		};

		return (
			<FormControl error={this.errorText()} {...this.props} className={`${style.formField} ${this.props.className || ''}`}>
				<InputLabel id={`multiple-professions-label-${time}`}>{this.props.label}</InputLabel>
				<Select ref={(r) => {
					this.input = r;
				}} helperText={this.errorText()}
				        InputLabelProps={{shrink: true}} labelId={`multiple-professions-label-${time}`} value='' {...this.props} multiple
				        renderValue={this.props.value.length > 0 ? renderValue : false}/>
				<FormHelperText>{this.errorText()}</FormHelperText>
			</FormControl>
		);
	}

	errorText() {
		const {isValid} = this.state;

		if (isValid) {
			return null;
		}

		return (
			<div>{this.getErrorMessage()}</div>
		);
	}
}

const StringField = (props) => {
	return <TextValidator
		// variant='outlined'
		InputLabelProps={{shrink: true}}
		{...props} className={`${style.formField} ${props.className || ''}`}
		// onBlur={(e) => { //This onBlur is messing with input focus sometimes - to be investigated, maybe ?
		// if (props.label && props.label.props && props.label.props.value !== 'step4.postalCode') { //KOCHFIX ???
		// 	let value = e && e.target && e.target.value ? e.target.value : '';
		// 	value     = typeof value === 'string' ? value.trim() : value;
		// 	if (props.onChange) {
		// 		e.target.value = value;
		// 		props.onChange(e);
		// 	} else if (props.onValueChange) {
		// 		props.onValueChange({value});
		// 	}
		// }
		// }}
	/>;
};

const SelectField = (props) => {
	let changeHandler = (e) => {
		let val = e.target.value;
		props.onChange(props.name, val);
	};

	return <SelectValidator
		value=''  {...props} className={`${style.formField} ${props.className || ''}`}
		InputLabelProps={{shrink: true}}
		// variant='outlined'
		input={<TextValidator onChange={changeHandler}
		                      InputLabelProps={{shrink: true}}
			// variant='outlined'
			                  {...props} />}/>
};

const SelectFieldItems = (props, className = '', multiple = false, value, separate_id = false) => {

	return props && props.map(prop => {
		let id = prop.key
		if (separate_id) {
			id = separate_id + '-' + prop.key;
		}
		return (
			<MenuItem value={prop.key} key={prop.key} id={id} variant='menu' className={className}>
				{multiple && <Checkbox disabled={true} checked={value.indexOf(prop.key) !== -1} className={'checkbox'}/>}
				{prop.name}
			</MenuItem>
		);
	});
};

const DateField = (props) => {
	return <DateValidator {...props} fullWidth/>
};

const CalendarField = (props) => {
	const customTheme = createMuiTheme({
		palette: {
			primary: {
				main: props.config.theme.primary,
				// light:  'YOUR COLOR',
				dark: props.config.theme.primary,
			},
			secondary: {
				main: '#2daddd',
			},
		},
	});
	return <MuiThemeProvider theme={customTheme}>
		<MuiPickersUtilsProvider utils={MomentUtils}>
			<DatePicker
				disablePast={false}
				disableFuture={false}
				variant='inline'
				validators={['required']}
				errorMessages={['Cette information doit être renseignée']}
				// inputVariant="outlined"
				utils={MomentUtils}
				format="DD/MM/YYYY"
				disabled={false}
				hintText="Jour"
				name="Jour"
				floatingLabelText="Jour"
				value={props.date}
				id={props.id}
				InputLabelProps={{shrink: true}}
				{...props}
				className={`${style.formField} ${props.className || ''}`}
			/>
		</MuiPickersUtilsProvider>
	</MuiThemeProvider>
};

const NumberField = (props) => {
	return <NumberValidator {...props} />
};

const YesNoRadio = (props) => {
	return (
		<div>
			<FormControlLabel
				className={props.buttonClicked === 'true' ? 'radioButton clickedButton' : 'radioButton'}
				value='true'
				id={`${props.name}_yes`}
				control={<Radio required/>}
				label={<Translate value='generic.yes'/>}/>
			<FormControlLabel
				className={props.buttonClicked === 'false' ? 'radioButton clickedButton' : 'radioButton'}
				value='false'
				id={`${props.name}_no`}
				control={<Radio required/>}
				label={<Translate value='generic.no'/>}/>
		</div>
	)
};

export {StringField, SelectField, SelectFieldItems, DateField, CalendarField, NumberField, YesNoRadio, SelectFieldMultiple};
