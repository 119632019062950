import moment from 'moment';
import {phoneValidation} from "../functions";

export const isFloatRequired      = (value) => !!(value + '').replace(/\s/g, '').match(new RegExp(/^(?:[1-9]\d*|0)?(?:\s\d+)*(?:,\d+|\.\d+)?\w*/i)) || !!(value + '').replace(/\s/g, '').match(new RegExp(/^(?:[1-9]\d*|0)?(?:,\d+)?\w*$/i));
export const requiredOnCondition  = (value, condition) => condition === 'true' ? !!value : true;
export const isInteger            = (value) => !!(value + '').replace(/\s/g, '').match(new RegExp(/^(?:[1-9]\d*|0)?(?:\s\d+)*\w+$/i));
export const isIntegerNotRequired = (value) => !!value ? !!(value + '').replace(/\s/g, '').match(new RegExp(/^(?:[1-9]\d*|0)?(?:\s\d+)*\w+$/i)) : true;
export const isFloatNotRequired   = (value) => !!value ? !!(value + '').replace(/\s/g, '').match(new RegExp(/^(?:[1-9]\d*|0)?(?:\s\d+)*(?:,\d+|\.\d+)?\w*/i)) || !!(value + '').replace(/\s/g, '').match(new RegExp(/^(?:[1-9]\d*|0)?(?:,\d+)?\w*$/i)) : true;
export const isMobileNumber       = (value) => {
	if (!!value) {
		let validation = phoneValidation(value, false);
		return validation.check;
	} else {
		return true;
	}
};
export const isName               = (value) => !!value ? value.match(new RegExp(/[0-9]/i)) ? false : value.match(new RegExp(/^(([A-Za-zÀ-ÿ]+[-'‘’ ]?)*)+$/u)) : true;
//export const isName = (value) => !!value ? value.match(new RegExp(/^(([A-Za-zÀ-ÿ]+[-' ]?)*)+([A-Za-zÀ-ÿ]+)?$/)) : true;
export const isPasswordLong       = (value) => !!value && value.length >= 6;
export const sixCharacters       = (value) => !!value && value.length === 6;
export const postalValidation     = (value) => !!value && value.length === 5;
export const passwordPattern      = (value) => /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/.test(value);
export const nameValidation       = (value) => /[!@#$%^&*()_+=\[\]{};:\\|,.<>\/?0-9]+/.test(value) ? false : true;
export const nameValidationLength = (value) => value && value.length < 25;
export const isMatch              = (value, toMatch) => !!value && value === toMatch;
export const isNotMatch           = (value, toNotMatch) => value !== toNotMatch;
export const hasNoWhitespace      = (value) => /\s/.test(value) ? false : true;

export const maxFloatNumber = (value, max) => {
	if (_isEmpty(value)) return true;
	value = parseFloat(value.replace(/\s/g, '').replace(',', '.'));
	return !isExisty(value) || parseFloat(value) <= parseFloat(max);
};

export const minFloatNumber = (value, min) => {
	if (_isEmpty(value)) return true;
	value = parseFloat(value.replace(/\s/g, '').replace(',', '.'));
	return !isExisty(value) || parseFloat(value) >= parseFloat(min);
};

export const trust = (value, passTrough) => {
	return !!passTrough;
};

// Helpers
const isExisty = function isExisty(value) {
	return value !== null && value !== undefined;
};

const _isEmpty                        = function _isEmpty(value) {
	return value === '' || value === undefined || value == null;
};
export const yearsBiggerThanRemaining = (value, diff) => {
	if (!value) return true;
	let d = moment().diff(moment(diff, 'DD/MM/YYYY'), 'years', true);
	return parseInt(value) > d;
};

export const monthsBiggerThanRemaining = (value, diff) => {
	if (!value) return true;
	let d = moment().diff(moment(diff, 'DD/MM/YYYY'), 'years', true);
	return (parseInt(value) / 12) > d;
};
