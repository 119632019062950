import React from 'react';
import connect from "react-redux/es/connect/connect";
import {isMobile} from "react-device-detect";
import Shell from "../../../components/Shell";
import {Col, Row} from "react-grid-system";
import style from "../../10.Signature/style.module.css";

class Sign extends React.Component {

	getStep() {
		return null;
	}

	render() {
		let web_signature    = process.env.REACT_APP_DESKTOP_SIGNATURE;
		let mobile_signature = process.env.REACT_APP_MOBILE_SIGNATURE;
		let signature        = isMobile ? mobile_signature : web_signature;

		return (
			<Row>
				<Col md={12}>
					<iframe title="wedou-sign" className={style.signing} src={this.props.config.sign.signLink + signature}/>
				</Col>
			</Row>
		);
	}

}

export default connect()(Shell(Sign));
