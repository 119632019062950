import React from 'react';
import PropTypes from 'prop-types';
import {StringField} from '../Fields';
import InputMask from 'react-input-mask'

let DateField = (props) => {
	let shouldFormat              = false; //true
	let shouldUpdate              = true;
	let {withoutDay, ...newProps} = props;
	withoutDay                    = withoutDay || false;

	// Polyfills to support IE 11
	Number.isNaN = Number.isNaN || function (value) {
		return (value !== value); //eslint-disable-line
	};

	Number.isInteger = Number.isInteger || function (value) {
		return typeof value === "number" &&
			isFinite(value) &&
			Math.floor(value) === value;
	};

	if (props.validators) props.validators.push(withoutDay ? 'dateMonthYear' : 'date');
	if (props.errorMessages) props.errorMessages.push('La date est incorrecte');

	let changeHandler = (e) => {
		let val = e.target.value;
		if (!shouldUpdate) return false;
		if (!withoutDay && val.length > 10) return false; // Limit to 10
		if (withoutDay && val.length > 7) return false; // Limit to 8 for dates without a day.
		val = shouldFormat ? formatDate(val, withoutDay) : val;
		props.onChange(props.name, val);
	};
	// let keyHandler    = (e, val) => {
	// 	// Handle browsers not properly supporting keyboard events
	// 	// An issue remains with older iOS versions
	// 	if ((typeof e.key !== 'undefined') && (e.key !== 'Unidentified')) {
	// 		shouldFormat = (e.key !== 'Backspace') && (e.key !== 'Delete');
	// 		shouldUpdate = Number.isInteger(parseInt(e.key, 10)) || (e.key === 'Backspace') || (e.key === 'Delete');
	// 	} else {
	// 		shouldFormat = false;
	// 		shouldUpdate = true;
	// 	}
	// };

	return !props.hidden ? <InputMask mask='99/99/9999' maskPlaceholder='DD/MM/YYYY' alwaysShowMask={true}
	                                  label={withoutDay ? 'MM/AAAA' : 'JJ/MM/AAAA'} {...newProps} onChange={changeHandler}
	                                  style={props.style}>
		{(newProps) => <StringField {...newProps}/>}
	</InputMask> : null;
};

DateField.propTypes = {
	style: PropTypes.string,
	onChange: PropTypes.func,
	hidden: PropTypes.bool,
	validators: PropTypes.array,
	errorMessages: PropTypes.array,
	withoutDay: PropTypes.bool
};

export default DateField;

export function formatDate(string) {
	// We need this sequence and not unified if's
	// if (string.length === 2) {
	// 	string += '/';
	// }
	// if (string.length > 2 && string[2] !== '/') {
	// 	string = (string.slice(0, 2) + '/' + string.slice(2));
	// }
	// if (string.length === 5) {
	// 	string += '/';
	// }
	// if (string.length > 5 && string[5] !== '/') {
	// 	string = string.slice(0, 5) + '/' + string.slice(5);
	// }

	return string;
}

// export function formatDate(string, withoutDay = false) {
// 	if (string.match(/^\d{2}$/) !== null) {
// 		return string + '/';
// 	} else if (string.match(/^\d{2}\/\d{2}$/) !== null && !withoutDay) {
// 		return string + '/';
// 	} else {
// 		return string;
// 	}
// };
