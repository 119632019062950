export const UPDATE_APPLICATION               = "UPDATE_APPLICATION";
export const ADD_BORROWER                     = "ADD_BORROWER";
export const LOAN_UPDATE                      = "LOAN_UPDATE";
export const RESIGN_DOCUMENTS                 = 'RESIGN_DOCUMENTS';
export const BORROWER_UPDATE                  = 'BORROWER_UPDATE';
export const COBORROWER_UPDATE                = 'COBORROWER_UPDATE';
export const INSURANCE_CALCULATION            = 'INSURANCE_CALCULATION';
export const INITIAL_CALCULATION              = 'INITIAL_CALCULATION';
export const UPDATED_PRICING                  = 'UPDATED_PRICING';
export const INITIAL_CALCULATION_NO_INSURANCE = 'INITIAL_CALCULATION_NO_INSURANCE';
export const REGISTRATION                     = 'REGISTRATION';
export const GET_UUID                         = 'FETCH_UUID';
export const JOB_INFO                         = 'JOB_INFO';
export const BRAVO_CONTACT                    = 'BRAVO_CONTACT';
export const BRAVO_DOCUMENTS                  = 'BRAVO_DOCUMENTS';
export const CONTRACT_DATA                    = 'CONTRACT_DATA';
export const AFFILIATE_DATA                   = 'AFFILIATE_DATA';
export const LOGIN                            = 'LOGIN';
export const ADD_LEAD                         = 'ADD_LEAD';
export const UPDATE                           = 'UPDATE';
export const DOCUMENTS                        = 'DOCUMENTS';
export const ATTACHMENTS                      = 'ATTACHMENTS';
export const SIGN                             = 'SIGN';
export const SETUP                            = 'SETUP';
export const BORROWER_INITIAL_FETCH           = 'BORROWER_INITIAL_FETCH';
export const COBORROWER_INITIAL_FETCH         = 'COBORROWER_INITIAL_FETCH';
export const LOAN_INITIAL_FETCH               = 'LOAN_INITIAL_FETCH';
export const HAS_SIGNED                       = 'HAS_SIGNED';
export const GET_COBORROWER                   = 'GET_COBORROWER';
export const RECOVER_APPICATION               = 'RECOVER_APPICATION';
export const CONFIG_UPDATE                    = 'CONFIG_UPDATE';
export const BORROWER_SET                     = 'BORROWER_SET';
export const COBORROWER_SET                   = 'COBORROWER_SET';
export const LOANS_SET                        = 'LOANS_SET';
export const RE_INITIALIZE_ALL                = 'RE_INITIALIZE_ALL';
export const CALCULATION_FETCH                = 'CALCULATION_FETCH';
export const COBORROWER_REMINDER              = 'COBORROWER_REMINDER';
export const ERROR_MODAL                      = 'ERROR_MODAL';
export const SET_STATE                        = 'SET_STATE';
export const BORROWER_IBAN                    = 'BORROWER_IBAN';
export const RELOAD_STATE                     = 'RELOAD_STATE';
export const COMPARE_INSURERS                 = 'COMPARE_INSURERS';
export const MNCAP_QUESTIONNAIRE              = 'MNCAP_QUESTIONNAIRE';
export const UPDATE_AFIESCA_IBAN              = 'UPDATE_AFIESCA_IBAN';
export const SMS_CONFIRMATION                 = 'SMS_CONFIRMATION';
export const CHECK_SMS_CODE                   = 'CHECK_SMS_CODE';
export const CHECK_LEMOINE                    = 'CHECK_LEMOINE';
export const UPDATE_INCOME_CUSTOMER           = 'UPDATE_INCOME_CUSTOMER';
export const INSURER_SPECIFICS                = 'INSURER_SPECIFICS';


