import {ATTACHMENTS} from '../../constants/action-types';
import {get, sendFile} from "js/helpers/rest";

import fileDownload from 'js-file-download';
import {getBackendUrl} from "js/helpers/functions";

const api_url               = getBackendUrl();
export const addAttachments = (params) => {
	let payload     = {};
	payload.body    = params || {};
	payload.headers = {
		'Content-Type': 'multipart/form-data'
	};
	return dispatch => {
		return dispatch(() => {
			return sendFile(payload, api_url + "/customer/attachments")
				.then(res => {
					let data               = {};
					data[params.get('id')] = res ? res.success : false;
					return dispatch({
						type: ATTACHMENTS,
						data: data,
						res,
					})
				})
		})
	}
};

export const downloadAttachment = (params) => {
	let payload          = {};
	payload.body         = {};
	payload.responseType = 'blob';

	return dispatch => {
		return dispatch(() => {
			return get(payload, api_url + "/customer/download/" + params.file)
				.then(res => {
					fileDownload(res, params.file);
				})
		})
	}
};

export const getAttachments = (params) => {
	let payload  = {};
	payload.body = params || {};

	return dispatch => {
		return dispatch(() => {
			return get(payload, api_url + "/customer/get_attachments")
				.then(res => {
					let data = {borrower: {}, coborrower: {}, documents_for_resigning: res.documents_for_resigning};
					res && res.borrower && res.borrower.forEach(file => {
						data.borrower[file.type] = {name: file.name, document_name: file.document_name};
					});
					res && res.coborrower && res.coborrower.forEach(file => {
						data.coborrower[file.type] = {name: file.name, document_name: file.document_name};
					});
					return dispatch({
						type: ATTACHMENTS,
						data: data
					})
				})
		})
	}
};
