import React from "react";
import {Row, Col} from 'react-grid-system';
import Translate from "components/CustomTranslate";
import {BsCloudDownload} from 'react-icons/bs'
import {TiTick} from 'react-icons/ti'

import './style.css';
import history from "../../../history";
import ReactTooltip from "react-tooltip";
import {StringField} from "../../../components/Form/Fields";
import FormButton from "../../../components/Form/FormButton";
import {ValidatorForm} from "react-material-ui-form-validator";
import Checkbox from "@material-ui/core/Checkbox";
import stepsImg1 from './stepsImg1.png';
import stepsImg2 from './stepsImg2.png';
import stepsImg3 from './stepsImg3.png';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import connect from "react-redux/es/connect/connect";
import Shell from "../../../components/Shell";
import {PersonalSpaceHeader} from "../../../components/Header/PersonalSpaceHeader";

class Steps extends React.Component {

	constructor(props) {
		super(props);
		this.state = {reminderSent: false};
	}

	getStep() {
		return 'personal.steps';
	}

	handleUploadButtonChange(e, id) {
		if (e.target.files[e.target.files.length - 1]) {
			let formData = new FormData();
			formData.append('id', id);
			formData.append('file', e.target.files[e.target.files.length - 1]);
			this.props.addAttachments(formData)
				.then(() => this.props.getAttachments({email: this.props.borrower.email}));
			e.target.value = null; //If needed to re-upload the same file
		}
	}

	transferredButton() {
		return (
			<div className='transferredButton'>
				<TiTick className="reactIcons tickIcon" size={20}/>Document téléchargé
			</div>
		)
	}

	stepsButton(document_type) {
		return (
			<div>
				<input hidden id={document_type} name={document_type} type="file" accept="pdf,jpeg,jpg,png" multiple
				       onChange={(e) => this.handleUploadButtonChange(e, document_type)}/>
				<label htmlFor={document_type} style={{borderColor: this.props.config.theme.primary, color: this.props.config.theme.primary}} className={'stepsButton'}>
					<BsCloudDownload className="reactIcons" size={20}/><Translate value={'generic.' + (!this.props.documents ? 'attach' : 'reattach')}/>
				</label>
			</div>
		)
	}

	render() {
		return (
			<div className="steps">
				<PersonalSpaceHeader
					contract={this.props.config.contract}
					signed={this.props.borrower.has_signed}
					health_selection={this.props.borrower.health_selection}
					showResigning={this.props.attachments.documents_for_resigning && this.props.attachments.documents_for_resigning.length > 0}
					title={'personal.steps.subHead'}/>
				<ReactTooltip style={{backgroundColor: this.props.config.theme.primary}} className="step2-tooltip"/>
				{this.props.config.contract && this.props.config.contract[0].coborrower_sign_link && <div>
					<Row>
						<Col md={12}>
							<h4 className='semiBold'>
								Le co-emprunteur n'a pas encore signé :
							</h4>
						</Col>
					</Row>
					<Row>
						<Col md={6}>
							<FormButton value='Connexion du co-emprunteur'
							            onClick={() => {
								            window.location.href = this.props.config.contract[0].coborrower_sign_link;
							            }}/>
						</Col>
						<Col md={6}>

							<FormButton value='Envoyer un lien au co-emprunteur'
							            disabled={(this.props.config.contract && this.props.config.contract[0].reminder_sent) || this.state.reminderSent}
							            onClick={() => {
								            this.setState({reminderSent: true});
								            this.props.sendCoborrowerReminder();
							            }}/>
						</Col>
					</Row>
				</div>}
				<Row className='fileRow'>
					<Col md={9}>
						<p className='name'><img src={stepsImg1} height="40px" className="stepsImg"/><Translate value='personal.steps.id_document' dangerousHTML/></p>
					</Col>
					<Col>
						{this.props.attachments.borrower.id_document ? this.transferredButton('id_document') : this.stepsButton('id_document')}
					</Col>
				</Row>

				{!!this.props.borrower.has_coborrower && (
					<div>
						<Row className='fileRow'>
							<Col md={9}>
								<p className='name'><img src={stepsImg1} height="40px" className="stepsImg"/><Translate value='personal.steps.id_document_co' dangerousHTML/></p>
							</Col>
							<Col>
								{this.props.attachments.borrower.id_document_co ? this.transferredButton('id_document_co') : this.stepsButton('id_document_co')}
							</Col>
						</Row>

					</div>
				)}

				<Row className='fileRow'>
					<Col md={9}>
						<p className='name'><img src={stepsImg2} height="40px" className="stepsImg"/><Translate value='personal.steps.contract' dangerousHTML/></p>
					</Col>
					<Col>
						{this.props.attachments.borrower.contract ? this.transferredButton('contract') : this.stepsButton('contract')}
					</Col>
				</Row>

				<Row className='fileRow'>
					<Col md={9}>
						<p className='name'><img src={stepsImg3} height="40px" className="stepsImg"/><Translate value='personal.steps.other_documents' dangerousHTML/></p>
					</Col>
					<Col>
						{this.props.attachments.borrower.other ? this.transferredButton('other') : this.stepsButton('other')}
					</Col>
				</Row>
				{this.props.config.contract && !this.props.attachments.borrower.sepa_mandate &&
					<ValidatorForm onSubmit={async () => {
					    let sepa = 'sepa_mandate';
					    this.props.updateConfig('loading', true);
					    if (this.props.loan.flow === 'afi-esca') {
					        await this.props.updateAfiescaIban({borrower: this.props.borrower, coborrower: this.props.coborrower})
					    }
					    await this.props.updateCustomer({borrower: {payment_iban: this.props.borrower.payment_iban, email: this.props.borrower.email, bic: this.props.borrower.bic}})
					    await this.props.documents({
					        flow: this.props.loan.flow,
					        personal_space: true,
					        templates: [sepa]
					    });
					    await this.props.sign({
					        borrower: this.props.borrower,
					        documents: this.props.config.documents
					    })
					    this.props.updateConfig('loading', false);
					    history.push('/personal_sign');
					}}>
					    <Row className='ibanRow' style={{color: this.props.config.theme.primary}}>
					        <Col md={12}>
					            <h3>Je complète mon IBAN et signe mon mandat SEPA</h3>
					        </Col>
					        <Col md={12}>
					            <p><Translate value='personal.steps.ibanSubtitle'/></p>
					        </Col>
					        <Col md={5}>
					            <StringField
					                variant="outlined"
					                validators={['required', 'IBAN', 'hasNoWhitespace']}
					                errorMessages={['Cette information doit être renseignée', 'IBAN incorrect', 'Saisir votre IBAN sans espaces']}
					                label={<Translate value='personal.steps.yourIban'/>}
					                placeholder="FRxx xxxx xxxx xxxx xxxx xxxx xxx"
					                onChange={(e) => this.props.updateBorrower('payment_iban', e.target.value)}
					                value={this.props.borrower.payment_iban}
					                margin="normal"
					            />
					        </Col>
					    </Row>
					    <Row>
					        <Col md={4}>

					            <StringField
					                variant="outlined"
					                validators={['required', 'hasNoWhitespace']}
					                errorMessages={['Cette information doit être renseignée', 'Saisir votre BIC sans espaces']}
					                label='Votre BIC'
					                placeholder="BNPAFRPPXXX"
					                onChange={(e) => this.props.updateBorrower('bic', e.target.value)}
					                value={this.props.borrower.bic}
					                margin="normal"
					            />
					        </Col>
					    </Row>
					    <Row>
					        <Col md={4}>
					            <FormButton value={<Translate value='personal.steps.ibanButton'/>}/>
					        </Col>
					    </Row>

					    <Row>
					        <Col md={12}>
					            <p>
					                <FormControlLabel
					                    control={
					                        <Checkbox
					                            checked={this.props.borrower.is_loan_iban}
					                            style={{color: this.props.config.theme.primary}}
					                            onChange={(e, val) => this.props.updateBorrower('is_loan_iban', val)}
					                        />
					                    } className="blueLabel"
					                    label={<Translate value='personal.steps.ibanCheckbox' dangerousHTML/>}
					                />
					            </p>
					        </Col>
					    </Row>
					    <Row>
					        <Col md={12}>
					            <p>
					                <FormControlLabel
					                    control={
					                        <Checkbox
					                            checked={this.props.borrower.different_sepa}
					                            style={{color: this.props.config.theme.primary}}
					                            onChange={(e, val) => this.props.updateBorrower('different_sepa', val)}
					                        />
					                    } className="blueLabel"
					                    label={<Translate value='personal.steps.different_sepa' dangerousHTML/>}
					                />
					            </p>
					        </Col>
					    </Row>
					</ValidatorForm>}
				{!!this.props.borrower.is_loan_iban && (
					<ValidatorForm onSubmit={() => {
						this.props.updateConfig('loading', true);
						this.props.updateCustomer({borrower: {loan_iban: this.props.borrower.loanIban}})
							.then(() => this.props.updateConfig('loading', false));
					}}>
						<Row className='ibanRow'>
							<Col md={5}>
								<StringField
									variant="outlined"
									validators={['required', 'IBAN', 'hasNoWhitespace']}
									errorMessages={['Cette information doit être renseignée', 'IBAN incorrect', 'Saisir votre IBAN sans espaces']}
									label={<Translate value='personal.steps.loanIban'/>}
									placeholder="FRxx xxxx xxxx xxxx xxxx xxxx xxx"
									onChange={(e) => this.props.updateBorrower('loanIban', e.target.value)}
									value={this.props.borrower.loanIban}
									margin="normal"
								/>
							</Col>
							<Col md={5}/>
							<Col md={4}>
								<FormButton value={<Translate value='personal.steps.confirmIbanButton'/>}/>
							</Col>
						</Row>
					</ValidatorForm>
				)}

				<Row className="stepsInfo">
					<Col md={12}>
						<p className='infoText'><Translate value='personal.steps.bankInfo'/></p>
					</Col>
					<Col md={12}>
						<p style={{borderTop: '1px solid rgba(30, 32, 83, 0.18)', color: '#2e305f', paddingTop: '16px'}}>
							<Translate value='personal.steps.effectiveDate' effectiveDate={
								this.props.config.contract &&
								this.props.config.contract.length > 0 &&
								this.props.config.contract[0].effective_date.split('T')[0]
							}/>
						</p>
					</Col>
				</Row>
			</div>
		)
	}
}

export default connect()(Shell(Steps));
