import React from 'react';
import Shell from '../../components/Shell';
import {Row, Col} from 'react-grid-system';
import FormButton from '../../components/Form/FormButton';
import Translate from "components/CustomTranslate";
import connect from 'react-redux/es/connect/connect';
import history from '../../history';
import Switch from "react-switch";
import ReactTooltip from 'react-tooltip';
import BackButton from "../../components/Form/BackButton";
import './style.css';
import {Profession, RiskyProfession, RiskyProfessionAfiesca, Sports, ExactProfession, Smoker, ExactSport, ExactSportOptions} from '../../components/Form/StepsFields/step5';
import {getBackendUrl} from "../../js/helpers/functions";
import {post} from "../../js/helpers/rest";
import {CustomValidatorForm} from "../../components/Form";
import Modal from "../../components/Modal";
import StandardButton from "../../components/Form/StandardButton";
import {Button} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import style from "../../components/Offer/style.module.css";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {StringField} from "../../components/Form/Fields";
import DatePickerDialog from "../../components/Form/Fields/DatePickerDialog";

var I18n = require('react-redux-i18n').I18n;

class ProfessionalInfo extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			dialogOpen: false,
			source: sessionStorage.getItem('utm_source'),
			source_tracked: false,
			declaration: false,
			coborrower_declaration: false,
			click_id: sessionStorage.getItem('click_id'),
			specifics: (this.props.config.insurer_specifics || []).map(e => e.cpdRefProperty)
		};

		if (this.state.click_id) {
			post({
				email: this.props.borrower.email,
				first_name: this.props.borrower.first_name,
				last_name: this.props.borrower.last_name,
				type: 'click_update',
				click_id: this.state.click_id
			}, getBackendUrl() + "/config/track")
				.then(() => {
					sessionStorage.removeItem('click_id');
				})
				.catch((e) => console.warn('Click update tracking issue:', e));
		}
	}

	getStep() {
		return 'step5';
	}

	professionalData(coBorrower = false) {
		let foreign_travel_translation = <Translate value={'step5.afiesca_political'} className='profession-question' dangerousHTML/>;
		if (this.props.loan.flow === 'IPTIQ') {
			foreign_travel_translation = 'Séjour planifié hors UE (>60 jours dans les 12 prochains mois) ?';
		} else if (this.props.loan.flow === 'MAESTRO') {
			foreign_travel_translation = 'Envisagez-vous de séjourner pour des raisons professionnelles en dehors de l\'Union européenne et AELE (Islande, Liechtenstein, Norvège, Suisse), Royaume-Uni, Amérique du Nord, Japon, Australie, Nouvelle Zélande, Hong-Kong, Singapour pour une durée supérieure à 60 jours consécutifs ?'
		} else if (this.props.loan.flow === 'AVENIRNAOASSUR') {
			foreign_travel_translation = 'Séjours professionnels à l\'étranger (hors Union Européenne, Etats Unis, Canada) pour une durée supérieure à 60 jours consécutifs ou dans un pays déconseillé par le ministère français des Affaires étrangères?'
		}
		console.log(this.props.coborrower.lemoine, 123123, this.props.loan.flow)
		let lemoineEligible     = this.props.borrower.lemoine && this.props.borrower.lemoine.eligible[this.props.loan.flow];
		let coboLemoineEligible = this.props.coborrower.lemoine && this.props.coborrower.lemoine.eligible && this.props.coborrower.lemoine.eligible[this.props.loan.flow];


		let update       = coBorrower ? this.props.updateCoBorrower : this.props.updateBorrower;
		let data         = coBorrower ? this.props.coborrower : this.props.borrower;
		let afiesca_data = this.props.config.risky_profession_afiesca.filter(p => p.category_code === data.risky_profession);
		return (
			<div style={{marginBottom: '50px'}}>
				<ReactTooltip style={{backgroundColor: this.props.config.theme.primary}} className="step2-tooltip"/>
				<h3>
					<Translate value={coBorrower ? 'step5.coSubtitle' : 'step5.subtitle'}/>
				</h3>
				<hr className='separator'/>
				<Row justify='space-between' sm={3} style={{marginBottom: '20px'}}>
					<Col sm={3} md={6} className="profession-selector">
						<Profession id={coBorrower ? 'cobo-profession' : 'profession'} data={data}
						            profession={this.props.config.professions.filter(p => p.flow === this.props.loan.flow)} update={update}/>
					</Col>
					<Col sm={3} md={6} className="profession-selector">
						<RiskyProfession id={coBorrower ? 'cobo-RiskyProfessionMultiple' : 'RiskyProfessionMultiple'} data={data}
						                 flow={this.props.loan.flow}
						                 risky_profession={this.props.config.risky_professions.filter(p => p.flow === this.props.loan.flow)}
						                 update={update}
						/>
					</Col>
				</Row>
				{this.props.loan.flow !== 'afi-esca' && <Row>
					<Col md={6} sm={3} className="profession-selector">
						<ExactProfession id={coBorrower ? 'cobo-exact-profession' : 'exact-profession'} data={data} update={update}/>
					</Col>
				</Row>}
				{['afi-esca', 'mncap'].indexOf(this.props.loan.flow) === -1 && !coBorrower && <Row>
					<Col md={6} sm={3} className="profession-selector">
						<StringField value={this.props.borrower.bank_reference_id}
						             validators={['required']}
						             errorMessages={['Cette information doit être renseignée']}
						             onChange={(e) => update('bank_reference_id', e.target.value)}
						             label={'Référence du prêt (auprès de l\'organisme prêteur)'}/>
					</Col>
					{/*<Col md={6} sm={3} className="profession-selector">*/}
					{/*	/!*minStringLength, maxStringLength don't work correctly with null value, so string, even if empty must be passed*!/*/}
					{/*	<StringField value={this.props.borrower.payment_iban || ''}*/}
					{/*	             placeholder="FRxx xxxx xxxx xx** **** **** ***"*/}
					{/*	             validators={['required', 'minStringLength:14', 'maxStringLength:14']}*/}
					{/*	             errorMessages={['Cette information doit être renseignée', 'Merci de bien vouloir saisir les 14 premiers caractères de votre IBAN']}*/}
					{/*	             onChange={(e) => update('payment_iban', e.target.value)}*/}
					{/*	             label={'Merci de saisir les 14 premiers caractères de votre IBAN'}/>*/}
					{/*</Col>*/}
				</Row>}
				{this.props.loan.flow !== 'afi-esca' &&
					<div>
					    <Translate value="step5.professionalActivityQuestions" dangerousHTML/>
					    <ul>
					        <div className="profession-question-wrapper">
					            <li className="profession-question">
					                <Translate value="step5.workAtHeight" dangerousHTML/>
					            </li>
					            <Switch
					                id="high_work"
					                name="high_work"
					                checked={data.high_work}
					                offColor="#7f97b0"
					                onColor="#0ddc91"
					                uncheckedIcon=""
					                checkedIcon=""
					                onChange={() => update('high_work', !(data.high_work))}
					            >
					            </Switch>
					        </div>
					        <div className="profession-question-wrapper">
					            <li className="profession-question">
					                <Translate value="step5.travelLotsForWork" dangerousHTML/>
					            </li>
					            <Switch
					                id="vehicle_travel"
					                name="vehicle_travel"
					                checked={data.vehicle_travel}
					                offColor="#7f97b0"
					                onColor="#0ddc91"
					                uncheckedIcon=""
					                checkedIcon=""
					                onChange={() => update('vehicle_travel', !(data.vehicle_travel))}
					            >
					            </Switch>
					        </div>
					        {this.state.specifics.indexOf('workRisk') !== -1 && <div className="profession-question-wrapper">
					            <li className="profession-question">
					                <Translate value="step5.lightManualWork" dangerousHTML/>
					            </li>
					            <Switch
					                id="manual_work"
					                name="manual_work"
					                checked={data.manual_work}
					                offColor="#7f97b0"
					                onColor="#0ddc91"
					                uncheckedIcon=""
					                checkedIcon=""
					                onChange={() => update('manual_work', !(data.manual_work))}
					            >
					            </Switch>
					        </div>}
					        <div className="profession-question-wrapper">
					            <li className="profession-question">
					                <Translate value="step5.dangerousProductWork" dangerousHTML/>
					            </li>
					            <Switch
					                id="dangerous_products_work"
					                name="dangerous_products_work"
					                checked={data.dangerous_products_work}
					                offColor="#7f97b0"
					                onColor="#0ddc91"
					                uncheckedIcon=""
					                checkedIcon=""
					                onChange={() => update('dangerous_products_work', !(data.dangerous_products_work))}
					            >
					            </Switch>
					        </div>
					        <div className="profession-question-wrapper">
					            <li className="profession-question">
					                <Translate value={this.props.loan.flow === 'mncap' ? 'step5.mncapIsPolitical' : 'step5.' + (coBorrower ? 'isPoliticalCo' : 'isPolitical')}
									           className="profession-question" dangerousHTML/>
					            </li>
					            <Switch
					                id='is_political'
					                name='is_political'
					                checked={data.is_political}
					                offColor="#7f97b0"
					                onColor="#0ddc91"
					                uncheckedIcon=""
					                checkedIcon=""
					                onChange={() => update('is_political', !(data.is_political))}
					            >
					            </Switch>
					        </div>
					        {this.state.specifics.indexOf('closeFamillyPoliticallyExposed') !== -1 && <div className="profession-question-wrapper">
					            <li className="profession-question">
									Proche d'une personne politiquement exposée? &nbsp;
					            </li>
					            <Switch
					                id='close_family_political'
					                name='close_family_political'
					                checked={data.close_family_political}
					                offColor="#7f97b0"
					                onColor="#0ddc91"
					                uncheckedIcon=""
					                checkedIcon=""
					                onChange={() => update('close_family_political', !(data.close_family_political))}
					            >
					            </Switch>
					        </div>}
					        {this.state.specifics.indexOf('previouslyAcceptedWithSpecificities') !== -1 && <div className="profession-question-wrapper">
					            <li className="profession-question">
									Le contrat de l'assuré a-t-il fait l'objet d'une surprime, exclusions ou de restrictions pour motif de santé? &nbsp;
					            </li>
					            <Switch
					                id='previously_accepted'
					                name='previously_accepted'
					                checked={data.previously_accepted}
					                offColor="#7f97b0"
					                onColor="#0ddc91"
					                uncheckedIcon=""
					                checkedIcon=""
					                onChange={() => update('previously_accepted', !(data.previously_accepted))}
					            >
					            </Switch>
					        </div>}
					        {this.state.specifics.indexOf('travelRisquedCountry') !== -1 && <Row style={{marginBottom: '20px'}}>
					            <Col md={8}>
					                <li className="profession-question">
					                    {foreign_travel_translation}
					                </li>
					            </Col>
					            <Col md={4}>
					                <Switch
					                    id='foreign_travel'
					                    name='foreign_travel'
					                    checked={data.foreign_travel}
					                    offColor="#7f97b0"
					                    onColor="#0ddc91"
					                    uncheckedIcon=""
					                    checkedIcon=""
					                    onChange={() => update('foreign_travel', !(data.foreign_travel))}
					                >
					                </Switch>
					            </Col>
					        </Row>}
					        <Row justify="center" style={{marginBottom: '20px'}}>
					            <Col md={4}>
					                <li className="profession-question">
					                    <Translate value={'step5.afiescaSmoker'} className='profession-question' dangerousHTML/>
					                </li>
					            </Col>
					            <Col md={4}>
					                <Smoker data={data} update={update}/>
					            </Col>
					            <Col md={4}/>
					        </Row>
					        {this.props.loan.flow !== 'afi-esca' && this.state.specifics.indexOf('sportActivitieDetail') !== -1 &&
								<>
								    <div className="profession-question-wrapper">
								        <li className="profession-question">
								            <Translate value='step5.sports_cover' className='profession-question' dangerousHTML/>
								        </li>
								        <Switch
								            id='sports_cover'
								            name='sports_cover'
								            checked={data.sports_cover}
								            offColor="#7f97b0"
								            onColor="#0ddc91"
								            uncheckedIcon=""
								            checkedIcon=""
								            onChange={() => update('sports_cover', !(data.sports_cover))}
								        >
								        </Switch>
								    </div>

								    {this.props.borrower.sports_cover && <Row>
								        <Col md={5.5}>
								            {this.props.config.insurer_sport_risks && this.props.config.insurer_sport_risks.length > 0 ?
								                <ExactSportOptions id={coBorrower ? 'cobo-sport_risk' : 'sport_risk'} data={data} update={update}
												                   exact_sports={this.props.config.insurer_sport_risks}/> :
								                <ExactSport id={coBorrower ? 'cobo-profession' : 'profession'} data={data}
												            profession={this.props.config.professions.filter(p => p.flow === this.props.loan.flow)} update={update}/>}
								        </Col>
								    </Row>}
								</>}

					        {this.state.specifics.indexOf('loanStartInitialDate') !== -1 && this.props.loan.loan_status !== 'new' && <Row>
					            <Col md={5.5}>
					                <li>
					                    <DatePickerDialog
					                        name='residence_in_belgium'
					                        label={'Date de 1ère échéance du prêt ?'}
					                        placeholder='JJ/MM/AAAA'
					                        value={data.loan_initial_start_date}
					                        validators={['required', 'isItRealDate']}
					                        className={'extraLongLabel'}
					                        // fromYear={moment().subtract(85, 'years').year()}
					                        // toYear={moment().subtract(19, 'years').year()}
					                        update={update}
					                        updateField='loan_initial_start_date'
					                        errorMessages={[I18n.t('step1.errors.needsToBeFilled'), I18n.t('step1.errors.incorrectDate')]}
					                    />
					                </li>
					            </Col>

					        </Row>}
					    </ul>
					</div>
				}
				{this.props.loan.flow === 'afi-esca' &&
					<Row justify='space-between' style={{marginBottom: '20px'}}>
					    <Col md={6} sm={3} className="profession-selector">
					        <Sports data={data} sports={this.props.config.sports} update={update} id={coBorrower ? 'cobo-sports' : 'sports'}/>
					    </Col>
					    <Col md={6} sm={3} className="profession-selector">
					        <ExactProfession id={coBorrower ? 'cobo-exact-profession' : 'exact-profession'} data={data} update={update}/>
					    </Col>
					    {afiesca_data.length > 0 &&
							<Col md={5.5} sm={3} className="profession-selector">
							    <RiskyProfessionAfiesca data={data} risky_profession_afiesca={afiesca_data} update={update}/>
							</Col>
					    }
					</Row>
				}


				{this.props.loan.flow === 'afi-esca' &&
					<>
					    <Row className="profession-question-wrapper">
					        <Col md={8}>
					            <Translate value={'step5.manual_work'} className="profession-question" dangerousHTML/>
					        </Col>
					        <Col md={4}>
					            <Switch
					                id='manual_work'
					                name='manual_work'
					                checked={data.manual_work}
					                offColor="#7f97b0"
					                onColor="#0ddc91"
					                uncheckedIcon=""
					                checkedIcon=""
					                onChange={() => update('manual_work', !(data.manual_work))}
					            >
					            </Switch>
					        </Col>
					    </Row>
					    <Row justify="center" style={{marginBottom: '20px'}}>
					        <Col md={8}>
					            <Translate value={'step5.afiescaSmoker'} className='profession-question' dangerousHTML/>
					        </Col>
					        <Col md={4}>
					            <Smoker data={data} update={update}/>
					        </Col>
					    </Row></>
				}
				{this.props.loan.flow !== 'mncap' &&
					<div>
					    {this.props.loan.flow === 'afi-esca' && <Row justify="center" style={{marginBottom: '20px'}}>
					        <Col md={8}>
					            <Translate value={'step5.foreign_country'} className='profession-question' dangerousHTML/>
					        </Col>
					        <Col md={4}>
					            <Switch
					                id='foreign_country'
					                name='foreign_country'
					                checked={data.foreign_country}
					                offColor="#7f97b0"
					                onColor="#0ddc91"
					                uncheckedIcon=""
					                checkedIcon=""
					                onChange={() => update('foreign_country', !(data.foreign_country))}
					            >
					            </Switch>
					        </Col>
					    </Row>}
					</div>
				}
				{((lemoineEligible && !coBorrower) || (coboLemoineEligible && coBorrower)) && <Row className="profession-question-wrapper">
					<Col md={8}>
						<FormControlLabel
							id="acceptCheckbox"
							control={
								<Checkbox
									name="CheckBox"
									checked={data.minimal_insured_amount}
									onChange={(e, s) => {
										update('minimal_insured_amount', s);
									}}
									className={`${style.acceptCheckbox}`}
									color="white"

								/>
							}
							label={<p><Translate value={'step5.insured_amount_law.checkbox_text'} className="profession-question" dangerousHTML/><span
								data-tip={I18n.t("step5.insured_amount_law.checkbox_tip")}><i className={`fa fa-info`} style={{color: '#3451b9'}}/></span></p>}
						/>
					</Col>
				</Row>}
				{this.props.loan.flow === 'mncap' && this.props.loan.loan_status !== 'new' && lemoineEligible &&
					<Row className="profession-question-wrapper">
					    <Col md={8}>
					        <FormControlLabel
					            id="acceptCheckbox"
					            control={
					                <Checkbox
					                    name="CheckBox"
					                    checked={data.insured_by_bank}
					                    onChange={(e, s) => {
					                        update('insured_by_bank', s);
					                    }}
					                    className={`${style.acceptCheckbox}`}
					                    color="white"

					                />
					            }
					            label={<p>Mon prêt n'est pas assuré par ma banque ou ses partenaires <span data-tip={I18n.t("step5.insured_amount_law.checkbox_tip")}> <i className={`fa fa-info`}
								                                                                                                                                          style={{color: '#3451b9'}}/></span></p>}
					        />
					    </Col>
					</Row>}
			</div>
		);
	}

	mapData(b, cb) {
		let data = {
			borrower:
				{
				    email: b.email,
				    profession: b.profession,
				    risky_profession: b.risky_profession,
				    smoker: !!b.smoker,
				    is_political: b.is_political,
				    close_family_political: b.close_family_political,
				    previously_accepted: b.previously_accepted,
				    step: 'profession',
				    sport: b.sports_cover,
				    dangerous_products_work: b.dangerous_products_work,
				    vehicle_travel: b.vehicle_travel,
				    high_work: b.high_work,
				    exact_sport: b.exact_sport,
				    manual_work: b.manual_work,
				    bank_reference_id: b.bank_reference_id,
				    payment_iban: b.payment_iban
				},
			state: {
				borrower: this.props.borrower
			}
		};
		if (b.has_coborrower) {
			data.has_coborrower   = true;
			data.coborrower       = {
				profession: cb.profession,
				risky_profession: cb.risky_profession,
				smoker: !!cb.smoker,
				is_political: cb.is_political,
				close_family_political: cb.close_family_political,
				previously_accepted: cb.previously_accepted,
				sport: cb.sports_cover,
				dangerous_products_work: cb.dangerous_products_work,
				vehicle_travel: cb.vehicle_travel,
				high_work: cb.high_work,
				exact_sport: cb.exact_sport,
				manual_work: cb.manual_work,
			};
			data.state.coborrower = this.props.coborrower;
		}
		return data;
	}

	updateAfiescaProfession(borrower, update) {
		let risky_profession       = this.props.config.risky_professions.find(p => p.key === borrower.risky_profession);
		let risky_profession_extra = this.props.config.risky_profession_afiesca.find(p => p.key === borrower.risky_profession_afiesca);
		if (risky_profession) {
			let afiesca_id = risky_profession_extra ? risky_profession_extra.afiesca_id : risky_profession.afiesca_id;
			update('risky_profession_afiesca_id', afiesca_id);
		}
		update('profession_csp_afiesca', this.props.config.professions.find(p => p.key === borrower.profession).csp);
	}

	saveLemoine() {
		let params     = this.props.borrower.lemoine;
		params.outcome = !!this.props.borrower.minimal_insured_amount;
		this.props.updateBorrower("lemoine", params);
		this.props.saveLemoine(params);
		if (this.props.borrower.has_coborrower) {
			let coborrower_lemoine     = Object.assign({}, params);
			coborrower_lemoine.outcome = this.props.coborrower.minimal_insured_amount;
			this.props.updateCoBorrower("lemoine", coborrower_lemoine);
			this.props.saveLemoine(coborrower_lemoine, true);
		}
	}

	updateCustomer() {
		let data = this.mapData(this.props.borrower, this.props.coborrower);
		return this.props.updateCustomer(data)
			.then(async response => {
				if (response && response.data && response.data.result) {
					if (this.props.loan.flow === 'afi-esca') {
						if (this.props.borrower.risky_profession === 'AUCUNE_ACTIVITE_SPECIFIQUE') {
							this.props.updateBorrower('risky_profession_afiesca', false);
						}
						this.updateAfiescaProfession(this.props.borrower, this.props.updateBorrower);
						if (this.props.borrower.has_coborrower) this.updateAfiescaProfession(this.props.coborrower, this.props.updateCoBorrower);
					}
					if (this.props.translations.locale === 'fr') {
						this.saveLemoine();
						if (this.props.borrower.lemoine.outcome || this.props.loan.flow !== 'afi-esca') {
							history.push('/resume');
						} else {
							history.push('/sante');
						}

					} else {
						return history.push('/soon')
					}
				}
			});
	}

	render() {
		return <>
			<Row justify="center">
				<Col md={10} className="incomeRow">
					{this.pixels()}
					<CustomValidatorForm onSubmit={() => {
						if (
							(this.props.borrower.lemoine && this.props.borrower.lemoine.eligible[this.props.loan.flow] && this.props.borrower.minimal_insured_amount) ||
							(this.props.coborrower.lemoine && this.props.coborrower.lemoine.eligible && this.props.coborrower.lemoine.eligible[this.props.loan.flow] && this.props.coborrower.minimal_insured_amount)
						) {
							this.setState({dialogOpen: true})
						} else {
							this.updateCustomer();
						}
					}}>
						<ReactTooltip style={{backgroundColor: this.props.config.theme.primary}} className="step2-tooltip"/>

						{this.professionalData(false)}
						{!!this.props.borrower.has_coborrower && this.professionalData(true)}
						<Row justify="center">
							<Col md={4} xs={12} sm={12}>
								<BackButton flow={this.props.loan.flow}/>
							</Col>
							<Col md={4}>
								<FormButton value={<Translate value='generic.follow'/>} name={'submitButtonProfessions'}/>
							</Col>
						</Row>
						<Modal title={<Translate value="step5.insured_amount_law.popup_header"/>}
						       dialogOpen={this.state.dialogOpen}
						       config={this.props.config}
						       onClose={() => this.setState({dialogOpen: false})}>
							<Row justify="center">
								<Col md={12}>
									<Checkbox
										name="CheckBox"
										value={this.state.declaration}
										onChange={(e, s) => {
											this.setState({declaration: s})
										}}
										color="white"

									/>
									{(this.props.borrower.has_coborrower && this.props.borrower.minimal_insured_amount && this.props.coborrower.minimal_insured_amount) ?
										<Translate value="step5.insured_amount_law.both_popup_text" borrower_first_name={this.props.borrower.first_name} borrower_last_name={this.props.borrower.first_name}
										           coborrower_first_name={this.props.coborrower.first_name} coborrower_last_name={this.props.coborrower.last_name}/> :
										<Translate value="step5.insured_amount_law.popup_text" first_name={this.props.borrower.minimal_insured_amount ? this.props.borrower.first_name : this.props.coborrower.first_name}
										           last_name={this.props.borrower.minimal_insured_amount ? this.props.borrower.last_name : this.props.coborrower.last_name}/>
									}
								</Col>
							</Row>
							<Row justify="center" style={{marginTop: '30px'}}>
								<Col md={12}>
									<Checkbox
										name="CheckBox"
										value={this.state.declaration}
										onChange={(e, s) => {
											this.setState({declaration: s})
										}}
										color="white"

									/>
									{(this.props.borrower.has_coborrower && this.props.borrower.minimal_insured_amount && this.props.coborrower.minimal_insured_amount) ?
										<Translate value="step5.insured_amount_law.both_checkbox_declaration" borrower_first_name={this.props.borrower.first_name} borrower_last_name={this.props.borrower.first_name}
										           coborrower_first_name={this.props.coborrower.first_name} coborrower_last_name={this.props.coborrower.last_name}/> :
										<Translate value="step5.insured_amount_law.checkbox_declaration"
										           first_name={this.props.borrower.minimal_insured_amount ? this.props.borrower.first_name : this.props.coborrower.first_name}
										           last_name={this.props.borrower.minimal_insured_amount ? this.props.borrower.last_name : this.props.coborrower.last_name}/>
									}
								</Col>
							</Row>
							<Row justify="center" style={{marginTop: '30px'}}>
								<Col md={6}>
									<Button style={{maxWidth: '230px', color: '#fff', backgroundColor: this.props.config.theme.primary}} onClick={() => {
										this.setState({dialogOpen: false})
									}} className='formButton backButton'><Translate value="generic.back"/></Button>
								</Col>
								<Col md={6}>
									<StandardButton style={{maxWidth: '230px', backgroundColor: this.state.declaration ? 'red' : 'blue'}} onClick={() => {
										if (this.state.declaration) {
											if (this.props.borrower.minimal_insured_amount) {
												this.saveLemoine();
												history.push('/programme_bien_etre');
											} else {
												this.updateCustomer();
											}

											//TODO: Comment all of this out when MNCAP deployes lemoine
											//
											// if (this.props.loan.flow === 'afi-esca') {
											// 	history.push('/programme_bien_etre');
											// } else {
											// 	this.props.updateLoan('flow', 'afi-esca');
											// 	this.props.updateCustomer(this.mapData(this.props.config.isCoborrower ? this.props.coborrower : this.props.borrower, 'afi-esca'))
											// 	this.props.afiEsca(mapData(Object.assign({}, this.props.borrower, {
											// 		step: 'resume',
											// 		health_selection: false
											// 	}), this.props.coborrower, this.props.loan))
											// 	history.push('/migrate');
											// }
											// // if (this.props.loan.flow === 'afi-esca') {
											// history.push('/programme_bien_etre');
											// // } else {
											// // 	this.props.updateLoan('flow', 'afi-esca');
											// // 	this.props.updateCustomer(this.mapData(this.props.config.isCoborrower ? this.props.coborrower : this.props.borrower, 'afi-esca'))
											// // 	this.props.afiEsca(mapData(Object.assign({}, this.props.borrower, {
											// // 		step: 'resume',
											// // 		health_selection: false
											// // 	}), this.props.coborrower, this.props.loan))
											// // 	history.push('/migrate');
											// // }

										} else {
											this.setState({dialogOpen: false});
										}
									}} value={<Translate value="step5.insured_amount_law.popup_continue"/>}/>
								</Col>
							</Row>
						</Modal>
						{this.props.loan.flow === 'afi-esca' &&
							<Row justify='center'>
							    <Col md={12}>
							        <p style={{fontSize: '10px', textAlign: 'center'}}><sup>*</sup>de l'Union Européenne, du Royaume-Uni, de la Suisse, des Etats-Unis, du Japon, du
										Canada, de
										la Norvège, de l'Islande, de l'Australie et de la Nouvelle Zélande</p>
							    </Col>
							</Row>}
					</CustomValidatorForm>
				</Col>
			</Row></>
	}

	pixels() {
		let source = sessionStorage.getItem('utm_source');
		if (source === 'swarmiz') {
			return <img src="https://affdjz.go2cloud.org/aff_l?offer_id=4343" onLoad={() => sessionStorage.removeItem('utm_source')} width="1" height="1"/>;
		} else if (source === 'taboola') {
			window._tfa = window._tfa || [];
			window._tfa.push({notify: 'event', name: 'page_view', id: 1320633});
			let i = function (t, f, a, x) {
				if (!document.getElementById(x)) {
					t.async  = 1;
					t.src    = a;
					t.id     = x;
					t.onload = () => sessionStorage.removeItem('utm_source');
					f.parentNode.insertBefore(t, f);
				}
			};
			i(document.createElement('script'), document.getElementsByTagName('script')[0], '//cdn.taboola.com/libtrc/unip/1320633/tfa.js', 'tb_tfa_script');
			return <noscript><img src='https://trc.taboola.com/1320633/log/3/unip?en=page_view' onLoad={() => sessionStorage.removeItem('utm_source')} width='0' height='0'
			                      style={{'display': 'none'}}/></noscript>;
		} else if (source === 'ocead') {
			return <img src={`https://track.oadstrack.com/?e=2&o=170&leadid=&lead_email=${this.props.borrower.email}`} onLoad={() => sessionStorage.removeItem('utm_source')}
			            width="1" height="1"/>;
		} else if (source === 'natexo') {
			let script    = document.createElement('script');
			script.type   = 'text/javascript';
			script.src    = "https://d.natexo-programmatic.com/compiled/LdrEcJs.js?co=fr&uid=769&e=" + this.props.borrower.email;
			script.onload = () => sessionStorage.removeItem('utm_source');
			script.async  = true;
			document.body.appendChild(script);
		}
		// Weedoit moved to PersonalInfo Page
		// } else if (source === 'weedoit') {
		// 	return <iframe src={`https://tracker-l2.wee-do-it.net/track/lead?idcpart=18081&idr=${this.state.click_id}`} width="0" height="0" frameBorder="0"
		// 	               scrolling="no" onLoad={() => sessionStorage.removeItem('utm_source')}></iframe>;
		// }
	}

}

export default connect()(Shell(ProfessionalInfo));
