import React from 'react';
import Shell from '../../components/Shell';
import {connect} from 'react-redux';
import {Container, Row, Col} from "react-grid-system";

import Translate from "components/CustomTranslate";
import StandardButton from "../../components/Form/StandardButton";

class NotInYourCountry extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			buttonClicked: false,
		}
	}


	getStep() {
		return 'not_in_your_country';
	}

	render() {

		return (
			<Container style={{color: 'rgb(83, 114, 227)', textAlign: 'center'}}>
				<Row justify="center">
					<Col md={6}>
						<h2>
							<Translate value="not_in_your_country.title"/>
						</h2>
					</Col>
				</Row>
				<Row justify="center">
					<Col md={6}>
						<h3>
							<Translate value="not_in_your_country.subtitle"/>
						</h3>
					</Col>
				</Row>
				<Row justify="center">
					<Col md={6}>
						<p>
							<Translate value="not_in_your_country.text"/>
						</p>
					</Col>
				</Row>
				<Row justify="center">
					<Col md={4}>
						<p>
							{this.state.buttonClicked ? 'Ще ви държим в течение!' : <StandardButton config={this.props.config} onClick={() => this.setState({buttonClicked: true})} value={<Translate value="generic.keepInTouch"/>} />}
						</p>
					</Col>
				</Row>
			</Container>
		)
	}

}

export default connect()(Shell(NotInYourCountry));
