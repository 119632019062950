import React from "react";
import connect from 'react-redux/es/connect/connect';
import Shell from "../../components/Shell";

import Translate from "components/CustomTranslate";
import {Col, Row} from "react-grid-system";
import {Slider} from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {isMobile} from "react-device-detect";
import FormButton from "../../components/Form/FormButton";
import {ValidatorForm} from "react-material-ui-form-validator";
import history from "../../history";

import clock from "./clock.svg";
import workout from "./workout.svg";
import physical from "./physical.svg";
import veggies from "./veggies.svg";
import doctors from "./doctors.svg";
import bravo from "./bravo.svg";
import girl_magnifying_glass from "./girl_magnifying_glass.svg";
import trait from "../../components/FinalOffer/trait.png";
import apple_store from "./apple_store.svg";
// import chart from "./chart.png";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import StandardButton from "../../components/Form/StandardButton";
import ReactSpeedometer from "react-d3-speedometer"
// and just use it
const i18n = require('react-redux-i18n').I18n;

class Wellness extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			validated: false,
			success: false,
			activity: 0,
			running: 0,
			workoutDays: 0,
			veggiesPerDay: 0,
			homemadeOrProcessed: 0,
			weightGain: 0,
			doctorVisits: 0,
			dentistVisits: 0,
			blood: 0,
			participateInProgram: true,
			appInstallation: true,
			free_months: 1,
		};
	}

	componentDidMount() {
		if (this.props.borrower.wellness && Object.keys(this.props.borrower.wellness).length > 0) {
			this.setState(this.props.borrower.wellness)
		}
	}

	getStep() {
		return null;
	}

	handleSubmit() {
	}

	proceed() {
		if (this.props.borrower.email) {
			if (this.props.borrower.lemoine.outcome || this.props.loan.flow === 'mncap') {
				history.push('/resume');
			} else {
				history.push('/sante');
			}
		} else {
			history.push('/prets')
		}
	}

	validate() {
		this.props.updateBorrower('wellness', this.state);
		if (this.state.participateInProgram !== null && this.state.appInstallation !== null) {
			this.props.updateConfig('loading', true);
			this.props.updateWellness({...this.state, lemoine: this.props.borrower.lemoine.outcome})
				.then(result => {
					let status = result.free_months && result.free_months >= 1;
					this.props.updateConfig('loading', false);
					this.setState({validated: true, success: status, free_months: result.free_months, percent: result.percent});
					window.scrollTo(0, 0);
				})
				.catch(error => {
					console.log(error);
					this.props.updateConfig('loading', false);
					this.setState({validated: true, success: false});
					window.scrollTo(0, 0);
				});
		}
	}

	render() {
		return <ValidatorForm onSubmit={() => this.handleSubmit()}>
			{!this.state.validated &&
			<Row justify="center" style={{textAlign: 'center', marginTop: '60px'}}>
			    <Col md={10}>
			        {this.props.config.loggedIn ? <span className="titlePrets"><Translate value="wellness.title"/></span>
			            :
			            <span className="titlePrets"><Translate value="wellness.titleNotLogged"/></span>}
			    </Col>
			</Row>
			}

			{!this.state.validated && <div>
				<Row justify="center">
					<Col md={10} className="centeredText">
						<p className="subtitle centeredText" style={{fontSize: "26px", marginTop: "70px"}}>
							<Translate value="wellness.subheader"/>
							<span style={{cursor: "pointer", marginLeft: "5px"}} data-tip={i18n.t('wellness.asteriskHover')} onClick={() => {
								document.querySelector('#buttonsContainer').scrollIntoView({
									behavior: 'smooth'
								});
							}}>*</span><br/>
							<img alt="Sub Header underscore svg image" className="underscoreSvg" src={trait}/>
						</p>
						{!isMobile && <img alt="Blue white stop clock that is already ticking" style={{width: "190px"}} src={clock}/>}
					</Col>
				</Row>

				<Row justify="center">
					<Col md={5} style={{textAlign: isMobile ? "center" : "left"}}>
						<img alt="Male doing physical workout outside with clock in hand" src={physical} style={{width: '200px'}}/>
					</Col>
					<Col md={5} style={{alignSelf: 'center'}}>
						<p className="centeredText" style={{fontSize: "28px", fontWeight: "bold"}}>
							<Translate value="wellness.physical"/><br/>
							<img alt="Sub Header underscore svg image" className="underscoreSvg" src={trait}/>
						</p>
					</Col>
				</Row>

				<Row justify="center">
					<Col md={5}>
						<p><Translate value="wellness.questions.physicalOne"/></p>
					</Col>
					<Col md={5}>
						<Slider
							aria-label="Custom marks"
							value={this.state.activity}
							step={50}
							onChange={(e, val) => {
								this.setState({activity: val})
							}}
							min={0}
							max={300}
							id="physicalOne"
							style={{marginTop: '28px'}}
							valueLabelDisplay="on"
							marks={[
								{
									value: 0,
									label: '0',
								},
								{
									value: 50,
									label: '50',
								},
								{
									value: 150,
									label: '150',
								},
								{
									value: 250,
									label: '250',
								},
								{
									value: 300,
									label: '+',
								},
							]}
						/>
						<p className="centeredText"><Translate value="wellness.minutesPerWeek"/></p>
					</Col>
				</Row>
				<Row justify="center">
					<Col md={5}>
						<p><Translate value="wellness.questions.physicalTwo"/></p>
					</Col>
					<Col md={5}>
						<Slider
							aria-label="Custom marks"
							value={this.state.running}
							step={25}
							id="physicalTwo"
							onChange={(e, val) => {
								this.setState({running: val})
							}}
							min={0}
							max={150}
							style={{marginTop: '28px'}}
							valueLabelDisplay="on"
							marks={[
								{
									value: 0,
									label: '0',
								},
								{
									value: 50,
									label: '50',
								},
								{
									value: 75,
									label: '75',
								},
								{
									value: 100,
									label: '100',
								},
								{
									value: 150,
									label: '+',
								},
							]}
						/>
						<p className="centeredText"><Translate value="wellness.minutesPerWeek"/></p>
					</Col>
				</Row>
				<Row justify="center">
					<Col md={5}>
						<p><Translate value="wellness.questions.physicalThree"/></p>
					</Col>
					<Col md={5}>
						<Slider
							aria-label="Custom marks"
							value={this.state.workoutDays}
							step={1}
							onChange={(e, val) => {
								this.setState({workoutDays: val});
							}}
							min={0}
							max={7}
							id="physicalThree"
							style={{marginTop: '28px'}}
							valueLabelDisplay="on"
							marks={[
								{
									value: 0,
									label: '0',
								},
								{
									value: 1,
									label: '1',
								},
								{
									value: 2,
									label: '2',
								},
								{
									value: 3,
									label: '3',
								},
								{
									value: 4,
									label: '4',
								},
								{
									value: 5,
									label: '5',
								},
								{
									value: 6,
									label: '6',
								},
								{
									value: 7,
									label: '+',
								},
							]}
						/>
						<p className="centeredText"><Translate value="wellness.timesPerWeek"/></p>
					</Col>
				</Row>

				{/*<Row justify="center" style={{marginTop: "100px"}}>*/}
				{/*	<Col md={10}>*/}
				{/*		<p className="centeredText">*/}
				{/*			<Translate value="wellness.chart"/><br/>*/}
				{/*			<img alt="Chart displaying the physcal activity active minutes per week and percange of life improvement" src={chart} width={600} style={{maxWidth: "90vw"}}/>*/}
				{/*		</p>*/}
				{/*	</Col>*/}
				{/*</Row>*/}

				<Row justify="center" style={{marginBottom: '50px'}}>
					<Col md={5} style={{textAlign: isMobile ? "center" : "left"}}>
						<img alt="Veggetables including carrots, tomato and some greens" src={veggies} style={{
							width: '500px',
							maxWidth: '90vw',
							marginLeft: isMobile ? "0px" : "-100px",
							marginTop: isMobile ? "-75px" : "-100px",
							marginBottom: isMobile ? "-75px" : "-150px"
						}}/>
					</Col>
					<Col md={5} style={{alignSelf: 'center'}}>
						<p className="centeredText" style={{fontSize: "28px", fontWeight: "bold"}}>
							<Translate value="wellness.food"/><br/>
							<img alt="Sub Header underscore svg image" className="underscoreSvg" src={trait}/>
						</p>
					</Col>
				</Row>

				<Row justify="center">
					<Col md={5}>
						<p><Translate value="wellness.questions.foodOne"/></p>
					</Col>
					<Col md={5}>
						<Slider
							aria-label="Custom marks"
							value={this.state.veggiesPerDay}
							step={1}
							id="foodOne"
							onChange={(e, val) => {
								this.setState({veggiesPerDay: val});
							}}
							min={0}
							max={6}
							style={{marginTop: '28px'}}
							valueLabelDisplay="on"
							marks={[
								{
									value: 0,
									label: '0',
								},
								{
									value: 1,
									label: '1',
								},
								{
									value: 2,
									label: '2',
								},
								{
									value: 3,
									label: '3',
								},
								{
									value: 4,
									label: '4',
								},
								{
									value: 5,
									label: '5',
								},
								{
									value: 6,
									label: '+',
								},
							]}
						/>
						{/*<p className="centeredText"><Translate value="wellness.grams"/></p>*/}
					</Col>
				</Row>
				<Row justify="center">
					<Col md={5}>
						<p><Translate value="wellness.questions.foodTwo"/></p>
					</Col>
					<Col md={5}>
						<Slider
							aria-label="Custom marks"
							value={this.state.homemadeOrProcessed}
							step={10}
							id="foodTwo"
							onChange={(e, val) => {
								this.setState({homemadeOrProcessed: val})
							}}
							style={{marginTop: '28px'}}
							valueLabelDisplay="on"
							marks={[
								{
									value: 0,
									label: '0%',
								},
								{
									value: 10,
									label: '10%',
								},
								{
									value: 20,
									label: '20%',
								},
								{
									value: 30,
									label: '30%',
								},
								{
									value: 30,
									label: '30%',
								},
								{
									value: 40,
									label: '40%',
								},
								{
									value: 50,
									label: '50%',
								},
								{
									value: 60,
									label: '60%',
								},
								{
									value: 70,
									label: '70%',
								},
								{
									value: 80,
									label: '80%',
								},
								{
									value: 90,
									label: '90%',
								},
								{
									value: 100,
									label: '100%',
								},
							]}
						/>
						<p className="centeredText"><Translate value="wellness.homeMeals"/></p>
					</Col>
				</Row>
				<Row justify="center">
					<Col md={5}>
						<p><Translate value="wellness.questions.foodThree"/></p>
					</Col>
					<Col md={5}>
						<Slider
							aria-label="Custom marks"
							value={this.state.weightGain}
							step={1}
							onChange={(e, val) => {
								this.setState({weightGain: val})
							}}
							min={-10}
							max={10}
							id="foodThree"
							style={{marginTop: '38px'}}
							valueLabelDisplay="on"
							marks={[
								{
									value: -10,
									label: '-',
								},
								{
									value: -5,
									label: '-5',
								},
								{
									value: 0,
									label: '0',
								},
								{
									value: 5,
									label: '5',
								},
								{
									value: 10,
									label: '+',
								},
							]}
						/>
						<p className="centeredText"><Translate value="wellness.kilograms"/></p>
					</Col>
				</Row>

				<Row justify="center" style={{marginBottom: "70xp", marginTop: "100px"}}>
					{!isMobile && <Col md={5}>
						<p style={{textAlign: "right"}}><Translate value="wellness.infoSection"/></p>
					</Col>}
					<Col md={5} style={{textAlign: "left"}}>
						<img alt="A woman looking through a looking glass" src={girl_magnifying_glass} style={{
							width: '600px',
							// maxWidth: '90vw',
							marginLeft: "-100px",
							marginTop: isMobile ? "-175px" : "-225px",
							marginBottom: "-150px",
						}}/>
					</Col>
					{isMobile && <Col md={5}>
						<p style={{textAlign: "right"}}><Translate value="wellness.infoSection"/></p>
					</Col>}
				</Row>


				<Row justify="center">
					<Col md={5} style={{textAlign: isMobile ? "center" : "left"}}>
						<img alt="A doctor performing a physical examination on a woman" src={doctors} style={{width: '400px', marginLeft: isMobile ? "-60px" : "-100px"}}/>
					</Col>
					<Col md={5} style={{alignSelf: 'center'}}>
						<p className="centeredText" style={{fontSize: "28px", fontWeight: "bold"}}>
							<Translate value="wellness.doctors"/><br/>
							<img alt="Sub Header underscore svg image" className="underscoreSvg" src={trait}/>
						</p>
					</Col>
				</Row>

				<Row justify="center">
					<Col md={5}>
						<p><Translate value="wellness.questions.doctorsOne"/></p>
					</Col>
					<Col md={5}>
						<Slider
							aria-label="Custom marks"
							value={this.state.doctorVisits}
							step={6}
							onChange={(e, val) => {
								this.setState({doctorVisits: val});
							}}
							id="doctorsOne"
							min={6}
							max={48}
							style={{marginTop: '28px'}}
							valueLabelDisplay="on"
							marks={[
								{
									value: 6,
									label: '6',
								},
								{
									value: 12,
									label: '12',
								},
								{
									value: 24,
									label: '24',
								},
								{
									value: 36,
									label: '36',
								},
								{
									value: 48,
									label: 'jamais',
								},
							]}
						/>
						<p className="centeredText"><Translate value="wellness.month"/></p>
					</Col>
				</Row>
				<Row justify="center">
					<Col md={5}>
						<p><Translate value="wellness.questions.doctorsTwo"/></p>
					</Col>
					<Col md={5}>
						<Slider
							aria-label="Custom marks"
							value={this.state.dentistVisits}
							step={6}
							id="doctorsTwo"
							onChange={(e, val) => {
								this.setState({dentistVisits: val});
							}}
							min={6}
							max={48}
							style={{marginTop: '28px'}}
							valueLabelDisplay="on"
							marks={[
								{
									value: 6,
									label: '6',
								},
								{
									value: 12,
									label: '12',
								},
								{
									value: 24,
									label: '24',
								},
								{
									value: 36,
									label: '36',
								},
								{
									value: 48,
									label: 'jamais',
								},
							]}
						/>
						<p className="centeredText"><Translate value="wellness.month"/></p>
					</Col>
				</Row>
				<Row justify="center">
					<Col md={5}>
						<p><Translate value="wellness.questions.doctorsThree"/></p>
					</Col>
					<Col md={5}>
						<Slider
							aria-label="Custom marks"
							value={this.state.blood}
							step={6}
							onChange={(e, val) => {
								this.setState({blood: val});
							}}
							id="doctorsThree"
							min={6}
							max={48}
							style={{marginTop: '28px'}}
							valueLabelDisplay="on"
							marks={[
								{
									value: 6,
									label: '6',
								},
								{
									value: 12,
									label: '12',
								},
								{
									value: 24,
									label: '24',
								},
								{
									value: 36,
									label: '36',
								},
								{
									value: 48,
									label: 'jamais',
								},
							]}
						/>
						<p className="centeredText"><Translate value="wellness.month"/></p>
					</Col>
				</Row>
				<Row justify="center" align="center">
					<Col md={5}>
						<p><Translate value="wellness.questions.doctorsFour"/></p>
					</Col>
					<Col md={5} className="centeredText" style={{fontWeight: "normal"}}>
						<RadioGroup className="radioGroup center_radioButtons" defaultValue="true" name="radio-participate" justify="center" align="center" row>
							<FormControlLabel
								className={'radioButton'}
								value="true"
								control={<Radio required/>}
								label={<Translate value='generic.yes'/>}
								onChange={() => {
									this.setState({participateInProgram: true});
								}}
							/>
							<FormControlLabel
								className={'radioButton'}
								value="false"
								control={<Radio required/>}
								onChange={() => {
									this.setState({participateInProgram: false});
								}}
								label={<Translate value='generic.no'/>}/>
						</RadioGroup>
					</Col>
				</Row>
				<Row justify="center" align="center">
					<Col md={5}>
						<p><Translate value="wellness.questions.doctorsFive"/></p>
					</Col>
					<Col md={5} className="centeredText" style={{fontWeight: "normal"}}>
						<RadioGroup className="radioGroup center_radioButtons" defaultValue="true" name="radio-app-installation" justify="center" align="center" row>
							<FormControlLabel
								className={'radioButton'}
								value="true"
								control={<Radio required/>}
								onChange={() => {
									this.setState({appInstallation: true});
								}}
								label={<Translate value='generic.yes'/>}/>
							<FormControlLabel
								className={'radioButton'}
								value="false"
								control={<Radio required/>}
								onChange={() => {
									this.setState({appInstallation: false});
								}}
								label={<Translate value='generic.no'/>}/>
						</RadioGroup>
					</Col>
				</Row>


				<Row justify="center" id="buttonsContainer" className='buttonGroup' style={{marginTop: '100px'}}>
					<Col md={4} lg={4} sm={12} xs={12}>
						<FormButton id='stepConfirm1' invertedColour={true} value={<Translate value='wellness.decline'/>} className="orange" onClick={() => {
							this.proceed();
						}}/>
					</Col>
					<Col md={4} lg={4} sm={12} xs={12}>
						<FormButton id='stepConfirm1' value={<Translate value='wellness.validate'/>} className="orangeBg" onClick={() => this.validate()}/>
					</Col>
				</Row>
			</div>}

			{(this.state.validated && this.state.success) && <div>
				<Row justify="center" align="center" style={{textAlign: 'center', height: '100vh'}}>
					<Col md={10}>
						<span className="titlePrets">
							<img alt="Confetti in the air in celebration of offer" src={bravo} style={{
								marginBottom: "-200px"
							}}/>
							<Translate value="wellness.titleSuccess"/><br/>
							<img alt="Sub Header underscore svg image" className="underscoreSvg" src={trait}/>
						</span>
					</Col>
					<Col md={10} style={{marginTop: "-200px"}}>
						<p style={{marginBottom: '30px'}}>
							{this.props.config.loggedIn && <Translate value="wellness.subtitleSuccess" months={this.state.free_months}/>}
						</p>
						<ReactSpeedometer maxValue={10} value={this.state.percent} />
						<p><Translate value="wellness.percentageGained" percent={this.state.percent}/></p>
					</Col>
				</Row>
				<Row justify="center">
					<Col md={10}>
						<p style={{fontSize: '14px', color: 'rgb(90,90,90)', textAlign: 'center'}}>
							<Translate value="wellness.clarification"/>
						</p>
					</Col>
					<Col md={10} style={{marginBottom: "50px", marginTop: "30px"}}>
						<Row justify="center">
							{/*<Col md={3}>*/}
							<a target="_blank" href='https://apps.apple.com/bg/app/wedou-care/id1632403148' rel="noreferrer">
								<img height={60} alt='Get it on Apple Store' src={apple_store}/>
							</a>
							{/*</Col>*/}
							{/*<Col md={3}>*/}
							<a target="_blank" href='https://play.google.com/store/apps/details?id=com.wedou.iot&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1' rel="noreferrer">
								<img height={89} style={{marginTop: '-14px'}} alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/>
							</a>
							{/*</Col>*/}
						</Row>
					</Col>
				</Row>
				<Row justify="center">
					<Col md={5}>
						<StandardButton value={<Translate value="generic.continue"/>} onClick={() => {
							this.proceed();
						}}/>
					</Col>
				</Row>
			</div>}

			{!!(this.state.validated && !this.state.success) ? <div>
				<Row justify="center" align="center" style={{textAlign: 'center', height: '100vh'}}>
					<Col md={4}>
						<img alt="Elderly lady holding dumbbells while exercising." src={workout}/>
					</Col>
					<Col md={6}>
						<span className="titlePrets">
							<Translate value="wellness.titleNoSuccess"/><br/>
							<img alt="Sub Header underscore svg image" className="underscoreSvg" src={trait}/>
						</span>
						<p><Translate value="wellness.percentageGained" percent={this.state.percent}/></p>
						<p style={{textAlign: "left"}}><Translate value="wellness.subtitleNoSuccess"/></p>
						<StandardButton id='stepConfirm1' value={<Translate value='generic.continue'/>} onClick={() => {
							this.proceed();
						}}/>
					</Col>
				</Row>
			</div> : <></>}
		</ValidatorForm>
	}
}


export default connect()(Shell(Wellness));
