import React from 'react';
import style from './loader.module.css';
import {Row, Col} from 'react-grid-system';

const Loader = (props) => {
	let logo = props.config.theme.logoLoader;
	return (
		<div>
			{!props.config.loading && document.body.classList.remove('prevent-scrolling')}
			{!!props.config.loading && document.body.classList.add('prevent-scrolling')}
			{!!props.config.loading && (
				<div className={style.loaderContainer}>

					<Row>
						<Col md={12}>
							<div className={style.ldsEclipse} style={{margin: 'auto'}}>
								<div style={{boxShadow: `0 5px 0 0 ${props.config.theme.primary}`}} />
								<img src={logo} className={style.loaderLogo} alt="Loading..."/>
							</div>
							{props.config.show_document_loading_message && <Row>
								<Col>
									<p><b>Merci de patienter quelques instants, nous préparons votre contrat.</b></p>
								</Col>
							</Row>}
						</Col>
					</Row>

				</div>
			)}
		</div>
	);
};

export default Loader;
