import React from 'react';
import history from "../../history";
import connect from 'react-redux/es/connect/connect';
import Shell from "../../components/Shell";
import {getParameterByName} from "../../js/helpers/functions";

class LiveTranslation extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			dots: ''
		}
	}

	componentDidMount() {
		let isMobile = getParameterByName('is_mobile', window.location.href);
		window.isMobile = isMobile;
		sessionStorage.setItem('isMobile', isMobile);

		window.__TRANSLATION_VIEW = true;
		sessionStorage.setItem('__TRANSLATION_VIEW', true);

		setInterval(() => {
			this.setState({dots: this.state.dots === '...' ? '' : (this.state.dots + '.')});
		}, 650);

		setTimeout(() => {
			history.push('/');
		}, 700 * 3);
	}

	getStep(){
		return null;
	}

	render() {
		return <h3 className='centeredText'>Live Translation is loading {this.state.dots}</h3>;
	}
}

export default connect()(Shell(LiveTranslation));