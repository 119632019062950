import React from 'react';
import Shell from '../../components/Shell';
import {connect} from 'react-redux';
import {Row, Col, Container} from 'react-grid-system';
import ReactTooltip from 'react-tooltip';
import Translate from "components/CustomTranslate";
import RadioGroup from '@material-ui/core/RadioGroup';
import {YesNoRadio} from '../../components/Form/Fields';
import FormButton from '../../components/Form/FormButton';
import {ValidatorForm} from 'react-material-ui-form-validator';
import history from '../../history';

class BravoSimplified extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			bravo: {
				simplified: true,
				questions: {}
			}
		};
	}

	getStep() {
		return 'bravo_simplified';
	}

	update(q, val) {
		this.setState(state => {
			state.bravo.questions[q] = val;
			return state;
		});
	}

	validation() {
	}

	render() {
		return (
			<div>
				<Container>
					<ValidatorForm onSubmit={() => {
						if (Object.values(this.state.bravo.questions).every(q => q === 'false')) {
							this.props.updateConfig('loading', true);
							this.props.bravoDocuments({flow: this.props.loan.flow, ...this.state})
								.then(documents => {
									this.props.updateBorrower('bravo_documents', documents.data);
									this.props.updateConfig('loading', false);
									history.push('rendez-vous');
								});
						} else {
							history.push('/bravo_detaille');
						}
					}}>
						<ReactTooltip style={{backgroundColor: this.props.config.theme.primary}} className="step2-tooltip"/>
						<Row justify="center">
							<Col md={8} style={{marginTop: '15px'}}>
								<p className="focusText">
									<b><Translate value='step6.text' name={this.props.borrower.first_name}/></b>
								</p>
							</Col>
						</Row>

						<Row justify="center">
							<Col md={9}>
								<p className='healthQuestion'>Etes−vous actuellement en arrêt de travail sur prescription médicale, pour raison de santé ou avez−vous eu un ou
									plusieurs arrêts de travail de plus de 30 jours consécutifs au cours des 5 dernières années, sur prescription médicale, pour raison de santé
									?</p>
							</Col>
							<Col md={3}>
								<RadioGroup className='radioGroup healthQuestion'
								            name='question_one'
								            value={this.state.bravo.questions.question_one}
								            onChange={(event, value) => this.update('question_one', value)}
								><YesNoRadio/></RadioGroup>
							</Col>
						</Row>

						<Row justify="center">
							<Col md={9}>
								<p className='healthQuestion'>Suivez−vous actuellement un traitement médical ?</p>
							</Col>
							<Col md={3}>
								<RadioGroup className='radioGroup healthQuestion'
								            name='question_two'
								            value={this.state.bravo.questions.question_two}
								            onChange={(event, value) => this.update('question_two', value)}
								><YesNoRadio/></RadioGroup>
							</Col>
						</Row>

						<Row justify="center">
							<Col md={9}>
								<p className='healthQuestion'>Etes−vous titulaire de l'Allocation pour adulte handicapé (AAH) ?</p>
							</Col>
							<Col md={3}>
								<RadioGroup className='radioGroup healthQuestion'
								            name='question_three'
								            value={this.state.bravo.questions.question_three}
								            onChange={(event, value) => this.update('question_three', value)}
								><YesNoRadio/></RadioGroup>
							</Col>
						</Row>

						<Row justify="center">
							<Col md={9}>
								<p className='healthQuestion'>Vous a−t−on déjà prescrit un traitement médical (hormis pour raison contraceptive ou pour traitement d'une allergie
									saisonnière bénigne) de plus de 21 jours au cours des 10 dernières années ?</p>
								<p className='healthQuestion'>Notamment :</p>
								<p className='healthQuestion'>
									<ul>
										<li>un traitement pour maladie rhumatismale, atteinte de la colonne vertébrale, lumbago, lombalgie ou sciatique ?</li>
										<li>un traitement pour troubles nerveux, affection neuropsychique, dépression nerveuse ?</li>
										<li>un traitement pour troubles cardiaques ou vasculaires, hypertension artérielle ?</li>
										<li>un traitement par rayons, cobalt, chimiothérapie ou immunothérapie ?</li>
										<li>d'autres traitements d'une durée continue supérieure à 1 mois ?</li>
									</ul>
								</p>
							</Col>
							<Col md={3}>
								<RadioGroup className='radioGroup healthQuestion'
								            name='question_four'
								            value={this.state.bravo.questions.question_four}
								            onChange={(event, value) => this.update('question_four', value)}
								><YesNoRadio/></RadioGroup>
							</Col>
						</Row>

						<Row justify="center">
							<Col md={9}>
								<p className='healthQuestion'>Avez−vous subi au cours des 10 dernières années une (ou des) intervention(s) chirurgicale(s) et/ou devez−vous en subir
									dans les 12 prochains mois ? − (à l'exclusion toutefois de l'ablation de l'appendice, des amygdales et/ou des végétations, de la vésicule
									biliaire, d'une maternité, d'une césarienne, d'une hernie inguinale ou ombilicale ou hiatale, de varices, d'hémorroïdes, d'une IVG, de chirurgie
									dentaire, ou pour le traitement de déviation de la cloison nasale)</p>
							</Col>
							<Col justify='right' md={3}>
								<RadioGroup className='radioGroup healthQuestion'
								            name='question_five'
								            value={this.state.bravo.questions.question_five}
								            onChange={(event, value) => this.update('question_five', value)}
								><YesNoRadio/></RadioGroup>
							</Col>
						</Row>

						<Row justify='center'>
							<Col md={12}>
								<p className='healthQuestion'><Translate value='step6.linkStatement' dangerousHTML/></p>
								<hr/>
							</Col>
						</Row>

						<Row justify='center'>
							<Col md={12}>
								<p className='smallFont'>
									Je certifie avoir répondu personnellement et avec attention aux questions ci-dessus. Je suis informé(e) que toute réticence, fausse déclaration
									intentionnelle ou non est sanctionnée par la loi qui prévoit la nullité du contrat ou une augmentation de prime d’assurance.
								</p>
							</Col>
						</Row>

						<Row justify="center" className="buttonSection">
							<Col md={4} xs={9} sm={9}>
								<FormButton value={<Translate value='generic.follow'/>}/>
							</Col>
						</Row>
					</ValidatorForm>
				</Container>
			</div>
		);
	}

}


export default connect()(Shell(BravoSimplified));
