import React from 'react';
import Shell from '../../components/Shell';
import {connect} from 'react-redux';
import {Container, Row, Col} from "react-grid-system";

import Translate from "components/CustomTranslate";
import peopleImage from 'components/FinalOffer/people_group.svg';
import {StringField} from "../../components/Form/Fields";
import {FirstName, LastName, Phone} from "../../components/Form/StepsFields/step4";
import {CustomValidatorForm} from "../../components/Form";
import FormButton from "../../components/Form/FormButton";
import history from "../../history";
class NotInYourCountry extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			buttonClicked: false,
			uniqueEmailError: '',
			uniquePhoneError: ''
		}
	}


	getStep() {
		return 'initial';
	}

	render() {

		return (
			<Container style={{color: 'rgb(83, 114, 227)', marginTop: '40px', textAlign: 'center'}}>
				<CustomValidatorForm onSubmit={() => {
					let params = {
						"source": "StanTheProviderMan",
						"borrower": {
							"phone": this.props.borrower.phone,
							"email": this.props.borrower.email,
							"coverage": 100,
							"birthDate": "10/10/1990",
							"firstName": this.props.borrower.first_name,
							"lastName": this.props.borrower.last_name,
							"salutations": 1,
							"hasCoborrower": false,
							"smoker": false,
							"postalCode": "92160",
							"town": "Antony",
							"street": "5 Allee des Vergers"
						},
						"loan": {
							"loanContractDate": "07/06/2022",
							"loanStatus": 1,
							"projectType": 1,
							"bank": 17,
							"totalLoans": 1,
							"loans": [
								{
									"number": 1,
									"amount": 100000,
									"type": 1,
									"interestRate": 1,
									"durationMonths": 180
								}
							]
						}
					};
					this.props.addLead(params);
					history.push('/prets');
				}
				}>
					<Row justify="center">
						<Col md={10}>
							<span className="titlePrets"><Translate value="step1.title"/></span><br/>
						</Col>
					</Row>
					<Row justify="center" style={{marginTop: '70px'}}>
						<Col md={6}>
							<h2>
								<img src={peopleImage}/>
							</h2>
						</Col>
						<Col md={6} style={{alignSelf: 'center'}}>
							<Row justify="center">
								<Col md={8}>
									<FirstName data={this.props.borrower} update={this.props.updateBorrower}/>
								</Col>
							</Row>
							<Row justify="center">
								<Col md={8}>
									<LastName data={this.props.borrower} update={this.props.updateBorrower}/>
								</Col>
							</Row>
							<Row justify="center">
								<Col md={8}>
									<StringField
										value={this.props.borrower.email}
										onChange={(e) => this.props.updateBorrower('email', e.target.value)}
										id='emailTextField'
										name='email'
										validators={['isEmail']}
										errorMessages={['L\'e-mail est incorrect']}
										label={<Translate value='step3.email'/>}
										margin="normal"
									/>
								</Col>
							</Row>
							<Row justify="center">
								<Col md={8}>
									<Phone update={this.props.updateBorrower}
									       data={this.props.borrower}
									       uniquePhoneError={this.state.uniquePhoneError}
									       id='phoneTextField'
									       updateUniquePhone={(value) => {
										       this.setState({uniquePhoneError: value})
									       }}
									/>
								</Col>
							</Row>
							<Row justify="center">
								<Col md={8}>
									<FormButton value={<Translate value="generic.continue"/>}/>
								</Col>
							</Row>
						</Col>
					</Row>
				</CustomValidatorForm>
			</Container>
		)
	}

}

export default connect()(Shell(NotInYourCountry));
