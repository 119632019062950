import React from "react";
import {all_equivalences} from "../../js/helpers/data";
import style from "./style.module.css";
import ReactTooltip from "react-tooltip";
import {calculateGuarantees} from "../../js/helpers/functions";
import {I18n} from "react-redux-i18n";
import mncap from "./mncap.png";
import mutlog from "./mutlog.png";
import "../../containers/InsuranceInfo/style.css";
import {isMobile} from "react-device-detect";
import Translate from "components/CustomTranslate";
import afiesca from "../InsurerCard/afiesca.png";
import AVENIRNAOASSUR from "../InsurerCard/AVENIRNAOASSUR.png";
import IPTIQ from "../InsurerCard/iptiq.png";
import MILTIS from "../InsurerCard/sopretis.png";
import IRIADE from "../InsurerCard/IRIADE.jpg";
import SEREVI3 from "../InsurerCard/serenity.png";
import CNP2 from "../InsurerCard/SCR-20230308-em1.png";
import MAESTRO from '../InsurerCard/maestro.png'

export default function AllGuarantees(props) {
	const logos           = {mutlog, 'afi-esca': afiesca, mncap,IPTIQ, MILTIS,MAESTRO,AVENIRNAOASSUR, IRIADE, MNCAP: mncap, SEREVI3, CNP2};
	const guaranteesTable = (props) => {
		let {extra_guarantees} = calculateGuarantees(props, all_equivalences);
		let rows               = [];
		extra_guarantees.forEach((r) => {
			rows.push(
				<span style={{textAlign: 'left'}}>
					<i className={`fa fa-plus`} style={{margin: '5px', color: 'rgb(50, 206, 155)'}}/>
					{<Translate
						value={['incap_back', 'incap_psychological'].indexOf(r.key) !== -1 ? `guarantees.${r.key}.${r.include_only.indexOf(1) !== -1 ? 'name' : 'name_with_condition'}` : `guarantees.${r.key}.name`}/>}
					<span className='extra-guarantees-tooltip' style={{marginLeft: '5px'}}>
						<i className={`fa fa-info`} style={{color: 'rgb(121, 121, 121)'}} data-tip={I18n.t(`guarantees.${r.key}.tip`)} data-multiline='true'/>
					</span>
				</span>
			);
		});

		return rows;
	}

	const bankGuaranteesTable = (props) => {
		let {bank_guarantees} = props;
		let rows              = [];

		bank_guarantees.forEach((r) => {
			let tip = ['incap_back', 'incap_psychological'].indexOf(r.key) !== -1 ? `guarantees.${r.key}.${r.include_only.indexOf(1) !== -1 ? 'tip' : 'tip_with_condition'}` : `guarantees.${r.key}.tip`;
			rows.push(
				<span style={{textAlign: 'left'}}><i style={{color: props.config.theme.primary, margin: '5px'}} className={`fa fa-check`}/><Translate
					value={['incap_back', 'incap_psychological'].indexOf(r.key) !== -1 ? `guarantees.${r.key}.${r.include_only.indexOf(1) !== -1 ? 'name' : 'name_with_condition'}` : `guarantees.${r.key}.name`}/>
				<span className='extra-guarantees-tooltip'>
					<i className={`fa fa-info`} style={{color: 'rgb(121, 121, 121)', marginLeft: '4px'}} data-tip={I18n.t(tip)} data-multiline='true'/>
				</span>
				</span>
			);
		});
		if (props.show_extra_guarantees) {
			let {extra_guarantees} = calculateGuarantees(props, all_equivalences);
			extra_guarantees.forEach((r) => {
				rows.push(
					<>
						{r.name[props.translations.locale]}
						<span className='extra-guarantees-tooltip'>
							<i style={{color: props.config.theme.primary}} className={`fa fa-info ${style.check}`} data-tip={r.tip} data-multiline='true'/>
							<i className={`fa fa-plus ${style.check}`}/>
						</span>
					</>
				);
			});
		}
		return rows;
	}

	const extraGuarantees = guaranteesTable(props);
	const bankGuarantees  = bankGuaranteesTable(props);

	return <div style={{flex: props.finalOffer && '2'}}>
		<ReactTooltip style={{backgroundColor: props.config.theme.primary}} className="step2-tooltip"/>

		<div
			onClick={props.onClick}
			className="dataTable" style={{minHeight: !isMobile ? '370px' : '50px', marginBottom: '20px'}}>
			<div className='insurer-card-first-row' style={{display: props.finalOffer ? 'none' : 'flex',}}>
				<div className='insurer-card-logo'>
					<img src={logos[props.flow]}/>
				</div>
			</div>

			<hr className='insurer-card-separator' style={{display: props.finalOffer ? 'none' : 'flex'}}/>
			<div className='extra-guarantees-submenu'>
				<span>	<i className="fa fa-university roundedIcon"/><Translate value="step2.bankGuarantees" dangerousHTML/></span>
			</div>
			{bankGuarantees.map((item) => {
				return <div className='insurer-card-row'>
					{item}
				</div>
			})
			}
			{extraGuarantees.length > 0 && (
				<>
					<div className='extra-guarantees-submenu'>
						<span><i className="fa fa-plus roundedIcon" style={{padding: '9px 11px'}}/><Translate value={`step3.betterGuarantees`}/></span>
					</div>
					{extraGuarantees.map((item) => {
						return <div className='insurer-card-row'>
							{item}
						</div>
					})
					}
				</>)}

		</div>
	</div>;
}
