import React, {useEffect, useState} from 'react';
import 'moment/locale/fr';
import './styles.css';
import img from './images/gergana.png';
import moment from "moment";
import 'react-calendar/dist/Calendar.css'
import Calendar from 'react-calendar';
import './Calendar.css'
import {FaCalendar} from "react-icons/fa";
import {FaClock} from "react-icons/all";

import {StringField} from "../../components/Form/Fields/index";
import {ValidatorForm} from 'react-material-ui-form-validator';
import {Phone} from '../../components/Form/StepsFields/step4'
import Modal from '../../components/Modal/index'
import {Row, Col, Container} from 'react-grid-system';
import {isMobile} from 'react-device-detect';
import FormButton from '../../components/Form/FormButton/index'
import createScrollSnap from "scroll-snap";
import style from "../../components/Modal/modal.module.css";
import Translate from "../../components/CustomTranslate";
import {getLanguage, getParameterByName} from "../../js/helpers/functions";

const Index = (props) => {
	const [meeting_date, setMeetingDate]            = useState('');
	const [meeting_hour_start, setMeetingHourStart] = useState('');
	const [meeting_hour_end, setMeetingHourEnd]     = useState('');
	const [buttonClicked, setButtonClicked]         = useState(false);
	const [message, setMessage]                     = useState('');
	const [dialogOpen, setDialogOpen]               = useState(false)
	const route                                     = window.location.href.split('/')[3];
	const agent                                     = getParameterByName('agent', window.location.href) || window.agent_param;

	let elements = [
		'hours_track',
		'minutes_track',
	];

	let hours   = [];
	let minutes = [];
	let step    = 15;
	let start   = 9;
	let end     = 20;

	function checkActive(initial) {
		elements.forEach(id => {
			let element       = document.getElementById(id);
			let pages         = document.querySelectorAll(`#${id} .page:not(.dummy)`);
			let dummies       = document.querySelectorAll(`#${id} .page.dummy`).length;
			let mid           = initial ? Math.floor((pages.length + dummies) / 2) - dummies / 2 : null;
			mid               = id === 'hours_track' ? mid : 1;
			element.scrollTop = initial ? mid * 35 : element.scrollTop;
			let entry         = initial && mid ? mid : element.scrollTop / 35;
			for (let i = 0; i < pages.length; i++) {
				if (i === entry) {
					pages[i].classList.add('selected');
				} else {
					pages[i].classList.remove('selected');
				}
			}
		});
		let selected_hour        = document.querySelector(`#${elements[0]} .page.selected`).dataset.entryValue;
		let selected_minutes     = document.querySelector(`#${elements[1]} .page.selected`).dataset.entryValue;
		let selected_end_minutes = parseInt(selected_minutes) + step;
		selected_end_minutes     = selected_end_minutes === 60 ? 0 : selected_end_minutes;
		selected_end_minutes     = selected_end_minutes < 10 ? '0' + selected_end_minutes : selected_end_minutes;
		setMeetingHourStart(`${selected_hour}:${selected_minutes}`);
		setMeetingHourEnd(`${selected_hour}:${selected_end_minutes}`);
	}

	useEffect(() => {
		elements.forEach(id => {
			let element = document.getElementById(id);
			createScrollSnap(
				element,
				{
					snapDestinationY: "35px",
					duration: 10,
					threshold: 0.2,
					snapStop: false,
					timeout: 100
				},
				() => checkActive()
			);
		});

		checkActive(true);
	}, []);

	const Popup = () => {
		return (
			<div className='body-info'>
				<ValidatorForm
					onSubmit={(e) => {
						if (props.data.phone && props.data.email) {
							setDialogOpen(false)
						}

						submit(e);
					}}
				>
					<Container justify="center">
						<div className='title-modal'>
							<Translate value='meetings.no_phone_header' date={moment(meeting_date).format('DD/MM/YYYY')} meeting_hour_start={meeting_hour_start} meeting_hour_end={meeting_hour_end}/>
							<br/>
							<div className='modal-title'><Translate value='meetings.no_phone_question'/></div>
						</div>
						<Row justify="center">
							<Col className='modal-field'>
								<Phone
									update={props.update}
									data={props.data}
									className='blueLabel'
									style={{color: '#000'}}
								/>
							</Col>
						</Row>
						<Row justify="center">
							<Col className='modal-field'>
								<StringField
									label='Email'
									onChange={(e) => props.update('email', e.target.value)}
									value={props.data.email}
									className='blueLabel'
									validators={['required', 'isEmail']}
									errorMessages={['Cette information doit être renseignée', 'L\'e-mail est incorrect']}
								/>
							</Col>
						</Row>
						<Row style={{marginTop: "15px"}} justify="center">
							<Col lg={10}>
								<FormButton style={{backgroundColor: "#3451b9"}} type='submit' value='Envoyer'/>
							</Col>
						</Row>

					</Container>
				</ValidatorForm>
			</div>
		)
	}

	for (let i = start; i < end; i++) {
		hours.push(i < 10 ? '0' + i : i);
	}

	for (let k = 0; k < 60 / step; k++) {
		let pk    = k + 1;
		let start = k * step < 10 ? '0' + k * step : k * step;
		let end   = pk * step < 10 ? '0' + pk * step : pk * step;
		end       = end === 60 ? '59' : end;
		// minutes.push(`${start} - ${end}`);
		minutes.push(`${start}`);
	}

	let today = moment().format('MM/DD/YYYY') === moment(meeting_date).format('MM/DD/YYYY');
	if (today) {
		hours = hours.filter(h => parseInt(h) >= moment().hour());
	}

	function renderSwitch(param) {
		switch (param) {
		case 'bravo_signature':
			return <div className='label-calendar'>
				<div style={{fontSize: "19px"}}>
					{props.data.first_name}, vous allez recevoir, <b>par mail</b>, un lien de notre partenaire
					<b> Afi-Esca</b> qui vous permettra de compléter votre questionnaire de risque.
				</div>
				<div style={{marginTop: '18px'}}>
						N'hésitez pas à nous appeler au <b>01 76 41 05 48</b> ou à prendre <b>rendez-vous</b> avec
						notre conseiller qui pourra vous aider.
				</div>
			</div>
		case 'rendez-vous':
		default:
			return <div className='rendez-vous'><Translate value='meetings.subheader'/></div>;
		}
	}

	function submit(e) {
		e.preventDefault();
		if (!props.data.phone || !props.data.email) {
			setDialogOpen(true);
		} else {
			if (meeting_date && meeting_hour_start && meeting_hour_end) {
				let meeting_datetime = moment(meeting_date)
					.set('hours', meeting_hour_start.split(':')[0])
					.set('minute', meeting_hour_start.split(':')[1]);
				setMessage(<div/>);
				if (moment(meeting_datetime).diff(moment()) >= 0) {
					setButtonClicked(true);
					let start_time   = meeting_hour_start.split(':');
					let end_time     = meeting_hour_end.split(':');
					let meeting_data = {
						agent: agent,
						type: 'meeting', //Is Bravo a thing of the past ? //window.location.pathname.indexOf('rendez-vous') !== -1 ? 'meeting' : 'bravo',
						date: moment(meeting_date).format('DD/MM/YYYY'),
						start: moment(meeting_date).set({
							hour: start_time[0],
							minute: start_time[1]
						}).format(),
						end: moment(meeting_date).set({hour: end_time[0], minute: end_time[1]}).format(),
						email: props.data.email,
						phone: props.data.phone
					};
					props.bravoContact(meeting_data);

					window.dataLayer = window.dataLayer || [];
					window.dataLayer.push({
						'event': 'meetingSubmit',
						'email': meeting_data.email,
						'phone': meeting_data.phone,
						'start': meeting_data.start,
						'end': meeting_data.end
					});
					window.dataLayer.push({'event': 'lead_rdv'});
					// window.gtag && window.gtag('event', 'conversion', {
					// 	'send_to': 'AW-820054480/WRhjCP_NkeUDENCThIcD',
					// });
				} else {
					setMessage(<div className='errors'><Translate value='meetings.error_date_in_past'/></div>)
				}
				// props.updateConfig('openCalendar', false)
			} else {
				setMessage(<div className='errors'><Translate value='meetings.error_please_choose'/></div>);
			}
		}
	}

	function moveElement(e) {
		let element  = e.target;
		let parent   = element.parentNode;
		let elements = parent.querySelectorAll('.page:not(.dummy)');
		elements.forEach((el, i) => {
			if (el.dataset.entryValue === element.dataset.entryValue) {
				parent.scrollTop = i * 35;
				checkActive();
			}
		})
	}

	function createEntry(type, data) {
		return data.map(entry => {
			return <div className="page" onClick={moveElement} data-entry-type={type} data-entry-value={entry}>{entry}</div>
		});
	}

	function dummyContent() {
		let dummies = [0, 1];
		if (!isMobile) {
			dummies.push(2);
		}
		return dummies.map(() => <div className="page dummy">
			<div className="dummy-content"/>
		</div>);
	}

	if (route === 'rendez-vous-page') {
		let wrapper = document.querySelector('.contentWrapper');
		if (wrapper) {
			wrapper.style.paddingBottom = 0;
		}
	}

	return (
		<Container fluid="md">
			<Row className={(route === 'rendez-vous' || route === 'rendez-vous-page') && !props.inPopup ? 'containerRendezVous' : 'containerRendezVous'} justify="center">
				<Col lg={12} xs={12} md={12} sm={12} style={{textAlign: 'center'}}>
					{!isMobile && !props.inPopup && <span className={isMobile ? style.titleModalPhone : style.titleModal}>
						<span><Translate value='meetings.heading' dangerousHTML/></span>
					</span>}
					<Col style={{marginBottom: isMobile ? 0 : '3vh'}}>


						<Modal minWidth="lg" maxWidth="lg" title='' noContentPadding={true}
						       dialogOpen={dialogOpen}
						       config={props.config}
						       disableImage={true}
						       onClose={() => setDialogOpen(false)}>
							{Popup()}
						</Modal>

						{route === 'rendez-vous' && <Row justify="center">
							<Col>
								<img alt="Support" className='image' src={img}/>
								<div className='label'>
									{renderSwitch(route)}
								</div>
							</Col>
						</Row>}

						<Row justify="center" className="calendarRow">
							<Col md={8} className="calendarCol">
								{!isMobile && <h4 className={`centeredText bold ${isMobile ? 'no-margin-bottom' : 'no-margin-top'}`}><Translate value='meetings.day'/> <FaCalendar size={16}/></h4>}
								<div className="calendarContainer">
									<Calendar
										tileDisabled={({date}) => date.getDay() === 0}
										minDate={new Date()}
										formatLongDate={(locale, date) => moment(date).format('DD/MM/YYYY')}
										locale={getLanguage()}
										className='calendar'
										onChange={value => {
											setMeetingDate(value);
											//If the first time you pick a date on mobile - scroll to the hours picking
											if (!meeting_date && isMobile) {
												let hours_container  = document.getElementById('hours_pick_container');
												let scroll_container = document.querySelector('.MuiDialogContent-root') || document.querySelector('html');
												scroll_container.scrollTo({
													top: hours_container ? hours_container.getBoundingClientRect().top : scroll_container.scrollTop,
													behavior: 'smooth'
												});
											}
										}}
										value={meeting_date}
									/>
								</div>
							</Col>

							<Col md={4} id="hours_pick_container" justify="center" className={`${'divForm'} calendarCol`}>
								<h4 className={`centeredText bold ${isMobile ? 'no-margin-bottom' : 'no-margin-top'}`}><Translate value='meetings.hour'/> <FaClock size={16}/></h4>
								<p className="centeredText minutes-header"><Translate value='meetings.hour_subheader'/></p>
								<div id="hours_container">
									<div className="scroll_middle_selector">
										<div className="scroll_middle_selector_hours"/>
										<div className="scroll_middle_selector_dots">:</div>
										<div className="scroll_middle_selector_minutes"/>
									</div>
									<div id="hours_track" className={getLanguage()}>
										{dummyContent()}
										{createEntry('hours', hours)}
										{dummyContent()}
									</div>
									<div id="minutes_track" className={getLanguage()}>
										{dummyContent()}
										{createEntry('minutes', minutes)}
										{dummyContent()}
									</div>
								</div>
							</Col>
						</Row>
						{message}
					</Col>

					{buttonClicked && meeting_hour_start && meeting_hour_end ?
						<div className='text minutes-header'>
							<Translate value='meetings.success' date={moment(meeting_date).format('DD/MM/YYYY')} meeting_hour_start={meeting_hour_start} meeting_hour_end={meeting_hour_end}/>
						</div> :
						<Row justify='center'>
							<Col sm={12} xs={12} lg={6} md={6} className='button'>
								<FormButton id='window-button' onClick={submit} value={<Translate value='meetings.submit_button'/>}/>
							</Col>
						</Row>}
				</Col>
			</Row>
		</Container>
	)
};

export default Index;
