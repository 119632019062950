import React from "react";
import {all_equivalences} from "../../js/helpers/data";
import {isMobile} from "react-device-detect";
import ReactTooltip from "react-tooltip";
import "../../containers/InsuranceInfo/style.css";
import Translate from 'components/CustomTranslate';
import moment from "moment";
import {I18n} from "react-redux-i18n";

export default function BorrowerGuarantees(props) {
	const guaranteesTable = (props) => {
		let rows           = [];
		let filtered       = all_equivalences.filter(eq => !eq.include_only || eq.include_only.includes(props.guarantees[eq.key]));
		const borrower_age = moment().diff(moment(props.borrower.birth_date, 'DD/MM/YYYY'), 'years');
		filtered.forEach((eq) => {
			if ((props.guarantees[eq.key] || eq.always_covered || (eq.include_only && eq.include_only.indexOf(props.guarantees[eq.key])))) {
				if (eq.itt_required && !props.guarantees.itt_covered) {
					// TODO: ipp?
				} else {
					if (!(['itt_covered', 'ipt_covered', 'ipp_covered', 'incap_pro'].indexOf(eq.key) !== -1 && borrower_age > 63)) {
						rows.push(
							<span style={{textAlign: 'left'}}>
								<i className={`fa fa-check`} style={{color: '#44D18B', margin: '5px'}}/>
								{<Translate value={`guarantees.${eq.key}.name`}/>}
								<span className='extra-guarantees-tooltip'>
									<i className={`fa fa-info`} data-tip={I18n.t(`guarantees.${eq.key}.tip`)} style={{marginLeft: '5px'}} data-multiline='true'/>
								</span>
							</span>
						);
					}
				}

			}
		});

		return rows;
	}

	const extraGuarantees = guaranteesTable(props);
	// let premium;
	// if (props.is_coborrower) {
	// 	premium = props.flow === 'afi-esca' ? props.loan.constant_premium ? props.config.calculation.total.coborrower.insurance_cost : props.config.calculation.variable.coborrower.guarantees.total : props.config.calculation.total.coborrower.insurance_cost;
	// } else {
	// 	premium = props.flow === 'afi-esca' ? props.loan.constant_premium ? props.config.calculation.total.borrower.insurance_cost : props.config.calculation.variable.borrower.guarantees.total : props.config.calculation.total.borrower.insurance_cost;
	// }
	return <div style={{flex: props.finalOffer && '2'}}>
		<ReactTooltip style={{backgroundColor: props.config.theme.primary}} className="step2-tooltip"/>
		<div
			onClick={props.onClick}
			className='dataTable'
			style={{
				padding: isMobile ? '5px' : '20px',
				textAlign: 'left'
			}}>
			<hr className='insurer-card-separator' style={{display: props.finalOffer ? 'none' : 'flex'}}/>
			<span style={{fontSize: isMobile ? '23px' : '30px', color: '#000', letterSpacing: 0}}>Vous serez assuré pour<br/> les garanties suivantes :</span><br/>
			<span style={{fontSize: isMobile ? '15px' : '20px', color: '#000', letterSpacing: 0}}>Elles respectent les garanties <br />de votre banque en améliorant vos couvertures :</span>
			{/*<div className='extra-guarantees-submenu'>*/}
			{/*	<span style={{fontWeight: '600'}}>{!props.is_coborrower ? 'Emprunteur' : 'Co-emprunteur'} <i className={`fa fa-info`} style={{color: '#5680ed'}}*/}
			{/*	                                                                                             data-tip="Garanties de l'emprunteur"*/}
			{/*	                                                                                             data-multiline='true'/> </span>*/}
			{/*</div>*/}
			{/*<Row justify='center'>*/}
			{/*	<Col md={6} style={{color: props.config.theme.primary, fontSize: '19px', fontWeight: 'bold'}}>*/}
			{/*		{toFrenchNumber(premium)} &euro;*/}
			{/*	</Col>*/}
			{/*</Row>*/}
			{extraGuarantees.length > 0 && (
				<>
					{extraGuarantees.map((item) => {
						return <div className='insurer-card-row'>
							{item}
						</div>
					})}
				</>
			)}
		</div>
	</div>;
}
