import React from 'react';
import Shell from '../../components/Shell';
import {connect} from 'react-redux';
import {Row, Col} from 'react-grid-system';
import history from '../../history';
import FormButton from '../../components/Form/FormButton/index'
import './styles.css'
import {isMobile} from 'react-device-detect';
import Translate from "../../components/CustomTranslate";

class Bravo_signature extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			bravo: {},
			submitted: false
		}
	}

	getStep() {
		return 'bravo';
	}

	render() {
		return (
			<Row justify="center">
				<Col md={10} className="incomeRow">
					<Row justify="center">
						<Col sm={10} xs={12} lg={9} md={10} align="center" className="bravo_title"
						     style={{marginBottom: !isMobile && '10vh', fontSize: isMobile ? '16px' : '1.3vw', lineHeight: !isMobile && "2.4vw"}}>

							{this.props.borrower.first_name}, vous allez recevoir, <b>par mail</b>, un lien qui vous permettra de compléter votre questionnaire chez notre partenaire
							<span style={{whiteSpace: "nowrap"}}><b> Afi-Esca</b></span>.

						</Col>
					</Row>
					<Row justify="center" className='title_calendar'>
						<Col align="center" sm={10} xs={12} lg={6} md={8}>
							<div style={{marginBottom: "30px", fontSize: !isMobile && '1.3vw', lineHeight: !isMobile && "30px"}}>N'hésitez pas à nous appeler au <b
								style={{color: this.props.config.theme.primary}}>01 76 41 05 48</b> ou à prendre <b><a href="/rendez-vous">rendez-vous</a></b> avec
								notre conseiller qui pourra vous aider.
							</div>
						</Col>
					</Row>
					<Row justify="center" className='title_calendar'>
						<Col align="center" sm={10} xs={12} lg={6} md={8}>
							<FormButton name={'thatButton'} id='registrationButton' className={'finalButton'}
							            onClick={() => history.push('/personal_home')}
							            value={<Translate value='generic.continue'/>}/>
						</Col>
					</Row>
				</Col>
			</Row>
		)
	}

}

export default connect()(Shell(Bravo_signature));
