import React from 'react';
import Shell from '../../components/Shell';
import {Row, Col} from 'react-grid-system';
import FormButton from '../../components/Form/FormButton';
import Translate from "components/CustomTranslate";
import connect from 'react-redux/es/connect/connect';
import history from '../../history';
import {ValidatorForm} from 'react-material-ui-form-validator';
import CountriesField from '../../components/Form/CountryField/index'
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {
	AdditionalDetails,
	BirthPlace,
	FirstName,
	LastName,
	PostalCode,
	Salutations,
	StreetDetails,
	Town,
	Phone,
	Email,
	SubscriberName,
	SubscriberTown,
	SubscriberАddress,
	SubscriberPostalCode,
	SubscriberCompany,
	SubscriberCompanyPhone,
	SubscriberNumber,
	SubscriberCompanyStatus, SubscriberCompanyEmail, MaidenName
} from '../../components/Form/StepsFields/step4';

import './style.css';

import {getWebsiteCountry, mapData} from '../../js/helpers/functions';
import BackButton from "../../components/Form/BackButton";
import {StringField} from "../../components/Form/Fields";

class PersonalInfo extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			validated: false,
			showAddressError: false,
			sameAddress: true,
			uniqueEmailError: '',
			click_id: sessionStorage.getItem('click_id'),
			source: sessionStorage.getItem('utm_source'),
			source_tracked: false,
			realZip: false
		};
	}

	getStep() {
		return 'step4';
	}

	componentDidMount() {
		if (this.props.borrower.zip) {
			this.getTownFromPostal(this.props.borrower.zip);
		}
	}

	personalData(coBorrower = false) {
		let update = coBorrower ? this.props.updateCoBorrower : this.props.updateBorrower;
		let data   = coBorrower ? this.props.coborrower : this.props.borrower;

		return (<>
			{/*TODO:theme*/}
			<div className="incomeRow">
				<Row justify='center'>
					<Col md={8} style={{textAlign: 'center'}}>
						<p className='titlePrets'>
							<Translate value={coBorrower ? 'step4.coPersonalInfo' : 'step4.personalInfo'}/>
						</p>
					</Col>
				</Row>

				<Row justify='center'>
					<Col md={4}>
						<Salutations id={coBorrower ? 'cobo-salutation' : 'salutation'} update={update} data={data}
							             disabled={this.props.config.login_type === 'france_connect' && !!data.salutation}/>
					</Col>
					<Col md={4}>
						<FirstName id={coBorrower ? 'cobo-name' : 'name'} update={update} data={data}
							           disabled={this.props.config.login_type === 'france_connect' && !!data.first_name}/>
					</Col>
					<Col md={4}>
						<LastName id={coBorrower ? 'cobo-lastName' : 'lastName'} update={update} data={data}
							          disabled={this.props.config.login_type === 'france_connect' && !!data.last_name}/>
					</Col>
				</Row>

				<Row justify='center'>
					<Col md={4}>
						<BirthPlace id={coBorrower ? 'cobo-birth-place' : 'birth-place'} update={update} data={data}
							            disabled={this.props.config.login_type === 'france_connect' && !!data.birth_city}/>
					</Col>
					<Col md={4}>
						<CountriesField update={update} data={data}/>
					</Col>
					<Col md={4}>
						<StringField value={data.birth_zip}
							             validators={['required']}
							             errorMessages={['Cette information doit être renseignée']}
							             onChange={(e) => update('birth_zip', e.target.value)}
							             label={'Département de naissance'}/>
					</Col>
				</Row>

				<Row justify='center'>
					<Col md={4}>
						<MaidenName data={data} update={update}/>
					</Col>
					<Col md={4}>
						<Phone id={coBorrower ? 'cobo-mobile-number' : 'mobile_number'} update={update} borrower={this.props.borrower} data={data}
							       uniquePhoneError={this.state.uniquePhoneError}
							       updateUniquePhone={(value) => this.setState({uniquePhoneError: value})}/>
					</Col>
					<Col md={4}>
					</Col>
				</Row>

				{coBorrower &&
						<Row justify='center'>
						    <Col md={6}>
						        <Email update={update} data={data} borrower={this.props.borrower} uniqueEmailError={this.state.uniqueEmailError}
								       updateUniqueEmail={(value) => this.setState({uniqueEmailError: value})}/>
						    </Col>
						</Row>}
			</div>
		</>
		);
	}

	createAddressToValidate() {
		const addressObject = {
			street_details: this.props.borrower.address,
			additional_address: this.props.borrower.additional_address || '',
			city: this.props.borrower.city
		};

		return `${addressObject.address}${addressObject.additional_address ? ', ' + addressObject.additional_address : ''}, ${addressObject.city}`;
	}

	googleApiValidation(req, callback) {
		if (window.google && window.google.maps) {
			let geocoder = new window.google.maps.Geocoder();
			geocoder.geocode ? geocoder.geocode(req, (result) => callback(result)) : callback(null);
		} else {
			callback(null);
		}
	}

	getTownFromPostal(postal_code) {
		return new Promise((resolve) => {
			let country = getWebsiteCountry(this.props.translations.locale);
			this.googleApiValidation({'address': postal_code + ', ' + country}, (result) => {
				let data = {};
				if (result && result.length > 0) {
					this.setState({realZip: true})
					data = result[0].address_components.find((c) => 'locality' === c.types[0]);
				} else {
					this.setState({realZip: false});
				}

				if (data && data.long_name && this.props.borrower.city !== data.long_name) {
					this.props.updateBorrower('city', data.long_name);
					if (this.props.borrower.has_coborrower && this.state.sameAddress) {
						this.props.updateCoBorrower('city', data.long_name);
					}
				}
				resolve();
			});
		});
	}

	validateAddress(request) {
		return new Promise((resolve) => {
			this.setState({validated: false});
			this.googleApiValidation(request, (result) => {
				let data = {};
				result && result.length > 0 && result[0].address_components.forEach((c) => {
					if (['zip', 'route', 'street_number', 'locality'].indexOf(c.types[0]) !== -1) {
						data[c.types[0]] = c.long_name;
					}
				});
				if (data['zip'] === this.props.borrower.zip) {
					if (this.props.borrower.address.indexOf(data['route']) === -1) {
						if (data['street_number'] && data['route']) {
							this.props.updateBorrower('address', data['street_number'] + ' ' + data['route']);
						}
					}
					if (data['locality'] && this.props.borrower.city !== data['locality']) {
						this.props.updateBorrower('city', data['locality']);
					}

					this.setState({validated: true});
				}
				resolve();
			});
		});
	}

	beneficiaryAddress() {
		return (
			<div>
				<Row justify='center'>
					<Col md={8} style={{textAlign: 'center'}}>
						<p className='titlePrets'>
							<p>Souscripteur</p>
						</p>
					</Col>
				</Row>
				<Row justify='center'>
					<Col md={6}>
						<SubscriberName update={this.props.updateBorrower} data={this.props.borrower}/>
					</Col>
					<Col md={6}>
						<SubscriberCompany update={this.props.updateBorrower} data={this.props.borrower}/>
					</Col>
				</Row>
				<Row justify='center'>
					<Col md={6}>
						<SubscriberNumber update={this.props.updateBorrower} data={this.props.borrower}/>
					</Col>
					<Col md={6}>
						<SubscriberCompanyStatus update={this.props.updateBorrower} data={this.props.borrower}/>
					</Col>
				</Row>
				<Row justify='center'>
					<Col md={6}>
						<SubscriberTown update={this.props.updateBorrower} data={this.props.borrower}/>
					</Col>
					<Col md={6}>
						<SubscriberАddress update={this.props.updateBorrower} data={this.props.borrower}/>
					</Col>
				</Row>
				<Row>
					<Col md={6}>
						<SubscriberPostalCode update={this.props.updateBorrower} data={this.props.borrower}/>
					</Col>
					<Col md={6}>
						<SubscriberCompanyEmail update={this.props.updateBorrower} data={this.props.borrower}/>
					</Col>
				</Row>
				<Row>
					<Col md={6}>
						<SubscriberCompanyPhone update={this.props.updateBorrower} data={this.props.borrower}/>
					</Col>
				</Row>
			</div>)
	}

	addressData(coBorrower = false) {
		let update = (key, value) => {
			if (!coBorrower) {
				this.props.updateBorrower(key, value);
				if (this.props.borrower.has_coborrower && this.state.sameAddress) {
					this.props.updateCoBorrower(key, value);
				}
			} else {
				this.props.updateCoBorrower(key, value);
			}
		};
		let data   = coBorrower ? this.props.coborrower : this.props.borrower;

		return (
			<div>
				<Row justify='center'>
					<Col md={6}>
						<StreetDetails update={update} data={data} isCoborrower={coBorrower}/>
					</Col>
					<Col md={6}>
						<AdditionalDetails update={update} data={data} isCoborrower={coBorrower}/>
					</Col>
				</Row>

				<Row justify='center'>
					<Col md={6}>
						<PostalCode update={update} additionalError={!this.state.realZip} isCoborrower={coBorrower} data={data} locale={this.props.translations.locale} updateTown={this.getTownFromPostal.bind(this)}/>
					</Col>
					<Col md={6}>
						<Town update={update} isCoborrower={coBorrower} data={data}/>
					</Col>
				</Row>
			</div>
		);
	}

	checkAddressData(value) {
		this.setState({sameAddress: value});
		if (value === true) {
			['address', 'additional_address', 'zip', 'city'].forEach(field => {
				this.props.updateCoBorrower(field, this.props.borrower[field]);
			});
		}
	}

	mapData(b, cb) {
		let data = {
			borrower:
				{
				    email: b.email,
				    first_name: b.first_name,
				    last_name: b.last_name,
				    city: b.city,
				    salutation: b.salutation,
				    zip: b.zip,
				    address: b.address,
				    birth_city: b.birth_city,
				    birth_zip: b.birth_zip,
				    maiden_name: b.maiden_name,
				    additional_address: b.additional_address,
				    step: 'donnes_personales',
				    phone: b.phone || '',
				    birth_country: b.birth_country,
				    subscriber_name: b.subscriber_name,
				    subscriber_town: b.subscriber_town,
				    subscriber_zip: b.subscriber_zip,
				    subscriber_address: b.subscriber_address,
				    subscriber_company: b.subscriber_company,
				    subscriber_company_number: b.subscriber_company_number,
				    subscriber_company_status: b.subscriber_company_status,
				    subscriber_company_email: b.subscriber_company_email,
				    subscriber_company_phone: b.subscriber_company_phone,

				},
			state: {
				borrower: this.props.borrower
			}
		};
		if (b.has_coborrower) {
			data.has_coborrower   = true;
			data.coborrower       = {
				email: cb.email,
				phone: cb.phone || '',
				first_name: cb.first_name,
				last_name: cb.last_name,
				city: this.state.sameAddress ? b.city : cb.city,
				salutation: cb.salutation,
				zip: this.state.sameAddress ? b.zip : cb.zip,
				birth_zip: b.birth_zip,
				address: this.state.sameAddress ? b.address : cb.address,
				birth_city: cb.birth_city,
				additional_address: this.state.sameAddress ? b.additional_address : cb.additional_address,
				birth_country: cb.birth_country
			};
			data.state.coborrower = this.props.coborrower;
		}
		return data;
	}

	render() {
		return (
			<div>
				{this.pixels()}
				<ValidatorForm onSubmit={async () => {
					if (!this.state.realZip) {
						return false;
					}
					this.props.updateConfig('loading', true);
					// await this.validateAddress({address: this.createAddressToValidate()});
					this.props.updateCustomer(this.mapData(this.props.borrower, this.props.coborrower))
						.then(async response => {
							if (response && response.data && response.data.result) {
								!!this.props.borrower.has_coborrower && this.props.updateCoBorrower('registered', true);
								await this.props.checkLemoine(mapData(Object.assign({}, this.props.borrower, {step: 'profession'}), this.props.coborrower, this.props.loan));
								history.push('profession');
							} else if (response && response.data && response.data.error && response.data.error.error === 'duplicate') {
								let error = response.data.error;
								this.props.updateConfig('loading', false);
								this.props.showModal({
									dialogTitle: 'Oups',
									dialogOpen: true,
									dialogContent: 'Un compte avec cette ' + (error.reason === 'email' ? 'adresse email' : 'numéro de mobile') + ' existe déjà.'
								});
								this.setState(error.reason === 'email' ?
									{uniqueEmailError: 'Email existe déjà.'} :
									{uniquePhoneError: 'Ce numéro de mobile existe déjà'}//,"Numéro de mobile existe déjà."}
								);
							}
						});
				}}>
					{this.personalData(false)}
					{!!this.props.borrower.has_coborrower && this.personalData(true)}

					<div className="incomeRow">
						<Row justify='center'>
							<Col md={12} style={{textAlign: 'center'}}>
								<p className='titlePrets' style={{textAlign: 'center !important'}}>
									<Translate value='step4.streetAddress'/>
								</p>
							</Col>
						</Row>

						{this.addressData(false)}

						{!!this.props.borrower.has_coborrower && (
							<Row justify='center'>
								<Col md={8} className='centeredText'>
									<FormControlLabel
										id="coborrower_has_same_address"
										control={
											<Checkbox
												checked={this.state.sameAddress}
												style={{color: this.props.config.theme.primary}}
												onChange={(e, value) => this.checkAddressData(value)}
											/>
										}
										label={<Translate value='step4.sameAddress'/>}
									/>
								</Col>
							</Row>
						)}
					</div>

					{!!this.props.borrower.has_coborrower && !this.state.sameAddress && this.addressData(true)}
					{this.props.loan.sci && this.beneficiaryAddress()}
					<div style={{marginTop: '20px'}}>
						<Row justify="center" style={{marginTop: '10px'}}>
							<Col md={5}>
								<BackButton flow={this.props.loan.flow}/>
							</Col>
							<Col md={5}>
								<FormButton name='suivant' value={<Translate value='generic.follow'/>}/>
							</Col>
						</Row>

					</div>
				</ValidatorForm>
			</div>
		);
	}

	pixels() {
		let source = sessionStorage.getItem('utm_source');
		if (source === 'weedoit') {
			return <iframe src={`https://tracker-l2.wee-do-it.net/track/lead?idcpart=18081&idr=${this.state.click_id}`} width="0" height="0" frameBorder="0"
			               scrolling="no" onLoad={() => sessionStorage.removeItem('utm_source')}></iframe>;
		}
	}
}

export default connect()(Shell(PersonalInfo));
