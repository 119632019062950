import React from 'react';
import {Col, Row} from "react-grid-system";
import history from "../../history";
import Translate from "components/CustomTranslate";

export const PersonalSpaceHeader = (props) => {
	let md = props.showResigning ? 3 : 2;
	return (
		<div>
			<Row justify="center">
				{((props.contract && props.contract[0] && props.contract[0]?.sign_date) || props.health_selection) && <>
					<Col md={md} className="personal_nav" onClick={() => history.push('/personal_home')}>
						<span className={history.location.pathname === '/personal_home' ? 'active_steps' : 'personal_nav_span'}><Translate value='personal.header.home' /></span>
					</Col>
					<Col md={md} className="personal_nav" onClick={() => history.push('/personal_steps')}>
						<span className={history.location.pathname === '/personal_steps' ? 'active_steps' : 'personal_nav_span'}><Translate value='personal.header.steps' /></span>
					</Col>
					<Col md={md} className="personal_nav" onClick={() => history.push('/personal_documents')}>
						<span className={history.location.pathname === '/personal_documents' ? 'active_steps' : 'personal_nav_span'}><Translate value='personal.header.documents' /></span>
					</Col>
					{props.showResigning && <Col md={md} className="personal_nav" onClick={() => history.push('/personal_resigning')}>
						<span className={history.location.pathname === '/personal_resigning' ? 'active_steps' : 'personal_nav_span'}>Documents à signer</span>
					</Col>}
				</>}
				<Col md={md} className="personal_nav" onClick={() => history.push('/personal_affiliate')}>
					<span className={history.location.pathname === '/personal_affiliate' ? 'active_steps' : 'personal_nav_span'}><Translate value='personal.header.affiliates' /></span>
				</Col>
			</Row>
			<br/>
			<h2 style={{textAlign: 'center', color: '#373355'}}><Translate value={props.title} dangerousHTML/></h2>
		</div>
	)
}
