import React from 'react';
import {Col, Row} from "react-grid-system";
import StandardButton from "../Form/StandardButton";
import history from "../../history";
import Translate from "../CustomTranslate";

export default function IncomeCard(props) {

	return (
		<Row justify="center" className="incomeCard">
			<Col md={12}
			     onClick={props.onClick} style={{paddingTop: '16px'}}>
				<Row justify="center" style={{textAlign: 'center', marginBottom: '12px'}}>
					<Col md={10}>
						<span style={{color: props.color, fontSize: '34px'}}><span style={{fontWeight: 600}}>wedou.bg </span>{props.plan}</span>
					</Col>
				</Row>
				<Row justify="center" style={{backgroundColor: '#F6FAFB', paddingBottom: '15px', height: props.totalNum > 4 ? '355px' : '255px', lineHeight: '15px', alignContent: 'flex-start'}}>
					<Col md={12}>

						{props.itt &&
							<p>
							    <span className="boldGuarantees"><Translate value="incomeProtection.coverages.itt"/></span><i className="fa fa-check" style={{color: '#49D090', float: 'right'}}/><br/>
							    <span className="incomeClarification"><Translate value="incomeProtection.coverages.ifCaseOfAccident"/></span>
							</p>}
						{props.ipp &&
							<span>
							    <p>
							        <span className="boldGuarantees">	<Translate value="incomeProtection.coverages.ipp"/> </span>
							        <i className="fa fa-check" style={{color: '#49D090', float: 'right'}}/><br/>
							        <span className="incomeClarification"><Translate value="incomeProtection.coverages.ifCaseOfAccident"/></span></p>

							</span>}
						{props.telemedicine &&
							<span>
							    <p className="boldGuarantees"><Translate value="incomeProtection.coverages.telemedicine"/> <i className="fa fa-check" style={{color: '#49D090', float: 'right'}}/></p>
							</span>}
						{props.unemployment &&
							<span>
							    <p className="boldGuarantees"><Translate value="incomeProtection.coverages.unemployment"/> <i className="fa fa-check" style={{color: '#49D090', float: 'right'}}/></p>
							</span>}
						<p>
							<span className="boldGuarantees"><Translate value="incomeProtection.coverages.death"/> </span><i className="fa fa-check" style={{color: '#49D090', float: 'right'}}/><br/>
							<span className="incomeClarification"><Translate value="incomeProtection.coverages.payOffFamily"/></span>
						</p>
					</Col>
				</Row>
				<Row justify="center" style={{textAlign: 'center', padding: '15px 0'}}>
					<Col md={12}>
						<span className="globalPricing" style={{fontWeight: 500, fontSize: '32px'}}>{props.price} лв./месец</span>
					</Col>
				</Row>

				<Row justify="center">
					<Col md={8}>
						<StandardButton style={{borderRadius: '25px', backgroundColor: props.color, fontSize: '11px !important'}} onClick={() => {
							if (props.isLogged) {
								history.push('income_personal')
							} else {
								props.openAuthModal('registration');
							}
						}} value="Напред"/>
					</Col>
				</Row>

			</Col>
		</Row>
	);
}
