import attachments from './attachments';
import borrower from './borrower';
import coborrower from './coborrower';
import config from './config';
import loan from './loan';
import {combineReducers} from 'redux';
import {RELOAD_STATE} from "../constants/action-types";

const appReducer  = combineReducers({
	attachments,
	borrower,
	coborrower,
	config,
	loan
});

const rootReducer = (state, action) => {
	if (action.type === RELOAD_STATE) {
		state = {...state, ...action.payload};
	}
	return appReducer(state, action)
};

export default rootReducer;
