import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText/DialogContentText";
import IconButton from '@material-ui/core/IconButton';
import {isMobile} from 'react-device-detect';
import femaleImage from './female.png';
import style from './modal.module.css';
import doctorImage from './doctor.svg';

// import DialogActions from '@material-ui/core/DialogActions';
class Modal extends React.Component {

	constructor(props) {
		super(props);
		this.content = props;
		this.state   = {
			dialogOpen: false,
		}
	}

	render() {
		if (this.props.dialogOpen) {
			return <div>

				<Dialog
					className={this.props.className || style.modalFrame}
					open={this.props.dialogOpen}
					onClose={this.props.onClose}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
					minWidth={this.props.minWidth || "sm"}
					maxWidth={this.props.maxWidth || "sm"}
					disableBackdropClick={this.props.disableBackdropClick || false}
				>
					{!this.props.disableImage && <img src={this.props.image || (this.props.hqImage ? doctorImage : femaleImage)} className={`${this.props.hqImage ? style.doctorImage : style.femaleImage} ${this.props.imageStyle || ''}`}/>}
					<DialogTitle id="alert-dialog-title" className={!this.props.type && style.dialogTitle}>
						<span className={isMobile ? style.titleModalPhone : style.titleModal}>{this.props.title}</span>
						<IconButton aria-label="close" className={style.closeButton} onClick={() => this.props.onClose()}>
							<i className='fa fa-close'/>
						</IconButton>
					</DialogTitle>
					<DialogContent style={this.props.noContentPadding && {padding: 0}}>
						<DialogContentText>{this.props.children}</DialogContentText>
					</DialogContent>
				</Dialog>
			</div>
		} else {
			return <div/>
		}

	}
}

export default Modal;
