import {COBORROWER_UPDATE, COBORROWER_INITIAL_FETCH, GET_COBORROWER, COBORROWER_SET} from "../../constants/action-types";
import {post} from "../../helpers/rest";
import {getBackendUrl} from "../../helpers/functions";

export const updateCoBorrower = (key, value) => {
	return dispatch => {
		dispatch({
			type: COBORROWER_UPDATE,
			key,
			value
		});
	}
};
export const coborrowerFetch  = (data) => {
	return dispatch => {
		dispatch({
			type: COBORROWER_INITIAL_FETCH,
			data
		});
	}
};


export const getCoborrower = (params) => {
	return dispatch => {
		return dispatch(() => {
			return post(params, getBackendUrl() + "/customer/coborrower").then(res => {
				return dispatch({
					type: GET_COBORROWER,
					data: res
				})
			})
		})
	}
};

export const setCoBorrower = (data) => {
	return dispatch => {
		dispatch({
			type: COBORROWER_SET,
			data
		});
	}
};
