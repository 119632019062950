import React from 'react';
import Modal from '../../components/Modal';
import Shell from '../../components/Shell';
import {Row, Col} from 'react-grid-system';
import FormButton from '../../components/Form/FormButton';
import Translate from "components/CustomTranslate";
import connect from 'react-redux/es/connect/connect';
import Pret from './pret.png'

import {ValidatorForm} from 'react-material-ui-form-validator';

import './style.css';
import ReactTooltip from 'react-tooltip';
import history from '../../history';
import {mapData, numberWithSpaces, getBackendUrl} from 'js/helpers/functions';
import {salutations} from "js/helpers/data";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {
	InterestRate,
	LoanAmount,
	LoanDurationMonths,
	LoanDurationYears,
	ProjectType,
	YourBank,
	LoanContractDate, DelayedLoan
} from "components/Form/StepsFields/step1";
import {BirthDate, Insurer} from "components/Form/StepsFields/step2";
import {
	AdditionalDetails,
	BirthPlace, Email,
	FirstName,
	LastName,
	Phone,
	PostalCode,
	Salutations,
	StreetDetails,
	Town
} from "components/Form/StepsFields/step4";
import {Profession, RiskyProfession, Sports, ExactProfession, RiskyProfessionAfiesca, Smoker} from "components/Form/StepsFields/step5";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import BackButton from "components/Form/BackButton";
import style from "../../components/Offer/style.module.css";

class Resume extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			updateForm: '',
			updateFor: 'borrower',
			dialogOpen: false,
			errorDialogOpened: false,
			dialogContent: '',
			uniqueEmailError: '',
			hqDialogOpen: false,
			bravoDialogOpen: false,
			openPret: true,
			openPretCobo: true,
			open: true,
			medicalExaminationAccepted: true
		}
	}

	getStep() {
		return 'step7';
	}

	bravoDialog() {
		return (
			<Modal minWidth="lg" maxWidth="lg" title='Oops'
			       dialogOpen={this.state.bravoDialogOpen}
			       config={this.props.config}
			       onClose={() => this.setState({bravoDialogOpen: false})}>
				<div style={{margin: '15px 0 40px 0'}}>Nous n'avons pas de solution pour cette simulation. <br/> Êtes-vous sûr de ne pas avoir fait une erreur 🙂?</div>
			</Modal>
		)
	}

	loanDetails() {
		let data = this.props.loan;

		let rows = [
			{name: <Translate value='step1.chooseBank'/>, value: (this.props.config.banks.find(b => b.key === data.bank) || {}).name},
			{name: <Translate value='step1.projectType'/>, value: (this.props.config.project_types.find(p => p.key === data.project_type) || {}).name},
			{name: <Translate value='step1.loanContractDate'/>, value: data.contract_date},
		];

		for (let i = 0; i < data.total_loans; i++) {
			rows.push(
				{
					name: <div className="resumeLoan"><img src={Pret} height='40px'/>
						<div style={{marginTop: '10px', marginLeft: '5px'}}><span><Translate value='generic.loan'/> {i + 1}</span></div>
					</div>, value: null
				},
				{name: <Translate value='step1.amount'/>, value: `${numberWithSpaces(data.loans[i].amount)} €`},
				{name: <span><Translate value='step1.durationOfLoan'/> <Translate value='step1.durationYears'/></span>, value: data.loans[i].duration_years},
				{name: <span><Translate value='step1.durationOfLoan'/> <Translate value='step1.durationMonths'/></span>, value: data.loans[i].duration_months},
				{name: <Translate value='step1.rate' dangerousHTML/>, value: `${parseFloat(data.loans[i].interest_rate).toFixed(2)} %`},
				{name: <Translate value='credit.deffered'/>, value: <span className='deffered'><Translate value={`generic.${data.loans[i].deferred ? 'yes' : 'no'}`}/></span>},
			);
		}

		return (
			<div id="selector" className={this.state.openPret ? 'visible' : 'hide'}>
				<TableContainer component={Paper}>
					<Table aria-label="simple table">
						<TableHead>
							<TableRow>
								<TableCell colspan={2}>
									<div style={{float: 'left', marginLeft: '15px'}}><h2><Translate value='step10.loanSection'/></h2></div>
									{/*<div onClick={() => this.setState({openPret: !this.state.openPret})} className="toggleTable"*/}
									{/*     style={{position: 'absolute', top: '30px', right: '30px'}}>*/}
									{/*	{this.state.openPret ? <IoIosArrowDropupCircle size={25}/> : <IoIosArrowDropdownCircle size={25}/>}*/}
									{/*</div>*/}
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>

							<>
								{rows.map((row) =>
									<TableRow className="resumeRow" key={row.name}>
										<TableCell className="resumeCell" component="th" scope="row"><b>{row.name}</b></TableCell>
										{/*TODO:theme color: ..*/}
										<TableCell style={{color: '#75ACBA'}} className="resumeCell rightCell" align="right">{row.value}</TableCell>
									</TableRow>
								)}
								<TableRow>
									<TableCell/>
									{/*TODO:theme color: ..*/}
									<TableCell align="right"><span style={{color: '#75ACBA', border: `1px solid #75ACBA`}}
									                               className="resumeEdit"
									                               onClick={() => this.setState({dialogOpen: true, updateForm: 'loan'})}>Modifier</span></TableCell>
								</TableRow>
							</>

							{this.bravoDialog()}
						</TableBody>
					</Table>
				</TableContainer>
			</div>
		);
	}


	personalDetails(coBorrower = false) {
		let data              = coBorrower ? this.props.coborrower : this.props.borrower;
		let profession        = this.props.config.professions.find(p => p.flow === this.props.loan.flow && p.key === data.profession);
		let risky_professions = this.props.config.risky_professions.find(p => p.flow === this.props.loan.flow && p.key === data.risky_profession);
		let sport             = this.props.config.sports.find(s => s.key === data.sport);
		let rows              = [
			{name: <Translate value='step4.salutation'/>, value: (salutations.find(s => s.key === data.salutation) || {}).name},
			{name: <Translate value='step4.firstName'/>, value: data.first_name},
			{name: <Translate value='step4.lastName'/>, value: data.last_name},
			{name: <Translate value='step4.placeOfBirth'/>, value: data.birth_city},
			{name: <Translate value='step2.birthDate'/>, value: data.birth_date},
			{name: <Translate value='step4.streetDetails'/>, value: data.address},
			{name: <Translate value='step4.additionalDetails'/>, value: data.additional_address},
			{name: <Translate value='step4.postalCode'/>, value: data.zip},
			{name: <Translate value='step4.town'/>, value: data.city},
			{name: <Translate value='step5.smokerLabel' dangerousHTML/>, value: <Translate value={data.smoker ? 'generic.yes' : 'generic.no'}/>},
			{name: <Translate value='step5.isPolitical' dangerousHTML/>, value: <Translate value={data.is_political ? 'generic.yes' : 'generic.no'}/>},
			{name: <Translate value='step9.phone'/>, value: data.phone},
			{name: <Translate value={coBorrower ? 'step4.coborrowerEmail' : 'step3.email'}/>, value: data.email}
		];

		return (
			<Row justify="center">
				<Col md={12}>
					<div id="selector" className={this.state.open ? 'visible' : 'hide'}>
						<TableContainer component={Paper}>
							<Table aria-label="simple table">
								<TableHead>
									<TableRow>
										<TableCell colspan={2}>
											<div style={{float: 'left'}}><h2><Translate value={coBorrower ? 'step4.coPersonalInfo' : 'step4.personalInfo'}/></h2></div>
											{/*<div onClick={() => this.setState({open: !this.state.open})} className="toggleTable"*/}
											{/*     style={{position: 'absolute', top: '30px', right: '30px'}}>*/}
											{/*	{this.state.open ? <IoIosArrowDropupCircle size={25}/> : <IoIosArrowDropdownCircle size={25}/>}*/}
											{/*</div>*/}
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{rows.map((row) =>
										<TableRow className="resumeRow" key={row.name}>
											<TableCell className="resumeCell" component="th" scope="row"><b>{row.name}</b></TableCell>
											{/*TODO:theme color: ..*/}
											<TableCell style={{color: '#75ACBA'}} className="resumeCell rightCell" align="right">{row.value}</TableCell>
										</TableRow>
									)}
									<TableRow className="resumeRow" key="profession">
										<TableCell className="resumeCell" component="th" scope="row"><b><Translate value='step5.profession'/></b></TableCell>
										{/*TODO:theme color: ..*/}
										<TableCell style={{color: '#75ACBA'}} className="resumeCell rightCell"
										           align="right">{profession && profession.name}</TableCell>
									</TableRow>
									{this.props.loan.flow !== 'mncap' && risky_professions && risky_professions.name &&
									<TableRow className="resumeRow" key="risky_profession">
										<TableCell className="resumeCell" component="th" scope="row"><b><Translate value='step5.riskyProfession'/></b></TableCell>
										{/*TODO:theme color: ..*/}
										<TableCell style={{color: '#75ACBA'}} className="resumeCell rightCell"
										           align="right">{risky_professions.name}</TableCell>
									</TableRow>}
									{this.props.loan.flow === 'afi-esca' && <>
										<TableRow className="resumeRow" key="sport">
											<TableCell className="resumeCell" component="th" scope="row"><b>Vous pratiquez des sports à risques ?</b></TableCell>
											<TableCell style={{color: '#75ACBA'}} className="resumeCell rightCell" align="right">{sport.name}</TableCell>
										</TableRow>
										<TableRow className="resumeRow" key="exact_profession">
											<TableCell className="resumeCell" component="th" scope="row"><b>Quelle est votre profession exacte?</b></TableCell>
											<TableCell style={{color: '#75ACBA'}} className="resumeCell rightCell"
											           align="right">{data.exact_profession}</TableCell>
										</TableRow>
									</>}

									<TableRow>
										<TableCell/>
										<TableCell style={{margin: 'auto'}} align="right">
											<span style={{color: '#75ACBA', border: `1px solid ${'#75ACBA'}`}} className="resumeEdit"
											      onClick={() => this.setState({
												      dialogOpen: true, updateForm: 'personal', updateFor: coBorrower ? 'coborrower' : 'borrower'
											      })}>Modifier</span>
										</TableCell>
									</TableRow>
								</TableBody>
							</Table>
						</TableContainer>
					</div>
				</Col>
			</Row>
		);
	}

	render() {
		return (
			<ValidatorForm onSubmit={async () => {
				this.props.updateConfig('loading', true);
				this.props.updateBorrower('initial', true)
				let response = await this.props.finalCalculation(mapData(Object.assign({}, this.props.borrower, {step: 'resume'}), this.props.coborrower, this.props.loan))
				if (response && response.data && response.data.error) {
					let error = response.data.error;
					if (error.error === 'duplicate') {
						this.setState({
							uniqueEmailError: error.reason === 'email' ? "Email existe déjà." : "Ce numéro de mobile existe déjà",//"Numéro de mobile existe déjà.",
							errorDialogOpened: true,
							errorDialogContent: 'Un compte avec cette ' + (error.reason === 'email' ? 'adresse email' : 'numéro de mobile') + ' existe déjà.'
						});
					}

				} else if (response.data) {
					if (response.data.result) {
						if (this.props.loan.flow === 'mncap') {
							if (this.props.borrower.lemoine && this.props.borrower.lemoine.outcome === false) {
								this.props.updateConfig('loading', false);
								this.setState({hqDialogOpen: true});
							} else {
								history.push('/offre')
							}
						} else {
							history.push('/offre');
						}
					} else {
						this.setState({bravoDialogOpen: true})
					}
				}
			}
			}>
				<Row justify="center">
					<Col className="titles" style={{textAlign: 'center', marginBottom: '3vh'}}>
						{this.props.borrower.first_name}, voici vos réponses.<br/>
						Merci de les relire attentivement et de les corriger si nécessaire.
					</Col>
				</Row>
				<Row justify="center">
					<Col md={6}>
						{this.loanDetails()}
					</Col>
					<Col md={6}>
						{this.personalDetails(false)}
					</Col>

				</Row>
				{!!this.props.borrower.has_coborrower && <Row justify="center">
					<Col md={6}>
						{this.personalDetails(true)}
					</Col>
				</Row>}
				<Row justify='center' style={{marginTop: '15px'}}>
					<Col md={5} xs={12} sm={12}>
						<BackButton flow={this.props.loan.flow}/>
					</Col>
					<Col md={5} xs={12} sm={12}>
						<FormButton id='resume_button' value={<Translate value='generic.follow'/>}/>
					</Col>
				</Row>
				<Modal minWidth="lg" maxWidth="lg" title={'Correction des données'}
				       dialogOpen={this.state.dialogOpen}
				       config={this.props.config}
				       onClose={() => this.setState({dialogOpen: false})}>
					{this.updateForm()}
					<Row justify="center">
						<Col md={6}>
							<FormButton value={<Translate value='generic.follow'/>}
							            onClick={() => this.setState({dialogOpen: false})}/>
						</Col>
					</Row>
				</Modal>

				<Modal title={'Oups'}
				       dialogOpen={this.state.errorDialogOpened}
				       config={this.props.config}
				       onClose={() => this.setState({errorDialogOpened: false})}
				>{this.state.errorDialogContent}</Modal>
				<Modal
					dialogOpen={this.state.hqDialogOpen}
					config={this.props.config}
					type='questionnaire'
					hqImage={true}
					onClose={() => this.setState({hqDialogOpen: false})}>
					{sessionStorage.getItem('hqp') === 'disabled' ?
						<Row justify='center'>
							<Col md={8}>
								<h1 className="titleModalQuest">Warning</h1>
								<p>
									You are logged as a client. To prevent issues with this contract, the simulation is stopped here. Please contact the client and ask them to
									sign.
								</p>
							</Col>
						</Row>
						:
						<div>
							<Row justify="center">
								<Col md={8}>
									<h1 className="titleModalQuest" style={{paddingLeft: '150px'}}>Questionnaire de Santé :</h1><br/>
									<p style={{fontSize: '14px', color: 'black'}}> Nous attirons votre attention sur la nécessité de compléter avec le plus grand soin ce questionnaire. L’absence de réponse à au moins une
										question nous empêchera de statuer sur votre demande individuelle d'adhésion à l'assurance. Comme le prévoit la convention AERAS, nous vous
										conseillons de prendre seul, connaissance, soit sur place, soit à votre domicile, des dispositions et questions figurant dans le
										questionnaire.
										<b>Conformément aux dispositions relatives au <a href={`${getBackendUrl()}/static/GRAmars2021.pdf`} target="_blank" rel="noreferrer">Droit à l’oubli</a> de la convention AERAS</b>, si vous avez été atteint de certaines pathologies,
										vous avez la possibilité de ne pas déclarer ce risque <b>sous certaines conditions</b>, énoncées dans le document d’information sur le droit à l’oubli, annexé à ce questionnaire.<br/><br/>
										Pour plus d’information sur le <a href={`${getBackendUrl()}/static/GRAmars2021.pdf`} target="_blank" rel="noreferrer">Droit à l’oubli</a>, nous
										vous invitons à consulter le site officiel de la convention AERAS : www.aeras-infos.fr.
										Quelles que soient les réponses données dans ce questionnaire, vous êtes formellement invité à le transmettre, avec votre Demande
										Individuelle d’Adhésion, au Médecin Conseil du service médical de la Mutuelle sous pli confidentiel à : <b>MNCAP - à l'attention du Médecin Conseil – 5 rue Dosne - 75116 PARIS</b> ou par tous moyens à votre convenance.<br/><br/>

										Vous pouvez accélérer l'étude de votre dossier en joignant à ce questionnaire tout document se rapportant à votre santé (bilans biologiques,
										radiologies, copies d'ordonnances, etc). Le Médecin Conseil de la Mutuelle se réserve le droit de vous demander la communication de
										documents médicaux complémentaires.<br/><br/>

										<b>Vos déclarations sont valables 6 mois, sauf en cas de substitution d’assurance auquel cas elles demeurent valables 14 mois, à compter de la signature du questionnaire.</b>
									</p>
								</Col>
							</Row>
							<Row className="profession-question-wrapper" justify="center">
								<Col md={8}>
									<FormControlLabel
										id="acceptCheckbox"
										control={
											<Checkbox
												name="CheckBox"
												checked={this.state.medicalExaminationAccepted}
												onChange={(e, s) => {
													this.setState({medicalExaminationAccepted: s});
												}}
												className={`${style.acceptCheckbox}`}
												color="white"

											/>
										}
										label={<Translate value="step7.medicalExaminationAccepted" email={this.props.borrower.email}/>}
									/>
								</Col>
							</Row>
							<Row justify='center'>
								<Col md={4} style={{marginTop: '20px'}}>
									<FormButton value={<Translate value='generic.follow'/>} onClick={() => {
										this.setState({hqDialogOpen: false});
										return this.props.mncapQuestionnaire()
											.then(() => {
												history.push('/sante');
											})
									}}/>
								</Col>
							</Row></div>
					}

				</Modal>
			</ValidatorForm>
		);
	}

	updateForm() {
		switch (this.state.updateForm) {
			case 'loan':
				return this.loanDetailsForm();
			case 'personal':
				return this.personalDetailsForm();
			case 'professional':
				return this.professionalDetailsForm();
			default:
				return this.loanDetailsForm();

		}
	}

	loanDetailsForm() {
		let data   = this.props.loan;
		let update = this.props.updateLoan;
		let loans  = [];

		for (let i = 0; i < data.total_loans; i++) {
			loans.push(
				<div>
					<Row justify='center'>
						<h4 className='centeredText'><Translate value='generic.loan'/> {i + 1}</h4>
					</Row>

					<Row justify="center">
						<Col md={3}>
							<LoanAmount update={update} data={data.loans} loan={i}/>
						</Col>
						<Col md={6}>
							<Row>
								<Col md={6} xs={6} sm={6}>
									<LoanDurationYears update={update} data={data.loans} loan={i} flow={this.props.loan.flow}/>
								</Col>
								<Col md={6} xs={6} sm={6}>
									<LoanDurationMonths update={update} data={data.loans} loan={i} flow={this.props.loan.flow}/>
								</Col>
							</Row>

						</Col>
						<Col md={3}>
							<InterestRate update={update} data={data.loans} loan={i}/>
						</Col>
					</Row>
					<Row justify='center'>
						{this.props.loan.loans[i].deferred &&
						<Col md={5}>
							<DelayedLoan update={update} data={data.loans} loan={i} loan_status={this.props.loan.loan_status}/>
						</Col>}
					</Row>
					<Row justify='center'>
						<Col md={5} className='centeredText'>
							<FormControlLabel
								control={
									<Checkbox
										checked={this.props.loan.loans[i].deferred}
										value={this.props.loan.loans[i].deferred}
										onChange={(e, val) => this.props.updateLoan([i, 'deferred'], val)}
										style={{color: '#75ACBA'}}
									/>
								}
								label={<Translate value='credit.deffered'/>}
							/>
						</Col>
					</Row>
				</div>
			);
		}
		return (
			<div>
				<Row justify="center">
					<Col md={5}>
						<YourBank data={data} update={update} banks={this.props.config.banks}/>
					</Col>
					<Col md={5}>
						<ProjectType data={data} update={update} projects={this.props.config.project_types} flow={this.props.loan.flow}/>
					</Col>
					<Col md={5}>
						<Insurer data={data} update={update} insurers={this.props.config.insurers}/>
					</Col>
					<Col md={5}>
						<LoanContractDate disabled={true} data={data} update={update} flow={this.props.loan.flow} config={this.props.config}
						                  updateBorrower={this.props.updateBorrower} updateCoBorrower={this.props.updateCoBorrower}/>
					</Col>
				</Row>
				{loans}
			</div>
		);
	}

	personalDetailsForm() {
		let coBorrower   = this.state.updateFor === 'coborrower';
		let data         = coBorrower ? this.props.coborrower : this.props.borrower;
		let update       = coBorrower ? this.props.updateCoBorrower : this.props.updateBorrower;
		let afiesca_data = this.props.config.risky_profession_afiesca.filter(p => p.category_code === data.risky_profession);
		return (
			<div>
				<ReactTooltip className="resume-tooltip"/>
				<Row justify='center'>
					<Col md={8}>
						<p className='separatorHeading'>
							<Translate value={coBorrower ? 'step4.coPersonalInfo' : 'step4.personalInfo'}/>
						</p>
					</Col>
				</Row>
				<Row justify='center'>
					<Col md={4}>
						<Salutations data={data} update={update} disabled={this.props.config.login_type === 'france_connect' && !!data.salutation}/>
					</Col>
					<Col md={4}>
						<FirstName data={data} update={update} disabled={this.props.config.login_type === 'france_connect' && !!data.first_name}/>
					</Col>
					<Col md={4}>
						<LastName data={data} update={update} disabled={this.props.config.login_type === 'france_connect' && !!data.last_name}/>
					</Col>
				</Row>

				<Row justify='center'>
					<Col md={4}>
						<BirthPlace data={data} update={update} disabled={this.props.config.login_type === 'france_connect' && !!data.birth_city}/>
					</Col>
					<Col md={4}>
						<BirthDate data={data} update={update} disabled={true}/>
					</Col>
					<Col md={4}>
						<Town data={data} update={update}/>
					</Col>
				</Row>

				<Row justify='center'>
					<Col md={4}>
						<StreetDetails data={data} update={update}/>
					</Col>
					<Col md={4}>
						<AdditionalDetails data={data} update={update}/>
					</Col>
					<Col md={4}>
						<PostalCode data={data} update={update}/>
					</Col>
				</Row>

				{coBorrower &&
				<Row>
					<Col md={6}>
						<Phone update={update} borrower={this.props.borrower} data={data} uniquePhoneError={this.state.uniquePhoneError}
						       updateUniquePhone={(value) => this.setState({uniquePhoneError: value})}/>
					</Col>
					<Col md={6}>
						<Email update={update} data={data} borrower={this.props.borrower} uniqueEmailError={this.state.uniqueEmailError}
						       updateUniqueEmail={(value) => this.setState({uniqueEmailError: value})}/>
					</Col>
				</Row>
				}

				<Row>
					<Col md={6} className="professions">
						<Profession data={data} update={update} profession={this.props.config.professions.filter(p => p.flow === this.props.loan.flow)}/>
					</Col>
					<Col md={6} className="riskyProfessions">
						<RiskyProfession data={data} flow={this.props.loan.flow} risky_profession={this.props.config.risky_professions.filter(p => p.flow === this.props.loan.flow)}
						                 update={update}/>
					</Col>
				</Row>
				{this.props.loan.flow === 'afi-esca' && <>
					<Row>
						<Col md={6} className="sports">
							<Sports data={data} sports={this.props.config.sports} update={update}/>
						</Col>
						<Col md={6} className="exact_profession">
							<ExactProfession data={data} update={update}/>
						</Col>
					</Row>
					<Row style={{marginBottom: '30px'}}>
						{afiesca_data.length > 0 &&
						<Col md={6} className="riskyProfessions">
							<RiskyProfessionAfiesca data={data} risky_profession_afiesca={afiesca_data} update={update}/>
						</Col>
						}
						<Col md={6} style={{paddingTop: '22px'}}>
							<Row>
								<Col md={4}>
									<p><span data-tip='y compris la cigarette électronique, consommez-vous quotidiennement des boissons alcoolisées, ou faites-vous
				usage de drogues ou stupéfiants '>Fumez-vous</span> ?</p>
								</Col>
								<Col md={6}>
									<Smoker data={data} update={update}/>
								</Col>
							</Row>
						</Col>
					</Row>
				</>}
			</div>
		);
	}

	professionalDetailsForm() {
		let coBorrower = this.state.updateFor === 'coborrower';
		let data       = coBorrower ? this.props.coborrower : this.props.borrower;
		let update     = coBorrower ? this.props.updateCoBorrower : this.props.updateBorrower;
		return (
			<div>
				<Row justify='center'>
					<Col md={8}>
						<p className='separatorHeading'>
							<Translate value={coBorrower ? 'step5.coProfessionalInfo' : 'step5.professionalInfo'}/>
						</p>
					</Col>
				</Row>
				<Row justify='center'>
					<Col md={5} className="professions">
						<Profession data={data} update={update} profession={this.props.config.professions.filter(p => p.flow === this.props.loan.flow)}/>
					</Col>
					<Col md={5} className="riskyProfessions">
						<RiskyProfession flow={this.props.loan.flow} data={data} risky_profession={this.props.config.risky_professions.filter(p => p.flow === this.props.loan.flow)}
						                 update={update}/>
					</Col>
				</Row>
			</div>
		);
	}
}

export default connect()(Shell(Resume));
