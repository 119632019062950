import React from 'react';
import Shell from '../../components/Shell';
import connect from 'react-redux/es/connect/connect';
import {isMobile} from 'react-device-detect';
import {Row, Col} from 'react-grid-system';

import style from './style.module.css';

class Signature extends React.Component {

	getStep() {
		return 'step10';
	}

	render() {
		let web_signature, mobile_signature;
		if (this.props.config.isCoborrower) {
			web_signature    = process.env.REACT_APP_DESKTOP_SIGNATURE_COBORROWER;
			mobile_signature = process.env.REACT_APP_MOBILE_SIGNATURE_COBORROWER;
		} else {
			web_signature    = process.env.REACT_APP_DESKTOP_SIGNATURE;
			mobile_signature = process.env.REACT_APP_MOBILE_SIGNATURE;
		}

		let signature = isMobile ? mobile_signature : web_signature;
		return (
			<Row>
				<Col md={12}>
					<iframe title="wedou-sign" className={style.signing} src={this.props.config.sign.signLink + signature}/>
				</Col>
			</Row>
		);
	}

}

export default connect()(Shell(Signature));