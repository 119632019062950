import React from 'react';
import history from "../../history";
import Translate from "components/CustomTranslate";
import {steps} from "../../js/helpers/data";
import SaveProgress from 'components/SaveProgress';
import Button from "@material-ui/core/Button";
import {FaPhoneAlt} from 'react-icons/fa'
import Modal from '../Modal/index';
import {BsFillChatFill} from 'react-icons/bs'
import {BsPersonFill} from 'react-icons/bs'
import style from './style.module.css';
import {Col, Row} from 'react-grid-system';
import Window from "../../containers/Window";
import sidebarAgent from './agent.png';
const I18n = require('react-redux-i18n').I18n;

class SideBar extends React.Component {
	constructor() {
		super();

		this.state = {
			route: window.location.pathname,
			openModal: false,
			openModalTelephone: false,
			height: (this.getActiveStep(this.props?.flow) + 1) * 20,
			notCalendarPage: ['rendez-vous-page', 'rendez-vous'].every(e => window.location.href.indexOf(e) === -1)
		}
	}


	getActiveStep(flow) {
		let activeStep = 0;
		let index      = steps && steps[flow] ? steps[flow].findIndex(step => step.page === window.location.pathname) : 1;

		if (index > 1 && index < 3) {
			activeStep = 1;
		} else if (index >= 3 && index < 8) {
			activeStep = 2;
		} else if (index >= 8 && index < 9) {
			activeStep = 3;
		} else if (index >= 9 || (index === -1 && window.location.pathname !== '/prets')) {
			activeStep = 4;
		} else {
			activeStep = 0;
		}

		return activeStep;
	}

	sideBarButtons() {
		let isWedouSite = ['app.wedou.fr', 'stage.wedou.fr', 'app.wedou.bg', 'staging.homelifeinsurance.fr','dev.wedou.fr', 'es.wedou.care', 'app.wedou.care'].indexOf(window.location.hostname) !== -1;
		let number      = isWedouSite ? I18n.t('header.sidebar.mobile') : this.props.config.theme.mobile;
		return (<>
			<Row style={{marginBottom: '5px'}}>
				<Col style={{fontSize: '2.2vh', textAlign: 'center', color: isWedouSite ? 'white' : this.props.config.theme.primary}}>
					<Translate value="header.sidebar.help" dangerousHTML/>
				</Col>
			</Row>
			{/*TODO:theme*/}
			{/*<Row style={{color: this.props.config.theme.secondary}} className={style.phoneNumber}>*/}
			<Row style={{color: '#FE7A59'}} className={style.phoneNumber}>
				<Col style={{textAlign: 'center'}}>
					{/*TODO:theme*/}
					{/*<FaPhoneAlt size={17}/><a href={'tel:' + number} style={{color: this.props.config.theme.secondary, fontSize: '20px', fontWeight: '600'}}> {number}</a>*/}
					<FaPhoneAlt size={17} color={isWedouSite ?  '#FE7A59' : this.props.config.theme.secondary}/><a href={'tel:' + number} style={{color:isWedouSite ?  '#FE7A59' : this.props.config.theme.secondary, fontSize: '20px', fontWeight: '600'}}> {number}</a>
				</Col>
			</Row>
			{/*TODO:theme*/}
			<Row style={{backgroundColor: isWedouSite ? '#F6B60D' : this.props.config.theme.buttonFirst}} className={style.besoin}>
				<Col onClick={() => {
					document.getElementById('chat-wrapper').style.display = 'block';
					document.body.classList.add('chatOpened');
				}}>
					<BsFillChatFill style={{marginRight: '5px'}}/> <Translate value="header.sidebar.chat" dangerousHTML/>
				</Col>
			</Row>
			{/*TODO:theme*/}
			<Row style={{backgroundColor: isWedouSite ? '#F6B60D' : this.props.config.theme.buttonFirst}} className={style.besoin} onClick={() => {
				if (this.state.notCalendarPage) {
					this.props.updateConfig('openCalendar', true);
				}
			}}>
				<Col>
					<BsPersonFill style={{marginRight: '5px'}}/> <Translate value="header.sidebar.meeting" dangerousHTML/>
				</Col>
			</Row>
		</>)
	}

	render() {
		let logo            = this.props.config.theme.logoHeader;
		let isWedouSite     = ['app.wedou.fr', 'staging.homelifeinsurance.fr', 'stage.wedou.fr', 'app.wedou.bg', 'dev.wedou.fr', 'es.wedou.care', 'app.wedou.care'].indexOf(window.location.hostname) !== -1;
		let redirectionLink = isWedouSite ? I18n.t('header.sidebar.website') : this.props.config.theme.website;

		return <div style={{backgroundImage: this.props.config.theme.sidebar}} className='sidebar'>
			<Modal
				dialogOpen={this.state.notCalendarPage && this.props.config.openCalendar}
				minWidth="lg"
				maxWidth="false"
				noContentPadding={true}
				config={this.props.config}
				title={<span><Translate value='meetings.heading' dangerousHTML/></span>}
				onClose={() => this.props.updateConfig('openCalendar', false)}>
				<Window data={this.props.borrower} update={this.props.updateBorrower} updateConfig={this.props.updateConfig} bravoContact={this.props.bravoContact}
				        config={this.props.config} inPopup={true}/>
			</Modal>
			<>
				<Row justify="center" style={{marginTop: '3vh'}}>
					<Col className="centeredText">
						<img src={logo} className={style.wedou_logo} alt="logo"
						     onClick={() => {
							     window.location.href = redirectionLink
						     }
						     }/>
					</Col>
				</Row>
				<div className='accountContainer'>
					{!this.props.config.loggedIn ?
						<Button className={'loginLogoutButton'} style={{color: isWedouSite ? 'white' : this.props.config.theme.primary, borderColor: isWedouSite ? 'white' : this.props.config.theme.primary}} name={'logButton'} onClick={() => this.props.openAuthModal('login')}>
							<strong><Translate value="header.alreadyClient"/></strong>
						</Button> :
						<>
							<Button className={'loginLogoutButton'} name={'logButton'}
							        style={{color: isWedouSite ? 'white' : this.props.config.theme.primary, borderColor: isWedouSite ? 'white' : this.props.config.theme.primary}}
							        onClick={() => this.props.updateConfig('sideMenu', true)}>
								<Translate value="header.myProfile"/>
							</Button>
							{this.props.translations.locale !== 'bg' &&
							<Button className={'loginLogoutButton'} name={'affiliateButton'}
							        style={{marginTop: 0, color: isWedouSite ? 'white' : this.props.config.theme.primary, borderColor: isWedouSite ? 'white' : this.props.config.theme.primary}}
								onClick={() => history.push('/personal_affiliate')}>
								<Translate value="personal.header.affiliates"/>
							</Button>
							}
						</>
					}
				</div>
				{this.props.translations.locale !== 'bg' && <Row style={{width: '250px', margin: 'auto'}}>
					{/*TODO:theme*/}
					{/*<div style={{backgroundColor: this.props.config.theme.secondary}} className={style.loader}>*/}
					<div style={{backgroundColor: '#75ACBA'}} className={style.loader}>
						<div style={{borderRadius: '15px', backgroundColor: 'white', height: `${this.state.height}%`}}>

						</div>
					</div>
					<div style={{marginTop: "20px", marginBottom: '2vh'}}>
						<span className={style.loaderPages} style={{fontWeight: this.state.height >= '20' ? '600' : '300', color: this.props.config.theme.secondary}} onClick={() => history.push('/prets')}><Translate
							value="header.sidebar.your_loan"/></span>
						<span className={style.loaderPages} style={{fontWeight: this.state.height >= '40' ? '600' : '300', color: this.props.config.theme.secondary}} onClick={() => history.push('/comparison')}><Translate
							value="header.sidebar.your_economy"/></span>
						<span className={style.loaderPages} style={{fontWeight: this.state.height >= '60' ? '600' : '300', color: this.props.config.theme.secondary}}
						      onClick={() => history.push('/données_personnelles')}><Translate value="header.sidebar.info"/></span>
						<span className={style.loaderPages} style={{fontWeight: this.state.height >= '80' ? '600' : '300', color: this.props.config.theme.secondary}} onClick={() => history.push('/offre')}><Translate
							value="header.sidebar.contract"/></span>
						<span className={style.loaderPages} style={{fontWeight: this.state.height >= '100' ? '600' : '300', color: this.props.config.theme.secondary}} onClick={() => history.push('/offre')}><Translate
							value="header.sidebar.signature"/></span>
					</div>
				</Row>}
				<Row justify="center">
					<Col style={{textAlign: 'center'}}>
						<img src={this.props.config.theme.assistanceLogo || sidebarAgent} style={{width: '120px'}}/>
					</Col>
				</Row>
				{this.sideBarButtons()}
				{(!this.props.borrower.has_signed) && this.props.config.loggedIn && <Row justify="center">
					<Col md={9}>
						<SaveProgress config={this.props.config} updateProgress={this.props.updateProgress} borrower={this.props.borrower}
						              coborrower={this.props.coborrower} loan={this.props.loan}/>
					</Col>
				</Row>}
			</>
		</div>
	}
}

export default SideBar;
