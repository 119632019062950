import React from 'react';
import Shell from '../../components/Shell';
import {connect} from 'react-redux';
import {Row, Col} from 'react-grid-system';
import Button from "@material-ui/core/Button";
import history from "../../history";
import Translate from "components/CustomTranslate";
import {isMobile} from "react-device-detect";
import FormButton from "../../components/Form/FormButton";
import {getBackendUrl} from "../../js/helpers/functions";
import {post} from "../../js/helpers/rest";
import CircularProgress from '@material-ui/core/CircularProgress';
import ssoHighFive from "./ssoHighFive.png";
import StandardButton from "../../components/Form/StandardButton";

class BravoMigrated extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			bravo: {},
			submitted: false,
			sso_disabled: true,
		}

		setTimeout(() => {
			this.setState({sso_disabled: false});
		}, 4 * 1000);
	}

	getStep() {
		return 'will_receive_mail';
	}

	render() {
		return (
			<Row justify="center">
				<Col md={10} className="incomeRow">
					<Row justify="center">
						<Col sm={10} xs={12} lg={10} md={10} align="center" className="bravo_title"
						     style={{marginBottom: '8vh', marginTop: isMobile ? '5px' : '50px', fontSize: isMobile ? '16px' : '1.5vw', lineHeight: !isMobile && "2.4vw"}}>
							<Translate value={'sso.first_header'} first_name={this.props.borrower.first_name} dangerousHTML/>
						</Col>
					</Row>
					<Row justify="center">
						<Col sm={10} xs={12} lg={9} md={10} align="center" className="bravo_title"
						     style={{fontSize: isMobile ? '16px' : '1.3vw', lineHeight: !isMobile && "2.4vw"}}>
							<img src={ssoHighFive} style={{maxWidth: isMobile ? '300px' : '500px'}}/>
						</Col>
					</Row>
					<Row justify="center">
						<Col sm={10} xs={12} lg={10} md={10} align="center" className="bravo_title"
						     style={{marginBottom: '8vh', marginTop: isMobile ? '5px' : '50px', fontSize: isMobile ? '16px' : '1.5vw', lineHeight: !isMobile && "2.4vw"}}>
							<Translate value={'sso.second_header'} first_name={this.props.borrower.first_name} dangerousHTML/>
						</Col>
					</Row>
					<Row justify="center" className='title_calendar' style={{marginTop: '20px'}}>
						<Col align="center" sm={10} xs={12} lg={6} md={8}>
							<FormButton name={'thatButton'} id='continueButton' className={'finalButton'} disabled={this.state.sso_disabled}
							            onClick={() => {
								            this.props.updateConfig('loading', true);
								            post({
									            borrower: this.props.borrower,
								            }, getBackendUrl() + "/calculate/client_sso")
									            .then((result) => {
										            let url = result?.sso?.clientInsurerAccess?.urlSsoAccess;
										            if (url) {
											            window.location.href = url;
											            // window.open('url', '_blank');
										            }
									            })
									            .catch((e) => {
										            console.warn('SSO request issue:', e);
										            // this.setState({loading: false});
										            this.props.updateConfig('loading', false);
									            });
							            }}
							            value={this.state.sso_disabled ? <CircularProgress color={this.props.config.theme.secondary || '#fff'}/> : <Translate value={'sso.button'} />}/>
						</Col>
					</Row>
					<Row justify="center" className='title_calendar' style={{marginTop: '70px'}}>
						<Col align="center" sm={10} xs={12} lg={8} md={8}>
							<div style={{marginBottom: "20px", fontSize: !isMobile && '1.3vw', lineHeight: !isMobile && "30px"}}>N'hésitez pas à nous appeler au <b
								style={{color: this.props.config.theme.primary}}>01 76 41 05 48</b> ou à prendre <b><a href="/rendez-vous">rendez-vous</a></b> avec
								notre conseiller qui pourra vous aider.
							</div>
						</Col>
					</Row>
					<Row justify="center" className='title_calendar'>
						<Col align="center" sm={10} xs={12} lg={3} md={3}>
							<Button className='formButton backButton' style={{borderRadius: '26px', backgroundColor: '#292F44', color: '#fff'}} onClick={() => history.push('/offre')}><Translate value="generic.back"/></Button>
						</Col>
						<Col align="center" sm={10} xs={12} lg={3} md={3}>
							<StandardButton name={'thatButton'} id='registrationButton' className={'finalButton'}
							                onClick={() => {
								                document.getElementById('chat-wrapper').style.display = 'block';
								                document.body.classList.add('chatOpened');
							                }}
							                value={<Translate value={'header.sidebar.chat'}/>}/>
						</Col>
					</Row>
				</Col>
			</Row>
		)
	}

}

export default connect()(Shell(BravoMigrated));
