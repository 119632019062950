import React from 'react';
import Shell from "../../components/Shell";
import {Row, Col} from 'react-grid-system';
import connect from 'react-redux/es/connect/connect';
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";

class AlreadyPassedHQ extends React.Component {
	getStep() {
		return 'co-sante-deja';
	}

	render() {
		return (<Row justify='center'>
			<Col md={6}>
				<TableContainer
					component={Paper}
					style={{
						boxShadow: '0 0 2pt 1pt #ccc',
						border: '2px solid transparent',
						display: 'block',
						minWidth: '200px',
						width: '100%',
						flex: '0 !important',
						cursor: 'pointer'
					}}>
					<Table size="medium">
						<TableBody>
							<TableRow>
								<TableCell component="th" scope="row" style={{fontSize: '18px'}}>
									<p>{this.props.coborrower.first_name} {this.props.coborrower.last_name}, vous avez déjà validé votre questionnaire de santé.</p>
									<p>Si vous besoin d'aide, appelez-nous au 01 76 42 05 48 ou prenez un rendez-vous en suivant ce <a href="/rendez-vous">lien</a>.</p>
								</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				</TableContainer>
			</Col>
		</Row>)
	}
}

export default connect()(Shell(AlreadyPassedHQ));
