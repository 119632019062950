import React from "react";
import {goBack} from "../../../js/helpers/functions";
import {Button} from "@material-ui/core";

import Translate from "components/CustomTranslate";
export default function BackButton(props) {

	return (
		<Button className='formButton backButton' style={{borderRadius: '26px', backgroundColor: '#292F44', color: '#fff'}} onClick={() => goBack(props.flow)}><Translate value="generic.back" /></Button>
	)
}
