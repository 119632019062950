import React from 'react';
import style from './style.module.css';
import {Col, Row} from 'react-grid-system';
import Translate from "../CustomTranslate";
import {translateString} from "../../js/helpers/functions"

const Footer = (props) => {
	let isWedouSite = ['app.wedou.fr', 'stage.wedou.fr',  'staging.homelifeinsurance.fr','app.wedou.bg', 'dev.wedou.fr', 'es.wedou.care', 'app.wedou.care'].indexOf(window.location.hostname) !== -1;

	let copyrights     = isWedouSite ? <Translate value="footer.copyright"/> : props.config.theme.copyrights;
	let poweredByWedou = '';
	if (!isWedouSite) { // render 'powered by Wedou' if site is not stage or prod
		poweredByWedou =
			<Col md={3} style={{paddingTop: "10px"}} className="centeredText">
			    <p>
			        <img src={props.config.theme.logoHeader} className={style.wedou_logo_footer} alt="wedou-logo"/>
			        <p style={{color: 'white', fontSize: '16px'}}><a href="https://wedou.fr">Wedou Assurances</a></p>
			        <p style={{color: 'white', fontSize: '16px'}}>votre partenaire prévoyance</p>
			    </p>
			</Col>;
	}

	let aboutLink  = isWedouSite ? translateString('footer.whoIsLink') : props.config.theme.aboutLink;
	let aboutTitle = isWedouSite ? translateString('footer.whoIs') : props.config.theme.aboutTitle;
	let about      = aboutLink ? <p><a href={aboutLink}>{aboutTitle}</a></p> : '';

	let legalLink  = isWedouSite ? translateString('footer.legalLink') : props.config.theme.legalLink;
	let legalTitle = isWedouSite ? translateString('footer.legalNotice') : props.config.theme.legalTitle;
	let legal      = legalLink ? <p><a href={legalLink}>{legalTitle}</a></p> : '';

	let aboutSection =
		    aboutLink || legalLink
			    ? <Col md={3}><p className={style.head}>
				    {isWedouSite
					    ? <Translate value="footer.about"/>
					    : props.config.theme.firstHeader
				    }</p>{about}{legal}</Col>
			    : '';

	let faqLink  = isWedouSite ? translateString('footer.faqLink') : props.config.theme.faqLink;
	let faqTitle = isWedouSite ? translateString('footer.faqTitle') : props.config.theme.faqTitle;
	let faq      = faqLink ? <p><a href={faqLink}>{faqTitle}</a></p> : '';

	let privacyLink  = isWedouSite ? translateString('footer.privacyLink') : props.config.theme.privacyLink;
	let privacyTitle = isWedouSite ? translateString('footer.policy') : props.config.theme.privacyTitle;
	let privacy      = privacyLink ? <p><a href={privacyLink}>{privacyTitle}</a></p> : '';

	let practicalSection =
		    faqLink || privacyLink
			    ? <Col md={3}><p className={style.head}>
				    {isWedouSite
					    ? <Translate value="footer.info"/>
					    : props.config.theme.secondHeader
				    }</p>{faq}{privacy}</Col>
			    : '';

	let rssLink = isWedouSite ? translateString('footer.rssLink') : props.config.theme.rssLink;
	let rss     = rssLink ? <a target="\_blank\" href={rssLink}>
		<li><i style={{color: '#fff'}} className="fa fa-rss"></i></li>
	</a> : '';

	let facebookLink = isWedouSite ? translateString('footer.facebookLink') : props.config.theme.facebookLink;
	let facebook     = facebookLink ? <a target="\_blank\" href={facebookLink}>
		<li><i className="fa fa-facebook-square" style={{color: '#fff'}} aria-hidden="true"></i></li>
	</a> : '';

	let twitterLink = isWedouSite ? translateString('footer.twitterLink') : props.config.theme.twitterLink;
	let twitter     = twitterLink ? <a target="\_blank\" href={twitterLink}>
		<li><i className="fa fa-twitter" style={{color: '#fff'}} aria-hidden="true"></i></li>
	</a> : '';

	let linkedInLink = isWedouSite ? translateString('footer.linkedInLink') : props.config.theme.linkedInLink;
	let linkedIn     = linkedInLink ? <a target="\_blank\" href={linkedInLink}>
		<li><i className="fa fa-linkedin" style={{color: '#fff'}} aria-hidden="true"></i></li>
	</a> : '';

	let socialsSection = rssLink || facebookLink || twitterLink || linkedInLink ?
		<Col md={3}>
			<div className={style.NoUnderline}>
				<p className={style.head}>
					{isWedouSite
						? <Translate value="footer.followUs"/>
						: props.config.theme.thirdHeader}</p>
				{rss}{facebook}{twitter}{linkedIn}
			</div>
		</Col> : '';

	return (
		<div style={{background: props.config.theme.footer}} className={style.personalFooter}>
			<Row style={{minHeight: "80px"}} justify='center'>
				{poweredByWedou}
				{aboutSection}
				{practicalSection}
				{socialsSection}
			</Row>
			<Row justify='center'>
				<Col md={12}>
					<p className={style.copyright}>{copyrights}</p>
				</Col>
			</Row>
		</div>
	);
};

export default Footer;
