import React from "react";
import {hydrate, render} from "react-dom";
import {Provider} from "react-redux";
import store from "./js/store";
import App from "./components/App";
import ErrorBoundary from "./errorBoundaries";

require('typeface-montserrat');
// if you're in create-react-app import the files as:
// import store from "./js/store/index";
// import App from "./js/components/App.jsx";
let root_element = document.getElementById("root");
let renderer     = root_element.hasChildNodes() ? hydrate : render;

renderer(
	<Provider store={store}>
		<ErrorBoundary>
			<App/>
		</ErrorBoundary>
	</Provider>,
	root_element
);
