import React, {useState, useRef} from 'react'
import {Row, Col} from 'react-grid-system';
import connect from 'react-redux/es/connect/connect';
import FormButton from "../../components/Form/FormButton";
import {ValidatorForm} from 'react-material-ui-form-validator';
import {StringField} from "../../components/Form/Fields/index";
import {Phone} from "../../components/Form/StepsFields/step4"
import history from '../../history'
import Img from './images/File.png'
import {getParameterByName, getLanguage} from '../../js/helpers/functions'
import {updateBorrower} from '../../js/actions/borrower';
import {jobInfo} from '../../js/actions/config'
import {isMobile, isTablet} from "react-device-detect";
import "./styles.css"
import Translate from "components/CustomTranslate";

const Index = (props) => {
	const [selectedFile, setSelectedFile] = useState('')
	const [fileBase64, setfileBase64] = useState('')
	const inputFile = useRef(null)
	const [error, setError] = useState('')
	const [isLogged, setIsLogged] = useState(false)

	const simulateClick = (e) => {
		e.preventDefault();
		inputFile.current.click()
	}

	const getBase64 = (file) => {
		var reader = new FileReader();

		if(file) {
			reader.readAsDataURL(file);
			reader.onload = () => {
				let base64 = reader.result
				setfileBase64(base64)
			};
			reader.onerror = (error) => {
				console.log(error)
			}
		}

	}

	const fileHandler = (e) => {
		const file = e.target.files[0]
		setSelectedFile(file)
		getBase64(file)
		setError('')
	}

	const submit = () => {
		let job = getParameterByName('position', window.location.href);

		if(!selectedFile) {
			setError(<Translate value='step1.errors.needsToBeFilled' />)
		}

		if(props.borrower.first_name_job !== '' && props.borrower.last_name_job !== '' && fileBase64 !== '') {
			props.jobInfo({
				first_name_job: props.borrower.first_name_job,
				last_name_job: props.borrower.last_name_job,
				email: props.borrower.email,
				telephone: props.borrower.phone,
				file: fileBase64,
				job_position: job,
				language: getLanguage()
			})
			setIsLogged(true)
		}
	}

	const loggedWindow = () => {
		return(
			<div>
				{isMobile || isTablet?
					<div>
						<div className='submitted-text-phone' ><Translate value='jobs_form.received'/></div>
						<div className='redirect'>
							<FormButton
								value='Redirect'
								onClick={() => {history.push('/')}}/>
						</div>
					</div>
					:
					<div className='wrapper-col'>
						<div>
							<div className='submitted-text'><Translate value='jobs_form.received'/></div>
							<div className='redirect'>
								<FormButton
									value={<Translate value='jobs_form.return' />}
									onClick={() => {
										if (window.location !== window.parent.location) {
											window.location.reload();
										} else {
											history.push('/')
										}
									}}/>
							</div>
						</div>
					</div>
				}
			</div>
		)
	}

	const main = () => {
		return(
			<div className='container-job'>
				<Row justify='center'>
					<Col>
						<StringField
							className='field-job'
							label=<Translate style={{color: '#5372e3'}} value='step4.firstName'/>
							name='first-name'
							value={props.borrower.first_name_job}
							onChange={(e) => props.updateBorrower('first_name_job', e.target.value)}
							validators={['required']}
							errorMessages={[<Translate value='step1.errors.needsToBeFilled' />]}
						/>
					</Col>
				</Row>

				<Row justify='center'>
					<Col>
						<StringField
							className='field-job'
							label=<Translate style={{color: '#5372e3'}} value='step4.lastName'/>
							name='last-name'
							value={props.borrower.last_name_job}
							onChange={(e) => props.updateBorrower('last_name_job', e.target.value)}
							validators={['required']}
							errorMessages={[<Translate value='step1.errors.needsToBeFilled' />]}
						/>
					</Col>
				</Row>

				<Row justify='center'>
					<Col>
						<StringField
							className='field-job'
							label=<Translate style={{color: '#5372e3'}} value='step3.email'/>
							name='email'
							value={props.borrower.email}
							onChange={(e) => props.updateBorrower('email', e.target.value)}
							validators={['required', 'isEmail']}
		                                errorMessages={[<Translate value='step1.errors.needsToBeFilled' />, 'L\'e-mail est incorrect']}
						/>
					</Col>
				</Row>

				<Row justify='center'>
					<Col>
						<Phone
							className='field-job'
							label=<Translate style={{color: '#5372e3'}} value='step9.phone'/>
							name='telephone'
							data={props.borrower}
							update={props.updateBorrower}
							validators={['required', 'isMobileNumber']}
		                                errorMessages={[<Translate value='step1.errors.needsToBeFilled' />, 'Ceci n\'est pas un n° de téléphone français.']}
						/>
					</Col>
				</Row>
				<Row justify='center'>
					<Col>
						<div className='job-div'>
							<button id='job-button' onClick={simulateClick}><Translate value='jobs_form.upload_cv'/> {selectedFile? <img className='file-img' src={Img} />: ''}</button>
							<input className='file' accept='.pdf' type='file' ref={inputFile} onChange={fileHandler}/>
							{error? <div className='error'>{error}</div>: ''}
						</div>
					</Col>
				</Row>
				<Row justify='center'>
					<Col>
						<FormButton
							id="suivant-job"
							value={<Translate value='jobs_form.send_button' />}
						/>
					</Col>
				</Row>
			</div>
		)
	}

	return(
		<div>
			<ValidatorForm
				onSubmit={submit}
			>
				<div>
					{isLogged ? loggedWindow() :
						(isTablet || isMobile)? main():
							<div className='wrapper-col'>
								{main()}
							</div>
					}
				</div>

			</ValidatorForm>
		</div>
	)
}

const mapStateToProps = (state) => {
	return {
		borrower: state.rootReducer.borrower,
	    config: state.rootReducer.config
	}
}

export default connect(mapStateToProps, {updateBorrower, jobInfo})(Index)
