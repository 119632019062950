import React from 'react';

import {ValidatorForm} from 'react-material-ui-form-validator';

let CustomValidatorForm = (props) => {
	return <ValidatorForm {...props} onSubmit={(e) => {
		props.onSubmit && props.onSubmit(e);
		// return !window.__MIRROR_AGENT ? props.onSubmit && props.onSubmit(e) : null;
	}}>{props.children}</ValidatorForm>
};

export {CustomValidatorForm};
