import React, {useState, useEffect} from "react";
import {loadStripe} from "@stripe/stripe-js";
import {Elements} from "@stripe/react-stripe-js";

import CheckoutForm from "./CheckoutForm";
import "./checkout.css";
import {getBackendUrl} from "../../../js/helpers/functions";
import axios from "axios";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is your test publishable API key.
const stripePromise = loadStripe("pk_test_dMqIpQXU4Q7UTYdSNPClV5bT");

export default function App() {
	const [clientSecret, setClientSecret] = useState("");

	useEffect(() => {
		// Create PaymentIntent as soon as the page loads
		return axios({
			method: 'post',
			withCredentials: true,
			url: getBackendUrl() + "/customer/create-subscription",
			data: JSON.stringify({items: [{id: "xl-tshirt"}]}),
			headers: {"Content-Type": "application/json"}
		}).then((res) => res.json())
			.then((data) => setClientSecret(data.clientSecret));
		// fetch(getBackendUrl() + "/customer/create-subscription", {
		// 	method: "POST",
		// 	headers: {"Content-Type": "application/json"},
		// 	body: JSON.stringify({items: [{id: "xl-tshirt"}]}),
		// })
		// 	.then((res) => res.json())
		// 	.then((data) => setClientSecret(data.clientSecret));
	}, []);

	const appearance = {
		theme: 'stripe',
	};
	const options    = {
		clientSecret,
		appearance,
	};

	return (
		<div className="App">
			{clientSecret && (
				<Elements options={options} stripe={stripePromise}>
					<CheckoutForm/>
				</Elements>
			)}
		</div>
	);
}
