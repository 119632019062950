import React from 'react';
import {connect} from 'react-redux';
import Shell from '../../components/Shell';
import {Col, Row} from "react-grid-system";
import FormButton from "../../components/Form/FormButton";
import Translate from "components/CustomTranslate";
import history from '../../history';
import AllGuarantees from "../../components/Guarantees/all_guarantees";
import {calculateGuarantees} from "../../js/helpers/functions";
import {all_equivalences} from "../../js/helpers/data";
import BackButton from "../../components/Form/BackButton";
import {I18n} from "react-redux-i18n";

class PreOffer extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			phaseOne: false,
			phaseTwo: false
		}
	}

	getStep() {
		return null;
	}

	bankGuaranteesTable(bank_guarantees) {
		let rows = [];

		bank_guarantees.forEach((r) => {
			let tip = ['incap_back', 'incap_psychological'].indexOf(r.key) !== -1 ? `guarantees.${r.key}.${r.include_only.indexOf(1) !== -1 ? 'tip' : 'tip_with_condition'}` : `guarantees.${r.key}.tip`;
			rows.push(
				<span style={{textAlign: 'left'}}><i style={{color: this.props.config.theme.primary, margin: '5px'}} className={`fa fa-check`}/><Translate
					value={['incap_back', 'incap_psychological'].indexOf(r.key) !== -1 ? `guarantees.${r.key}.${r.include_only.indexOf(1) !== -1 ? 'name' : 'name_with_condition'}` : `guarantees.${r.key}.name`}/>
				<span className='extra-guarantees-tooltip'>
					<i className={`fa fa-info`} style={{color: 'rgb(121, 121, 121)', marginLeft: '4px'}} data-tip={I18n.t(tip)} data-multiline='true'/>
				</span>
				</span>
			);
		});
		return rows;
	}

	render() {
		let {bank_guarantees} = calculateGuarantees(this.props, all_equivalences);
		return <>
			<Row justify="center">
				<Col md={6}>
					<p className="titlePrets"><Translate value="step2.title" /></p>
				</Col>
			</Row>
			<Row justify="center" style={{marginTop: '30px'}}>
				<Col md={11}>
					<Row justify="center">
						<Col md={10} className="incomeRow">
							<Row justify='center'>
								{Object.keys(this.props.config.comparison.insurers).map(insurer => {
									return <Col md={Object.keys(this.props.config.comparison.insurers).length === 3 ? 4 : 6} className='guarantees-cards-wrapper'>
										<AllGuarantees config={this.props.config} translations={this.props.translations} flow={insurer} loan={this.props.loan}
										               borrower={this.props.borrower}
										               bank_guarantees={bank_guarantees}/>
									</Col>
								})}
							</Row>
						</Col>
					</Row>
				</Col>
			</Row>
			<Row justify='center' style={{marginTop: '30px'}}>
				<Col md={5}>
					<BackButton flow={this.props.loan.flow}/>
				</Col>
				<Col md={5} sm={12} xs={12}>
					<FormButton id='stepConfirm2' value={<Translate value="generic.continue" dangerousHTML/>} name={'logButton'} onClick={() => {
						if (this.props.config.loggedIn) {
							history.push('/comparison');
						} else {
							this.props.openAuthModal('registration');
						}
					}}/>
				</Col>
			</Row>
		</>;
	}
}

export default connect()(Shell(PreOffer));
