import React from 'react';
import Shell from '../../components/Shell';
import {Row, Col} from 'react-grid-system';
import Translate from "components/CustomTranslate";
import connect from 'react-redux/es/connect/connect';
import FormButton from '../../components/Form/FormButton';
import {ValidatorForm} from 'react-material-ui-form-validator';

import history from '../../history';
import './style.css';

import NumberFormat from 'react-number-format';
import {Phone} from "../../components/Form/StepsFields/step4";

class PhoneNumber extends React.Component {

	componentWillMount() {
		if (this.props.config.isCoborrower) {
			this.props.updateLoan('flow', this.props.coborrower.flow);
		}
		this.borrower = this.props.isCoborrower ? this.props.coborrower : this.props.borrower;
		this.update   = this.props.isCoborrower ? this.props.updateCoBorrower : this.props.updateBorrower;
		if (window.parent.location.pathname === '/co-sante') {
			window.parent.location = '/co-numero';
		}
	}

	getStep() {
		return 'step9';
	}

	reassurances() {
		return (<div>
			<Row justify="center">
				<Col md={10} sm={12} xs={12} style={{marginTop: '45px'}}>
					<p>
						<Translate value='step9.p1.partOne' insurer={this.props.loan.flow} dangerousHTML/>
						<strong className={'noWrap'}>
							<NumberFormat value={this.props.config.calculation.monthly} displayType={'text'} thousandSeparator={" "} decimalScale={0} suffix={' €'}/>
						</strong>
						<Translate value='step9.p1.partTwo' dangerousHTML/>
					</p>
				</Col>
				<Col md={9}>
					<p><Translate value='step9.p2' dangerousHTML/></p>
				</Col>
			</Row>

			<Row justify='center'>
				<Col md={9}>
					<ul className={'documentsList'}>
						<li><Translate value='step9.li1' dangerousHTML/></li>
						<li><Translate value='step9.li2' dangerousHTML/></li>
						<li><Translate value='step9.li3' dangerousHTML/></li>
						<li><Translate value='step9.li4' dangerousHTML/></li>
					</ul>
				</Col>
			</Row>

		</div>);
	}

	render() {
		return (
			<ValidatorForm style={{color: this.props.config.theme.secondary}}  className='phoneNumberPage' onSubmit={() => {
				let borrower = this.props.config.isCoborrower ? this.props.coborrower : this.props.borrower;
				this.props.updateConfig('loading', true);
				let templates = 'all';
				if (this.props.loan.flow === 'afi-esca') {
					templates = ['HealthQuestionnaireAfiesca'];
					if (this.props.loan.loan_status !== 'new') {
						templates.push('SubstitutionMandate');
					}
				}
				if (this.props.loan.flow === 'mncap') {
					templates = ['FsiMncap', 'DIAMncap'];
				}
				this.props.documents({
					coborrower: true,
					flow: this.props.loan.flow,
					templates
				})
					.then(() => {
						this.props.sign({
							borrower,
							documents: this.props.config.documents
						})
							.then(() => {
								let update                                                         = {};
								update[this.props.config.isCoborrower ? 'coborrower' : 'borrower'] = {phone: borrower.phone};
								this.props.updateCustomer(update);
								this.props.updateConfig('loading', false);
								history.push('signing');
							});
					});

			}}>
				{!this.props.config.isCoborrower && this.reassurances()}

				<Row justify="center">
					<Col md={9}>
						<p><Translate value='step9.p3' dangerousHTML/></p>
					</Col>
					{this.props.config.isCoborrower &&
					<Col md={12}>
					    <Row justify='center'>
					        <Col md={6}>
					            <Phone update={this.props.updateCoBorrower} data={this.props.coborrower}/>
					        </Col>
					    </Row>
					</Col>}
				</Row>
				<Row justify="center" className="buttonSection">
					<Col md={4} sm={9} xs={9}>
						<FormButton value={<Translate value='generic.follow'/>}/>
					</Col>
				</Row>
			</ValidatorForm>
		)
	}

}

export default connect()(Shell(PhoneNumber));
