import {RE_INITIALIZE_ALL, RELOAD_STATE} from "../constants/action-types";

export const reInitializeState = (payload) => {
	return dispatch => {
		dispatch({
			type: RE_INITIALIZE_ALL,
			payload
		});
	}
};

export const reloadState = (payload) => {
	return dispatch => {
		return dispatch({
			type: RELOAD_STATE,
			payload
		});
	}
};