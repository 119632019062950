import React from 'react';
import {StringField} from '../Form/Fields';
import {ValidatorForm} from 'react-material-ui-form-validator';
import FormButton from '../Form/FormButton';
import {Row, Col} from 'react-grid-system';
import Translate from "components/CustomTranslate";
import history from "../../history";
import Modal from '../../components/Modal';
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import style from "../Offer/style.module.css";
import Captcha from '../Captcha';
import Turnstile from "react-turnstile";
// import Turnstile, {useTurnstile} from "react-turnstile";
import GoogleLogin from 'react-google-login';
import FacebookLogin from 'react-facebook-login';
import Button from "@material-ui/core/Button";
import FranceConnect from "../FranceConnect";
import {AutomationTest, getBackendUrl} from "../../js/helpers/functions";
import {post} from "../../js/helpers/rest";
import {Phone} from "../Form/StepsFields/step4";
import 'react-phone-number-input/style.css'
import FormControlLabel from "@material-ui/core/FormControlLabel";

// Used for reset of turnstile if needed
// const turnstile = useTurnstile();

class Authentication extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			password: '',
			accepted: false,
			notRobot: false,
			portrait: !this.props.registered,

			email: this.props.authEmail || '',
			sent: false,
			error: false,
			dialogOpen: this.props.authOpened,
			dialogTitle: '',//this.props.authType === 'login' ? 'Login' : 'Registration',
			dialogContent: this.methods,

			loginInfoMessage: ''
		};
	}

	componentDidMount() {
		window.addEventListener("resize", this.resize.bind(this));
		this.resize();
		// this.props.authOpened && this.setState({dialogOpened: this.props.authOpened});
	}

	resize() {
		// if (!this.props.registered) {
		this.setState({portrait: window.innerWidth < window.innerHeight});
		// }
	}

	verifyCallback() {
		this.setState({notRobot: true});
	}

	async registerIncome(data) {
		try {
			this.props.updateConfig('loading', true);
			data.borrower = this.props.borrower;
			await this.props.incomeRegistration(data);
			this.props.updateConfig('loading', false);
			history.push('/income_personal')
		} catch (error) {
			this.props.updateConfig('loading', false);
			this.setState({
				dialogOpen: true,
				dialogTitle: "Oups",
				dialogContent: 'Съществуващ имейл/телефон.'
			});
		}

	}

	register(data) {
		return this.props.income ? this.registerIncome(data) : this.registerLoan(data);
	}

	registerLoan(data) {
		this.props.updateConfig('loading', true);
		data.borrower       = this.props.borrower;
		data.coborrower     = this.props.coborrower;
		data.loan           = this.props.loan;
		data.calculation    = this.props.config.calculation;
		data.comparison     = this.props.config.comparison.insurers;
		data.affiliate_link = localStorage.getItem('affiliate_link');
		data.guid           = localStorage.getItem('guid');
		data.partner_source = this.props.config.partner_source;

		this.props.registration(data)
			.then(() => {
				this.props.updateConfig('login_type', data.type);
				data.registration = true;
				let redirect      = '/comparison';
				if (!this.props.borrower.phone && (data.type === 'facebook' || data.type === 'google')) {
					redirect = '/social_connect';
				} else if (!data.registration && this.props.borrower.step) {
					redirect = this.props.borrower.step;
				} else if (this.props.borrower.has_signed) {
					redirect = '/personal_home';
				}
				history.push(redirect);
			})
			.catch(error => {
				let message = error && error.response && error.response.data && error.response.data.reason === 'phone' ? "Ce numéro de téléphone n'existe pas!" : "Un compte avec cette adresse email existe déjà.";
				this.props.updateConfig('loading', false);
				this.setState({
					dialogOpen: true,
					dialogTitle: "Oups",
					dialogContent: message
				});
			});
	}

	login(data) {
		let options = {
			type: data.type,
			email: data.email,
			password: data.password,
			guid: localStorage.getItem('guid'),
		};
		if (data.type === 'facebook' || data.type === 'google') {
			options.userID      = data.userID;
			options.accessToken = data.accessToken;

		}
		if (data.type === 'france_connect') {
			options.accessToken = data.accessToken;
		}


		this.props.login(options).then(() => {
			this.props.updateConfig('login_type', data.type);
			let redirect = '/resultat';
			if (!this.props.borrower.phone && (data.type === 'facebook' || data.type === 'google')) {
				redirect = '/social_connect';
			} else if (this.props.borrower.has_signed) {
				redirect = '/personal_home';
			} else if (!data.registration && this.props.borrower.step) {
				if (window.location.href.indexOf(this.props.borrower.step) !== -1) {
					return window.location.reload();
				} else {
					redirect = this.props.borrower.step;
				}
			}
			history.push(redirect);
		}).catch(() => {
			if (options.type === 'email') {
				this.setState({
					dialogTitle: "Oups",
					dialogContent: [<span style={{color: this.props.config.theme.primary}}>Mauvais email ou mot de passe.</span>],
					dialogOpen: true
				});
			} else {
				this.setState({
					dialogTitle: "Oups",
					dialogContent: [
						<span key="error-div">
							<span style={{
								maxWidth: '100%',
								width: '400px',
								textAlign: 'center',
								padding: "10px 10px 20px",
								display: "block",
								color: this.props.config.theme.primary
							}}>Ce compte n'est pas enregistré <br/>Vous pouvez vous inscrire en suivant</span>
							<FormButton value="Calculer mon économie" onClick={() => history.push('/prets')}/>
						</span>
					],
					dialogOpen: true
				});
			}
		});
	}

	facebook() {
		return (<FacebookLogin
			appId={process.env.REACT_APP_FACEBOOK_APP_ID}
			autoLoad={false}
			fields='name,email'
			redirectUri={window.location.href}
			onClick={() => {
				// this.props.updateConfig('loading', true);
			}}
			callback={(res) => {
				if (res.accessToken) {
					let names = res.name.split(' ');

					this.props.updateBorrower('first_name', names.slice(0, -1).join(' '));
					this.props.updateBorrower('last_name', names[names.length - 1]);
					let data = {
						type: 'facebook',
						email: res.email,
						accessToken: res.accessToken,
						userID: res.userID,
						password: '',
					};
					if (this.props.authType === 'registration') {
						this.register(data);
					} else {
						this.login(data);
					}
				} else {
					this.props.updateConfig('loading', false);
				}

			}}
			disableMobileRedirect={true}
			textButton='Facebook'
		/>)
	}

	franceConnect() {
		return <FranceConnect config={this.props.config} type={this.props.authType}/>
	}

	google() {
		return <GoogleLogin
			clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
			buttonText="Google"
			className="googleLogin"
			onSuccess={(res) => {

				if (res.accessToken) {
					this.props.updateBorrower('first_name', res.profileObj.givenName);
					this.props.updateBorrower('last_name', res.profileObj.familyName);

					let data = {
						type: 'google',
						email: res.profileObj.email,
						accessToken: res.tokenId,
						userID: res.profileObj.googleId,
						password: '',
					};

					if (this.props.authType === 'registration') {
						this.register(data);
					} else {
						this.login(data);
					}
				} else {
					this.props.updateConfig('loading', false);
				}
			}}
			onFailure={(r) => console.log('failure', r)}
			cookiePolicy={'single_host_origin'}
		/>;
	}

	emailRegistration() {
		return <ValidatorForm className="emailForm" onSubmit={() => {
			this.register({
				type: 'email',
				accessToken: '',
				email: this.props.borrower.email,
				phone: this.props.borrower.phone,
			});
		}}>
			<Row justify="center" className="paddingTop">
				<Col lg={12} md={9}>
					<StringField
						value={this.props.borrower.email}
						onChange={(e) => this.props.updateBorrower('email', e.target.value)}
						id='emailTextField'
						name='email'
						validators={['isEmail']}
						errorMessages={['L\'e-mail est incorrect']}
						label={<Translate value='step3.email'/>}
						margin="normal"
					/>
				</Col>
			</Row>
			<Row justify='center'>
				<Col lg={12} md={9}>
					<Phone update={this.props.updateBorrower}
					       data={this.props.borrower}
					       uniquePhoneError={this.state.uniquePhoneError}
					       id='phoneTextField'
					       updateUniquePhone={(value) => {
						       this.setState({uniquePhoneError: value})
					       }}
					/>
				</Col>
			</Row>

			<Row justify="center">
				<Col lg={12} md={9}>
					<FormControlLabel
						id="acceptCheckbox"
						control={
							<Checkbox
								name="CheckBox"
								checked={this.state.accepted}
								onChange={(e, s) => this.setState({accepted: s})}
								className={`${style.acceptCheckbox}`}
								color="white"

							/>
						}
						label={<p className={style.acceptTerms}><Translate value='step3.accept' dangerousHTML/></p>}
					/>
				</Col>
			</Row>
			<Row justify="center">
				{AutomationTest() ? null :
					<Col lg={12} md={9} className={'centeredText'}>
						{process.env.REACT_APP_CAPTCHA === 'turnstile' ?
							<Turnstile
								sitekey={process.env.REACT_APP_TURNSTILE_ID}
								onVerify={(token) => this.verifyCallback(token)}
							/> :
							<Captcha lang={this.props.translations.locale} verifyCallback={this.verifyCallback} expiredSession={this.expiredSession}/>
						}
					</Col>
				}
			</Row>
			<Row justify='center'>
				<Col lg={12} md={9}>
					<FormButton name={'thatButton'} id='registrationButton' className={'finalButton'}
					            disabled={AutomationTest() ? !this.state.accepted : !this.state.accepted || !this.state.notRobot}
					            onClick={() => {
						            window.dataLayer.push({'event': 'sign_up'});
						            window.dataLayer.push({'event': 'conversion', 'send_to': 'AW-820054480/WxRKCMLc9O0BENCThIcD'});
					            }}
					            value={<Translate value='step3.continue'/>}/>
				</Col>
			</Row>

			<Row justify="center">
				<Col lg={12} md={9} align={'center'} className="centeredText">
					<a style={{color: this.props.config.theme.primary}} className="white" onClick={() => {
						this.setState({
							dialogTitle: '',
							dialogContent: this.emailLogin
						});
					}}><Translate value="header.alreadyClient"/></a>
				</Col>

				<Col lg={12} md={9} align={'center'} className="centeredText">
					<a className="white" onClick={() => {
						this.setState({
							dialogTitle: '',
							dialogContent: this.methods
						});
					}}></a>
				</Col>
			</Row>
		</ValidatorForm>
	}

	emailLogin() {
		return <ValidatorForm className="emailForm"
		                      onSubmit={() => this.login({
			                      email: this.state.email,
			                      password: this.state.password,
			                      type: 'email'
		                      })}>
			{this.state.loginInfoMessage && <p style={{textAlign: 'center'}} dangerouslySetInnerHTML={{__html: this.state.loginInfoMessage}}></p>}
			<Row justify="center" className="paddingTop">
				<Col lg={12} md={9}>
					<StringField
						label={<Translate value='step3.email'/>}
						onChange={(e) => this.setState({email: e.target.value})}
						value={this.state.email}
						name='email'
						validators={['isEmail']}
						className='longLabel'
						errorMessages={['L\'e-mail est incorrect']}
						margin="normal"
					/>
				</Col>
			</Row>

			<Row justify="center">
				<Col lg={12} md={9}>
					<StringField
						name='password'
						onChange={(e) => this.setState({password: e.target.value})}
						value={this.state.password}
						type='password'
						validators={['required']}
						errorMessages={['Cette information doit être renseignée']}
						label={<Translate value='step3.password'/>}
						margin="normal"
					/>
				</Col>
			</Row>
			<Row justify='center'>
				<Col lg={12} md={9}>
					<FormButton value={<Translate value='generic.follow'/>}/>
				</Col>
			</Row>

			<Row justify='center' className="alternativeMethods">

				<Col lg={12} md={9} align={'center'} className="centeredText">
					<a style={{color: this.props.config.theme.primary}} onClick={() => {
						this.setState({
							dialogOpen: true,
							dialogTitle: 'Réinitialiser votre mot de passe',
							dialogContent: () => this.forgottenPassword()
						});
					}}><Translate value='forgottenPassword.link'/></a>
				</Col>

				<Col lg={12} md={9} align={'center'} className="centeredText">
					{this.props.authType === 'login' ?
						<a style={{color: this.props.config.theme.primary}} href="/prets">Je n'ai pas de compte</a>
						:
						<a style={{color: this.props.config.theme.primary}} onClick={() => {
							this.setState({
								dialogTitle: '',
								dialogContent: this.emailRegistration
							});
						}}>Je n'ai pas de compte</a>}
				</Col>

				<Col lg={12} md={9} align={'center'} className="centeredText">
					<a style={{color: this.props.config.theme.primary}} onClick={() => {
						this.setState({
							dialogTitle: '',
							dialogContent: this.methods
						});
					}}>Autres méthodes d'authentification</a>
				</Col>
			</Row>
		</ValidatorForm>
	}

	forgottenPassword() {
		return <ValidatorForm className="emailForm" style={{color: '#2e305f'}} onSubmit={() => {
			this.props.updateConfig('loading', true);

			return post({email: this.state.email}, getBackendUrl() + "/customer/request_password_reset")
				.then(() => this.setState({sent: true, error: false}))
				.catch(() => this.setState({sent: true, error: true}))
				.then(() => this.props.updateConfig('loading', false));
		}}>
			<Row justify="center" className='firstRow'>
				<Col lg={12} md={9}>
					<StringField
						label={<Translate value='step3.email'/>}
						onChange={(e) => this.setState({email: e.target.value})}
						value={this.state.email}
						name='email'
						validators={['required', 'isEmail']}
						errorMessages={['Cette information doit être renseignée', 'L\'e-mail est incorrect']}
						margin="normal"
					/>
				</Col>
			</Row>
			{!!this.state.sent && (
				<Row justify='center'>
					<Col lg={12} md={9}>
						{!this.state.error ?
							<p className='successMessage'><Translate value='forgottenPassword.requestSent'/></p>
							:
							<p className='errorMessage'><Translate value='forgottenPassword.requestError'/></p>
						}
					</Col>
				</Row>
			)}

			<Row justify='center'>
				<Col lg={12} md={9}>
					<FormButton value={<Translate value='generic.follow'/>} disabled={this.state.sent && !this.state.error}/>
				</Col>
			</Row>

			<Row justify="center" class="alternativeMethods">
				<Col className="centeredText">
					<a style={{color: this.props.config.theme.primary}} onClick={() => {
						this.props.changeType('login');
						this.setState({
							dialogTitle: '',
							dialogContent: this.methods
						});
					}}><Translate value="header.alreadyClient"/></a>
				</Col>
			</Row>

			<Row justify="center" class="alternativeMethods">
				<Col className="centeredText">
					{this.props.authType === 'login' ?
						<a style={{color: this.props.config.theme.primary}} onClick={() => {
							window.location.href = '/prets';
						}}>Je n'ai pas de compte</a>
						:
						<a style={{color: this.props.config.theme.primary}} onClick={() => {
							this.props.changeType('registration');
							this.setState({
								dialogTitle: '',
								dialogContent: this.methods
							});
						}}>Je n'ai pas de compte</a>}
				</Col>
			</Row>

		</ValidatorForm>
	}

	methods() {
		return <div>
			{/*{((isTablet && this.state.portrait) || !this.props.registered) || (!isTablet && isMobile) ? (*/}
			<div className={`authenticationSection ${!this.props.authType}`}>
				{this.props.translations.locale === 'fr' ?
					<div>
						<Row justify='center' className="franceConnectSection">
							<Col md={10} lg={12} sm={9}>{this.franceConnect()}</Col>
						</Row>
						<Row justify='center'>
							<Translate style={{color: this.props.config.theme.primary}} className='ou' value='or'/>
						</Row>
					</div> :
					<Row justify='center' style={{textAlign: 'center', marginBottom: 15}}>
						<Translate value='step3.registrationMessage'/>
					</Row>
				}
				<Row justify='center'>
					<Col md={8} lg={8} sm={9}>
						<Button style={{backgroundColor: this.props.config.theme.primary}} className='formButton emailButton' id='email' onClick={() => this.setState({
								dialogTitle: '',//this.props.authType === 'login' ? 'Login' : 'Registration',
								dialogContent: this.props.authType === 'login' ? this.emailLogin : this.emailRegistration
							}
						)}> Email </Button>
					</Col>
				</Row>
				<Row justify='center'>
					<Translate style={{color: this.props.config.theme.primary}} className='ou' value='or'/>
				</Row>
				<Row justify='center'>
					<Col md={8} lg={8} sm={9}>{this.facebook()}</Col>
				</Row>
				{process.env.REACT_APP_GOOGLE_CLIENT_ID ?
					<>
						<Row justify='center'>
							<Translate style={{color: this.props.config.theme.primary}} className='ou' value='or'/>
						</Row>
						<Row justify='center'>
							<Col md={8} lg={8} sm={9}>{this.google()}</Col>
						</Row>
					</> : <></>
				}
			</div>
			{/* ) : (
				<div className="authenticationSection">
					<Row justify='center'>
						<Col lg={6} md={6} sm={6} xs={6} className="franceConnectSection">
							<Row justify='center'>
								<Col>{this.franceConnect()}</Col>
							</Row>
						</Col>

						<Col lg={6} md={6} sm={6} xs={6}>
						<Row justify='center'>
							<Col lg={8}>
									<Button className='formButton emailButton'
											onClick={() => this.setState({
												dialogOpen: true,
											dialogTitle: 'Login',
												dialogContent: this.emailLogin
											})}>
										Email
									</Button>
								</Col>
							</Row>
							<Row justify='center'>
							<Translate style={{color: this.props.config.theme.primary}} className='ou' value='or'/>
							</Row>
							<Row justify='center'>
								<Col lg={8}>{this.facebook()}</Col>
							</Row>
							<Row justify='center'>
								<Translate style={{color: this.props.config.theme.primary}} className='ou' value='or'/>
							</Row>
							<Row justify='center'>
								<Col lg={8}>{this.google()}</Col>
							</Row>
						</Col>
					</Row>
				</div>
			) */}
		</div>
	}

	render() {
		if (this.props.authEmail && this.state.email !== this.props.authEmail) {
			this.setState({
				email: this.props.authEmail,
				loginInfoMessage: 'Cette adresse email existe déjà. <br/>Connectez-vous avec votre mot de passe ou suivez le lien dans votre e-mail d\'inscription.',
				dialogTitle: '',
				dialogContent: this.emailLogin
			});
		}
		return <Row>
			<Modal title={<Translate value="step2.modalTitle"/>}
			       dialogOpen={this.props.authOpened}
			       config={this.props.config}
			       onClose={() => {
				       this.setState({dialogContent: this.methods});
				       this.props.authClose();
				       if (this.props.config.sessionExpired) {
					       this.props.logout();
				       }
			       }}
			       disableBackdropClick={true}
			>{typeof this.state.dialogContent === 'function' ?
				this.state.dialogContent.call(this) :
				this.state.dialogContent}
			</Modal>
		</Row>;
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.resize.bind(this));
	}
}

export default Authentication;
