import React from 'react';
import Shell from '../../components/Shell';
import {connect} from 'react-redux';
import {Row, Col, Container} from 'react-grid-system';
import ReactTooltip from 'react-tooltip';
import Translate from "components/CustomTranslate";
import RadioGroup from '@material-ui/core/RadioGroup';
import {StringField, YesNoRadio} from '../../components/Form/Fields';
import FormButton from '../../components/Form/FormButton';
import {ValidatorForm} from 'react-material-ui-form-validator';
import Modal from '../../components/Modal';
import history from '../../history';
import {getBackendUrl} from "../../js/helpers/functions";

class BravoDetailed extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			bravo: {
				extraQuestions: {
					motive: 'Pour quels motifs ?',
					medication: 'Noms et doses des médicaments :',
					medicationNames: 'Noms et doses des médicaments :',
					dates: 'Depuis quelle(s) date(s) ?',
					tax: 'Le ou les taux ?',
					duration: 'Durée(s) ?'
				},
				questions: {}
			}
		};
		for (let i = 6; i < 23; i++) {
			this.state.bravo.questions['Q' + i] = {answer: '', extra: {}};
		}
	}

	getStep() {
		return 'bravo_detailed';
	}

	validation() {
		return Object.values(this.state.bravo.questions).every(q => q.answer !== '');
	}

	downloadFile(name) {
		window.open(getBackendUrl() + '/customer/download/' + name);
	}

	extraQuestions(question, value) {
		this.setState(state => {
			state.bravo.questions[question].answer = value;
			state.dialogOpen                       = value === 'true';
			state.bravo.current                    = question;
			return state;
		});
	}

	render() {
		return (
			<div style={{marginTop: '5rem'}}>
				<Container>
					<ReactTooltip style={{backgroundColor: this.props.config.theme.primary}} className="step2-tooltip"/>
					<ValidatorForm onSubmit={() => {
						if (this.validation()) {
							this.props.updateConfig('loading', true);
							this.props.bravoDocuments({flow: this.props.loan.flow, ...this.state})
								.then(documents => {
									this.props.updateBorrower('bravo_documents', documents.data);
									this.props.updateConfig('loading', false);
									history.push('/bravo_signature');
								});
						}

					}}>
						<ReactTooltip style={{backgroundColor: this.props.config.theme.primary}} className="step2-tooltip"/>
						<Row justify="center">
							<Col md={12} style={{textAlign: 'center'}}>
								<p className="focusText">
									<b>{this.props.borrower.first_name}, en cas de réponse positive, donner les précisions demandées</b>
								</p>
							</Col>
						</Row>

						<Row justify="center">
							<Col md={9}>
								<p className='healthQuestion'>Cette différence est−elle inférieure à 80 ou supérieure à 120 ?</p>
							</Col>
							<Col md={3}>
								<RadioGroup className='radioGroup healthQuestion'
								            name='Q6'
								            value={this.state.bravo.questions.Q6.answer}
								            onChange={(event, value) => {
									            this.setState(state => {
										            state.bravo.questions.Q6.answer = value;
										            return state;
									            });
								            }}
								><YesNoRadio/></RadioGroup>
							</Col>
						</Row>

						<Row justify="center">
							<Col md={9}>
								<p className='healthQuestion'> Etes−vous actuellement en arrêt de travail sur prescription médicale, pour raison de santé ?</p>
							</Col>
							<Col md={3}>
								<RadioGroup className='radioGroup healthQuestion'
								            name='Q7'
								            value={this.state.bravo.questions.Q7.answer}
								            validators={['required']}
								            onChange={(event, value) => {
									            this.extraQuestions('Q7', value);
								            }}
								><YesNoRadio/></RadioGroup>
							</Col>
						</Row>

						<Row justify="center">
							<Col md={9}>
								<p className='healthQuestion'>Suivez−vous actuellement un traitement médical ?</p>
							</Col>
							<Col md={3}>
								<RadioGroup className='radioGroup healthQuestion'
								            name='Q8'
								            value={this.state.bravo.questions.Q8.answer}
								            onChange={(event, value) => {
									            this.extraQuestions('Q8', value);
								            }}
								><YesNoRadio/></RadioGroup>
							</Col>
						</Row>

						<Row justify="center">
							<Col md={9}>
								<p className='healthQuestion'>Etes−vous titulaire de l'Allocation pour adulte handicapé (AAH) ? </p>
							</Col>
							<Col md={3}>
								<RadioGroup className='radioGroup healthQuestion'
								            name='Q9'
								            value={this.state.bravo.questions.Q9.answer}
								            onChange={(event, value) => {
									            this.extraQuestions('Q9', value);
								            }}
								><YesNoRadio/></RadioGroup>
							</Col>
						</Row>

						<Row justify="center">
							<Col md={9}>
								<p className='healthQuestion'>Etes−vous titulaire d'une pension, rente ou allocation au titre d'une inaptitude au travail ou d'une invalidité
									supérieure à 15 % ?</p>
							</Col>
							<Col justify='right' md={3}>
								<RadioGroup className='radioGroup healthQuestion'
								            name='Q10'
								            value={this.state.bravo.questions.Q10.answer}
								            onChange={(event, value) => {
									            this.extraQuestions('Q10', value);
								            }}
								><YesNoRadio/></RadioGroup>
							</Col>
						</Row>

						<Row justify="center">
							<Col md={9}>
								<p className='healthQuestion'>Etes−vous pris en charge à 100% pour raison médicale par un organisme de sécurité sociale ou un régime assimilé ?</p>
							</Col>
							<Col justify='right' md={3}>
								<RadioGroup className='radioGroup healthQuestion'
								            name='Q11'
								            value={this.state.bravo.questions.Q11.answer}
								            onChange={(event, value) => {
									            this.extraQuestions('Q11', value);
								            }}
								><YesNoRadio/></RadioGroup>
							</Col>
						</Row>
						<Row justify="center">
							<Col md={9}>
								<p className='healthQuestion'> Avez−vous eu un ou plusieurs arrêts de travail de plus de 30 jours consécutifs au cours des 5 dernières années, sur
									prescription médicale,
									pour raison de santé ?</p>
							</Col>
							<Col justify='right' md={3}>
								<RadioGroup className='radioGroup healthQuestion'
								            name='Q12'
								            value={this.state.bravo.questions.Q12.answer}
								            onChange={(event, value) => this.extraQuestions('Q12', value)}
								><YesNoRadio/></RadioGroup>
							</Col>
						</Row>
						<Row justify="center">
							<Col md={9}>
								<p className='healthQuestion'>Vous a−t−on déjà prescrit un traitement médical (hormis pour raison contraceptive ou pour traitement d'une allergie
									saisonnière bénigne) de plus de 21 jours au cours des 10 dernières années ?</p>
							</Col>
							<Col justify='right' md={3}>
							</Col>
						</Row>
						<Row justify='center'>
							<Col md={9}>
								<p className='healthQuestion'>− un traitement pour maladie rhumatismale, atteinte de la colonne vertébrale, lumbago, lombalgie ou sciatique ?</p>
							</Col>
							<Col justify='right' md={3}>
								<RadioGroup className='radioGroup healthQuestion'
								            name='Q13'
								            value={this.state.bravo.questions.Q13.answer}
								            onChange={(event, value) => {
									            this.extraQuestions('Q13', value);
								            }}
								><YesNoRadio/></RadioGroup>
							</Col>
						</Row>
						<Row justify='center'>
							<Col md={9}>
								<p className='healthQuestion'>− un traitement pour troubles nerveux, affection neuropsychique, dépression nerveuse ?</p>
							</Col>
							<Col justify='right' md={3}>
								<RadioGroup className='radioGroup healthQuestion'
								            name='Q14'
								            value={this.state.bravo.questions.Q14.answer}
								            onChange={(event, value) => {
									            this.extraQuestions('Q14', value);
								            }}
								><YesNoRadio/></RadioGroup>
							</Col>
						</Row>
						<Row justify='center'>
							<Col md={9}>
								<p className='healthQuestion'>− un traitement pour troubles cardiaques ou vasculaires, hypertension artérielle ?</p>
							</Col>
							<Col justify='right' md={3}>
								<RadioGroup className='radioGroup healthQuestion'
								            name='Q15'
								            value={this.state.bravo.questions.Q15.answer}
								            onChange={(event, value) => {
									            this.extraQuestions('Q15', value);
								            }}
								><YesNoRadio/></RadioGroup>
							</Col>
						</Row>
						<Row justify='center'>
							<Col md={9}>
								<p className='healthQuestion'>− un traitement par rayons, cobalt, chimiothérapie ou immunothérapie ?</p>
							</Col>
							<Col justify='right' md={3}>
								<RadioGroup className='radioGroup healthQuestion'
								            name='Q16'
								            value={this.state.bravo.questions.Q16.answer}
								            onChange={(event, value) => {
									            this.extraQuestions('Q16', value);
								            }}
								><YesNoRadio/></RadioGroup>
							</Col>
						</Row>
						<Row justify='center'>
							<Col md={9}>
								<p className='healthQuestion'>− d'autres traitements d'une durée continue supérieure à un mois ?</p>
							</Col>
							<Col justify='right' md={3}>
								<RadioGroup className='radioGroup healthQuestion'
								            name='Q17'
								            value={this.state.bravo.questions.Q17.answer}
								            onChange={(event, value) => {
									            this.extraQuestions('Q17', value);
								            }}
								><YesNoRadio/></RadioGroup>
							</Col>
						</Row>
						<Row justify='center'>
							<Col md={9}>
								<p className='healthQuestion'>Au cours des 10 dernières années, avez−vous été hospitalisé dans une clinique, un hôpital ou une maison de santé pour
									un (ou des) autres(s) motif(s) que les suivants : maternité, césarienne, appendicectomie, ablation des amygdales et/ou des végétations, ablation
									de la vésicule biliaire, hernie inguinale, hernie ombilicale, hernie hiatale, varices, hémorroïdes, IVG, chirurgie dentaire, déviation de la
									cloison nasale ?</p>
							</Col>
							<Col justify='right' md={3}>
								<RadioGroup className='radioGroup healthQuestion'
								            name='Q18'
								            value={this.state.bravo.questions.Q18.answer}
								            onChange={(event, value) => {
									            this.extraQuestions('Q18', value);
								            }}
								><YesNoRadio/></RadioGroup>
							</Col>
						</Row>
						<Row justify='center'>
							<Col md={9}>
								<p className='healthQuestion'> Avez−vous été informé que, dans les 12 prochains mois, vous devrez :</p>
							</Col>
							<Col justify='right' md={3}>
							</Col>
						</Row>
						<Row justify='center'>
							<Col md={9}>
								<p className='healthQuestion'>− subir un scanner, une IRM, des examens en milieu hospitalier ?</p>
							</Col>
							<Col justify='right' md={3}>
								<RadioGroup className='radioGroup healthQuestion'
								            name='Q19'
								            value={this.state.bravo.questions.Q19.answer}
								            onChange={(event, value) => {
									            this.extraQuestions('Q19', value);
								            }}
								><YesNoRadio/></RadioGroup>
							</Col>
						</Row>
						<Row justify='center'>
							<Col md={9}>
								<p className='healthQuestion'>− être hospitalisé ? </p>
							</Col>
							<Col justify='right' md={3}>
								<RadioGroup className='radioGroup healthQuestion'
								            name='Q20'
								            value={this.state.bravo.questions.Q20.answer}
								            onChange={(event, value) => {
									            this.extraQuestions('Q20', value);
								            }}
								><YesNoRadio/></RadioGroup>
							</Col>
						</Row>
						<Row justify='center'>
							<Col md={9}>
								<p className='healthQuestion'>− suivre un traitement médical ?</p>
							</Col>
							<Col justify='right' md={3}>
								<RadioGroup className='radioGroup healthQuestion'
								            name='Q21'
								            value={this.state.bravo.questions.Q21.answer}
								            onChange={(event, value) => {
									            this.extraQuestions('Q21', value);
								            }}
								><YesNoRadio/></RadioGroup>
							</Col>
						</Row>
						<Row justify='center'>
							<Col md={9}>
								<p className='healthQuestion'>− subir une intervention chirurgicale ?</p>
							</Col>
							<Col justify='right' md={3}>
								<RadioGroup className='radioGroup healthQuestion'
								            name='Q22'
								            value={this.state.bravo.questions.Q22.answer}
								            onChange={(event, value) => {
									            this.extraQuestions('Q22', value);
								            }}
								><YesNoRadio/></RadioGroup>
							</Col>
						</Row>
						<Modal title={'Donner les précisions demandées'}
						       dialogOpen={this.state.dialogOpen}
						       config={this.props.config}
						       onClose={() => this.setState({dialogOpen: false})}
						>{this.modalContent()}</Modal>
						<Row justify='center'>
							<Col md={12}>
								<p className='healthQuestion'><Translate value='step6.linkStatement' dangerousHTML/></p>
								<hr/>
							</Col>
						</Row>

						<Row justify='center'>
							<Col md={12}>
								<p className='smallFont'>
									Je certifie avoir répondu personnellement et avec attention aux questions ci-dessus. Je suis informé(e) que toute réticence, fausse déclaration
									intentionnelle ou non est sanctionnée par la loi qui prévoit la nullité du contrat ou une augmentation de prime d’assurance.
								</p>
							</Col>
						</Row>

						<Row justify="center" className="buttonSection">
							<Col md={4} xs={9} sm={9}>
								<FormButton value={<Translate value='generic.follow'/>}/>
							</Col>
						</Row>
					</ValidatorForm>
				</Container>
			</div>
		);
	}

	extraField(field) {
		return <StringField
			validators={['required']}
			errorMessages={['Cette information doit être renseignée']}
			name={field}
			label={this.state.bravo.extraQuestions[field]}
			onChange={e => {
				let val = e.target.value;
				this.setState(function (state) {
					state.bravo.questions[state.bravo.current].extra[field] = val;
					return state;
				});
			}}
			margin="normal"
		/>;
	}

	modalContent() {
		if (!this.state.bravo.current) return <div/>;
		let mapping   = {
			Q8: ['medication'],
			Q10: ['tax'],
			Q12: ['duration'],
			Q13: ['medication', 'duration'],
			Q14: ['medication', 'duration'],
			Q15: ['medication', 'duration'],
			Q16: ['medication', 'duration'],
			Q17: ['medication', 'duration'],
			Q18: ['duration']
		};
		let questions = ['motive', 'dates'];
		if (mapping[this.state.bravo.current]) questions = questions.concat(mapping[this.state.bravo.current]);
		return <div>{questions.map(q => this.extraField(q))}</div>;
	}

}


export default connect()(Shell(BravoDetailed));
