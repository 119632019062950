import {
	INSURANCE_CALCULATION,
	INITIAL_CALCULATION,
	INITIAL_CALCULATION_NO_INSURANCE,
	REGISTRATION,
	GET_UUID,
	BRAVO_CONTACT,
	BRAVO_DOCUMENTS,
	CONTRACT_DATA,
	AFFILIATE_DATA,
	LOGIN,
	UPDATE,
	DOCUMENTS,
	SIGN,
	SETUP,
	HAS_SIGNED,
	CONFIG_UPDATE,
	RECOVER_APPICATION,
	RE_INITIALIZE_ALL,
	CALCULATION_FETCH,
	RESIGN_DOCUMENTS,
	CHECK_SMS_CODE,
	ERROR_MODAL,
	UPDATE_AFIESCA_IBAN,
	SET_STATE,
	SMS_CONFIRMATION,
	COMPARE_INSURERS,
	RELOAD_STATE,
	INSURER_SPECIFICS
} from '../../constants/action-types';
import {loadState} from '../../localStorage';

const persistedState = loadState();

let defaultState = Object.assign({}, persistedState.config);
export default (state = defaultState, action) => {
	switch (action.type) {
	case SET_STATE:
		return action.data.config;
	case RE_INITIALIZE_ALL:
		return defaultState;
	case RELOAD_STATE:
		return {
			...state,
			...action.payload.config
		};
	case INSURANCE_CALCULATION:
		return {
			...state,
			calculation: Object.assign({}, state.calculation, {
				insurance: action.data.total
			})
		};
	case INSURER_SPECIFICS:
		let risky_professions = state.risky_professions;
		let professions       = state.professions;
		//If risky_professions in data, replace the corresponding current ones from state
		if (action.data && action.data.risky_professions && action.data.risky_professions.length > 0) {
			let flow          = action.data.risky_professions[0].flow;
			risky_professions = state.risky_professions.filter(p => {
				return p.flow !== flow;
			}).concat(action.data.risky_professions);
		}
		console.log(professions, 'before')
		if (action.data && action.data.professions && action.data.professions.length > 0) {
			let flow    = action.data.professions[0].flow;
			professions = state.professions.filter(p => {
				return p.flow !== flow;
			}).concat(action.data.professions);
		}
		console.log(professions, 'after')
		return {
			...state,
			insurer_specifics: action.data?.fields || [],
			insurer_sport_risks: action.data?.sport_risks?.map(e => ({name: e.label, key: e.code})) || [],
			risky_professions: risky_professions
		};
	case INITIAL_CALCULATION:
		return {
			...state,
			calculation: action.data
		};
	case INITIAL_CALCULATION_NO_INSURANCE:
		return {
			...state,
			calculation: action.data
		};
	case HAS_SIGNED:
		return {
			...state,
			signed: action.data.result
		};
	case LOGIN:
		return {
			...state,
			login: action.data,
			comparison: {asd: 3}
		};
	case REGISTRATION:
		return {
			...state,
			registration: action.data
		};
	case COMPARE_INSURERS:
		return {
			...state,
			comparison: action.data
		};
	case CHECK_SMS_CODE:
		return {
			...state,
			sms_code: action.data
		};
	case BRAVO_CONTACT:
	case BRAVO_DOCUMENTS:
		return {
			...state,
			calculation: {
				bravo: {...action.data}
			}
		};
	case CONTRACT_DATA:
		return {
			...state,
			contract: action.data
		};
	case AFFILIATE_DATA:
		return {
			...state,
			affiliate_data: action.data
		};
	case ERROR_MODAL:
		return {
			errorModalOpen: true
		};
	case DOCUMENTS:
	case RESIGN_DOCUMENTS:
		return {
			...state,
			documents: {
				...action.data
			}
		};
	case CALCULATION_FETCH:
		return {
			...state,
			calculation: {
				...action.data
			}
		};
	case UPDATE_AFIESCA_IBAN:
		return {...state}
	case SIGN:
		return {
			...state,
			sign: {
				...action.data
			}
		};
	case GET_UUID:
	case UPDATE:
	case SETUP:
		return {
			...state,
			...action.data
		};
	case SMS_CONFIRMATION:
		return {
			...state,
			smsConfirmation: {...action.data}
		};
	case CONFIG_UPDATE:
		return {
			...state,
			[action.key]: action.value
		};
	case RECOVER_APPICATION:
	default:
		return state;
	}

}

