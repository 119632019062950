import Translate from "components/CustomTranslate";
import {SelectField, SelectFieldItems, SelectFieldMultiple, StringField} from "../Fields";
import React from "react";
import Switch from "react-switch";

let Profession = (props) => {
	return <SelectField
		onChange={(e) => {
			props.update('profession', e.target.value);
			let profession = props.profession.find(p => p.key === e.target.value);
			props.update('csp', profession.csp);
		}}
		value={props.data.profession}
		label={<Translate value='step5.profession'/>}
		validators={['required']}
		errorMessages={['Cette information doit être renseignée']}
		margin="normal"
		id={props.id}
		// className="extraLongLabel"
		disabled={props.disabled}
	>{SelectFieldItems(props.profession, 'profession', false, false, props.id)}</SelectField>;
};
let ExactSport = (props) => {
	return <StringField
		id='exactProfession'
		onChange={(e) => props.update('exact_sport', e.target.value)}
		value={props.data.exact_sport}
		validators={['required']}
		errorMessages={['Cette information doit être renseignée']}
		label={<Translate value='step5.exact_sport'/>}
		margin="normal"
		disabled={props.disabled}
	/>
};
let ExactSportOptions = (props) => {
	return <SelectField
		onChange={(e) => {
			props.update('exact_sport', e.target.value);
		}}
		value={props.data.exact_sport}
		label={<Translate value='step5.exact_sport'/>}
		validators={['required']}
		errorMessages={['Cette incontainers/07.Resume/index.jsformation doit être renseignée']}
		margin="normal"
		id={props.id}
		// className="extraLongLabel"
		disabled={props.disabled}
	>{SelectFieldItems(props.exact_sports, 'exact_sport', false, false, props.id)}</SelectField>;
}
let Smoker     = (props) => {
	return <Switch className='healthQuestion'
	               name='smoker'
	               id="smoker"
	               checked={props.data.smoker}
	               offColor="#7f97b0"
	               onColor="#0ddc91"
	               uncheckedIcon=""
	               checkedIcon=""
	               onChange={() => props.update('smoker', !(props.data.smoker))}
	>
	</Switch>
};

let RiskyProfession        = (props) => {
	let blocking   = ['retraite', 'preRetraite', 'sansProfession'];
	let isDisabled = props.disabled;
	if (blocking.includes(props.data.profession)) {
		props.data.risky_profession = props.flow === 'afi-esca' ? 'AUCUNE_ACTIVITE_SPECIFIQUE' : 'aucuneRisque';
		isDisabled                  = true;
	}
	return <SelectField
		onChange={(e) => props.update('risky_profession', e.target.value)}
		value={props.data.risky_profession}
		label={<Translate value='step5.riskyProfession'/>}
		validators={['required']}
		errorMessages={['Cette information doit être renseignée']}
		margin="normal"
		id={props.id}
		className="longLabel"
		disabled={isDisabled}
	>{SelectFieldItems(props.risky_profession, 'riskyProfessions', false, false, props.id)}</SelectField>;
};
let RiskyProfessionAfiesca = (props) => {
	return <SelectField
		// id='risky-profession-afiesca'
		onChange={(e) => props.update('risky_profession_afiesca', e.target.value)}
		value={props.data.risky_profession_afiesca}
		label="Plus précisément ?"
		validators={['required']}
		errorMessages={['Cette information doit être renseignée']}
		margin="normal"
		id='RiskyProfessionAfiesca'
		className="longLabel"
	>{SelectFieldItems(props.risky_profession_afiesca, 'riskyProfessions')}</SelectField>;
};
let Sports                 = (props) => {
	return <SelectField
		id={props.id}
		onChange={(e) => props.update('sport', e.target.value)}
		value={props.data.sport}
		label={<Translate value='step5.riskySports'/>}
		validators={['required']}
		errorMessages={['Cette information doit être renseignée']}
		margin="normal"
		className="longLabel"
	>{SelectFieldItems(props.sports, 'sports', false, false, props.id)}</SelectField>;
};
let ExactProfession        = (props) => {
	return <StringField
		id={props.id}
		onChange={(e) => props.update('exact_profession', e.target.value)}
		value={props.data.exact_profession}
		validators={['required']}
		errorMessages={['Cette information doit être renseignée']}
		label={<Translate value='step5.exactProfession'/>}
		margin="normal"
		disabled={props.disabled}
	/>
};

let RiskyProfessionMultiple = (props) => {
	let blocking   = ['preRetraite', 'sansProfession'];
	let isDisabled = props.disabled;
	if (blocking.includes(props.data.profession)) {
		props.data.risky_profession = ['aucuneRisque'];
		isDisabled                  = true;
	}

	let value = props.data.risky_profession;
	if (!Array.isArray(props.data.risky_profession) && props.data.risky_profession) {
		value = [props.data.risky_profession];
	} else if (!props.data.risky_profession) {
		value = [];
	}

	return <SelectFieldMultiple
		onChange={(e) => {
			let value = e.target.value;
			if (value[value.length - 1] === 'aucuneRisque') {
				value = ['aucuneRisque'];
			} else {
				let position = value.indexOf('aucuneRisque');
				if (position !== -1) {
					value.splice(position, 1);
				}
			}
			props.update('risky_profession', value)
		}}
		value={value}
		label={<Translate value='step5.riskyProfession'/>}
		validators={['required']}
		errorMessages={['Cette information doit être renseignée']}
		margin="normal"
		id={props.id}
		options={props.risky_profession}
		disabled={isDisabled}
	>{SelectFieldItems(props.risky_profession, 'riskyProfessions', true, value)}</SelectFieldMultiple>;
};

export {Profession, ExactProfession, ExactSport, ExactSportOptions, RiskyProfession, RiskyProfessionMultiple, RiskyProfessionAfiesca, Sports, Smoker};
