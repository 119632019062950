import React from 'react';
import {connect} from 'react-redux';
import Shell from '../../components/Shell';
import {Col, Row} from "react-grid-system";
import InsurerCard from "../../components/InsurerCard";
import history from "../../history";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Modal from '../../components/Modal';
import InstallmentOffre from "../../components/Installments/installment_offre";
import {getBackendUrl} from '../../js/helpers/functions';
import {mapData, toFrenchNumber} from "../../js/helpers/functions";
import './style.css'
import BackButton from "../../components/Form/BackButton";
import {post} from "../../js/helpers/rest";
import comparisonImage from './PERSO1.svg';
import Translate from "components/CustomTranslate";
import StandardButton from "../../components/Form/StandardButton";

import {I18n} from "react-redux-i18n";
import {FaInfo} from "react-icons/all";
class Comparison extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			selectedInsurer: this.props.loan.flow || '',
			selectedInsurerData: this.props.config.comparison.insurers[this.props.loan.flow] || '',
			click_id: sessionStorage.getItem('click_id'),
			modalOpened: false,
			guaranteesOpened: false,
			source: sessionStorage.getItem('utm_source'),
			source_tracked: false,
		}

		if (this.state.click_id) { //If we have a click_id update it with email after registration
			post({
				email: this.props.borrower.email,
				first_name: this.props.borrower.first_name, //If social login - we might have names
				last_name: this.props.borrower.last_name,
				type: 'click_update',
				click_id: this.state.click_id
			}, getBackendUrl() + "/config/track")
				.catch((e) => console.warn('Click update tracking issue:', e));
		}

	}

	modal() {
		const insurance_cost = this.props.loan.constant_premium ? this.state.selectedInsurerData.total.insurance_cost : this.state.selectedInsurerData.variable ? this.state.selectedInsurerData.variable.insurance_cost : this.state.selectedInsurerData.total.insurance_cost;
		const installments   = this.props.loan.constant_premium ? this.state.selectedInsurerData.installments : this.state.selectedInsurerData.variable ? this.state.selectedInsurerData.variable.installments : this.state.selectedInsurerData.installments
		return (
			<Modal
				dialogOpen={this.state.modalOpened}
				config={this.props.config}
				maxWidth={'sm'}
				onClose={() => this.setState({modalOpened: false})}>
				<h3 className='titleInstallments'>Votre échéancier</h3>
				{this.state.selectedInsurerData.variable && this.checksConstant() &&
				<div className='installments-modal-text-wrapper'>
				    <span><Translate value="step3.choose_your_payment_type" /></span>
				    <RadioGroup
				        className='radioGroup'
				        name='question_three'
				        style={{marginTop: 0}}
				        value={this.props.loan.constant_premium}
				        onChange={(event, value) => {
				            this.props.updateLoan('constant_premium', value === 'true');
				        }}>
				        <FormControlLabel
				            className='radioButton'
				            value={true}
				            control={<Radio required/>}
				            label='Constant'
				        />
				        <FormControlLabel
				            className='radioButton'
				            value={false}
				            control={<Radio required/>}
				            label='Variable'
				        />
				    </RadioGroup>
				</div>}
				<div className='modal-text-wrapper'>
					<span style={{marginRight: '15px'}}>Coût global d'assurance</span>
					<span style={{color: this.props.config.theme.primary}} className='insurer-card-number'>{toFrenchNumber(insurance_cost)} &euro;</span>
				</div>
				<div className='modal-text-wrapper'>
					<span style={{marginRight: '15px'}}>Taux moyen</span>
					<span style={{color: this.props.config.theme.primary}} className='insurer-card-number'>{parseFloat(this.state.selectedInsurerData.total.insurance_rate * 100).toFixed(3)}%</span>
				</div>
				<InstallmentOffre
					type={'constant'}
					selected='true'
					config={this.props.config}
					installments={installments}
					payment_period={this.state.selectedInsurerData.payment_period}
				/>
			</Modal>
		)
	}

	getStep() {
		return 'compare';
	}

	checkModal(modal) {
		if (modal === 'modalOpened') {
			this.setState({modalOpened: true});
		} else {
			this.setState({guaranteesOpened: true});
		}
	}

	checksConstant() {
		return this.state.selectedInsurerData.installments.slice(0, -1).every(e => e.total === this.state.selectedInsurerData.installments[0].total);
	}

	async selectInsurer(insurer) {
		if (insurer !== this.props.loan.flow) {
			// Clear professions if going to another insurer to prevent issues
			this.props.updateBorrower('profession', '')
			this.props.updateBorrower('risky_profession', '')
			if (this.props.borrower.has_coborrower) {
				this.props.updateCoBorrower('profession', '')
				this.props.updateCoBorrower('risky_profession', '')
			}
		}
		await this.props.updateLoan('flow', insurer)
		let calculation           = this.props.config.comparison.insurers[insurer];
		calculation.simulation_id = this.props.config.comparison.simulation_id;
		this.props.updateBorrower('initial', true)
		this.props.finalCalculation(mapData(Object.assign({}, this.props.borrower, {step: 'resume'}), this.props.coborrower, this.props.loan, this.props.config.comparison.insurers));
		if (['afi-esca', 'mncap', 'mutlog'].indexOf(insurer) === -1) {
			this.props.insurerSpecifics(insurer);
		}
		if (calculation.total.payment_type === 'VARIABLE') {
			await this.props.updateLoan('constant_premium', false);
		}
		if (this.props.loan.flow === 'afi-esca') {
			this.props.afiEsca(mapData(Object.assign({}, this.props.borrower, {
				step: 'resume',
				send_proposition: true
			}), this.props.coborrower, this.props.loan));
		}
		history.push('/donnes_personales')
	}

	render() {
		return <div>
			<Row justify='center' className="centeredAlign" style={{marginTop: '40px'}}>
				<Col className="titles" md={12}>
					<Row justify='center'>
						<Col md={4}><img src={comparisonImage} style={{height: '400px'}}/></Col>
						<Col md={3} style={{alignSelf: 'center', fontSize: '40px', fontWeight: 700, textAlign: 'left'}}>Comparez nos <span style={{color: '#75ACBA'}}>propositions</span></Col>
					</Row>
				</Col>
				<Col md={12}>
					<div>
						<Row justify='center'>
							<RadioGroup style={{display: 'block'}}
							            value={this.props.loan.constant_premium}
							            onChange={(event, value) => {
								            this.props.updateLoan('constant_premium', value === 'true');
							            }}>
								<Col style={{float: 'left', paddingTop: '10px', color: this.props.config.theme.primary}}>
									<span><Translate value="step3.choose_your_payment_type" /> <span data-tip={I18n.t('step3.payment_type_info')}><FaInfo size={15} /></span> :</span>
								</Col>
								<Col style={{float: 'left'}}>
									<FormControlLabel
										value={true}
										control={<Radio
											className="blueLabel"
											style={{color: this.props.config.theme.primary}} required/>}
										style={{color: this.props.config.theme.primary}}
										label='Constant'
									/>
								</Col>
								<Col style={{float: 'left'}}>
									<FormControlLabel
										value={false}
										style={{color: this.props.config.theme.primary}}
										control={<Radio
											style={{color: this.props.config.theme.primary}} required/>}
										label='Variable'
									/>
								</Col>

							</RadioGroup>

						</Row>
					</div>
					{Object.keys(this.props.config.comparison.insurers)
						.filter(i => {
							let ins = this.props.config.comparison.insurers[i];
							return (this.props.loan.constant_premium && ins.total?.premium) || (!this.props.loan.constant_premium && ins.variable?.premium);
						})
						.sort((c, n) => {
							let current = this.props.config.comparison.insurers[c];
							let next = this.props.config.comparison.insurers[n];
							if (this.props.loan.constant_premium) {
								return current.total?.insurance_cost - next.total?.insurance_cost;
							} else {
								return current.variable?.insurance_cost - next.variable?.insurance_cost;
							}
						})
						.map(index => {
							return (
								<InsurerCard
									payment_period={this.props.config.comparison.insurers[index].payment_period}
									flow={index}
									selected={this.state.selectedInsurer === index}
									config={this.props.config}
									loan={this.props.loan}
									borrower={this.props.borrower}
									updateLoan={this.props.updateLoan}
									translations={this.props.translations}
									openModal={(modal) => this.checkModal(modal)}
									continueButton={<StandardButton style={{backgroundColor: this.props.config.theme.secondary, borderRadius: '26px'}} onClick={() => this.selectInsurer(index)} className="selectedInsurerButton"
									                                id={`select-insurer-${index}`} value={<Translate value="step3.choose"/>}></StandardButton>}
									insurer={this.props.config.comparison.insurers[index]}
								/>
							)
						})}
				</Col>
			</Row>
			<Row justify='center' style={{marginTop: '30px'}}>
				<Col md={4}>
					<BackButton flow={this.props.loan.flow}/>
				</Col>
			</Row>
		</div>;
	}
}

export default connect()(Shell(Comparison));
