import React from 'react';
import {Route, Router, Redirect} from 'react-router-dom';
import Switch from '../CustomSwitch'
import history from '../../history';
import * as rdd from 'react-device-detect';

//Flow components
import LoanInfo from '../../containers/01.LoanInfo';
import PreOffer from '../../containers/02.PreOffer';
import InitialOffer from '../../containers/03.InitialOffer';
import PersonalInfo from '../../containers/04.PersonalInfo';
import ProfessionalInfo from '../../containers/05.ProfessionalInfo';
import HealthQuestions from '../../containers/06.HealthQuestions';
import AlreadyPassedHQ from '../../containers/06.HealthQuestions/already_passed.js';
import Resume from '../../containers/07.Resume';
import SmsConfirmation from '../../containers/Confirmation/sms.js';
import Offer from '../../containers/08.Offer';
import PhoneNumber from '../../containers/09.PhoneNumber';
import Signature from '../../containers/10.Signature';
import SCISignature from '../../containers/SCISignature';
import Finalize from '../../containers/11.Finalize';
import BravoSimplified from '../../containers/BravoProcess/simplified';
import BravoDetailed from '../../containers/BravoProcess/detailed';
import BravoMigrated from '../../containers/BravoProcess/migrated';
import BravoSignature from '../../containers/BravoProcess/signature';
import Details from '../../containers/Window/person-info'
import BravoSuccess from '../../containers/BravoProcess/success';
import Wellness from '../../containers/Wellness';
import WillReceiveEmail from '../../containers/08.Offer/receive_email';
//Generic components
import ForgottenPassword from '../../containers/ForgottenPassword';
import ChangePassword from '../../containers/ChangePassword';
import WelcomeScreen from '../../containers/WelcomeScreen';
import Bravo from '../Bravo';
//Personal Space components
import Home from '../../containers/PersonalSpace/Home';
import Steps from '../../containers/PersonalSpace/Steps';
import Sign from '../../containers/PersonalSpace/Sign';
import Documents from '../../containers/PersonalSpace/Documents';
import ResignedDocuments from '../../containers/PersonalSpace/ResignedDocuments';
import Affiliates from '../../containers/PersonalSpace/Affiliates';
import PersonalDigitalInsuree from '../../containers/PersonalSpace/DigitalInsuree';
import Comparison from '../../containers/02.PreOffer/comparison';
import {getBackendUrl, getLanguage, getParameterByName, parseJwt} from '../../js/helpers/functions';
import 'font-awesome/css/font-awesome.css';
import connect from 'react-redux/es/connect/connect';
import {updateBorrower, setBorrower, mncapQuestionnaire} from '../../js/actions/borrower';
import {getCoborrower, setCoBorrower} from '../../js/actions/coborrower';
import {setLoans, updateLoan} from '../../js/actions/loan';
import {setup, reCheckState, updateConfig, recoverApplication, login, leadLogin} from '../../js/actions/config';
import {reInitializeState} from '../../js/actions';
import JobPage from '../../containers/jobPage'
import {post} from "../../js/helpers/rest";
import Mirror from "../../containers/Mirror";
import LiveTranslation from "../../containers/LiveTranslation";
import BravoWithEmail from '../../containers/Window/bravo_signature';
import Inactivity from "../Inactivity";
import QuickPricing from "../../containers/QuickPricing";
import BuickPricing from "../../containers/QuickPricing/buick";
import NotInYourCountry from '../../containers/NotInYourCountry';
import Initial from '../../containers/Initial';
import MinimalAmountLimit from '../../containers/MinimalAmountLimit';
import IncomeStepTwo from 'containers/IncomeProtection/second';
import IncomeStepThree from 'containers/IncomeProtection/third';
import IncomePaymentSuccess from 'containers/IncomeProtection/success';
import IncomePersonal from 'containers/IncomeProtection/Personal';
import IncomeSpace from 'containers/IncomeProtection/PersonalSpace/Home';

// import Tracker from "../Tracker";
class App extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			dialogOpen: false,
			block: false,
		};
	}

	async componentWillMount() {
		// Tracker();
		let result = await this.props.setup(getLanguage())
		if (result && result.data && (!localStorage.guid || localStorage.guid == 'undefined')) {
			localStorage.setItem('guid', result.data.guid);
			document.cookie = 'guid=' + result.data.guid;
		}
		if (localStorage.guid && document.cookie.indexOf("guide=" + localStorage.guid) === -1) {
			document.cookie = 'guid=' + localStorage.guid;
		}
		this.appendChat()
		this.appendTrackingScripts(result && result.data ? result.data.theme : {});
		this.handleClicks();
		this.liveTranslation();

		window.agent_param = getParameterByName('agent', window.location.href);

		history.listen((location) => {
			window.gtag('config', 'UA-115743187-1', {
				'page_title': document.title,
				'page_path': location.pathname
			});

			if (window.self !== window.top) {
				window.parent.postMessage({
					type: 'page_change',
					from: 'translation_frame',
					new_page: location.pathname
				}, '*');
			}
		});

		this.props.reCheckState();
	}

	handleClicks() {
		let utm_source   = getParameterByName('utm_source', window.location.href);
		let utm_medium   = getParameterByName('utm_medium', window.location.href);
		let utm_campaign = getParameterByName('utm_campaign', window.location.href);
		let utm_term     = getParameterByName('utm_term', window.location.href);
		let utm_content  = getParameterByName('utm_content', window.location.href);
		let email        = getParameterByName('email', window.location.href);
		let phone        = getParameterByName('phone', window.location.href);
		let first_name   = getParameterByName('firstname', window.location.href);
		let last_name    = getParameterByName('lastname', window.location.href);

		//F-king weedoit
		let brackets = [['{{', '}}'], ['{', '}'], ['[', ']'], ['%%', '%%'], ['%', '%'], ['&', '&']];
		//F-king weedoit
		brackets.forEach(bracket => {
			//F-king weedoit
			email        = (email && email.indexOf(bracket[0]) !== -1 && email.indexOf(bracket[1]) !== -1) ? '' : email;
			//F-king weedoit
			first_name   = (first_name && first_name.indexOf(bracket[0]) !== -1 && first_name.indexOf(bracket[1]) !== -1) ? '' : first_name;
			//F-king weedoit
			last_name    = (last_name && last_name.indexOf(bracket[0]) !== -1 && last_name.indexOf(bracket[1]) !== -1) ? '' : last_name;
			//F-king weedoit
			utm_medium   = (utm_medium && utm_medium.indexOf(bracket[0]) !== -1 && utm_medium.indexOf(bracket[1]) !== -1) ? '' : utm_medium;
			//F-king weedoit
			utm_campaign = (utm_campaign && utm_campaign.indexOf(bracket[0]) !== -1 && utm_campaign.indexOf(bracket[1]) !== -1) ? '' : utm_campaign;
			//F-king weedoit
			utm_term     = (utm_term && utm_term.indexOf(bracket[0]) !== -1 && utm_term.indexOf(bracket[1]) !== -1) ? '' : utm_term;
			//F-king weedoit
			utm_content  = (utm_content && utm_content.indexOf(bracket[0]) !== -1 && utm_content.indexOf(bracket[1]) !== -1) ? '' : utm_content;
		});

		this.getSetParam('phone', 'phone', phone);
		this.getSetParam('email', 'email', email);
		this.getSetParam('firstname', 'first_name', first_name);
		this.getSetParam('lastname', 'last_name', last_name);

		if (utm_source) {
			window.history.replaceState(null, document.title, window.location.href.split('?')[0]);
			sessionStorage.setItem('utm_source', utm_source);

			post({
				utm_source, utm_medium, utm_campaign, utm_term, utm_content, email, first_name, last_name,
				url: window.location.href, type: 'click'
			}, getBackendUrl() + "/config/track")
				.then((result) => {
					if (result && result.click_id) {
						sessionStorage.setItem('click_id', result.click_id);
					}
				})
				.catch((e) => console.warn('Click tracking issue:', e));

			if (utm_campaign && utm_medium) {
				window.gtag('config', 'UA-115743187-1', {
					campaign: {
						medium: utm_medium,
						source: utm_source,
						name: utm_campaign,
						term: utm_term,
						content: utm_content,
					}
				});
			}
		}
	}

	getSetParam(search, key, value) {
		if (value) {
			this.props.updateBorrower(key, value);
			let pattern = window.location.href.indexOf(`?${search}=${value}`) !== -1 ? `?${search}=${value}` : `&${search}=${value}`;
			window.history.replaceState(null, document.title, window.location.href.replace(pattern, ''));
		}
	}

	appendChat() {
		if (window.__MIRROR_AGENT || (!!sessionStorage.__MIRROR_AGENT && window.self !== window.top)) {
			if ((typeof sessionStorage.isMobile === 'string' && sessionStorage.isMobile === 'true') ||
				(typeof sessionStorage.isMobile === 'boolean' && !!sessionStorage.isMobile)) {
				rdd.isMobile = true;
			}
		} else {
			window.isMobile = rdd.isMobile;
		}
		let script  = document.createElement('script');
		script.type = 'text/javascript';
		script.src  = `${process.env.REACT_APP_API_URL}/static/chat.js`;
		document.body.appendChild(script);
	}

	appendTrackingScripts(theme) {
		if (theme) {
			if (theme.gtm) {
				(function (w, d, s, l, i) {
					w[l] = w[l] || [];
					w[l].push({'gtm.start': new Date().getTime(), event: 'gtm.js'});
					let f   = d.getElementsByTagName(s)[0],
					    j   = d.createElement(s),
					    dl  = l != 'dataLayer' ? '&l=' + l : '';
					j.async = true;
					j.src   = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
					f.parentNode.insertBefore(j, f);
				})(window, document, 'script', 'dataLayer', theme.gtm);
			}

			if (theme.facebook) {
				(function (f, b, e, v, n, t, s) {
					if (f.fbq) return;
					n = f.fbq = function () {
						n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments)
					};
					if (!f._fbq) f._fbq = n;
					n.push    = n;
					n.loaded  = !0;
					n.version = '2.0';
					n.queue   = [];
					t         = b.createElement(e);
					t.async   = !0;
					t.src     = v;
					s         = b.getElementsByTagName(e)[0];
					s.parentNode.insertBefore(t, s)
				})(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
				window.fbq('init', theme.facebook);
				window.fbq('track', 'PageView');
			}
		}
	}

	isLogged(Component, props) {
		props              = props || {};
		props.mustBeLogged = !this.props.config.isCoborrower;

		return <Component {...props} />;
	}

	liveTranslation() {
		window.addEventListener('message', (event) => {
			let data = event.data.data;
			let type = event.data.type;
			if (type === 'translation.page_change') {
				history.push(data);
			}
		});
		document.addEventListener("click", (event) => preventClick(event, null), true);

		function preventClick(event, target) {
			if (window.__TRANSLATION_VIEW && window.__TRANSLATION_LOCK_CLICK) {
				event.preventDefault();
				event.stopPropagation();
				event.stopImmediatePropagation();

				if ((!event.detail || !event.detail.translation_skip)) {
					target     = target || event.target;
					let parent = target.parentNode;

					if (target && target.classList && target.classList.contains('custom_translate_item')) {
						let key = target.classList.toString().match(/(key_.*?\s)/g);
						key     = key.length > 0 ? key[0].replace('key_', '').trim() : key;
						window.__TRANSLATION_VIEW_ENTRY && window.__TRANSLATION_VIEW_ENTRY(event, key);
						return;
					}

					if (parent) {
						preventClick(event, parent);
					} else {
						try {
							let new_event = new CustomEvent('click', {cancelable: true, bubbles: true, detail: {translation_skip: true}, translation_skip: true});
							event.target.dispatchEvent(new_event);
						} catch (e) {
							console.warn('Error dispatching event', e);
						}
					}
				}
			}
		}
	}

	render() {
		if (this.props.config.guid) {
			if (this.props.translations.locale === 'fr' && (!this.props.config.banks || !this.props.config.insurers)) {
				return <div/>;
			}
			return (<div>
				<Inactivity/>
				<Router history={history}>
					<Switch>
						<Route exact path="/initial" component={Initial}/>
						<Route exact path="/jobs_wedou" component={JobPage}/>
						<Route exact path="/prets" component={LoanInfo}/>
						<Route exact path="/programme_bien_etre" component={Wellness}/>
						<Route exact path="/guaranties" component={PreOffer}/>
						<Route exact path="/comparison" component={Comparison}/>
						<Route exact path="/resultat" component={InitialOffer}/>
						<Route exact path="/donnes_personales">
							<Redirect to="/données_personnelles"/>
						</Route>
						<Route exact path="/translate" render={() => {
							localStorage.setItem('translate', true);
							history.push('/prets');
						}}>
						</Route>
						<Route exact path="/données_personnelles" render={() => this.isLogged(PersonalInfo)}/>
						<Route exact path="/profession" render={() => this.isLogged(ProfessionalInfo)}/>
						<Route exact path="/sante" render={() => this.isLogged(HealthQuestions)}/>
						<Route exact path="/co-sante" render={() => this.isLogged(HealthQuestions)}/>
						<Route exact path="/co-sante-deja" component={AlreadyPassedHQ}/>
						<Route exact path="/resume" render={() => this.isLogged(Resume)}/>
						<Route exact path="/offre" render={() => this.isLogged(Offer)}/>
						<Route exact path="/numero" render={() => this.isLogged(PhoneNumber, {isCoborrower: false})}/>
						<Route exact path="/co-numero" render={() => this.isLogged(PhoneNumber, {isCoborrower: true})}/>
						<Route exact path="/signing" render={() => this.isLogged(Signature)}/>
						<Route exact path="/sci_signing" component={SCISignature}/>
						<Route exact path="/personal_home" render={() => this.isLogged(Home)}/>
						<Route exact path="/personal_steps" render={() => this.isLogged(Steps)}/>
						<Route exact path="/personal_documents" render={() => this.isLogged(Documents)}/>
						<Route exact path="/personal_sign" render={() => this.isLogged(Sign)}/>
						<Route exact path="/personal_resigning" render={() => this.isLogged(ResignedDocuments)}/>
						<Route exact path="/personal_affiliate" render={() => this.isLogged(Affiliates)}/>
						<Route exact path="/digital_space" render={() => this.isLogged(PersonalDigitalInsuree)}/>
						{/*<Route exact path="/change_password" component={ChangePassword}/>*/}
						<Route exact path="/no_digital" render={() => this.isLogged(Bravo)}/>
						<Route exact path="/bravo_simplifie" render={() => this.isLogged(BravoSimplified)}/>
						<Route exact path="/bravo_detaille" render={() => this.isLogged(BravoDetailed)}/>
						<Route exact path="/bravo_signature" render={() => this.isLogged(BravoWithEmail)}/>
						<Route exact path="/migrate" component={BravoMigrated}/>
						<Route exact path="/recevoir" component={WillReceiveEmail}/>
						<Route exact path="/rendez-vous" component={BravoSignature}/>
						<Route exact path="/quick-pricing" component={QuickPricing}/>
						<Route exact path="/buick-pricing" component={BuickPricing}/>
						<Route exact path="/soon" component={NotInYourCountry}/>
						<Route exact path="/lemoine" component={MinimalAmountLimit}/>
						<Route exact path="/rendez-vous-page" component={BravoSignature}/>
						<Route exact path="/details" component={Details}/>
						<Route exact path="/confirmation" component={SmsConfirmation}/>
						<Route exact path="/bravo_termine" render={() => this.isLogged(BravoSuccess)}/>
						<Route exact path="/termine" render={() => this.isLogged(Finalize, {isCoborrower: false})}/>
						<Route exact path="/co-termine" render={() => this.isLogged(Finalize, {isCoborrower: true})}/>
						<Route exact path="/connexion" render={() => <LoanInfo openLogin={true}/>}/>
						<Route exact path="/forgotten_password" component={ForgottenPassword}/>
						<Route exact path="/mirror_setup" component={Mirror}/>
						<Route exact path="/live_translation_setup" component={LiveTranslation}/>
						<Route exact path="/social_connect" component={WelcomeScreen}/>
						<Route exact path="/income" component={IncomeStepTwo}/>
						<Route exact path="/income_result" component={IncomeStepTwo}/>
						<Route exact path="/income_hq" component={IncomeStepThree}/>
						<Route exact path="/income_personal" component={IncomePersonal}/>
						<Route exact path="/income_space" component={IncomeSpace}/>
						<Route exact path="/payment_success" component={IncomePaymentSuccess}/>
						<Route exact path="/data_recovery" render={(router) => {
							let token  = getParameterByName('t', router.location.search);
							let params = parseJwt(token);

							if (params.email && params.temp_account && params.simulationID) {
								// localStorage.removeItem('guid');
								localStorage.removeItem('token');
								localStorage.removeItem('state');
								localStorage.token = token;

								this.props.recoverApplication({email: params.email, simulationID: params.simulationID})
									.then(result => {
										let json = result.data.data;
										for (let i = 0; i < json.loan.loans.length; i++) {
											json.loan.loans[i].duration_years = null; //Sanitization
										}
										this.props.setBorrower(json.borrower);
										this.props.setCoBorrower(json.coborrower);
										this.props.setLoans(json.loan);
										this.props.updateConfig('recovery', true);
										this.props.updateBorrower('email', params.email);
										localStorage.removeItem('token'); //Make sure user is not logged.
										history.push('/prets');
									});
							}
						}}/>
						<Route exact path="/affiliate_link" render={(router) => {
							let link = getParameterByName('link', router.location.search);
							// let params = parseJwt(link);
							localStorage.setItem('affiliate_link', link);
							return <Redirect to="prets"/>

						}}/>
						<Route exact path="/france_connect" render={(router) => {
							let code     = getParameterByName('c', router.location.search);
							let email    = getParameterByName('e', router.location.search);
							let response = getParameterByName('r', router.location.search);
							let idHint   = getParameterByName('ih', router.location.search);

							localStorage.setItem('id_hint', idHint);

							// if (response !== '0') {
							// 	this.updateConfig('login_type', 'france_connect');
							// }

							return <WelcomeScreen type='france_connect' code={code} email={email}
								                      error={response === '0' ? 'User does not exist!' : null}/>;
						}}/>
						<Route exact path="/token" render={(router) => {
							let token  = getParameterByName('t', router.location.search);
							let params = parseJwt(token);
							if (!this.state.block && (params.coborrower || params.password_reset)) {
								// localStorage.removeItem('guid');
								localStorage.removeItem('token');
								localStorage.removeItem('state');

								if (params.coborrower) {
									this.setState({block: true});
									this.props.reInitializeState();

									this.props.getCoborrower({token: token})
										.then((r) => {
											console.log('Response', r);
											this.props.updateConfig('isCoborrower', true);
											if (this.props.coborrower.flow === 'mncap') {
												return this.props.mncapQuestionnaire({coborrower: true})
													.then(() => {
														window.location.href = '/co-sante';
													})
													.catch(() => {
														history.push('/co-numero');
													})
											}
											// history.push('/co-sante');
											window.location.href = '/co-sante';
										});
								} else if (params.password_reset) {
									// this.setState({block: false});
									return <ChangePassword token={token} email={params.email}/>;
								}
							}
						}}/>
						<Route exact path="/download" render={() => {
							// const android = () => {
							// 	return /Android/i.test(navigator.userAgent);
							// }
							const iOS     = () => {
								return /iPhone|iPad|iPod/i.test(navigator.userAgent);
							}
							if (iOS()) {
								window.location.href = this.props.config.theme.ios_app_url || 'https://apps.apple.com/bg/app/wedou-care/id1632403148';
							} else {
								window.location.href = this.props.config.theme.android_app_url || 'https://play.google.com/store/apps/details?id=com.wedou.iot';
							}
						}}/>
						<Route exact path="/magic_link" render={(router) => {
							let token  = getParameterByName('t', router.location.search);
							let params = parseJwt(token);
							console.log('magic lik params', params)
							if (!this.state.block && params.email && (params.password || params.guid)) {
								console.log('asdijasidjasidjaisdjiasd')
								if (params.hqp) {
									sessionStorage.setItem('hqp', 'disabled'); // session storage flag used for disallowing non-SUPER_ADMIN roles to see the Health Questionnaire page
								}
								this.setState({block: true});
								params.type  = 'magic_link';
								params.token = token;
								this.props.login(params)
									.then(() => {
										this.props.updateConfig('isCoborrower', false);
										let redirect = params.destination ? params.destination : this.props.borrower.has_signed ? '/personal_home' : (this.props.borrower.step ? this.props.borrower.step : '/donnes_personales');
										history.push(redirect);
									})
									.catch(() => {
										localStorage.removeItem('token');
										localStorage.removeItem('state');
										this.props.reInitializeState();
										history.push('/connexion?email=' + params.email);
									});
							} else if (params.lead_id) {
								this.props.leadLogin(params)
									.then(() => {
										history.push('/prets')
									})
									.catch(e => console.log(e));
							}
						}}/>
						{window.location.hostname == 'app.wedou.bg' ?
							<Redirect strict from="/" to="/income"/>
							:
							<Redirect strict from="/" to="/prets"/>
						}

					</Switch>
				</Router>
			</div>
			);
		} else return <div>Something went wrong. Please try again.</div>
	}
}

const mapStateToProps = (state) => {
	return {
		config: state.rootReducer.config,
		borrower: state.rootReducer.borrower,
		coborrower: state.rootReducer.coborrower,
		translations: state.i18n
	};
};
export default connect(
	mapStateToProps,
	{
		setup,
		reCheckState,
		getCoborrower,
		updateBorrower,
		updateConfig,
		updateLoan,
		reInitializeState,
		recoverApplication,
		setBorrower,
		setCoBorrower,
		setLoans,
		login,
		leadLogin,
		mncapQuestionnaire
	}
)(App);
