import {BORROWER_UPDATE, BORROWER_INITIAL_FETCH, BORROWER_SET, RE_INITIALIZE_ALL, SET_STATE, RELOAD_STATE, CHECK_LEMOINE, MNCAP_QUESTIONNAIRE} from "../../constants/action-types";
import {loadState} from '../../localStorage';

const persistedState = loadState();

let initialState = {
	birth_country: 'France',
	birth_country_id: 67,
	first_name: '',
	last_name: '',
	birth_date: '',
	has_coborrower: false,
	insurance_rate: '',
	insurance_premium: '',
	coverage: 100,
	taea: '',
	csp: 1,
	salutation: '',
	floating_premium: false,
	minimal_insured_amount: false,
	zip: null,
	phone: null,
	submitted_bravo_email: false,
	smoker: false,
	city: '',
	manual_work: false,
	question_one: "false",
	question_two: "false",
	question_three: "false",
	question_four: "false",
	question_five: "false",
	meeting_date: new Date(),
	meeting_hours: '',
	foreign_country: false,
	foreign_travel: false,
	sports: '',
	exact_profession: '',
	subscriber_name: '',
	subscriber_town: '',
	subscriber_address: '',
	subscriber_zip: '',
	lemoine: false,
	insured_by_bank: true,
	payment_iban: ''
};

let defaultState = Object.assign({}, initialState, persistedState.borrower);

export default (state = defaultState, action) => {
	switch (action.type) {
	case SET_STATE:
		return action.data.borrower;
	case RE_INITIALIZE_ALL:
		return initialState;
	case RELOAD_STATE:
		return {
			...state,
			...action.payload.borrower
		};
	case BORROWER_INITIAL_FETCH:
		return {
			...action.data
		};
	case BORROWER_UPDATE:
		return {
			...state,
			[action.key]: action.value
		};
	case CHECK_LEMOINE:
		return {
			...state,
			lemoine: action.data && action.data.borrower ? action.data.borrower : {}
		};
	case BORROWER_SET:
		return {
			...state,
			...action.data
		};
	case MNCAP_QUESTIONNAIRE:
		return {
			...state,
			'mncap_url': action.data.url
		};
	default:
		return state;
	}

}
