import React from 'react';
import PropTypes from 'prop-types';
import {StringField} from '../Fields';
import NumberFormat from 'react-number-format';

const NumberField = (props) => {
	if (props.hidden) {
		return null;
	}

	return !isNaN(props.value)
		? <NumberFormat
			{...props}
			displayType={'input'}
			value={(props.value + '').replace('.', ',')}
			thousandSeparator={props.thousandSeparator || ' '}
			decimalSeparator={','}
			decimalScale={props.decimalScale || 2}
			allowNegative={false}
			onValueChange={(values) => {
				if (values.value === '' || typeof (values.value) == "undefined") {
					values.value      = 0;
					values.floatValue = 0;
				}
				if (values.value.length > 15) return false;
				let value = values.floatValue !== '' && !Number.isNaN(values.floatValue) ? values.floatValue : values.value;
				value     = value === '.' ? '' : value;
				props.onValueChange(props.name, String(value));
			}}
			customInput={StringField}
			style={props.style}
			variant='standard'
			InputLabelProps={{ shrink: true }}
		/>
		: <StringField {...props} InputLabelProps={{ shrink: true }} style={props.style} onChange={(e) => props.onValueChange(props.name, e.target.value)}/>;
};

export default NumberField;

NumberField.propTypes = {
	style: PropTypes.string,
	name: PropTypes.string,
	onValueChange: PropTypes.func,
	hidden: PropTypes.bool,
	validators: PropTypes.array,
	errorMessages: PropTypes.array,
	// value: PropTypes.number,
	decimalScale: PropTypes.number
};
