import React from 'react';
import connect from 'react-redux/es/connect/connect';
import Shell from "../../components/Shell";
import {getParameterByName} from "../../js/helpers/functions";

class Mirror extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			dots: ''
		}
	}

	componentDidMount() {
		let isMobile = getParameterByName('is_mobile', window.location.href);
		window.isMobile = isMobile;
		sessionStorage.setItem('isMobile', isMobile);

		window.__MIRROR_AGENT = true;
		sessionStorage.setItem('__MIRROR_AGENT', true);

		setInterval(() => {
			this.setState({dots: this.state.dots === '...' ? '' : (this.state.dots + '.')});
		}, 650);
	}

	getStep(){
		return null;
	}

	render() {
		return <h3 className='centeredText'>Mirroring is loading {this.state.dots}</h3>;
	}
}

export default connect()(Shell(Mirror));