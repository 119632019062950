import React, {useState, useEffect} from 'react';
import styles from './styles.module.css';
import logo from "../Loader/loader-logo.png";
import {Col, Row} from "react-grid-system";
import Button from "@material-ui/core/Button";

const Inactivity = () => {
	const [active, setActive] = useState(true);
	const [restricted, setRestricted] = useState(false);

	useEffect(() => {
		if (!window.__MIRROR_AGENT && window.self === window.top) {
			let session = new Date().getTime().toString();
			sessionStorage.setItem('tab_activity', session);
			localStorage.setItem('tab_activity', session);

			window.addEventListener('focus', () => {
				let local = localStorage.getItem('tab_activity');
				let session = sessionStorage.getItem('tab_activity');
				setActive(local === session);
			});
		}
	}, []);

	useEffect(() => {
		active ? document.body.classList.remove('prevent-scrolling') :
			document.body.classList.add('prevent-scrolling');
	}, [active]);

	useEffect(() => {
		if (window.__MIRROR_AGENT) {
			let restricted = ['sante', 'co-sante', 'bravo_simplifie', 'bravo_detaille'].find(page => {
				return window.location.pathname.indexOf(page) !== -1;
			});
			setRestricted(!!restricted);
		}
	}, [window.location.href]);

	return (
		<div className={`${styles.inactivityWrapper} ${!active && styles.visible} ${restricted && styles.restricted}`}>

			<Row>
				{!active && <Col className={styles.inactivityContent}>
					<img src={logo} className={styles.inactivityLogo} alt="Wedou Assurances"/>
					<p className={styles.inactivityText}>
						<b>Vous avez une autre simulation en cours.</b><br/><br/>
                        Pour poursuivre avec cette fenêtre et rendre la précédente inactive<br/>
                        vous pouvez utiliser le button ci dessous.<br/>
					</p>
					<Button className={styles.inactivityButton} onClick={() => window.location.reload()}>
                        Ma simulation
					</Button>
				</Col>}

				{restricted && <Col className={styles.inactivityContent}>
					<img src={logo} className={styles.inactivityLogo} alt="Wedou Assurances"/>
					<p className={styles.inactivityText}>
						<b>Cette page est limitée à la mise en miroir.</b><br/>
                        Veuillez patienter pendant que l'utilisateur avance.
					</p>
				</Col>}
			</Row>
		</div>
	);
}

export default Inactivity;
