import React from 'react';
import connect from "react-redux/es/connect/connect";
import PropTypes from "prop-types";

import Shell from '../../components/Shell';
import {updateConfig} from "../../js/actions/config";
import {borrowerFetch} from "../../js/actions/borrower";
import {coborrowerFetch} from "../../js/actions/coborrower";
import {loanFetch} from "../../js/actions/loan";

class PasswordReset extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			email: '',
			sent: false,
			error: false,
		};
		this.props.updateConfig('loading', false);
	}


	getStep() {
		return 'forgottenPassword';
	}

	render() {
		return (
			<div>
				{/*<ValidatorForm onSubmit={() => {*/}
				{/*	this.props.updateConfig('loading', true);*/}

				{/*	let params = {*/}
				{/*		email: this.state.email*/}
				{/*	};*/}
				{/*	return post(params, process.env.REACT_APP_API_URL + "/customer/request_password_reset")*/}
				{/*		.then(() => this.setState({sent: true, error: false}))*/}
				{/*		.catch(() => this.setState({sent: true, error: true}))*/}
				{/*		.then(() => this.props.updateConfig('loading', false));*/}
				{/*}}>*/}
				{/*	<Row justify="center" className='firstRow'>*/}
				{/*		<Col md={8}>*/}
				{/*			<StringField*/}
				{/*				label={<Translate value='step3.email'/>}*/}
				{/*				onChange={(e) => this.setState({email: e.target.value})}*/}
				{/*				value={this.state.email}*/}
				{/*				name='email'*/}
				{/*				validators={['required', 'isEmail']}*/}
				{/*				errorMessages={['Cette information doit être renseignée', 'L\'e-mail est incorrect']}*/}
				{/*				margin="normal"*/}
				{/*			/>*/}
				{/*		</Col>*/}
				{/*	</Row>*/}

				{/*	{!!this.state.sent && (*/}
				{/*		<Row justify='center'>*/}
				{/*			<Col md={8}>*/}
				{/*				{!this.state.error ? (*/}
				{/*					<p className='successMessage'><Translate value='forgottenPassword.requestSent'/></p>*/}
				{/*				) : (*/}
				{/*					<p className='errorMessage'><Translate value='forgottenPassword.requestError'/></p>*/}
				{/*				)}*/}
				{/*			</Col>*/}
				{/*		</Row>*/}
				{/*	)}*/}

				{/*	<Row justify='center'>*/}
				{/*		<Col md={4} lg={4} sm={12} xs={12}>*/}
				{/*			<FormButton value={<Translate value='generic.follow'/>}*/}
				{/*						disabled={this.state.sent && !this.state.error}/>*/}
				{/*		</Col>*/}
				{/*	</Row>*/}

				{/*</ValidatorForm>*/}
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		borrower: state.rootReducer.borrower,
		coborower: state.rootReducer.coborrower,
		loan: state.rootReducer.loan,
		config: state.rootReducer.config
	};
};
export default connect(
	mapStateToProps, {
		borrowerFetch,
		coborrowerFetch,
		updateConfig,
		loanFetch
	}
)(Shell(PasswordReset));

PasswordReset.propTypes = {
	// Data
	config: PropTypes.object,
	coborower: PropTypes.object,
	loan: PropTypes.object,
	borrowerFetch: PropTypes.func,
	coborrowerFetch: PropTypes.func,
	updateConfig: PropTypes.func,
	loanFetch: PropTypes.func,
};
