import React from "react";

import connect from "react-redux/es/connect/connect";
import Shell from "../../../components/Shell";
import {post} from "../../../js/helpers/rest";
import {getBackendUrl} from "../../../js/helpers/functions";
import Loader from "../../../components/Loader";

class PureIframe extends React.PureComponent {
	render() {
		const {src, width, height, style, stateUpdate} = this.props;
		return (
			<iframe samesite="none" secure={true} src={src} width={width} height={height} style={style} onLoad={() => stateUpdate({loading: false})}></iframe>
		);
	}
}

class PersonalDigitalInsuree extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			sso: null,
			loading: true,
			theme: this.props.config.theme
		};
	}

	componentDidMount() {
		post({
			borrower: this.props.borrower,
		}, getBackendUrl() + "/calculate/client_sso")
			.then((result) => {
				this.setState({sso: result?.sso?.clientInsurerAccess?.urlSsoAccess || null, loading: false});
			})
			.catch((e) => {
				console.warn('SSO request issue:', e);
				// this.setState({loading: false});
			});
	}

	getStep() {
		return 'personal.home';
	}

	render() {
		let wrapper = document.getElementsByClassName('contentWrapper')[0];
		if (wrapper) {
			wrapper.style.paddingBottom = '0';
		}
		return (
			<div>
				<Loader config={this.state}/>
				{this.state.sso && <PureIframe src={this.state.sso}
				                               stateUpdate={this.setState}
				                               width={(window.innerWidth - document.getElementsByClassName('sidebar')[0]?.offsetWidth) + 'px'}
				                               height={window.innerHeight + 'px'}
				                               style={{border: 'none'}}
				/>}
			</div>
		)
	}
}

export default connect()(Shell(PersonalDigitalInsuree));
