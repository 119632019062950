import style from './style.module.css';
import React from 'react';
import history from '../../history';
import Modal from '../../components/Modal';
import {FaPhoneAlt} from 'react-icons/fa'
import {Row, Col} from 'react-grid-system';
import agentImage from './chat_agent.svg';
import StandardButton from "../Form/StandardButton";
const I18n = require('react-redux-i18n').I18n;

class SideEar extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			active: false,
			callBack: false,
			dialogOpen: false,
			callBackSent: false,
			openModal: false
		};

	}

	render() {
		let isWedouSite = ['app.wedou.fr', 'stage.wedou.fr', 'staging.homelifeinsurance.fr', 'app.wedou.bg', 'dev.wedou.fr', 'es.wedou.care', 'app.wedou.care'].indexOf(window.location.hostname) !== -1;
		let number = isWedouSite ? I18n.t('header.sidebar.mobile') : this.props.config.theme.mobile;
		return (
			<div>
				<Modal title={<div><b>Besoin d'aide?</b><br/><br/>Nous sommes là pour vous accompagnera à chaque étape. À vous de décider comment et quand!</div>}
					dialogOpen={this.state.openModal}
					config={this.props.config}
					onClose={() => {
						this.setState({openModal:false});
					}}>

					<Row className={style.conseilFrame}>
						<Col className="centeredText">
							<p className={style.contactAction}>
								<FaPhoneAlt size={17} style={{marginRight:'10px'}}/> Appelez-nous
							</p>
							<a href={'tel:' + number}><button style={{color: this.props.config.theme.primary, border: `2px solid ${this.props.config.theme.primary}`}} className={style.conseilButton}>{number}</button></a>
						</Col>
					</Row>
					<Row className={style.conseilFrame} onClick={() => {
						document.getElementById('chat-wrapper').style.display = 'block';
						document.body.classList.add('chatOpened');
						this.setState({active: false});
					}}>
						<Col className="centeredText">
							<p className={style.contactAction}>
								<svg height="20px" style={{marginRight:'10px'}} viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M21.6666 9.47915C21.6666 14.7143 16.816 18.9583 10.8333 18.9583C9.76034 18.9597 8.69182 18.8204 7.65509 18.5439C6.86426 18.9448 5.04832 19.7139 1.99333 20.215C1.7225 20.2583 1.51666 19.9766 1.62364 19.7248C2.10302 18.5927 2.53635 17.0841 2.66635 15.7083C1.0075 14.0427 0 11.8625 0 9.47915C0 4.24395 4.85062 0 10.8333 0C16.816 0 21.6666 4.24395 21.6666 9.47915ZM6.77082 9.47915C6.77082 9.12 6.62815 8.77557 6.37419 8.52161C6.12024 8.26766 5.7758 8.12498 5.41666 8.12498C5.05751 8.12498 4.71307 8.26766 4.45912 8.52161C4.20516 8.77557 4.06249 9.12 4.06249 9.47915C4.06249 9.8383 4.20516 10.1827 4.45912 10.4367C4.71307 10.6906 5.05751 10.8333 5.41666 10.8333C5.7758 10.8333 6.12024 10.6906 6.37419 10.4367C6.62815 10.1827 6.77082 9.8383 6.77082 9.47915V9.47915ZM12.1875 9.47915C12.1875 9.12 12.0448 8.77557 11.7909 8.52161C11.5369 8.26766 11.1925 8.12498 10.8333 8.12498C10.4742 8.12498 10.1297 8.26766 9.87577 8.52161C9.62182 8.77557 9.47915 9.12 9.47915 9.47915C9.47915 9.8383 9.62182 10.1827 9.87577 10.4367C10.1297 10.6906 10.4742 10.8333 10.8333 10.8333C11.1925 10.8333 11.5369 10.6906 11.7909 10.4367C12.0448 10.1827 12.1875 9.8383 12.1875 9.47915V9.47915ZM16.25 10.8333C16.6091 10.8333 16.9536 10.6906 17.2075 10.4367C17.4615 10.1827 17.6041 9.8383 17.6041 9.47915C17.6041 9.12 17.4615 8.77557 17.2075 8.52161C16.9536 8.26766 16.6091 8.12498 16.25 8.12498C15.8908 8.12498 15.5464 8.26766 15.2924 8.52161C15.0385 8.77557 14.8958 9.12 14.8958 9.47915C14.8958 9.8383 15.0385 10.1827 15.2924 10.4367C15.5464 10.6906 15.8908 10.8333 16.25 10.8333V10.8333Z" fill="#fff"></path>
								</svg> Démarrer une conversation
							</p>
							<button style={{color: this.props.config.theme.primary, border: `2px solid ${this.props.config.theme.primary}`}} className={style.conseilButton}>Tchatter avec un conseiller</button>
						</Col>
					</Row>
					<Row className={style.conseilFrame} onClick={() => {history.push('/rendez-vous')}}>
						<Col className="centeredText">
							<p className={style.contactAction}>
								<svg height="20px" style={{marginRight:'10px'}} viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M8.05625 15.11V8.4425H7.265C6.6983 8.74459 6.15062 9.08105 5.625 9.45V10.3188C6.09375 9.9975 6.83625 9.54375 7.1975 9.3475H7.2125V15.11H8.05625V15.11ZM9.54125 13.4788C9.6 14.2788 10.2838 15.2362 11.67 15.2362C13.2425 15.2362 14.17 13.9038 14.17 11.6475C14.17 9.23 13.1938 8.3125 11.7288 8.3125C10.5713 8.3125 9.4825 9.1525 9.4825 10.5738C9.4825 12.0237 10.5125 12.7863 11.5775 12.7863C12.51 12.7863 13.115 12.3162 13.3063 11.7987H13.34C13.335 13.4437 12.7638 14.5038 11.7088 14.5038C10.8788 14.5038 10.4488 13.9413 10.3963 13.4788H9.54125V13.4788ZM13.2325 10.5825C13.2325 11.4525 12.5338 12.0575 11.7525 12.0575C11.0013 12.0575 10.3225 11.5787 10.3225 10.5575C10.3225 9.52875 11.05 9.045 11.7825 9.045C12.5738 9.045 13.2325 9.5425 13.2325 10.5825Z" fill="#fff"></path>
									<path d="M4.375 0.5C4.54076 0.5 4.69973 0.565848 4.81694 0.683058C4.93415 0.800269 5 0.95924 5 1.125V1.75H15V1.125C15 0.95924 15.0658 0.800269 15.1831 0.683058C15.3003 0.565848 15.4592 0.5 15.625 0.5C15.7908 0.5 15.9497 0.565848 16.0669 0.683058C16.1842 0.800269 16.25 0.95924 16.25 1.125V1.75H17.5C18.163 1.75 18.7989 2.01339 19.2678 2.48223C19.7366 2.95107 20 3.58696 20 4.25V18C20 18.663 19.7366 19.2989 19.2678 19.7678C18.7989 20.2366 18.163 20.5 17.5 20.5H2.5C1.83696 20.5 1.20107 20.2366 0.732233 19.7678C0.263392 19.2989 0 18.663 0 18V4.25C0 3.58696 0.263392 2.95107 0.732233 2.48223C1.20107 2.01339 1.83696 1.75 2.5 1.75H3.75V1.125C3.75 0.95924 3.81585 0.800269 3.93306 0.683058C4.05027 0.565848 4.20924 0.5 4.375 0.5V0.5ZM1.25 5.5V18C1.25 18.3315 1.3817 18.6495 1.61612 18.8839C1.85054 19.1183 2.16848 19.25 2.5 19.25H17.5C17.8315 19.25 18.1495 19.1183 18.3839 18.8839C18.6183 18.6495 18.75 18.3315 18.75 18V5.5H1.25Z" fill="#fff"></path>
								</svg> Prendre rendez-vous</p>
							<button style={{color: this.props.config.theme.primary, border: `2px solid ${this.props.config.theme.primary}`}} className={style.conseilButton}>Planifier un appel</button>
						</Col>
					</Row>

				</Modal>
				{/*<div style={{background: `${this.props.config.theme.primary}`, color: `#fff`}} className={style.conseil_btn} onClick={() => {this.setState({openModal: !this.state.openModal})}}>*/}
				{!this.state.openModal &&
						<div style={{position: 'fixed', bottom: '12px', right: '12px', zIndex: '9999'}} onClick={() => {this.setState({openModal: !this.state.openModal})}}>
						    <img src={agentImage} style={{width: '130px', height: '130px', position: 'relative', top: '44px'}} />
						    <StandardButton value={"Aide"} style={{width: '110px', height: '35px', background: '#F6B60D 0% 0% no-repeat padding-box', borderRadius: '26px'}} />
						</div>
				}
				{/*</div>*/}
			</div>
		)

	}
}

export default SideEar;
