import {LOAN_UPDATE, LOAN_INITIAL_FETCH, LOANS_SET} from '../../constants/action-types';

export const updateLoan = (key, value) => {
	return dispatch => {
		dispatch({
			type: LOAN_UPDATE,
			key,
			value
		});
	}
};
export const loanFetch  = (data) => {
	return dispatch => {
		dispatch({
			type: LOAN_INITIAL_FETCH,
			data
		});
	}
};

export const setLoans = (data) => {
	return dispatch => {
		dispatch({
			type: LOANS_SET,
			data
		});
	}
};