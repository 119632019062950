import React from 'react';
import css from './sideMenu.module.css';
import history from "../../history";
import {Col, Row} from "react-grid-system";
import {
	Town,
	PostalCode,
	StreetDetails, Phone,
} from "../Form/StepsFields/step4";
import {ValidatorForm} from "react-material-ui-form-validator";
import Translate from "components/CustomTranslate";
import {StringField} from "../Form/Fields";
import FormButton from "../Form/FormButton";

class SideMenu extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			updated: false,
			error: false,
		};
	}

	login() {
		this.props.updateConfig('sideMenu', false);
		// history.push('/connexion');
		this.props.openAuthModal('login')
	}

	profileData() {
		return (
			<ValidatorForm onSubmit={() => {
				this.props.updateCustomer(this.mapData(this.props.borrower, this.props.coborrower))
					.then(() => this.setState({updated: true, error: false}))
					.catch(() => this.setState({updated: true, error: true}));
			}}>
				<div style={{backgroundColor: this.props.config.theme.secondary}} className={css.sideBarFrame}>

					<span style={{color: "black"}} className={css.uppercase}>
						<p style={{textAlign: 'center', fontSize: '24px', fontWeight: 'bold'}}>
							<Translate value="welcomeScreen.welcome"/>
						</p>
						<p style={{textAlign: 'center', fontSize: '24px', fontWeight: 'bold', lineHeight: '0'}}>
							{this.props.borrower.salutation} {this.props.borrower.first_name} {this.props.borrower.last_name}
						</p>
					</span>
					<Row style={{marginBottom: '10px'}}>
						<Col className={css.infoSideBar} style={{fontSize: '20px'}}>
							<p>Informations Personneles</p>
						</Col>
					</Row>
					<Row className={css.nonEditable} justify="center">
						<Col md={6}>
							<p style={{color: 'white'}}><Translate value="step2.birthDate"/>:<p style={{color: "black"}}
							                                                                    className={css.infoSideBar}> {this.props.borrower.birth_date}</p></p>
						</Col>
						<Col md={6}>
							<p style={{color: 'white', textAlign: 'center'}}><Translate value='step4.placeOfBirth'/>:<p style={{color: "black"}}
							                                                                                            className={css.infoSideBar}> {this.props.borrower.birth_city}</p>
							</p>
						</Col>
					</Row>

					<Row justify="center" className={css.editable}>
						<Col md={12}>
							<StringField
								onChange={(e) => this.props.updateBorrower('email', e.target.value)}
								value={this.props.borrower.email}
								name='email'
								className={'emailButton'}
								validators={['isEmail']}
								errorMessages={['L\'e-mail est incorrect']}
								label={<Translate value='step3.email'/>}
								disabled={true}
								margin="normal"
							/>
						</Col>
						<Col md={12}>
							<Phone update={this.props.updateBorrower} data={this.props.borrower}/>
						</Col>

						<Col md={6}>
							<StreetDetails update={this.props.updateBorrower} data={this.props.borrower}/>
						</Col>

						<Col md={6} sm={6} xs={6}>
							<PostalCode update={this.props.updateBorrower} data={this.props.borrower} updateTown={() => {
							}}/>
						</Col>
						<Col md={6} sm={6} xs={6}>
							<Town update={this.props.updateBorrower} data={this.props.borrower}/>
						</Col>

						<Col md={6} sm={9} xs={9} style={{marginTop: '35px'}}>
							<FormButton value="Actualiser"/>
						</Col>

						{!!this.state.updated && (
							<Col md={12}>
								{!this.state.error ?
									<p className='successMessage'>Actualisation réussie</p>
									:
									<p className='errorMessage'>Erreur</p>
								}
							</Col>
						)}
					</Row>
				</div>
			</ValidatorForm>
		);
	}

	render() {
		!this.props.config.sideMenu && document.body.classList.remove('prevent-scrolling');
		!!this.props.config.sideMenu && document.body.classList.add('prevent-scrolling');

		return (
			<div className={`${css.sideMenu} ${!!this.props.config.sideMenu ? css.opened : css.closed}`}>
				<div style={{backgroundImage: this.props.config.theme.sidebar}} className={css.container}>
					<div className={css.header}>
						<i style={{color: this.props.config.theme.primary}} className={`fa fa-close ${css.close}`}
						   onClick={() => {
							   this.props.updateConfig('sideMenu', false);
							   this.setState({updated: false, error: false});
						   }}/>
					</div>

					<ul className={`${css.menu} ${css.profile}`}>
						{!this.props.config.loggedIn ?
							<div>
								<li style={{color: 'white'}}>
									<p className={css.uppercase}>
										Vous n'êtes actuellement pas connecté.
									</p>
									<p className={css.uppercase}>
										Veuillez saisir vos coordonnées et vous inscrire.
									</p>
								</li>
								<li style={{color: 'white'}} className={css.goToBonjour} onClick={() => {
									this.props.updateConfig('sideMenu', false);
									history.push('/prets');
								}}>
									<i className="fa fa-calculator"/> Calculer mon économie
								</li>
							</div>
							:
							<div>
								<li>{this.profileData()}</li>
							</div>
						}
					</ul>

					<ul className={`${css.menu} ${css.authentication}`}>
						{!this.props.config.loggedIn ?
							<li style={{color: 'white'}}>
								<span onClick={() => this.login()}><i className="fa fa-sign-in"/>
									Reprendre ma proposition
								</span>
							</li> :

							<li style={{color: this.props.config.theme.primary || "white"}} onClick={() => {
								this.props.updateConfig('sideMenu', false);
								this.props.reInitializeState();
								this.props.logout();
							}}><i className="fa fa-sign-out"/> Se déconnecter </li>
						}
					</ul>
				</div>
				<div className={css.background} onClick={() => this.props.updateConfig('sideMenu', false)}/>
			</div>
		)
	}

	mapData(b, cb) {
		let data = {
			borrower:
				{
				    email: b.email,
				    first_name: b.first_name,
				    last_name: b.last_name,
				    city: b.city,
				    phone: b.mobile,
				    salutation: b.salutation,
				    zip: b.zip,
				    address: b.address,
				    birth_city: b.birth_city,
				    additional_address: b.additional_address
				}
		};
		if (b.has_coborrower) {
			data.has_coborrower = true;
			data.coborrower     = {
				email: cb.email,
				first_name: cb.first_name,
				last_name: cb.last_name,
				city: cb.city,
				phone: cb.mobile,
				salutation: cb.salutation,
				zip: cb.postalCode,
				address: cb.address,
				birth_city: cb.birth_city,
				additional_address: cb.additional_address,
			}
		}
		return data;
	}

}

export default SideMenu;
