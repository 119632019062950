import React from 'react';
import Shell from '../../components/Shell';
import {connect} from 'react-redux';
import {Row, Col} from 'react-grid-system';
import Button from "@material-ui/core/Button";
import history from "../../history";
import Translate from "components/CustomTranslate";
class BravoMigrated extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			bravo: {},
			submitted: false
		}
	}

	getStep() {
		return 'bravo_migrated';
	}

	render() {
		return (
			<div>
				<Row justify='center' style={{fontSize: '18px', textAlign: 'center', marginTop: '50px'}}>
					<Col md={8}>
						<p>Votre situation ne permet pas de vous proposer une solution adaptée à votre besoin avec MNCAP.</p>
						<p>Nous allons rechercher ensemble une solution auprès d’une autre assureur.</p>
						<p>Vous devrez répondre à quelques questions rapides.</p>
					</Col>
				</Row>
				<Row justify="center">
					<Col md={4}>
						<Button id='button-economise' style={{backgroundColor: this.props.config.theme.primary}} className={'formButton'} onClick={() => history.push('/profession')}>
							<Translate value='generic.continue'/>
						</Button>
					</Col>
				</Row>
			</div>
		)
	}

}

export default connect()(Shell(BravoMigrated));
