export default {
	'login': {
		title: 'Bon retour parmi nous&nbsp;!',
		email: 'Votre adresse e-mail',
		password: 'Mot dePolitique de confidentialité passe',
		facebookButton: 'Continuer avec Facebook',
		linkedInButton: 'Continuer avec LinkedIn',
		forgottenPassword: 'Mot de passe oublié&nbsp;?',
	},
	'header': {
		'myProfile': 'Моят профил',
		'alreadyClient': 'Вече сте клиент?',
		sidebar: {
			your_loan: "Вашият заем",
			your_economy: "Вашето спестяване",
			info: "Лични данни",
			contract: "Договор",
			signature: "Подписване",
			help: "Нуждаете се <br/><span style=\"font-weight: 600\">от помощ ?</span>",
			chat: "Чат",
			meeting: "Среща с наш агент",
			later: 'Довършете по късно',
			mobile: '0898 298 354'
		}
	},
	'forgottenPassword': {
		title: 'Pour réinitialiser votre mot de passe, veuillez entrer votre adresse e-mail.',
		email: 'Votre adresse e-mail',
		link: 'J\'ai oublié mon mot de passe',
		requestSent: 'Nous avons adressé un email pour réinitialiser votre mot de passe.',
		requestError: 'E-mail inconnu ...',

	},
	'changePassword': {
		title: 'Choisissez votre nouveau mot de passe.',
	},
	'franceConnect': {
		loginText: 'Se connecter avec FranceConnect<br/>Accédez à tous les services avec un seul compte :<br/>ameli, impôts, la poste, Mobile Connect et moi ou MSA',
		registrationText: 'Je pré-remplis mon formulaire avec FranceConnect<br/>Pour cela, mes données d\'identité et de contact <br/>seront transmises à Wedou',
	},
	'welcomeScreen': {
		title: 'Vous êtes connecté avec',
		welcome: 'Bienvenue',
	},
	'personal': {
		'resignate': 'Renonciation',
		'resignateDescription': 'Vous disposez de 30 jours pour renoncer à votre souscription. Il vous suffit de nous adresser sur papier libre une demande signée comportant vos noms, prénom et N° de contrat à wedou@wedou.fr.',
		// "resignateDescription": "Vous disposez d’un droit de renonciation à votre adhésion dans les 30 jours calendaires révolus à compter de la date de signature de votre demande d’adhésion.",
		'resignateButton': 'Je renonce',
		'frequentQuestions': 'Questions fréquentes',
		'header': {
			'home': 'accueil',
			'steps': 'mes démarches',
			'documents': 'mes documents',
			'inbox': 'nos échanges',
		},
		'home': {
			'title': '%{name}, bienvenue dans votre Espace Personnel',
			'header': '%{name}, bienvenue dans votre Espace Personnel',
			'myContacts': 'Mes contrats',
			'finishApplication': 'Plusieurs documents sont manquants pour finaliser votre demande de substitution',
			'finishedApplication': 'Votre dossier est complet, nos équipes s’occupent de la substitution de votre assurance !',
			'resignDocuments': 'Vous pouvez signez les documents ci-dessous en ligne ou les télécharger'
		},
		'steps': {
			'title': 'Vous trouverez ci-dessous vos démarches en cours',
			'subtitle': 'Vous trouverez ci-dessous vos démarches en cours',
			'ibanSubtitle': 'IBAN Banque de prélèvement de mon assurance Wedou (mon compte courant)',
			'subHead': 'Pour compléter votre dossier de substitution, transmettez-nous au plus vite les documents suivants <i class=\'fa fa-question-circle question-icon\' data-tip=\'scan ou photo<br />smart phone lisible\' data-multiline=\'true\'/>',
			'id_document': 'Pièce d’identité (recto - verso) <i class=\'fa fa-question-circle question-icon\' data-tip=\'Passeport ou CNI\' data-multiline=\'true\'/>',
			'id_document_front': 'Pièce d’identité /recto/ <i class=\'fa fa-question-circle question-icon\' data-tip=\'Passeport ou CNI\' data-multiline=\'true\'/>',
			'id_document_back': 'Pièce d’identité /verso/ <i class=\'fa fa-question-circle question-icon\' data-tip=\'Passeport ou CNI\' data-multiline=\'true\'/>',
			'id_document_co': 'Pièce d’identité du co-emprunteur (recto - verso) <i class=\'fa fa-question-circle question-icon\' data-tip=\'Passeport ou CNI\' data-multiline=\'true\'/>',
			'id_document_co_front': 'Pièce d’identité du co-emprunteur /recto/ <i class=\'fa fa-question-circle question-icon\' data-tip=\'Passeport ou CNI\' data-multiline=\'true\'/>',
			'id_document_co_back': 'Pièce d’identité du co-emprunteur /verso/ <i class=\'fa fa-question-circle question-icon\' data-tip=\'Passeport ou CNI\' data-multiline=\'true\'/>',
			'contract': 'Échéancier de prêt <i class=\'fa fa-question-circle question-icon\' data-tip=\'incluant l’échéancier\' data-multiline=\'true\'/>',
			'isp': 'Fiche Standardisée d’Information <i class=\'fa fa-question-circle question-icon\' data-tip=\'La FSI remise par votre banque\' data-multiline=\'true\'/>',

			'fsi': 'Fiche Standardisée d’Information <i class=\'fa fa-question-circle question-icon\' data-tip=\'La FSI remise par Wedou\' data-multiline=\'true\'/>',

			'general_conditions': 'Conditions Générales',
			'subst_mandate': 'Mandat de Substitution',
			'proposition': 'Proposition Assurance',
			'tarification': 'Projet Tarification',
			'MembershipRequest': 'Demande d\'adhésion',
			'subst_mandat': 'Mandat résiliation', //Mandat résiliation
			'renunciation_form': 'Document de renonciation',
			'LetterInsurance': 'Lettre à l\'assureur',
			'membership_bundle': 'Demande individuelle d\'affiliation',
			'membership_certificate': 'Certificat d\'adhésion',
			'MembershipCertificat': 'Certificat d\'adhésion',
			'certificate_afiesca': 'Conditions Particuliers',
			'health_questionnaire': 'Questionnaire de santé',
			'sepa_mandate': 'Mandat SEPA',
			'bpa': 'Bon Pour Accord',
			'LetterBank': 'Lettre à la banque',
			'cg': 'Conditions générales',
			'other': 'Autre document',

			'other_documents': 'Autres documents',
			'yourIban': 'Votre IBAN',
			'loanIban': 'IBAN Banque de mon prêt',
			'ibanButton': 'Je signe mon mandat SEPA',
			'confirmIbanButton': 'Confirmer l’IBAN',
			'ibanAccount': 'Il s’agit de mon compte dans l’établissement prêteur',
			'ibanCheckbox': 'Mon compte de prélèvement n’est pas celui de la banque de mon prêt&nbsp;<span data-tip=\'Il s’agit de l’IBAN du compte dans la banque <br/>où vous avez contracté votre prêt. Il <br/>nous permet de vous identifier lors de la <br/>demande de substitution. S’il <br/>est différent de celui renseigné, vous <br/>pouvez le renseigner ici.\' data-multiline=\'true\' class=\'tip\'><i class=\'fa fa-question-circle purple\'></i></span>',
			'ibanAccountHint': 'Il s’agit de l’IBAN du compte sur lequel sera prélevé votre cotisation Wedou à compter de la date d’anniversaire. Idéalement il s’agit du compte lié à votre crédit.',
			'bankInfo': 'Dès que nous aurons tous ces documents, nous pourrons prendre en charge les démarches de substitution auprès de votre banque.',
			'effectiveDate': 'Environ 15 jours avant le %{effectiveDate}, date prévisionnelle d’entrée en vigueur de votre contrat Wedou, nous vous confirmerons la mise en place de votre nouveau prélèvement automatique.',
		},
		'documents': {
			'title': 'Vous retrouverez ci-dessous l’ensemble de vos documents de souscription :',
			'subtitle': 'Vous retrouverez ci-dessous l’ensemble de vos documents de souscription :',
			'subHead': 'Les documents que vous avez téléchargés',
			'noDocuments': 'Aucun document',
			'wedouDocuments': 'Les documents de Wedou',
			'wedouDocumentsCo': 'Les documents de Wedou du co-emprunteur',
		},
		'inbox': {
			'title': '',
			'subtitle': 'Vous trouverez ci-desous vos echanges avec Wedou',
			'requestInput': 'Ma demande',
			'sendDemand': 'Envoyer ma demande',
		},
		'footer': {
			'motto': 'Ensemble, transformons l\'assurance !',
			'connections': 'Liens',
			'borrowerInsurance': 'L’assurance emprunteur',
			'whoWeAre': 'Qui nous sommes',
			'actualInformation': 'Actualités et Informations',
			'reclamation': 'Réclamations',
			'copyright': '© Wedou, Ensemble transformons l\'assurance. %{year}. Tous droits réservés'
		}
	},
	'sections': {
		1: 'Les informations sur votre crédit',
		2: 'Vous',
		3: 'Coût de votre assurance en cours',
	},
	quickPricing: {
		ourBestOffer: 'Нашата оферта'
	},
	'not_in_your_country': {
		title: 'Отваряме врати скоро!',
		subtitle:'Работим усилено за да можем да предложим най-ниските цени в България.',
		text: 'За да предложим ненадминати оферти, нашите екипи работят постоянно над всеки един детайл.'
	},
	'generic': {
		mins: "минути",
		or: 'Или',
		yes: 'Oui',
		no: 'Non',
		currency: 'лeва',
		keepInTouch: 'Дръжте ме в течение',
		currencySymbol: ' лева',
		back: 'Назад',
		attach: 'Je choisis un document',
		reattach: 'J’ajoute un document',
		missing: 'Manquant',
		transferred: 'Transféré',
		accept: 'Приемам',
		follow: 'Напред',
		continue: 'Напред',
		validate: 'Valider',
		one: 'Un',
		two: 'Deux',
		three: 'Trois',
		years: 'Années',
		months: 'Mois',
		loan: 'Заем',
		myEstimation: 'Моята цена',
		monthlyPremium: 'Месечна вноска',
		iWantProfit: 'Искам да се възползвам',
		email: 'wedou@wedou.fr',
		for: "за",
		// your: "La vôtre",
		your: 'Votre',
		borrower: 'Emprunteur:',
		coborrower: 'Co-emprunteur:',
		ofCoborrower: 'Du co-emprunteur',
		coverage: 'Quotité',
		withFacebook: 'avec Facebook',
		withLinkedIn: 'avec LinkedIn',
		withEmail: 'avec votre Email',
		personalise: 'Персонализиране',
		acceptAll: 'Приемам всички',
		save: 'Запазване',
		cookieEssential: 'Задължителни',
		cookieStatistics: 'Бисквитки за статистики',
		cookiePersonaliseMessage: 'Изберете как ще използваме Вашите данни:',
		cookieYourChoice: 'Вашите данни, вашият избор',
		cookieMessage: 'Използването на рекламни бисквитки подобрява и персонализира потребителското Ви изживяване. С натискане на бутона "Приемам" Вие се съгласявате с използването на всички бисквитки.',
		cookiePolicyMessage: 'Политика на конфиденциалност',
		//days of week
		Mon: 'Lundi',
		Tue: 'Mardi',
		Wed: 'Mercredi',
		Thu: 'Jeudi',
		Fri: 'Vendredi',
		Sat: 'Samedi',
		Sun: 'Dimanche',
		//months
		Jan: 'janvier',
		Feb: 'février',
		Mar: 'mars',
		Apr: 'avril',
		Jun: 'juin',
		Jul: 'juillet',
		Aug: 'aout',
		Sep: 'septembre',
		Oct: 'octobre',
		Nov: 'novembre',
		Dec: 'décembre'

	},
	'step0': {
		title: "Твърде скъпо ли струва Вашата застраховка?",
		new_loan: 'Нов заем',
		renegotiated_loan: 'Заем с променени условия',
		existing_loan: 'Съществуващ заем',
		loan_type: "Какъв е вашият проект ?",
		first_benefit: "Идентично покритие с това на банката",
		second_benefit: "Избор от селектирани застрахователи",
		third_benefit: "Вземете точната цена за вашата застраховка",
	},
	'step1': {
		title: 'Votre assurance de prêt vous coûte trop cher ? <br /><span class="step1subtitle"><strong>2 minutes</strong> suffisent pour connaître gratuitement le <b>juste prix</b> de votre assurance, et c’est ici !</span>',
		subtitle: 'Вашият кредит',
		loanContractDate: 'Дата на вашия кредит',
		loanContractDateHint: 'Date de l\'offre de prêt.',
		button: 'Напред',
		loan_type: 'Тип на заема',
		firstName: 'Votre prénom',
		tooltip: 'Si vous avez souscrit plusieurs prêts  merci de saisir ici les informations concernant votre prêt principal. Vous pourrez compléter votre dossier avec les autres prêts ultérieurement .',
		rate: 'Лихвен процент',
		number_of_loans: "Брой кредити",
		amount: 'Сума',
		birthDate: 'Votre date de naissance',
		chooseBank: 'Вашата банка',
		durationOfLoan: 'Продължителност',
		durationYears: 'в години',
		durationMonths: 'в месеци',
		projectType: 'Тип проект',
		loanNumber: 'Avez-vous plusieurs prêts ?',
		hasCoBorrower: 'Имам съкредитор',
		almostThere: 'Nous y sommes presque...',
		your_birth_date: 'Вашата дата на раждане',
		birth_date: 'Дата на раждане',
		hamon: "Changement Hamon",
		borquin: "Changement Bourquin",
		new_loan: "Нов заем",
		firstAssessment: 'Notre 1re estimation sans vos données d\'assurance…',
		sci: "<span>Компания за недвижими имоти <span data-tip=\"(Société Civile Immobilière)\"><i class=\"fa fa-info\" style=\"color: '#fff'\"/></span></span>",
		errors: {
			amountLimits: 'Сумата трябва да бъде между 5 000 и 3 000 000 лева',
			amountMax: 'Сумата не трябва да бъде повече от 3 000 000 лева',
			durationMin: 'Минималната продължителност е 1 месец',
			durationMax: 'Продължителността не може да бъде повече от 360 месеца',
			needsToBeFilled: 'Това поле е задължително',
			incorrectDate: 'Грешна дата',
			maxYear: 'Продължителността не може да бъде повече от 30 години',
			yearsOrMonths: 'Продължителността трябва да бъде в месеци или години'
		}

	},
	'step2': {
		// title: "Nous avons trouvé l'assurance qui correspond à votre besoin au juste prix Wedou assurance",
		title: 'Нашите страхотни оферти за Вас',
		chooseInsurer: 'Votre assureur',
		birthDatesLabel: 'Дата на раждане',
		birthDate: 'Вашата дата на раждане',
		coBorrowerBirthDate: 'Дата на раждане на съкредитор',
		insurancePremium: 'Cotisation mensuelle d\'assurance',
		globalInsurance: 'Coût global d\'assurance',
		bankGuarantees: "Покритие на Вашата банка <i className={`fa fa-info`} style={{color: '#5680ed'}} data-tip=\"Garanties de l'emprunteur\" data-multiline=\'true\'/> ",
		coBirthDate: 'Рождена дата на съкредитор', //This makes the "-" non breaking
		insuranceRate: 'Taux d’assurance',
		coInsuranceRate: 'Taux d’assurance',
		// coInsuranceRate: "Le taux d'assurance de votre co-emprunteur",
		borrowerCoverage: 'Покритие',
		coBorrowerCoverage: 'Покритие на съкредитор',
		coverageError: 'La somme des quotités des emprunteurs doit être comprise entre 100% et 200%.',
		taea: 'Votre TAEA ',
		coTaea: 'Le TAEA du co-emprunteur',
		inputOfChoice: 'Merci de choisir :',
		choiceGlobalInsurance: 'Coût global d\'assurance <span data-tip=\'C’est le coût total de l’assurance qui doit inclure les assurance « facultatives ». <br /> Le coût global d’assurance figure sur votre offre de prêt et sur la Fiche Standardisée d’Information (FSI).\'><i class=\'fa fa-info\' style=\'color:red\'/></span>',
		choiceInsurancePremium: 'Cotisation mensuelle d\'assurance',
		choiceInsuranceRate: 'Taux d’assurance',
		choiceTaea: 'TAEA <span style="max-width: 300px !important;"  data-tip="Vous trouverez votre TAEA sur Fiche Standardisée d’Information remise par votre banquier. <br /> Attention, il doit également inclure l’assurance facultative. <br /> En cas de doute, saisissez le coût global de l’assurance qui figure sur votre offre de prêt."><i class="fa fa-info" style="color:red"/></span>',
		continueButton: 'Mon économie',
		voila: 'Et voilà, c’est tout, plus qu\'un clic sur mon économie !',
		thatIsEverything: 'Et voilà, c’est tout …',
		modalTitle: 'Избор на метод за регистрация'
	},
	'step2c': {
		title: 'Merci %{name}. Quelques informations sur votre co-emprunteur&nbsp;?',
		firstName: 'Le prénom de votre co-emprunteur',
		birthDate: 'La date de sa naissance',
	},
	'step3': {
		title: 'Et voilà, Wedou a négocié auprès de "<span class=\'capitalize\'>%{flow}</span>"',
		subtitle: 'Votre gain*',
		register: 'S’enregistrer:',
		savings: 'Votre économie*',
		coSavings: 'L\'économie du co-emprunteur**',
		sameGuarantees: 'Avec des garanties et une protection au moins équivalentes !',
		betterGuarantees: 'И като бонус получавате :',
		legend: {
			go: 'Pour réaliser cette économie ?',
			bravoGo: 'Votre économie est de <span class=\'purple\'>%{percent}%</span> car vous bénéficiez sans doute déjà d’un tarif concurrentiel.<br />Vous souhaitez réaliser cette économie supplémentaire ? <br />',
			partTwo: ' Rien de plus simple, enregistrez-vous et en quelques clics nous allons préparer votre nouveau contrat. Vous pourrez alors le signer en ligne.',
		},
		basedOn: 'calculé sur la durée restante du prêt à partir d’un coût total d’assurance restant estimé à ',
		accept: 'С регистрацията се съгласявам, че моите данни ще бъдат съхранявани и използвани в съответствие с <a href=\'https://www.wedou.fr/politique-de-confidentialite\' target=\'_blank\'>политиката за обработка на лични данни от Wedou Assurances</a>, за да получа най-добрата оферта за застраховка на заеми и, където е приложимо, търговски оферти от партньорите на Wedou Assurances.',
		email: 'Вашият email',
		password: 'Mot de passe',
		otherMethods: 'Други методи за регистрация',
		passwordRepeat: 'Confirmation',
		continue: 'Искам да спестя',
		cost8: 'Цена за 8 години',
		costGlobal: 'Цена за целия период',
		premium: 'Месечна вноска',
		tax: 'Застрахователен процент',
		installments: 'Вноски',
		guarantees: 'Покрития',
		choose: 'Изберете тази оферта',
		axa: {
			title: 'Et voilà, %{name}, Wedou a négocié auprès de <br/> <img height=\'75\' alt=\'insurerLogo\' src=\'%{logo}\'/> <br/> l\'assurance de votre prêt, pour seulement :',
			info: 'Par mois'
		}
		// projectType: "Votre projet",
		// loanContractDate: "La date de signature de l'offre de prêt",
	},
	'compare': {
		title: 'Je compare les offres'
	},
	'step4': {
		title: 'Nous allons à présent préparer votre<br/> proposition personnalisée d\'assurance.',
		personalInfo: 'Вашите лични данни',
		streetAddress: 'Вашият адрес',
		coPersonalInfo: 'Données personnelles du co-emprunteur',
		coStreetAddress: 'Adresse du co-emprunteur',
		salutation: 'Обръщение',
		firstName: 'Име',
		lastName: 'Фамилия',
		country: 'Държава',
		maritalStatus: 'Statut marital',
		maidenName: 'Nom d\'usage',
		coborrowerEmail: 'Email на съкредитор',
		placeOfBirth: 'Място на раждане',
		cityOfBirth: 'Град на раждане',
		streetDetails: 'Улица',
		additionalDetails: 'Допълнение към адрес',
		town: 'Град',
		postalCode: 'Пощенски код',
		sameAddress: 'L\'adresse du co-emprunteur est identique',
		wrongAddress: 'Votre adresse semble incorrecte, merci de la modifier si c\'est le cas, ou de la confirmer.',
		// mobileNumber: "Votre numéro de téléphone",
		// mobileNumberHint: "+33",

		// birthDate: "Votre date de naissance",
		// additionalDetailsHint: "Le cas échéant : appartement, étage, etc.",


		// title: "Auprès de quelle banque avez-vous emprunté&nbsp;?",
		// bankId: "La banque",
		// insuranceProvidedByBank: "J’ai souscrit mon assurance emprunteur auprès de la banque",
	},
	'step5': {
		title: '%{name}, pouvez-vous m’en dire un peu plus ?',
		subtitle: 'Няколко въпроса относно Вашите дейности за да получите нашето предложение',
		coSubtitle: 'Quelques questions sur les activités du co-emprunteur',
		professionalActivityQuestions: 'Като част от вашата професионална дейност :',
		professionalLabel: 'Statut professionnel',
		riskyLabel: 'Activité à risque',
		professionalInfo: 'Vos activités',
		coProfessionalInfo: 'Activités du co-emprunteur',
		workAtHeight: 'Работите ли на високи места (повече от 20 метра над земята)?',
		profession: 'Какъв е Вашият професионален статус ?',
		riskyProfession: 'Практикувате ли рискова професия ?',
		riskySports: 'Pratiquez-vous un ou des sports à risques ?',
		exactProfession: 'Каква е точната Ви професия ?',
		doSports: 'Pratique d\'un sport amateur',
		lightManualWork: 'Занимавате ли се с лек ръчен труд?',
		manual_work: '<span data-tip="Avec un travail physique ou des mouvements répétitifs, ou l\'utilisation d\'outils motorisés ou de manutention, d\'engins de construction, de machines agricoles">Votre activité nécessite-t-elle un travail manuel ? </span>',
		travelLotsForWork: 'Пътувате ли повече от 20 000 км годишно с МПС?',
		travelsWorldNonProfessional: 'Непрофесионално пътуване',
		dangerousProductWork: 'Изпълнявате ли тежък ръчен труд или боравите с опасни продукти? <span data-tip="Avec utilisation d’outillage (outils pour perforer, déchiqueter, découper, trancher, creuser, broyer, déformer toute matière,…),\n' +
			'de produits dangereux et/ou avec utilisation de machines dangereuses (agricoles, industrielles, travaux publics)"><i class="fa fa-info" style="color: #3451b9" /></span>',
		travelsWorldHumanitary: 'Déplacements monde entier professionnels ou à titre humanitaire',
		smokerLabel: 'Пушите ли ? <span data-tip="Je déclare ne pas avoir consommé de tabac sous toutes ses  formes ni n’avoir vapoté (cigarette électronique)<br/> au cours des 18  derniers mois, même à titre exceptionnel, et que cette absence<br/> de consommation de tabac et de vapotage n’a pas été motivée par la demande expresse du corps médical." data-multiline=\'true\' className="hint smoker"><i className=\'fa fa-question-circle question-icon\'/></span>',
		mncapSmoker: 'Пушите ли ? <span data-tip="Non fumeur: ne pas avoir fumé de cigarettes (y compris cigarettes électroniques), cigares ou pipes au cours des 24 derniers mois, même occasionnellement. <br /> Je déclare que je n\'ai pas dû arrêter de fumer suite à la demande expresse du corps médical. Cette déclaration fait partie intégrante de ma souscription. <br /> Toute réticence ou fausse déclaration entraîne la nullité de la souscription, conformément à l\'article L 221-14 du Code de la Mutualité." data-multiline=\'true\' className="hint smoker"><i class="fa fa-info" style="color: #3451b9"/></span>',
		afiescaSmoker: 'Fumez-vous (y compris la cigarette électronique), consommez-vous quotidiennement des boissons alcoolisées, ou faites-vous usage de drogues ou stupéfiants ?',
		isPolitical: 'Политически свързана личност (<span data-tip=\'au sens de l’article R. 561-18 du Code Monétaire et Financier\' data-multiline=\'true\' class=\'tip\'>PPE</span>)',
		mncapIsPolitical: 'Политически свързана личност ? <span data-tip=\'Êtes-vous membre de la famille d’une Personne Politiquement Exposée et/ou entretenez-vous des liens étroits avec une Personne Politiquement Exposée Cf article R.561-18 du Code monétaire et financier ?\' data-multiline=\'true\' class=\'tip\'><i class="fa fa-info" style="color: #3451b9"/></span>',
		afiesca_political: "<span data-tip=\"de l'Union Européenne, du Royaume-Uni, de la Suisse, des Etats-Unis, du Japon, du Canada, de la Norvège, de l'Islande, de l'Australie et de la Nouvelle Zélande?\">Êtes-vous amené(e) à voyager à titre professionnel ou dans un but humanitaire en dehors des pays surlignés<sup>*</sup> :</span>",
		foreign_country: "Résidez-vous de façon permanente ou temporaire à l'étranger ?",
		smokerLabelCo: 'Fumeur <span data-tip="Je déclare ne pas avoir consommé de tabac sous toutes ses  formes ni n’avoir vapoté (cigarette électronique)<br/> au cours des 18  derniers mois, même à titre exceptionnel, et que cette absence<br/> de consommation de tabac et de vapotage n’a pas été motivée par la demande expresse du corps médical." data-multiline=\'true\' className="hint smoker"><i className=\'fa fa-question-circle question-icon\'/></span>',
		isPoliticalCo: 'Personne politiquement exposée (<span data-tip=\'au sens de l’article R. 561-18 du Code Monétaire et Financier\' data-multiline=\'true\' class=\'tip\'>PPE</span>)',
		sports_cover: `Искате ли да осигурите и практикуване на рискови спортове? <span data-tip="
					les sports pratiqués à titre professionnel ou rémunéré ; <br /> 
					les paris, défis, raids et tentatives de record ; <br /> 
					boxe et autres sports de combat (sauf pratique amateur et hors compétition)  <br /> 
					les compétitions et entraînements préparatoires de sports équestres ou de sport avec usage d’engins à moteur ;<br />  
					la spéléologie, l’escalade et la varappe (sauf si pratiquée en salle) ;<br /> 
					motonautisme, planche à voile à plus de 1 mille des côtes, yachting, plongée sous-marine (sauf plongée jusqu’à 30 mètres et pratiquée moins de 20 fois par an et toujours accompagnée, hors exploration de grotte ou épave) ;<br /> 
					l’alpinisme et tous les sports pratiqués en montagne au-delà de 3 000 mètres d’altitude. Cette exclusion ne s’applique toutefois pas à la pratique de ski alpin, de fond ou de snowboard sur pistes réglementées, ouvertes et accessibles en remontées mécaniques ; <br /> 
					le kitesurf, windboard, funboard, snowboard, saut à l’élastique, parachutisme, parapente, wingsuit, bobsleigh, toboggan, skeleton, saut à ski ou au tremplin, vol d’essai et vol sur engin non muni de certificat de navigabilité, acrobatie aérienne, ULM, deltaplane. <br /> 
" data-multiline="true" class="tip"  style="color: #3451b9"><i class="fa fa-info" /></span>`
	},
	'step6': {
		title: 'Et enfin, votre questionnaire de santé.', // TODO:  <span data-tip='y compris en ambulatoire, SAUF appendicite dasdas asjdasdjasnd
		text: 'Questionnaire de santé',
		isPolitical: 'Etes vous une Personne Politiquement Exposée (<span data-tip=\'au sens de l’article R. 561-18 du Code Monétaire et Financier\' data-multiline=\'true\' class=\'tip\'>PPE</span>).',
		linkStatement: 'La convention AERAS pour l’accès au crédit prévoit <a target=\'_blank\' href=\'http://www.aeras-infos.fr/files/live/sites/aeras/files/contributed/1.%20Convention_AERAS/Les_textes-de-reference/DocumentInformationjuin2019.pdf\'>un droit à l’oubli</a> pour certaines pathologies définies par une grille de référence que vous pourrez consulter <a target=\'_blank\' href=\'http://www.aeras-infos.fr/files/live/sites/aeras/files/contributed/1.%20Convention_AERAS/Les_textes-de-reference/ConventionAERAS2019.pdf\'>ici.</a>',
		Q1: 'Lors de la souscription de votre assurance de prêt actuelle, la proposition communiquée par l’assureur a-t-elle donné lieu à une majoration de votre prime, à une exclusion totale ou partielle de votre couverture, à un ajournement ou un refus de garanties ?',
		Q2: 'Suivez-vous actuellement ou avez-vous suivi au cours des 5 dernières années un traitement médical sous la forme d’une prise de médicaments ou de séances régulières de soins médicaux ou paramédicaux de plus de 21 jours consécutifs, hormis pour contraception ou affections saisonnières ?',
		Q3: 'Êtes-vous actuellement et /ou avez-vous été au cours des 5 dernières années en arrêt de travail de plus de 21 jours consécutifs sur prescription médicale pour raison de santé (hors congés légaux de maternité) ?',
		Q4: 'Êtes-vous actuellement et /ou avez-vous été, au cours des 10 dernières années, atteint(e) d’une maladie ou de séquelles nécessitant une surveillance médicale ?',
		Q5: 'Au cours des 6 prochains mois, devez-vous réaliser des examens à <span data-tip=\'bilans sanguins, tests hépatiques, analyses d’urines, radiographies, imageries, examens cardiologiques\' multiline=\'true\' class=\'tip\'> visée diagnostique</span>  ou devez-vous subir une intervention chirurgicale ?',
		Q6: '',
		staticText: 'Je certifie avoir répondu personnellement et avec attention aux questions ci-dessus. Je suis informé(e) que toute réticence, fausse déclaration intentionnelle ou non est sanctionnée par les articles L221-14 et L221-15 du Code de la Mutualité qui prévoient la nullité du contrat ou une augmentation de prime d’assurance.',
		afiesca: {
			Q1: 'Fumez-vous (y compris la cigarette électronique),consommez-vous quotidiennement des boissons alcoolisées, ou faites-vous usage de drogues ou stupéfiants ?',
			Q2: ''
		}
	},
	'coStep6': {
		title: 'Votre questionnaire de santé', // TODO:  <span data-tip='y compris en ambulatoire, SAUF appendicite dasdas asjdasdjasnd
		text: '%{name}, je vous invite à répondre aux questions ci-dessous avec attention',
		isPolitical: 'Etes vous une Personne Politiquement Exposée (<span data-tip=\'au sens de l’article R. 561-18 du Code Monétaire et Financier\' data-multiline=\'true\' class=\'tip\'>PPE</span>).',
		linkStatement: 'La convention AERAS pour l’accès au crédit prévoit <a target=\'_blank\' href=\'reference.pdf\'>un droit à l’oubli</a> pour certaines pathologies définies par une grille de référence que vous pourrez consulter <a target=\'_blank\' href=\'http://www.aeras-infos.fr\'>ici.</a>',
		Q1: 'Lors de la souscription de votre assurance de prêt actuelle, la proposition communiquée par l’assureur a-t-elle donné lieu à une majoration de votre prime, à une exclusion totale ou partielle de votre couverture, à un ajournement ou un refus de garanties ?',
		Q2: 'Suivez-vous actuellement ou avez-vous suivi au cours des 5 dernières années un traitement médical sous la forme d’une prise de médicaments ou de séances régulières de soins médicaux ou paramédicaux de plus de 21 jours consécutifs, hormis pour contraception ou affections saisonnières ?',
		Q3: 'Êtes-vous actuellement et /ou avez-vous été au cours des 5 dernières années en arrêt de travail de plus de 21 jours consécutifs sur prescription médicale pour raison de santé (hors congés légaux de maternité) ?',
		Q4: 'Êtes-vous actuellement et /ou avez-vous été, au cours des 10 dernières années, atteint(e) d’une maladie ou de séquelles nécessitant une surveillance médicale ?',
		Q5: 'Au cours des 6 prochains mois, devez-vous réaliser des examens à <span data-tip=\'bilans sanguins, tests hépatiques, analyses d’urines, radiographies, imageries, examens cardiologiques\' multiline=\'true\' class=\'tip\'> visée diagnostique</span>  ou devez-vous subir une intervention chirurgicale ?',
		Q6: '',
		staticText: 'Je certifie avoir répondu personnellement et avec attention aux questions ci-dessus. Je suis informé(e) que toute réticence, fausse déclaration intentionnelle ou non est sanctionnée par les articles L221-14 et L221-15 du Code de la Mutualité qui prévoient la nullité du contrat ou une augmentation de prime d’assurance.',
	},
	'credit': {
		loanType: 'Type de prêt',
		amount: 'Somme empruntée',
		rate: 'Taux d’intérêt hors assurance',
		isRateFixed: 'Le taux d’intérêt est fixe',
		durationYears: 'Durée en années', // "Durée du prêt en années",
		durationMonths: 'ou en mois',
		deffered: 'Плащането по кредита отложено ли е ?',
	},

	'step7': {
		title: '%{name}, voici vos réponses.<br />Merci de les relire attentivement et de les corriger si nécessaire.',
		insruanceFixed: 'Est-ce que votre cotisation mensuelle d’assurance est fixe ?',
		commonInsurance: 'Vous et %{name} payez une cotisation commune ?',
		bravo: 'Je ne suis malheureusement pour l’instant pas en mesure de vous proposer une offre si vous avez une cotisation d’assurance variable sur la durée du prêt.'
	},
	'step8': {
		title: '%{name}, voici notre offre :',
		title2: '%name,   MNCAP a besoin d\'information complémentaires.',
		savings: 'Votre cotisation mensuelle',
		bravoGo: 'Votre économie est de <span class=\'purple\'>%{percent}%</span> après prise en compte de vos données personnelles.',
		totalSavings: 'soit une économie de ',
		original: '* calculée à partir d’un coût restant total d’assurance estimé à '
	},
	'step9': {
		title: '%{name}, voici les documents d\'adhésion de votre assurance Wedou que vous pouvez signer en ligne',

		p1: {
			partOne: '<b>Wedou Assurances</b> vous propose avec <strong class=\'capital\'>%{insurer}</strong> les garanties dont vous avez besoln pour seulement&nbsp;',
			partTwo: '&nbsp;par mois'
		},
		p2: 'Nous allons vous soumettre les document suivants :',
		li1: 'Votre <b>Fiche Standardisée d’Information</b> ;',
		li2: 'Votre <b>Questionnaire de Santé</b> ;',
		li3: 'Votre <b>Demande d\'Adhésion</b> ;',
		li4: 'Un <b>Mandat</b> pour que Wedou Assurances accomplisse gratuitement toutes les formalités.',
		li5: 'Votre <b>Mandat SEPA</b>',
		p3: 'Vous pourrez visualiser ces documents suivant a l\'écran suivant et ils vous sont également adressés par mail pour en faciliter la lecture',
		phone: 'Телефонен номер',
		finePrint: 'Afin d’en faciliter la lecture, nous vous avons transmis par email ces documents ainsi que la Notice d\'Information de votre contrat .',
		insurer: 'Votre assureur',
		subheading: 'Merci de renseigner votre Taux Annuel Effectif d’Assurance (TAEA)',
		taeaBorrower: 'Votre TAEA',
		taeaCoBorrower: 'Le TAEA de %{name}',
		optionalPremium: 'A défaut, quelle est la cotisation mensuelle de votre assurance de prêt ?',
		premium: 'La cotisation d’assurance de %{name}',

		taeaPopupText: 'Le TAEA c’est le taux qui détermine le coût total de votre assurance. Il figure sur votre Fiche Standardisée d’Information&nbsp;:',

		rate: 'ou votre taux d’assurance',
		coPremium: 'Coût mensuel de l\'assurance total', // no place for the name...
		coRate: 'ou taux d’assurance de %{name}', // no place for the name...
		ourPremium: 'Votre cotisation',
		ourRate: 'ou votre taux d’assurance',
		premiumHint: 'EUR par mois',
		percentHint: '% par an',
		radioButtonQuestion: 'Quelle information concernant votre assurance connaissez-vous&nbsp;?',
		capital: 'Quelles sont les quotités assurées par %{name} et %{coborrowerName} ?',
	},
	'step8OneLoanBorrower': {
		title: 'Combien vous coûte actuellement votre assurance&nbsp;?',
		monthlyInstallment: 'Votre mensualité globale du prêt + assurance',
		monthlyInstallmentHint: 'Assurance incluse, en EUR',
		premium: 'ou votre cotisation',
		premium2: 'votre cotisation ',
		taeaBorrower: 'Votre TAEA',
	},
	'step10': {
		title: '%{name}, voici les documents contractuels que vous allez pouvoir signer en ligne.',
		loanSection: 'A propos de votre prêt',
	},
	'step11': {
		title: 'Bienvenue dans la communauté Wedou !',
		continueButton: 'Vers mon espace'
	},
	'go': {
		savings: 'Votre économie*',
		coSavings: 'L\'économie du co-emprunteur**',
		info: 'Avec des garanties et une protection au moins équivalentes !',
		legend: 'Pour réaliser cette économie ?<br />Rien de plus simple, enregistrez-vous et en quelques clics nous allons préparer votre nouveau contrat. <br /> Vous pourrez alors le signer en ligne.',
		basedOn: '* calculé sur la durée restante du prêt à partir d’un coût total d’assurance restant estimé à ',
		coBasedOn: '** calculé sur la durée restante du prêt à partir d’un coût total d’assurance restant estimé à ',
		empty: '',
		yourGain: 'Votre gain*',
		accept: 'En m’enregistrant, j’accepte que mes données soient stockées et utilisées en accord avec la <a href=\'http://wedou.fr/politique-de-confidentialite\' target=\'_blank\' rel=\'noopener noreferrer\'>politique de traitement des données personnelles de Wedou Assurances</a>, afin de recevoir la meilleure offre d’assurance de prêt et, le cas échéant, des offres commerciales des partenaires de Wedou Assurances.'
	},
	'bravo_go': {
		announcement: 'Je ne peux pas diviser le coût de votre assurance par 2, mais presque !',
		savingsWithCoborrower: 'Les économies de %{name} et %{coborrowerName} :<br /> <br /> %{percentage}%',
		savings: 'Les économies de %{name} :<br /> <br /> %{percentage}%',
		message: 'sur le coût de votre assurance, soit'
	},
	'bravo_signature': {
		'title': 'Questionnaire Medical'
	},
	'bravo_detailed': {
		'title': 'Questionnaire Medical Detaille'
	},
	'bravo_simplified': {
		'title': 'Questionnaire Medical Simplifié'
	},
	'bravo_success': {
		'title': 'Successio'
	},
	bravo_migrated: {
		title: '%{name}, encore un petit effort.'
	},
	'bravo': {
		title: 'Nous allons rechercher ensemble une solution adaptée à votre situation...',
		leaveEmail: 'Si vous le souhaitez, <br />vous pouvez renseigner votre adresse e-mail afin que je vous mette en relation avec un partenaire qui étudiera votre situation&nbsp;:',
		merci: 'Merci pour votre intérêt&nbsp;!',
		thankYouBravo: 'Merci nous allons faire nos meilleurs efforts pour trouver une réponse adaptée à votre besoin et reviendront vers vous dès que possible.',
		mobileNumber: 'Le numéro de mobile',
		checkbox: 'J’accepte que mes données soient stockées et utilisées en accord avec la <a href=\'https://www.wedou.fr/politique-de-confidentialite\' target=\'_blank\'>politique de traitement des données personnelles de Wedou Assurances </a>, afin de recevoir la meilleure offre d’assurance de prêt, et le cas échéant, des offres commerciales des partenaires de Wedou Assurances.',
		thankYou: 'Merci %{name}, nous allons faire nos meilleurs efforts pour trouver une réponse adaptée à votre besoin et reviendront vers vous dès que possible.',
		'1': '<br />Nous vous remercions pour votre intérêt. Malheureusement, nous ne pouvons pas vous proposer un tarif adapté, car nous ne prenons pas encore en charge les souscripteurs  de plus de 50 ans. <br /><br />' +
			'Cependant, votre situation a retenu toute notre attention et nous aidera pour négocier un assouplissement de nos limitations auprès des réassureurs avec lesquels nous travaillons. <br /><br />' +
			'Vous pouvez, si vous le souhaitez, nous communiquer une adresse e-mail ou votre numéro de téléphone pour que nous revenions vers vous dès que notre offre pourra répondre à votre besoin. <br /><br />' +
			'Nous pouvons également vous mettre en relation avec un partenaire à même d’améliorer le coût actuel de votre assurance.',
		'2': '<br />Nous vous remercions pour votre intérêt. Malheureusement, nous ne pouvons pas vous proposer un tarif adapté, car nous ne prenons pas encore en charge les souscripteurs  de plus de 50 ans. <br /><br />' +
			'Cependant, votre situation a retenu toute notre attention et nous aidera pour négocier un assouplissement de nos limitations auprès des réassureurs avec lesquels nous travaillons. <br /><br />' +
			'Vous pouvez, si vous le souhaitez, nous communiquer une adresse e-mail ou votre numéro de téléphone pour que nous revenions vers vous dès que notre offre pourra répondre à votre besoin. <br /><br />' +
			'Nous pouvons également vous mettre en relation avec un partenaire à même d’améliorer le coût actuel de votre assurance.',
		'3': '<br /><br /> Nous vous remercions pour votre intérêt. Malheureusement, nous ne prenons pas encore en charge les prêts dont l’encours est supérieur à 600 000 € . <br /><br />' +
			'Cependant, votre situation a retenu toute notre attention et nous aidera pour négocier un assouplissement de nos limitations auprès des réassureurs avec lesquels nous travaillons.<br /><br />' +
			'Vous pouvez, si vous le souhaitez, nous communiquer une adresse e-mail ou votre numéro de téléphone pour que nous revenions vers vous dès que notre offre pourra répondre à votre besoin. <br /><br />' +
			'Nous pouvons également vous mettre en relation avec un partenaire à même d’améliorer le coût actuel de votre assurance.',
		'4': '<br /><br /> Vous avez presque remboursé votre prêt . <br /> <br /> un changement d’assurance à ce stade ne serait pas intéressant pour vous.<br /> <br /> Je vous conseille de garder votre assurance actuelle.',
		'5': '<br /> 	Si vous n’avez pas fait d’erreur sur le coût de votre assurance, vous avez bien négocié votre tarif et nous vous conseillons de ne pas changer votre assurance. <br /><br />' +
			'Vous pouvez vérifier votre saisie et la corriger si nécessaire en ',
		'5extra': 'N’hésitez pas à demander de l’aide ou à consulter notre page « <a target=\'_blank\' href=\'https://www.wedou.fr/assurance-credit-immobilier\'>bon à savoir</a> ».',
		'7': '<br /><br /> Nous vous remercions pour votre intérêt. Malheureusement, nous ne pouvons pas vous proposer un tarif adapté en raison de nos critères actuels. <br /><br />' +
			'Nous pouvons  vous mettre en relation avec un partenaire à même d’améliorer le coût actuel de votre assurance. ',


	},
	'step12': {
		title: 'Merci %{name}&nbsp;!<br/>A bientôt&nbsp;!',
		text: 'Je vous recontacte dès l’entrée en vigueur de la loi vous permettant de substituer votre assurance emprunteur.',
		secondText: 'A bientôt !',
	},
	'call-us': {
		title: 'Parler à Wedou',
		text: 'Besoin d\'un conseil ? Appelez-nous en cliquant ici. <sub>*</sub>'
	},
	'offer': {
		monthly: '%{name}, votre cotisation mensuelle*',
		monthlyOne: 'Votre cotisation mensuelle*',
		coMonthly: '%{nameCo}, votre cotisation mensuelle**',
		savings: 'soit une économie de',
		coSavings: 'soit une économie de',
		bravoGoSavings: 'Soit une réduction de %{percentage}% et une économie de',
		basedOn: '* calculée à partir d’un coût restant total d’assurance estimé à ',
		coBasedOn: '** calculée à partir d’un coût restant total d’assurance estimé à ',
		coEmail: 'En poursuivant, %{nameCo} recevra un e-mail afin de parcourir les documents spécifiques au co-emprunteur'
	},
	'reassurance': {
		title: 'Très bien %{name}, récapitulons :',
		coTitle: 'Très bien %{name}, %{nameCo}, récapitulons :',
		subheading: '<b>Wedou Assurances</b> vous propose une assurance qui reprend les garanties attendues par votre banque, %{bankName}, pour %{premium} € par mois.',
		// para1: "Les prochaines étapes de la substitution sont détaillées dans votre espace. Dès que possible envoyez-nous les documents demandés et pendant ce temps Wedou s’occupe de tout&nbsp;!",
		para1: '<b>Wedou Assurances</b> a négocié cette offre, pour vous, avec la société d’assurance  <img src=%{mutlog} style="height: 29px"  /><br />   et son réassureur   <img src=%{scor} style="height: 41px" />',
		para2: 'Au vu de ce que vous nous avez communiqué, votre nouvelle assurance au prix Wedou pourra être mise en place à compter du %{date}. ',
		para3: 'Pour bénéficier de cette offre, nous vous remercions de signer numériquement dans les écrans qui vont suivre les documents qui nous permettront d’organiser le changement d\'assurance auprès de votre banque.',
		para4: 'Vous devrez également télécharger, dans votre espace personnel, une copie de votre contrat et d’une pièce d’identité. ',
		para5: 'Dès réception, nous transmettrons votre dossier à votre banque qui disposera alors d’un délai de 10 jours pour valider que notre offre respecte bien les conditions de garanties qu’elle exige. ',
		para6: 'A aucun moment vous ne resterez sans assurance, la loi prévoit que la résiliation de votre assureur actuel ne peut intervenir que si les nouvelles garanties sont validées par votre banque. ',
		title2: 'Pour en faciliter la lecture, nous vous transmettons par email :',
		listItem1: 'La <b>Notice d’Information</b> du contrat collectif souscrit par Wedou Assurances auprès de Mutlog ;',
		listItem2: 'La <b>Fiche Standardisée d\'Information</b> qui détaille vos garanties et  permettra à  votre banque de valider le transfert ;',
		listItem3: 'Votre <b>Questionnaire Simplifié de Santé</b> ;',
		listItem4: 'Votre <b>Demande d\'Adhésion</b> pour rejoindre le contrat collectif d\'assurance créé par <b>Wedou Assurances</b> ;',
		listItem5: 'et enfin un <b>Mandat</b> pour permettre à Wedou Assurances d\'effectuer gratuitement toutes les formalités de la mise en place de votre nouveau contrat.',
		para7: 'A compter de votre signature numérique, vous disposerez de 30 jours pour exercer votre droit de rétractation d’un simple clic sur votre espace client. ',
		subscribe: 'Je souscris'
	},
	'step21': {
		title: '%{name}, bienvenue dans la communauté Wedou',
		subheading: 'Vous retrouverez dans quelques instants vos documents signés dans votre boîte email.',
		// para1: "Les prochaines étapes de la substitution sont détaillées dans votre espace. Dès que possible envoyez-nous les documents demandés et pendant ce temps Wedou s’occupe de tout&nbsp;!",
		para1: 'Afin de finaliser la substitution nous devons faire parvenir à votre banque certains documents. Pour nous les transmettre, nous vous invitons à vous connecter à votre espace personnel.',
		mySpace: 'Vers mon espace'
	},
	'personalSpace': {
		other: 'Autres documents',
		subheading: 'Pour compléter votre dossier de substitution, transmettez-nous au plus vite les documents suivants',
		bankInfo: 'Dès que nous aurons tous ces documents, nous pourrons prendre en charge les démarches de substitution auprès de votre banque.',
		anniversaryDate: 'Environ 15 jours avant le %{anniversaryDate}, date prévisionnelle d’entrée en vigueur de votre contrat Wedou, nous vous confirmerons la mise en place de votre nouveau prélèvement automatique.',
		downloadButton: 'Téléchargez vos documents signés',
		uploadButton: 'Je choisis un document',
		reuploadButton: 'J’ajoute un document',
		// reuploadButton: "Je change le document",
		ibanText: 'Il s’agit de l’IBAN du compte sur lequel sera prélevé votre cotisation Wedou à compter de la date d’anniversaire. Idéalement il s’agit du compte lié à votre crédit.',
		ibanLabel: 'Votre IBAN',
		ibanLabel2: 'IBAN de ce compte',
		ibanHint: 'FRxx xxxx xxxx xxxx xxxx xxxx xxx',
		ibanHint2: 'IBAN de ce compte',
		// ibanButton: "Je rensigne mon IBAN",
		ibanButton: 'Valider mon IBAN',
		sepaButton: 'Je signe mon mandat SEPA',
		sepaButton2: 'Confirmer l’IBAN',
		// stepTitle1: "Je transmets mon document d’identité&nbsp;<span data-tip='Passeport ou CNI' class='tip'><i class='fa fa-question-circle specialCircle'></i></span>",
		stepDetails1: 'Cela nous permet de confirmer votre identité auprès de votre banque. Vous pouvez nous transmettre une copie de CNI, de passeport, de permis de conduire ou de titre de séjour.',
		stepTitle2: 'Je transmets mon contrat de prêt&nbsp;<span data-tip=\'incluant l’échéancier\' class=\'tip\'><i class=\'fa fa-question-circle specialCircle\'></i></span>',
		stepDetails2: 'Afin d’éviter tout rejet de la banque, nous contrôlons la conformité des informations que vous avez renseignées sur notre site, à partir des documents contractuels de votre prêt que vous attachez ci-dessous.',
		stepLoanContract2: 'Votre contrat de prêt&nbsp;:',
		stepLoanRepaymentPlan2: 'Votre échéancier de prêt&nbsp;:',
		stepFSI3: 'La Fiche Standardisée d’Information de votre prêt&nbsp;:',
		stepTitle3: 'Je transmets ma Fiche d’Information Standardisée',
		// stepDetails3: "Passé un délai de 30 jours lors duquel vous avez la possibilité de changer d’avis, nous contactons votre banque avec notre dossier de demande de substitution. La banque a 10 jours pour étudier la conformité de notre demande et valider l’équivalence de vos nouvelles garanties. L’assureur est informé en parallèle de votre souhait de résiliation.",
		stepTitle4: 'Je complète mon IBAN et signe mon mandat SEPA',
		// stepDetails4: "Afin de procéder à la substitution de votre assurance actuelle, nous allons émettre un mandat de prélèvement SEPA à partir de votre IBAN, afin d’assurer le paiement de votre cotisation mensuelle (deux fois inférieure à l’actuelle).",
		// step5Title: "Nous vous confirmons l’entrée en vigueur prochaine de votre contrat Wedou",
		// step5Details: "Environ 15 jours avant l’entrée en vigueur de votre nouveau contrat, nous vous envoyons un rappel des modalités de votre contrat, de la date d’échéance à venir, du montant du compte sur lequel votre première cotisation sera prélevée.",
		// step6Title: "Votre contrat est actif au plus tard au %{date}",
		// step6Details: "A la date d’anniversaire de votre contrat actuel, votre nouvelle assurance Wedou entre en vigueur. Dès lors vous bénéficiez de l’assurance Mi2 et d’un prélèvement mensuel deux fois inférieur au précédent&nbsp;!",
		wedouContract: 'Wedou Emprunteur N°%{contractId}',
		oneDocumentMissing: 'Un document est manquant pour finaliser votre demande de substitution',
		signedOn: 'Signé le %{signatureDate}',
		contracts: 'Mes contrats',
		finishApplication: 'Plusieurs documents sont manquants pour finaliser votre demande de substitution &mdash;',
		finishedApplication: 'Votre dossier est complet, nos équipes s’occupent de la substitution de votre assurance !',
		clickHere: 'cliquez ici',
		documentListHeader: 'Vous retrouverez ci-dessous l’ensemble de vos documents de souscription :',
		contractDocuments: 'Les documents de Wedou',
		uploadedDocuments: 'Les documents que vous avez téléchargés',
		download: 'Télécharger',
		identityCard: 'Pièce d’identité&nbsp;<span data-tip=\'Passeport ou CNI\' class=\'tip\'><i class=\'fa fa-question-circle specialCircle\'></i></span>',
		missing: 'Manquant',
		uploaded: 'Transféré',
		rejected: 'Refusé',
		contratDePret: 'Contrat de prêt&nbsp;<span data-tip=\'incluant l’échéancier\' class=\'tip\'><i class=\'fa fa-question-circle specialCircle\'></i></span>',
		fsiDoc: 'Fiche Standardisée d’Information',
		fsiOriginal: 'Fiche Standardisée d’Information (La FSI remise par votre banque lors de la signature du prêt)',
		sepaSigning: 'Je complète mon IBAN et signe mon mandat SEPA',
		ibanCheckbox: 'Il s’agit de mon compte dans l’établissement prêteur&nbsp;<span data-tip=\'Il s’agit de l’IBAN du compte dans la banque <br/>où vous avez contracté votre prêt. Il <br/>nous permet de vous identifier lors de la <br/>demande de substitution. S’il <br/>est différent de celui renseigné, vous <br/>pouvez le renseigner ici.\' data-multiline=\'true\' class=\'tip\'><i class=\'fa fa-question-circle specialCircle\'></i></span>',
		renunciationConsideration: 'Votre demande a bien été prise en compte'
	},
	contractPhase: {
		application: 'En cours de validation',
		applicationSubstituted: 'Actif',
		contract: 'Actif',
		contractManagement: 'Actif',
		documentsGathering: 'En cours de validation',
		ForFirstInstallmentDebit: 'Actif',
		forSubstitutionPreparation: 'En cours de validation',
		invalidated: 'Invalidé',
		offer: 'En cours de validation',
		simulation: 'En cours de validation',
		'under substitution': 'En cours de validation',
		underValidation: 'En cours de validation',
		waitingForSubstitution: 'En cours de substitution'
	},
	contractPhaseColor: {
		application: 'rgb(255, 115, 0)',
		applicationSubstituted: 'rgb(11, 102, 35)',
		contract: 'rgb(11, 102, 35)',
		contractManagement: 'rgb(11, 102, 35)',
		documentsGathering: 'rgb(255, 115, 0)',
		ForFirstInstallmentDebit: 'rgb(11, 102, 35)',
		forSubstitutionPreparation: 'rgb(255, 115, 0)',
		invalidated: 'rgb(161, 40, 48)',
		offer: 'rgb(255, 115, 0)',
		simulation: 'rgb(255, 115, 0)',
		'under substitution': 'rgb(255, 115, 0)',
		underValidation: 'rgb(255, 115, 0)',
		waitingForSubstitution: 'rgb(255, 115, 0)',
	},
	documentType: {
		account_closure: 'Autre',
		CNI: 'CNI',
		contract_conditions: 'Demande d’adhésion',
		driving_license: 'Autre',
		fsi: 'Fiche standardisée d’information',
		fsiOriginal: 'Fiche standardisée d’information',
		general_conditions: 'Notice d’information',
		health_questionnaire: 'Questionnaire de santé',
		id_card: 'CNI',
		idDocument: 'CNI',
		loanContract: 'Contrat de prêt',
		loanRepaymentPlan: 'Passeport',
		other: 'Autre',
		Passeport: 'Passeport',
		passport: 'Passeport',
		power_of_attorney: 'Autre',
		profile: 'Autre',
		proof_document: 'Autre',
		proof_of_address: 'Autre',
		sepa_mandate: 'Mandat SEPA',
		signature: 'Autre',
		subst_mandate: 'Mandat de substitution',
		terminate_agent: 'Autre',
		vat_registration: 'Autre',
		verification: 'Autre',
		voucher: 'Autre',
		LetterInsurance: 'Lettre à l’assureur',
		MembershipCertificat: 'Certificat d’adhésion',
		LetterBank: 'Lettre à la banque'
	},
	'footer': {
		insuranceBorrower: 'L’assurance emprunteur',
		whoWeAre: 'Qui nous sommes',
		who: '',
		newsAndInformation: 'Actualités et Informations',
		claims: 'Réclamations',
		legalNotice: 'Правно известие',
		info: 'Информация',
		policy: 'Политика на конфиденциалност',
		connections: 'LIENS',
		about: 'За нас',
		contactUs: 'Contactez-nous',
		whoIs: 'Кои сме ние?',
		followUs: 'Последвайте ни',
		copyright: 'Wedou, Ensemble transformons l\'assurance. 2022. Tous droits réservés.'
	},
	'questionStep': {
		title: 'Votre opinion.'
	},
	'signing': {
		title: '%{name}, voici les documents contractuels que je vous invite à lire puis à signer.',
	},
	'passwordReset': {
		title: 'Pour réinitialiser votre mot de passe, veuillez entrer votre adresse e-mail.',
		reset: 'Merci&nbsp;! Je vous ai envoyée un e-mail avec des instructions',
		notReset: 'Merci&nbsp;! Je vous ai envoyée un e-mail avec des instructions',
	},
	'passwordSet': {
		title: 'Veuillez définir votre nouveau mot de passe.',
		newPasswordSet: 'Votre mot de passe est maintenant changé et vous pouvez vous connecter',
	},
	'error': {
		connection: 'Je suis désolée, une erreur de connexion s’est produite. S’il vous plaît réessayer plus tard.',
		other: 'Je suis désolée, une erreur s’est produite. S’il vous plaît réessayer plus tard.'
	},
	'metaTags': {
		step1: {
			title: 'Le simulateur de l’assurance emprunteur',
			description: 'Le simulateur de l’assurance emprunteur',
			url: 'prets'
		},
		step2: {
			title: 'Le coût de votre assurance emprunteur',
			description: 'Le coût de votre assurance emprunteur',
			url: 'guaranties'
		},
		step3: {
			title: 'Calcul de l’assurance emprunteur',
			description: 'Calcul de l’assurance emprunteur',
			url: 'resultat'
		},
		step4: {
			title: 'L’assurance de prêt pour vous',
			description: 'L’assurance de prêt pour vous',
			url: 'données_personnelles'
		},
		step5: {
			title: 'L’assurance de prêt et votre métier',
			description: 'L’assurance de prêt et votre métier',
			url: 'profession'
		}, //proffesion
		step6: {
			title: 'L’assurance de prêt et votre santé',
			description: 'L’assurance de prêt et votre santé'
		}, //sante
		step7: {
			title: 'Synthèse pour assurance emprunteur',
			description: 'Synthèse pour assurance emprunteur',
			url: 'resume'
		},	//resume
		step8: {
			title: 'Le juste prix de votre assurance de prêt',
			description: 'Le juste prix de votre assurance de prêt',
			url: 'offre'
		}, //offer
		step9: {
			title: 'Assurance emprunteur au téléphone',
			description: 'Assurance emprunteur au téléphone'
		}, //numero
		step10: {
			title: 'Signer son assurance de prêt',
			description: 'Signer son assurance de prêt',
			url: 'signing'
		}, //signing
		step11: {
			title: 'Bienvenue chez Wedou Emprunteur',
			description: 'Bienvenue chez Wedou Emprunteur',
			url: 'termine'
		}, //termine
		step12: {
			title: 'Wedou Emprunteur vous accueille',
			description: 'Wedou Emprunteur vous accueille'
		},
		personal: {
			home: {
				title: '',
			},
			steps: {
				title: ''
			},
			documents: {
				title: ''
			}
		},
		changePassword: {
			title: 'Le mot de passe de Wedou Emprunteur',
			description: 'Le mot de passe de Wedou Emprunteur'
		},
		login: {
			title: 'Connexion Wedou Emprunteur',
			description: 'Connexion Wedou Emprunteur'
		},
		forgottenPassword: {
			title: 'La clé de Wedou Emprunteur',
			description: 'La clé de Wedou Emprunteur'
		},
	},
	yes: 'Oui',
	no: 'Non',
	dontKnow: 'Je ne sais pas',
	iContinue: 'Suivant',
	iConfirm: 'Je confirme',
	iContinueGo: 'J’économise',
	validate: 'Valider',
	close: 'Fermer',
	accueil: 'Accueil',
	survey: 'Soumettre mes réponses',
	optionEnsuranceOne: 'La cotisation mensuelle ',
	optionEnsuranceTwo: 'Le TAEA',
	myOpinion: 'Je donne mon opinion',
	familyNameHint: 'et nom d’usage le cas échéant',
	or: 'или'

}
