import React from 'react';
import Shell from '../Shell';
import {connect} from 'react-redux';
import {Row, Col, Container} from 'react-grid-system';
import './style.css';
import Button from '@material-ui/core/Button';

class Bravo extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			bravo: {}
		}
	}

	getStep() {
		return 'bravo';
	}

	componentWillMount() {
		this.props.bravoDocuments({flow: this.props.loan.flow})
			.then(response => {
				this.setState({bravo: response.data})
			});
	}

	downloadFile(name) {
		return this.props.downloadAttachment({file: name});
	}

	render() {
		if (!this.state.bravo.bravo_membership) {
			return <div/>
		} else {
			return (
				<div style={{marginTop: '5rem'}}>
					<Container>
						<Row style={{textAlign:'center', justifyContent: 'center'}}>
							<Col md={7} className='bravoMessage'>
								<p>{this.props.borrower.first_name}, nous ne pouvons pas vous proposer une souscription digitale pour votre assurance
									emprunteur.</p>
								<p>Nous vous proposons de télécharger le formulaire papier sur le lien ci-dessous et de nous l’adresser par mail à <a
									href='mailto:confidentiel@wedou.fr'>confidentiel@wedou.fr</a>.</p>
								<p>Nous reviendrons vers vous très vite.</p>
							</Col>
						</Row>
						<Row style={{marginTop: '1rem', justifyContent: 'center'}}>
							<Col md={4}>
								<Button style={{padding: 0, minWidth: '250px', backgroundColor: this.props.config.theme.primary }} className='formButton emailButton'
								        onClick={() => this.downloadFile(this.state.bravo.bravo_membership)}><p>Demande d'adhésion <i className="fa fa-download"/></p></Button>
							</Col>
							<Col md={4}>
								<Button style={{padding: 0, minWidth: '250px', backgroundColor: this.props.config.theme.primary}} className='formButton emailButton'
								        onClick={() => this.downloadFile(this.state.bravo.bravo_questionnaire)}><p>Questionnaire de santé <i className="fa fa-download"/></p>
								</Button>
							</Col>
						</Row>
					</Container>
				</div>
			)
		}
	}

}


export default connect()(Shell(Bravo));
