import React from 'react';
import style from "../Fields/style.module.css";
import Switch from "react-switch";
import NumberField from '../NumberField'
import checkedIcon from './ptz.png';

class InterestField extends React.Component {

	constructor(props) {
		super(props);
		this.state        = {checked: false};
		this.handleChange = this.handleChange.bind(this);
	}

	handleChange(checked) {
		this.setState({checked});
	}

	render() {
		return (
			<NumberField className={style.formField}
						 {...this.props}
						 disabled={!!this.state.checked}
						 value={!!this.state.checked ? 0 : this.props.value}
						 InputProps={{
							 endAdornment:
								 <span data-tip="Cocher si prêt à taux Zero">
									 <Switch onChange={this.handleChange}
											 checkedIcon={<img src={checkedIcon} title='asdasd' alt="PTZ"
															   style={{paddingLeft: "2px", paddingTop: "2px"}} width={28}/>}
											 uncheckedIcon={<img src={checkedIcon} title='asdasd' alt="Regular"
																 style={{paddingLeft: "2px", paddingTop: "2px"}} width={28}/>}
											 checked={this.state.checked}/>
								 	<i className="fa fa-info-circle question-icon ptz"/>
								 </span>,
						 }}
			/>

		)
	}
}

export default InterestField;
