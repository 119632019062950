let banks = [
	{key: "allianzbanque", name: "Allianz banque", parentKey: "allianz", parentName: "Allianz"},
	{key: "axabanque", name: "Axa banque", parentKey: "aXA", parentName: "AXA"},
	{key: "banqueBCP", name: "Banque BCP", parentKey: "cNP", parentName: "CNP"},
	{key: "banqueCIC", name: "Banque CIC", parentKey: "aCMVVIE", parentName: "ACMN VIE"},
	{key: "banqueCICEst", name: "Banque CIC Est", parentKey: "aCMVVIE", parentName: "ACMN VIE"},
	{key: "banqueCICNordOuest", name: "Banque CIC Nord Ouest", parentKey: "aCMVVIE"},
	{key: "banqueCICOuest", name: "Banque CIC Ouest", parentKey: "aCMVVIE", parentName: "ACMN VIE"},
	{key: "banqueCICSudOuest", name: "Banque CIC Sud Ouest", parentKey: "aCMVVIE", parentName: "ACMN VIE"},
	{key: "banqueCourtoisCN", name: "Banque Courtois - CN", parentKey: "sogecap", parentName: "Sogecap"},
	{key: "banquedeSavoieBPCE", name: "Banque de Savoie BPCE", parentKey: "cNP", parentName: "CNP"},
	{key: "banqueEuropeenneduCreditMutuel", name: "Banque Européenne du Crédit Mutuel"},
	{key: "banqueKolbCN", name: "Banque Kolb CN", parentKey: "sogecap", parentName: "Sogecap"},
	{key: "banqueLaydernierCN", name: "Banque Laydernier CN", parentKey: "sogecap", parentName: "Sogecap"},
	{key: "banqueMarzeBPCE", name: "Banque Marze BPCE", parentKey: "cNP", parentName: "CNP"}
];

let payment_periods = {
	MENSUELLE: 'par mois',
	TRIMESTRIELLE: 'par trimestre',
	ANNUELLE: 'par an'
}

let insurers = [
	{"key": "caci", "name": "CACI"},
	{"key": "hsbc_assurances_vie", "name": "HSBC assurances Vie"},
	{"key": "acmn_vie", "name": "ACMN VIE"},
	{"key": "cnp", "name": "CNP"},
	{"key": "probtp", "name": "PROBTP"},
	{"key": "suravenir", "name": "Suravenir"},
	{"key": "mutlog", "name": "Mutlog"},
	{"key": "prepar", "name": "Prépar"},
	{"key": "sogecap", "name": "Sogecap"},
	{"key": "natixis", "name": "Natixis"},
	{"key": "cardif_assurances", "name": "Cardif Assurances"},
	{"key": "autre", "name": "Autre"},
	{"key": "axa", "name": "AXA"},
	{"key": "allianz", "name": "Allianz"},
	{"key": "acmn_vie", "name": "ACMN VIE"}
];

let steps = {
	'afi-esca': [
		{page: '/guaranties', back: "/prets"},
		{page: '/comparison', back: "/guaranties"},
		{page: '/donn%C3%A9es_personnelles', back: "/comparison"},
		{page: '/profession', back: "/donn%C3%A9es_personnelles"},
		// {page: '/programme_bien_etre', back: "/profession"},
		{page: '/sante', back: "/profession"},
		{page: '/resume', back: "/sante"},
		{page: '/offre', back: "/resume"},
		{page: '/signing', back: "/offre"},
		{page: '/bravo_signature', back: "/sante"},
		{page: '/rendez-vous', back: "/bravo_signature"},
		{page: '/migrate', back: "/sante"},
	],
	'mncap': [
		{page: '/guaranties', back: "/prets"},
		{page: '/comparison', back: "/guaranties"},
		{page: '/donn%C3%A9es_personnelles', back: "/comparison"},
		{page: '/profession', back: "/donn%C3%A9es_personnelles"},
		// {page: '/programme_bien_etre', back: "/profession"},
		{page: '/resume', back: "/profession"},
		{page: '/sante', back: "/profession"},
		{page: '/offre', back: "/resume"},
		{page: '/signing', back: "/offre"},
		{page: '/bravo_signature', back: "/sante"},
		{page: '/rendez-vous', back: "/bravo_signature"},
		{page: '/migrate', back: "/sante"},
	],
	'IRIADE': [
		{page: '/guaranties', back: "/prets"},
		{page: '/comparison', back: "/guaranties"},
		{page: '/donn%C3%A9es_personnelles', back: "/comparison"},
		{page: '/profession', back: "/donn%C3%A9es_personnelles"},
		// {page: '/programme_bien_etre', back: "/profession"},
		{page: '/resume', back: "/profession"},
		{page: '/sante', back: "/profession"},
		{page: '/offre', back: "/resume"},
		{page: '/will_receive_mail', back: "/offre"},
		{page: '/signing', back: "/offre"},
		{page: '/bravo_signature', back: "/sante"},
		{page: '/rendez-vous', back: "/bravo_signature"},
		{page: '/migrate', back: "/sante"},
	],
	'IPTIQ': [
		{page: '/guaranties', back: "/prets"},
		{page: '/comparison', back: "/guaranties"},
		{page: '/donn%C3%A9es_personnelles', back: "/comparison"},
		{page: '/profession', back: "/donn%C3%A9es_personnelles"},
		// {page: '/programme_bien_etre', back: "/profession"},
		{page: '/resume', back: "/profession"},
		{page: '/sante', back: "/profession"},
		{page: '/offre', back: "/resume"},
		{page: '/recevoir', back: "/offre"},
		{page: '/signing', back: "/offre"},
		{page: '/bravo_signature', back: "/sante"},
		{page: '/rendez-vous', back: "/bravo_signature"},
		{page: '/migrate', back: "/sante"},
	],
	'MILTIS': [
		{page: '/guaranties', back: "/prets"},
		{page: '/comparison', back: "/guaranties"},
		{page: '/donn%C3%A9es_personnelles', back: "/comparison"},
		{page: '/profession', back: "/donn%C3%A9es_personnelles"},
		// {page: '/programme_bien_etre', back: "/profession"},
		{page: '/resume', back: "/profession"},
		{page: '/sante', back: "/profession"},
		{page: '/offre', back: "/resume"},
		{page: '/recevoir', back: "/offre"},
		{page: '/signing', back: "/offre"},
		{page: '/bravo_signature', back: "/sante"},
		{page: '/rendez-vous', back: "/bravo_signature"},
		{page: '/migrate', back: "/sante"},
	],
	'SEREVI3': [
		{page: '/guaranties', back: "/prets"},
		{page: '/comparison', back: "/guaranties"},
		{page: '/donn%C3%A9es_personnelles', back: "/comparison"},
		{page: '/profession', back: "/donn%C3%A9es_personnelles"},
		// {page: '/programme_bien_etre', back: "/profession"},
		{page: '/resume', back: "/profession"},
		{page: '/sante', back: "/profession"},
		{page: '/offre', back: "/resume"},
		{page: '/recevoir', back: "/offre"},
		{page: '/signing', back: "/offre"},
		{page: '/bravo_signature', back: "/sante"},
		{page: '/rendez-vous', back: "/bravo_signature"},
		{page: '/migrate', back: "/sante"},
	],
	'AVENIRNAOASSUR': [
		{page: '/guaranties', back: "/prets"},
		{page: '/comparison', back: "/guaranties"},
		{page: '/donn%C3%A9es_personnelles', back: "/comparison"},
		{page: '/profession', back: "/donn%C3%A9es_personnelles"},
		// {page: '/programme_bien_etre', back: "/profession"},
		{page: '/resume', back: "/profession"},
		{page: '/sante', back: "/profession"},
		{page: '/offre', back: "/resume"},
		{page: '/recevoir', back: "/offre"},
		{page: '/signing', back: "/offre"},
		{page: '/bravo_signature', back: "/sante"},
		{page: '/rendez-vous', back: "/bravo_signature"},
		{page: '/migrate', back: "/sante"},
	],
	'MAESTRO': [
		{page: '/guaranties', back: "/prets"},
		{page: '/comparison', back: "/guaranties"},
		{page: '/donn%C3%A9es_personnelles', back: "/comparison"},
		{page: '/profession', back: "/donn%C3%A9es_personnelles"},
		// {page: '/programme_bien_etre', back: "/profession"},
		{page: '/resume', back: "/profession"},
		{page: '/sante', back: "/profession"},
		{page: '/offre', back: "/resume"},
		{page: '/recevoir', back: "/offre"},
		{page: '/signing', back: "/offre"},
		{page: '/bravo_signature', back: "/sante"},
		{page: '/rendez-vous', back: "/bravo_signature"},
		{page: '/migrate', back: "/sante"},
	],
	"mutlog": [
		{page: '/guaranties', back: "/prets"},
		{page: '/comparison', back: "/guaranties"},
		{page: '/donn%C3%A9es_personnelles', back: "/comparison"},
		{page: '/profession', back: "/donn%C3%A9es_personnelles"},
		{page: '/sante', back: "/profession"},
		{page: '/migrate', back: "/sante"},
		{page: '/resume', back: "/sante"},
		{page: '/offre', back: "/resume"},
		{page: '/signing', back: "/offre"},
		{page: '/rendez-vous', back: "/bravo_signature"},
	]
};

let projectTypes = [
	{key: "mainResidence", name: "Résidence principale"},
	{key: "secondaryResidence", name: "Résidence secondaire"},
	{key: "investmentForRenting", name: "Investissement locatif"},
	{key: "works", name: "Travaux"}
];

let coverage = [
	{key: "10", name: "10%"},
	{key: "15", name: "15%"},
	{key: "20", name: "20%"},
	{key: "25", name: "25%"},
	{key: "30", name: "30%"},
	{key: "35", name: "35%"},
	{key: "40", name: "40%"},
	{key: "45", name: "45%"},
	{key: "50", name: "50%"},
	{key: "55", name: "55%"},
	{key: "60", name: "60%"},
	{key: "65", name: "65%"},
	{key: "70", name: "70%"},
	{key: "75", name: "75%"},
	{key: "80", name: "80%"},
	{key: "85", name: "85%"},
	{key: "90", name: "90%"},
	{key: "95", name: "95%"},
	{key: "100", name: "100%"},
	{key: "99", name: "Ne sais pas"},
];

let salutations = [
	{
		"key": "mr",
		"name": "Monsieur"
	}, {
		"key": "mrs",
		"name": "Madame"
	}
];

let maritalStatus = [
	{
		"key": "married",
		"name": "Marié(e)"
	}, {
		"key": "inPartnership",
		"name": "PACS",
	},
	{
		"key": "single",
		"name": "Célibataire",
	},
	{
		"key": "widowed",
		"name": "Veuf/Veuve",
	}
];

let loanTypes = [
	{
		"key": "classic",
		"name": "Classique"
	}, {
		"key": "ptz",
		"name": "Prêt à Taux Zéro"
	}, {
		"key": "pel",
		"name": "Prêt Epargne Logement"
	}
];

let professions       = [
	{
		"key": "artisan",
		"name": "Artisan"
	}, {
		"key": "commercantAvecResponsabiliteDePersonnel",
		"name": "Commerçant avec responsabilité de personnel"
	}, {
		"key": "commercantSansResponsabiliteDePersonnel",
		"name": "Commerçant sans responsabilité de personnel"
	}, {
		"key": "dirigeantDeSociete",
		"name": "Dirigeant de société"
	}, {
		"key": "enseignant",
		"name": "Enseignant"
	}, {
		"key": "professionLiberale",
		"name": "Profession libérale"
	}, {
		"key": "professionMedicale",
		"name": "Profession médicale (hors para médicale)"
	}, {
		"key": "professionParaMedicale",
		"name": "Profession para médicale"
	}, {
		"key": "salarieDuPrive",
		"name": "Salarié du privé"
	}, {
		"key": "salarieDuPublic",
		"name": "Salarié du public"
	}
];
let risky_professions = [
	{
		"key": "secteurPetrolier",
		"name": "Secteur pétrolier"
	}, {
		"key": "roduitsDangereux",
		"name": "Manipulation ou transport de produits dangereux"
	}, {
		"key": "hauteurSuperieure",
		"name": "Profession exercée à une hauteur supérieure à 20 mètres"
	}, {
		"key": "sportifsProfessionnels",
		"name": "Sportif professionnel"
	}, {
		"key": "activitesSportives ",
		"name": "Encadrants d’activités sportives (hors écoles ou universités)"
	}, {
		"key": "secoursSecurite",
		"name": "Profession de secours, sécurité, maintien  de l’ordre, militaire"
	}, {
		"key": "necessitantArmes",
		"name": "Profession nécéssitant un port d'armes"
	}, {
		"key": "pompierProfessionnel",
		"name": "Pompier professionnel"
	}, {
		"key": "merNavalsDockers",
		"name": "Activitiés en mer et sur les chantiers navals, dockers"
	}, {
		"key": "CinemaTelevisionSpectacle",
		"name": "Cirque, artistes de cinéma ou de télévision, intermittents du spectacle"
	}, {
		"key": "mineGalerieSpeleologues",
		"name": "Travail de la mine, travail souterrain ou en galerie, spéléologues"
	}, {
		"key": "piloteAerien",
		"name": "Pilote aérien (hors lignes de transport de passagers de sociétés situées dans l’OCDE)"
	}, {
		"key": "voyagesFrequents",
		"name": 'Voyages fréquents (+ de 20 000 kms / an)',
	}, {
		"key": "activiteDeManutentionLegere",
		"name": 'Activité de manutention légère'
	}
];
let productLabels = {
	"IPTIQ": "ALLAZO Emprunteur",
	"MNCAP": "MNCAP Emprunteur Pro",
	"IRIADE": "Iriade emprunteur",
	"MAESTRO": "Maestro Emprunteur",
	"SEREVI3": "Sérénité Emprunteur",
	"MILTIS": "Sopretis CI",
	"NAOASSUR": "Naoassur Emprunteur",
	"AVENIRNAOASSUR": "Avenir Naoassur emprunteur 2"
}

let productInsurers = {
	"IPTIQ": "IPTIQ",
	"MNCAP": "MNCAP Emprunteur Pro",
	"IRIADE": "OradéaVie",
	"MAESTRO": "Quatrem",
	"SEREVI3": "Sérénité Emprunteur",
	"MILTIS": "Miltis",
	"NAOASSUR": "Naoassur",
	"AVENIRNAOASSUR": "SURAVENIR"
}
let all_equivalences  = [
	{
		key: 'death_covered',
		name: {fr: 'Décès', bg: 'Смърт'},
		tip: 'Votre crédit est pris en charge au prorata <br/>de la quotité choisie en cas de décès de l’assuré.',
		ageLimit: 90,
		csp_limit: 5
	},
	{
		key: 'ptia_covered',
		name: {fr: 'Perte d\'autonomie', bg: 'Пълна загуба на работоспособност'},
		tip: 'La perte totale et irreversible d\'autonomie (PTIA) entraine la prise <br/>en charge de votre crédit au prorata de la quotité souscrite.',
		ageLimit: 70,
		csp_limit: 5
	},
	{
		key: 'itt_covered',
		name: {fr: 'Incapacité (ITT)'},
		tip: 'Les mensualités de votre crédit sont prises en charge<br/> en cas d\'incapacité totale de travail à compter du 91e jour.',
		ageLimit: 62,
		bankOnlyRule: true, // only give it when the bank does
		csp_limit: 2
	},
	{
		key: 'ipt_covered',
		name: {fr: 'Invalidité permanente totale (IPT)'},
		tip: 'Etat de santé empêchant de façon totale et définitive d’exercer la profession  pratiquée au jour du Sinistre (le taux d’invalidité doit être supérieur à 66%)',
		ageLimit: 62,
		bankOnlyRule: true,// only give it when the bank does
		csp_limit: 3
	},
	{
		key: 'ipp_covered',
		name: {fr: 'Invalidité partielle permanente (IPP)'},
		tip: 'Etat de santé empêchant de façon définitive et permanente d’exercer la profession pratiquée au jour du Sinistre (le taux d’invalidité doit être compris entre 33% et 66%)',
		ageLimit: 62,
		bankOnlyRule: true, // only give it when the bank does
		csp_limit: 2
	},
	{
		key: 'incap_pro',
		name: {fr: 'Pour votre profession'},
		tip: 'C\'est l\'incapacité à exercer votre activité<br/> habituelle qui est prise en compte (et pas n\'importe quelle activité)',
		ageLimit: 62,
		csp_limit: 3
	},
	{
		key: 'incap_back',
		name: {fr: 'Affections dorsales avec conditions'},
		tip: 'Les pathologies dorsales  sont<br/> couvertes sous conditions d\'hospitalisation.',
		include_only: [0, 2, 3],
		ageLimit: 62,
		itt_required: true,
		csp_limit: 3
	},
	{
		key: 'incap_psychological',
		name: {fr: 'Affections psychiatriques avec conditions'},
		tip: 'Les pathologies psychiques sont<br/> couvertes sous conditions d\'hospitalisation.',
		include_only: [0, 2, 3],
		ageLimit: 62,
		itt_required: true,
		csp_limit: 3
	},
	{
		key: 'incap_back',
		name: {fr: 'Affections dorsales sans conditions'},
		tip: 'Les pathologies dorsales et psychiques sont<br/> couvertes sans conditions.',
		include_only: [1],
		ageLimit: 62,
		itt_required: true,
		csp_limit: 3
	},
	{
		key: 'incap_psychological',
		name: {fr: 'Affections psychiatriques sans conditions'},
		tip: 'Les pathologies dorsales et psychiques sont<br/> couvertes sans conditions.',
		include_only: [1],
		ageLimit: 62,
		itt_required: true,
		csp_limit: 3
	},
	{
		key: 'sport_covered',
		name: {fr: 'Sport amateur', bg: 'Непрофесионален спорт'},
		tip: 'La pratique du sport amateur n\'est pas une<br/> cause d\'exclusion.',
		ageLimit: 90,
		csp_limit: 5,
		always_covered: true
	},
	{
		key: 'travel_professional',
		name: {fr: 'Voyages', bg: 'Пътуване'},
		tip: 'Les déplacements privés, professionnels ou <br/>à but humanitaire sont couverts.',
		ageLimit: 90,
		csp_limit: 5,
		always_covered: true
	}
];
export {banks, coverage, productLabels, productInsurers, salutations, maritalStatus, all_equivalences, loanTypes, projectTypes, insurers, professions, risky_professions, steps, payment_periods};
