import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Table from "@material-ui/core/Table";
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {parseInstallments} from '../../js/helpers/functions'
import {payment_periods} from '../../js/helpers/data'
import './style.css';


export default function Installments(props) {
	const StyledTableRow = withStyles((theme) => ({
		root: {
			'&:nth-of-type(odd)': {
				backgroundColor: theme.palette.action.hover,
			},
		},
	}))(TableRow);

	const showTable         = (installments) => {
		return (
			<TableContainer style={{margin:'5px', width:'4vw'}} component={Paper} >
				<Table size="large" aria-label="a dense table">
					<TableHead>
						<StyledTableRow >
							<TableCell style={{background: props.config.theme.primary, color:'white'}}>Année</TableCell>
							<TableCell style={{background: props.config.theme.primary, color:'white'}} align="right">{payment_periods[props.payment_period || "MENSUELLE"]}</TableCell>
						</StyledTableRow>
					</TableHead>
					<TableBody>
						{installments.map(installment => {
							return (
								<StyledTableRow>
									<TableCell component="th" scope="row" style={{color: props.config.theme.primary}}>
										{installment.year}
									</TableCell>
									<TableCell align="right" style={{textAlign:'right', color: props.selected ? '#000000' : props.config.theme.primary}}>{installment.total} &euro;</TableCell>
								</StyledTableRow>
							)
						})}
					</TableBody>
				</Table>
			</TableContainer>)

	}
	const chunkArray        = (installments) => {
		let chunked = [];
		let times = Math.ceil(installments.length / 3);
		while (installments.length) {
			chunked.push(installments.splice(0, times));
		}
		return chunked;
	}
	const installmentParse = () => {

		let chunks = chunkArray(parseInstallments(props));
		return (
			<div className="installments-table-wrapper">
				{chunks.map(installments => {
					return (
						showTable(installments)
					)
				})}
			</div>
		)
	}
	return (<>
		{installmentParse()}
	</>)


}
