import React from 'react';
import Shell from '../../components/Shell';
import {connect} from 'react-redux';
import {Container, Row, Col} from "react-grid-system";
import StandardButton from "../../components/Form/StandardButton";
import {CustomValidatorForm} from "../../components/Form";
import {getBackendUrl} from "../../js/helpers/functions";
import successImage from './payment_success.svg';
import history from "../../history";
import {post} from "../../js/helpers/rest";

class IncomePaymentSuccess extends React.Component {
	constructor(props) {
		super(props);
		this.state = {}
	}


	getStep() {
		return 'income_step_one';
	}

	render() {

		return (
			<div>
				<Container style={{color: '#000', textAlign: 'center', width: '90%',}}>
					<CustomValidatorForm>
						<Row justify="center" className="incomeRow">
							<Col md={8} style={{paddingTop: '20px', textAlign: 'center', fontWeight: 600, fontSize: '35px'}}>
								<p>И готово, вече сте наш клиент!</p>
							</Col>
						</Row>
						<Row justify="center" className="incomeRow">
							<Col md={8} style={{paddingTop: '20px', textAlign: 'left'}}>
								<img src={successImage}/>
							</Col>
						</Row>
						<Row justify="center" className="incomeRow">
							<Col md={8} style={{paddingTop: '20px', textAlign: 'center'}}>
								<StandardButton value="Към личното Ви пространство" onClick={async () => {
									post({}, getBackendUrl() + '/customer/add_certificate');
									await this.props.getContractData();
									history.push('/income_space')
								}}/>
							</Col>
						</Row>
					</CustomValidatorForm>
				</Container>
			</div>
		)
	}

}

export default connect()(Shell(IncomePaymentSuccess));
