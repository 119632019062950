import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Table from "@material-ui/core/Table";
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {isMobile} from 'react-device-detect';
import {parseInstallments} from '../../js/helpers/functions'
import {payment_periods} from '../../js/helpers/data'

const StyledTableRow = withStyles((theme) => ({
	root: {
		'&:nth-of-type(odd)': {
			backgroundColor: theme.palette.action.hover,
		},
	},
}))(TableRow);

export default function Installments(props) {
	return (
		<TableContainer
			onClick={props.onClick}
			component={Paper}
		                style={{border: props.selected ? `3px solid ${props.config.theme.primary}` : `1px solid ${props.config.theme.primary}`, display: isMobile ? props.selected ? 'block' : 'none' : 'block'}}>
			<Table size="small" aria-label="a dense table">
				<TableHead>
					<TableRow>
						<TableCell>Année</TableCell>
						<TableCell align="right"><b style={{color: props.config.theme.primary}}>{payment_periods[props.payment_period]}</b></TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{Array.isArray(parseInstallments(props)) && parseInstallments(props).map(installment => (
						<StyledTableRow>
							<TableCell component="th" scope="row" style={{color: props.config.theme.primary}}>
								{installment.year}
							</TableCell>
							<TableCell align="right" style={{color: props.selected ? '#000000' : props.config.theme.primary}}>{installment.total} &euro;</TableCell>
						</StyledTableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);


}
