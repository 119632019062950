import React from 'react';

import {Row, Col} from 'react-grid-system';
import Button from '@material-ui/core/Button';
import Translate from "components/CustomTranslate";
import Odometer from 'react-odometerjs';
import style from './style.module.css';
import {mapData} from '../../js/helpers/functions';
import history from '../../history';
import {isMobile} from 'react-device-detect'
import './style.module.css';
import Installments from '../Installments';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {payment_periods} from '../../js/helpers/data'
import Radio from '@material-ui/core/Radio';

class Offer extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			odometerValue: 0.01,
			odometerValueCo: 0.01,
			savingsOdometerValue: 0.01,
			dialogOpen: false,
			dialogTitle: '',
			dialogContent: '',
			estimation: true,
			sameEquivalences: [
				'death_covered', 'ptia_covered', 'itt_covered', 'incap_pro',
				'incap_psychological', 'sport_covered', 'travel_professional'
			].every(eq => this.props.config.calculation.equivalence[eq] && !!this.props.config.calculation.equivalence[eq]),
		};
	}

	componentDidMount() {
		if (this.props.config.calculation.total.payment_type !== 'CONSTANTE') {
			this.props.updateLoan('constant_premium', false);
		}
		this.updateOdometers(this.props.loan.constant_premium === 'true' ? this.props.config.calculation.total.insurance_cost : this.props.config.calculation.variable.insurance_cost);
	}

	async updateOdometers(insurance_cost) {
		let premium = await this.props.config.calculation.total.premium ? (this.props.loan.constant_premium === 'true' ? this.props.config.calculation.total.premium : this.props.config.calculation.variable.premium) : 0.01;
		this.setState({
			insuranceCost: (insurance_cost.toFixed(0) + '1').replace('.', ','),
			odometerPremiumValue: (premium.toFixed(2) + '1').replace('.', ','),
			odometerInsuranceRateValue: (((this.props.loan.constant_premium === 'true' ? this.props.config.calculation.total.insurance_rate : this.props.config.calculation.variable.insurance_rate) * 100).toFixed(3) + '1').replace('.', ','),
		});
	}

	installmentsTitle(constant_premium = true) {
		let calculation = constant_premium ? this.props.calculation : this.props.calculation.variable;
		return <Row style={{padding: '0px 0px 10px 15px'}}>
			<Col style={{fontSize: '16px', textAlign: 'center'}}
			     align={calculation.payment_period === "MENSUELLE" && 'center'}>{constant_premium ? 'Constante' : 'Variable'}</Col>

		</Row>
	}

	installments() {
		return (
			<Col md={6} sm={12} className={!isMobile && style.verticalCenter}>
				<Row justify='center' style={{fontSize: '20px'}}>
					<Col style={{fontWeight: '450', textAlign: 'center'}}><p>Les échéanciers</p></Col>
				</Row>
				<Row justify="center">
					{this.props.config.calculation.total.payment_type === 'CONSTANTE' &&
					<Col md={5} sm={12} xs={12}>
					    {!isMobile && this.installmentsTitle(true)}
					    <Installments type={'constante'}
						              onClick={() => {
							              this.props.updateLoan('constant_premium', "true")
							              let insurance_cost = this.props.config.calculation.total.insurance_cost;
							              this.updateOdometers(insurance_cost);
						              }}
						              config={this.props.config}
						              selected={this.props.loan.constant_premium === 'true'}
						              installments={this.props.calculation.installments}
						              payment_period={this.props.calculation.payment_period}/>
					</Col>}
					<Col md={5} sm={12} xs={12}>
						{!isMobile && this.installmentsTitle(false)}
						<Installments type={'variable'}
						              onClick={() => {
							              this.props.updateLoan('constant_premium', "false")
							              let insurance_cost = this.props.config.calculation.variable.insurance_cost;
							              this.updateOdometers(insurance_cost);
						              }}
						              config={this.props.config}
						              selected={this.props.loan.constant_premium === 'false'}
						              installments={this.props.calculation.variable.installments}
						              payment_period={this.props.calculation.variable.payment_period}/>
					</Col>
				</Row>
			</Col>
		)
	}

	cost() {
		return (
			<Col md={6} sm={12} className={style.verticalCenter}>
				<Row justify="center">
					<Col md={12}>
						<p className='centeredText' style={{fontWeight: '450'}}>
							Soit un coût global d'assurance de:
						</p>
					</Col>
				</Row>
				<Row justify="center">
					<Col md={12}>
						<h1 style={{color: this.props.config.theme.primary}} className={`${style.odometerWrapper}`}>
							<Odometer value={this.state.insuranceCost} format='( dddd)' duration={5500}/>
							<span> €</span>
						</h1>
					</Col>
				</Row>
				{this.props.config.calculation.total.insurance_rate &&
				<div>
				    <Row justify="center">
				        <Col md={12}>
				            <p className='centeredText' style={{fontWeight: '450'}}>
								Taux moyen d’assurance:
				            </p>
				        </Col>
				    </Row>
				    <Row justify="center">
				        <Col md={12}>
				            <h1 style={{color: this.props.config.theme.primary}} className={`${style.odometerWrapper}`}>
				                <Odometer value={this.state.odometerInsuranceRateValue} format='( ddd),ddddd' duration={5500}/>
				                <span> %</span>
				            </h1>
				        </Col>
				    </Row>
				</div>}
				<Row justify="center">
					<Col md={12}>
						<p className='centeredText' style={{fontWeight: '450'}}>
							Prime moyenne:
						</p>
					</Col>
				</Row>
				<Row justify="center">
					<Col md={12}>
						<h1 style={{color: this.props.config.theme.primary}} className={`${style.odometerWrapper}`}>
							<Odometer value={this.state.odometerPremiumValue} format='( ddd),ddd' duration={5500}/>
							<span> €</span>
						</h1>
						<p style={{
							textAlign: 'center',
							fontSize: '12px',
							textTransform: 'lowercase'
						}}>{payment_periods[this.props.loan.constant_premium === 'true' ? this.props.calculation.payment_period : this.props.calculation.variable.payment_period]}</p>
					</Col>

				</Row>

				<Row justify="center">
					<Col md={8}>
						<p className='centeredText'>
							<Translate style={{fontSize: '20px', fontWeight: '450'}} value={`step3.${this.state.sameEquivalences ? 'sameGuarantees' : 'betterGuarantees'}`}/>
						</p>
					</Col>
				</Row>
				<Row justify="center">
					<Col md={5} xs={10} sm={10}>
						<Button style={{backgroundColor: this.props.config.theme.primary}} className={'formButton'} id='afiesca_button' onClick={() => {
							if (this.props.loan.flow === 'afi-esca') {
								this.props.afiEsca(mapData(Object.assign({}, this.props.borrower, {
									step: 'resume',
									send_proposition: true
								}), this.props.coborrower, this.props.loan));
							}
							history.push('/donnes_personales');
						}}>
							<Translate value='step3.continue'/>
						</Button>
					</Col>
				</Row>
				{this.props.config.calculation.total.payment_type === 'CONSTANTE' && <div>
					<Row>
						<Col md={12}>
							<p className='centeredText' style={{fontWeight: '450'}}> Choisissez votre mode de paiement : </p>
						</Col>
					</Row>
					<Row justify="center">
						<RadioGroup className={isMobile ? 'radioGroup' : 'radioGroup center_radioButtons'}
						            name='question_three'
						            style={{marginTop: 0, color: this.props.config.theme.primary }}
						            value={this.props.loan.constant_premium}
						            onChange={(event, value) => {
							            this.props.updateLoan('constant_premium', value);
							            let insurance_cost = value === 'true' ? this.props.config.calculation.total.insurance_cost : this.props.config.calculation.variable.insurance_cost;
							            this.updateOdometers(insurance_cost);
						            }}>
							<Col justify='center' md={3} sm={12} xs={12} style={{margin: 10, padding: 0}}>
								<FormControlLabel
									className='radioButton'
									value='true'
									control={<Radio required/>}
									label='Constante'
								/>
							</Col>
							<Col justify='center' md={4} sm={12} xs={12} style={{margin: 10, padding: 0}}>
								<FormControlLabel
									className='radioButton'
									value='false'
									control={<Radio required/>}
									label='Non-constante'
								/>
							</Col>
						</RadioGroup>
					</Row>
				</div>}
			</Col>
		)
	}

	render() {
		return (
			<div>
				<Row>
					{isMobile ? [this.cost(), this.installments()] : [this.installments(), this.cost()]}
				</Row>
			</div>
		)
	}
}

export default Offer;
