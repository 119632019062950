import {ATTACHMENTS, RE_INITIALIZE_ALL} from "../../constants/action-types";
import {loadState} from "../../localStorage";

const persistedState = loadState();

let initialState = {
	borrower: {},
	coborrower: {}
};
let defaultState = Object.assign({}, initialState, persistedState.attachments);
export default (state = defaultState, action) => {
	switch (action.type) {
	case RE_INITIALIZE_ALL:
		return initialState;
	case ATTACHMENTS:
		console.log(action.res);
		// console.log(`Your name is ${action.res.result.firstName}`)
		//Slim Shady above is breaking all the attachments
		return {
			...state,
			...action.data
		};
	default:
		return state;
	}
}
