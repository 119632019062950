import React, {useEffect, useState} from "react";
import {
	PaymentElement,
	useStripe,
	useElements
} from "@stripe/react-stripe-js";
import StandardButton from "../../../components/Form/StandardButton";
import history from "../../../history";
import Translate from 'components/CustomTranslate';
import {Row, Col} from 'react-grid-system';
import {Button} from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import style from "../../../components/Offer/style.module.css";
import {getBackendUrl} from "../../../js/helpers/functions";
export default function CheckoutForm() {
	const stripe   = useStripe();
	const elements = useElements();

	const [message, setMessage]     = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [acceptTerms, setAcceptTerms] = useState(false);

	useEffect(() => {
		if (!stripe) {
			return;
		}

		const clientSecret = new URLSearchParams(window.location.search).get(
			"payment_intent_client_secret"
		);

		if (!clientSecret) {
			return;
		}

		stripe.retrievePaymentIntent(clientSecret).then(({paymentIntent}) => {
			switch (paymentIntent.status) {
			case "succeeded":
				setMessage("Payment succeeded!");
				break;
			case "processing":
				setMessage("Your payment is processing.");
				break;
			case "requires_payment_method":
				setMessage("Your payment was not successful, please try again.");
				break;
			default:
				setMessage("Something went wrong.");
				break;
			}
		});
	}, [stripe]);

	const handleSubmit = async (e) => {
		e.preventDefault();

		if (!stripe || !elements) {
			// Stripe.js has not yet loaded.
			// Make sure to disable form submission until Stripe.js has loaded.
			return;
		}

		setIsLoading(true);

		const {error} = await stripe.confirmPayment({
			elements,
			confirmParams: {
				// Make sure to change this to your payment completion page
				return_url:process.env.REACT_APP_FRONTEND_URL + "/payment_success",
			},
		});

		// This point will only be reached if there is an immediate error when
		// confirming the payment. Otherwise, your customer will be redirected to
		// your `return_url`. For some payment methods like iDEAL, your customer will
		// be redirected to an intermediate site first to authorize the payment, then
		// redirected to the `return_url`.
		if (error.type === "card_error" || error.type === "validation_error") {
			setMessage(error.message);
		} else {
			setMessage("An unexpected error occured.");
		}

		setIsLoading(false);
	};

	return (
		<form id="payment-form" onSubmit={handleSubmit}>
			<Row justify="center">
				<Col md={10}>
					<PaymentElement id="payment-element"/>
				</Col>
			</Row>
			<Row justify="center">
				<Col md={10}>
					<FormControlLabel
						id="acceptCheckbox"
						control={
							<Checkbox
								name="CheckBox"
								checked={acceptTerms}
								onChange={(e, s) => setAcceptTerms(s)}
								className={`${style.acceptCheckbox}`}
								color="white"

							/>
						}
						label={<p style={{fontSize: '12px'}}>

							С плащането се съгласям с общите условия на Euroins, както и с обработка на личните ми данни от страна на Wedou Assurance.
							За повече информация натиснете <a target="_blank" href={`${getBackendUrl()}/static/WEDOU_Pre_Contractual.pdf`} rel="noreferrer">тук</a> за да разгледате преддоговрните условия.
						</p>}
					/>
				</Col>
			</Row>
			<Row justify="center" style={{marginTop: '30px'}}>
				<Col md={5}>
					<Button className='formButton backButton' style={{borderRadius: '26px', backgroundColor: '#292F44', color: '#fff'}} onClick={() => history.push('/income_personal')}><Translate
						value="generic.back"/></Button>
				</Col>
				<Col md={5}>

					<StandardButton type="submit" disabled={isLoading || !stripe || !elements} id="submit" value="Плати">
					</StandardButton>
					{/* Show any error or success messages */}
					{message && <div id="payment-message">{message}</div>}
				</Col>
			</Row>
		</form>
	);
}
