import {SelectField} from "../Fields";
import React from "react";
import MenuItem from "@material-ui/core/MenuItem";
import moment from "moment";
import DateField from "../DateField";

let BravoHoursDropdown = (props) => {
	let getHours = () => {
		let items = [];
		for (let i = 8; i < 20; i++) {
			let j    = i + 1;
			let text = i + 'h - ' + j + 'h';
			items.push(<MenuItem value={text} key={i} primaryText={text}>{text}</MenuItem>);
		}
		return items;
	};

	return <SelectField
		name="hours"
		placeholder="Créneau horaire"
		label="Créneau horaire"
		validators={['required']}
		errorMessages={['Cette information doit être renseignée']}
		value={props.data.meeting_hours}
		onChange={(e) => props.update('meeting_hours', e.target.value)}>
		{getHours()}
	</SelectField>;
};

let BravoDate = (props) => {
	return <DateField
		hintText="Jour"
		name="Jour"
		floatingLabelText="Jour"
		validators={['required', 'finalOfferValidation', 'noSunday']}
		errorMessages={['Cette information doit être renseignée', 'La date est incorrecte', 'Veuillez choisir un jour du lundi au samedi']}
		value={props.data.meeting_date}
		defaultValue={moment().format('DD/MM/YYYY')}
		onChange={(event, value) => {
			props.update('meeting_date', value);
		}}
	/>;
};

export {BravoHoursDropdown, BravoDate};