import React from 'react';
import Shell from '../../components/Shell';
import {connect} from 'react-redux';
import {Row, Col} from 'react-grid-system';
import {StringField} from "../../components/Form/Fields";
import {ValidatorForm} from 'react-material-ui-form-validator';
import FormButton from "../../components/Form/FormButton";

class SmsConfirmation extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			bravo: {}
		};
	}

	getStep() {
		return 'sms_confirmation';
	}

	render() {
		return (
			<ValidatorForm
				onSubmit={async () => {
					let check = await this.props.checkSMSCode({code: this.props.borrower.temporary_code})
					if(check.data.success) {
						window.location.href = check.data.link;
					} else {
						alert("Ce n'est pas le bon code.")
					}
				}
				}>
				<Row justify="center" style={{textAlign: 'center', marginTop: '60px'}}>
					<Col md={12}>
						<h3>Vous allez recevoir votre code d'accès par SMS sur le {this.props.borrower.phone}.</h3>
						<Row justify='center' style={{textAlign: 'center'}}>
							<Col md={12}>
								<p>Merci de saisir votre code pour poursuivre votre parcours.</p>
							</Col>
						</Row>
						<Row justify='center' style={{textAlign: 'center'}}>
							<Col md={4}>
								<StringField
									type={'tel'}
									// label="Durée en mois"
									id='duration_months'
									errorMessages={['Les six chiffres reçus par sms']}
									validators={['sixCharacters']}
									// placeholder={<Translate value='generic.months'/>}
									onChange={(e) => {
										this.props.updateBorrower("temporary_code", e.target.value);
									}}
									value={this.props.borrower.temporary_code}
									className={'mediumLabel'}
									name={'monthsLoan'}
									margin="normal"
								/>
							</Col>
						</Row>
						<Row justify='center' style={{textAlign: 'center'}}>
							<Col md={4}>
								<FormButton value="Poursuivre chez Afi-Esca"/>
							</Col>
						</Row>
						<Row justify='center' style={{textAlign: 'center'}}>
							<Col md={4}>
								<p style={{fontSize: '12px'}}><a href='#' onClick={() => {
								}}>Je n'ai pas reçu mon code?</a></p>
							</Col>
						</Row>
					</Col>
				</Row>
			</ValidatorForm>
		);
	}

}


export default connect()(Shell(SmsConfirmation));
