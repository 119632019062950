import React from 'react';
import Shell from '../../components/Shell';
import {connect} from 'react-redux';
import {Container, Row, Col} from "react-grid-system";

import Translate from "components/CustomTranslate";

class MinimalAmountLimit extends React.Component {


	getStep() {
		return 'minimal_amount_page';
	}

	render() {

		return (
			<Container style={{color: 'rgb(83, 114, 227)', textAlign: 'center'}}>
				<Row justify="center">
					<Col md={6}>
						<h2>
							<Translate value="minimal_amount_page.title"/>
						</h2>
					</Col>
				</Row>
				<Row justify="center">
					<Col md={6}>
						<h3>
							<Translate value="minimal_amount_page.subtitle"/>
						</h3>
					</Col>
				</Row>
				<Row justify="center">
					<Col md={6}>
						<p>
							<Translate value="minimal_amount_page.text"/>
						</p>
					</Col>
				</Row>
			</Container>
		)
	}

}

export default connect()(Shell(MinimalAmountLimit));
