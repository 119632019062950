import React from 'react';

import {Row, Col} from 'react-grid-system';
import Button from "@material-ui/core/Button";
import Translate from "components/CustomTranslate";
import Odometer from 'react-odometerjs';
import style from './style.module.css';

import history from "../../history";
import './style.module.css';
import Modal from "../Modal";
import InsuranceInfo from "../../containers/InsuranceInfo";
import {numberWithSpaces} from "../../js/helpers/functions";
import Installments from "../Installments";
import {isMobile} from "react-device-detect";

class Offer extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			odometerValue: 0.01,
			odometerValueCo: 0.01,
			savingsOdometerValue: 0.01,
			dialogOpen: false,
			dialogTitle: "",
			dialogContent: "",
			estimation: true,
			sameEquivalences: [
				'death_covered', 'ptia_covered', 'itt_covered', 'incap_pro',
				'incap_psychological', 'sport_covered', 'travel_professional'
			].every(eq => this.props.config.calculation.equivalence[eq] && !!this.props.config.calculation.equivalence[eq]),
		};
	}

	componentDidMount() {
		this.updateOdometers();
	}

	updateOdometers() {
		let estimation  = this.props.config.calculation.estimation;
		let calculation = this.props.config.calculation.total;
		let insurance   = this.props.config.calculation.insurance;

		let data    = insurance || estimation;
		let savings = data ? data.insurance_cost - calculation.insurance_cost : 0;

		let borrower   = calculation.borrower && calculation.borrower.premium ? calculation.borrower.premium : calculation.premium;
		let coborrower = calculation.coborrower && calculation.coborrower.premium ? calculation.coborrower.premium : 0;

		this.setState({
			estimation: !insurance,
			currentCost: numberWithSpaces(data.insurance_cost.toFixed(2)),
			insuranceCost: (calculation.insurance_cost.toFixed(2) + '1').replace('.', ','),
			savings: savings,
			//Add + 0.001 to fix trimming 1.00 values - yep
			odometerValue: (borrower.toFixed(2) + '1').replace('.', ','),
			odometerValueCo: (coborrower.toFixed(2) + '1').replace('.', ','),
			savingsOdometerValue: (savings.toFixed(2) + '1').replace('.', ','),
		});
	}

	render() {
		return (
			<div>
				<Row justify="center" className={style.result}>
					<Col md={8} style={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center'
					}}>
						<Row justify="center">
							<Col md={12}>
								<h3 className='centeredText'>
									Soit un coût global d'assurance{this.props.borrower.has_coborrower && ', pour les deux emprunteurs,'} de :
								</h3>
							</Col>
						</Row>
						<Row justify="center">
							<Col md={8}>
								<h1 style={{color: this.props.config.theme.primary}} className={`${style.odometerWrapper}`}>
									<Odometer value={this.state.insuranceCost} format='( ddd),ddd' duration={5500}/>
									<span> €</span>
								</h1>
							</Col>
							<Col md={8}>
								{this.state.savings > 100 && <div>
									<div className={style.stepSubtitle}>
										<p className="centeredText"> et {!!this.state.estimation ? 'une économie estimée à :' : 'une économie de :'} </p>
									</div>
									<h1 style={{color: this.props.config.theme.primary}} className={`${style.odometerWrapperSmall}`}>
										<Odometer value={this.state.savingsOdometerValue} format='( ddd),ddd' duration={5500}/>
										<span> € *</span>
									</h1>
								</div>}

								<p className='centeredText'>
									<Translate value={`step3.${this.state.sameEquivalences ? 'sameGuarantees' : 'betterGuarantees'}`}/>
								</p>
							</Col>
						</Row>
						<Row justify="center">
							<Col md={5} xs={10} sm={10}>
								<Button style={{backgroundColor: this.props.config.theme.primary}} id='button-economise' className={'formButton'}
								        onClick={() => history.push('/donnes_personales')}>
									<Translate value='step3.continue'/>
								</Button>
							</Col>
						</Row>
					</Col>
				</Row>
				:
				<Row justify="center" className={style.result}>
					<Col md={8}>
						<div className={style.stepSubtitle}>
							<p className="centeredText">Soit un coût global d'assurance de <span style={{color: this.props.config.theme.primary}} className='bold'>{this.state.insuranceCost} €</span></p>
						</div>
					</Col>
				</Row>

				<div>
					<Row justify="center">
						<Col md={8}>
							<div className='separatorHeading'/>
						</Col>
					</Row>

					{this.state.savings > 100 &&
					<Row justify="center">
					    <Col md={8} xs={10} sm={10}>
					        <p className="centeredText" style={{fontSize: '11px'}}>*sur la base d'un cout d'assurance bancaire estimé à : <b
					            style={{color: this.props.config.theme.primary}} className="inline-block">{this.state.currentCost} €</b>
					        </p>
					    </Col>
					</Row>
					}
					<Row justify='center'>
						<Col md={10} xs={10} sm={10}>
							<p className={style.compareText}>
								Cette estimation est réalisée sur la base d'une moyenne des tarifs bancaires relevés par Wedou.<br/>
								Pour connaître précisément votre économie c'est ici : &nbsp;
								<Button style={{background: this.props.config.theme.primary}} className='fadingButton' onClick={() => {
									this.props.updateConfig('comparison', true);
									this.setState({
										dialogOpen: true,
										dialogContent: this.emailLogin
									});
								}}>Je compare</Button>
							</p>
						</Col>
					</Row>
					<Row justify='center'>
						<Col md={3} style={{textAlign: 'center'}}>
							<p>Votre échéancier</p>
							<Col md={5} sm={12} xs={12}>
								{!isMobile && this.installmentsTitle(true)}
							</Col>
							{this.props.calculation.variable && this.props.calculation.variable.installments.length > 0 ?
								<Col md={5} sm={12} xs={12}>
									{!isMobile && this.installmentsTitle(false)}
									<Installments type={'variable'}
								              onClick={() => {
									              this.props.updateLoan('constant_premium', "false")
									              let insurance_cost = this.props.config.calculation.variable.insurance_cost;
									              this.updateOdometers(insurance_cost);
								              }}
								              config={this.props.config}
								              selected={this.props.loan.constant_premium === 'false'}
								              installments={this.props.calculation.variable.installments}
								              payment_period={this.props.calculation.variable.payment_period}/>
								</Col> : <></>}
							<Col md={5} sm={12} xs={12}>
								{!isMobile && this.installmentsTitle(false)}
								<Installments type={'constante'}
								              onClick={() => {
									              this.props.updateLoan('constant_premium', "true")
									              let insurance_cost = this.props.config.calculation.total.insurance_cost;
									              this.updateOdometers(insurance_cost);
								              }}
								              config={this.props.config}
								              selected={this.props.loan.constant_premium === 'true'}
								              installments={this.props.calculation.installments}
								              payment_period={this.props.calculation.payment_period}/>
							</Col>
						</Col>
					</Row>
				</div>


				<Modal
					maxWidth={"md"}
					dialogOpen={this.state.dialogOpen}
					config={this.props.config}
					onClose={() => this.setState({dialogOpen: false})}>
					<InsuranceInfo
						popupClose={() => this.setState({dialogOpen: false})}
						updateOdometers={() => this.updateOdometers()}
						loan={this.props.loan}
						borrower={this.props.borrower}
						coborrower={this.props.coborrower}
						updateLoan={this.props.updateLoan}
						updateConfig={this.props.updateConfig}
						updateBorrower={this.props.updateBorrower}
						updateCoBorrower={this.props.updateCoBorrower}
						insuranceCalculation={this.props.insuranceCalculation}
					/>
				</Modal>
			</div>
		)
	}
}

export default Offer;
