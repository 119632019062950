// src/js/store/simplified.js
import {createStore, combineReducers, applyMiddleware, compose} from 'redux';
import rootReducer from "../reducers/index";

import thunk from 'redux-thunk';
import {loadTranslations, setLocale, syncTranslationWithStore, i18nReducer} from 'react-redux-i18n';
import {saveState} from '../localStorage';
import * as _ from 'lodash';
import {getBackendUrl, getLanguage} from "../helpers/functions";
import {get} from "../helpers/rest";
import fr from './../helpers/translations/fr.json'
import bg from './../helpers/translations/bg.json'
import en from './../helpers/translations/en.json'
import es from './../helpers/translations/es.json'

const languages        = {'en': en, 'fr': fr, 'es': es, 'bg': bg}
const composeEnhancers = process.env.NODE_ENV === 'development' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
	window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : compose;

const store = createStore(
	combineReducers({
		rootReducer,
		i18n: i18nReducer,
	}),
	composeEnhancers(applyMiddleware(thunk))
);
if (window.location.pathname !== '/buick-pricing') {
	let lang = getLanguage();
	get({body: {lang}}, getBackendUrl(lang) + '/config/translations').then(async res => {
		syncTranslationWithStore(store);
		store.dispatch(loadTranslations({[lang]: res}));
		store.dispatch(setLocale(lang));
	});
	syncTranslationWithStore(store);
	store.dispatch(loadTranslations({[lang]: languages[lang]}));
	store.dispatch(setLocale(lang));
}

window.updateLanguageKey = function (key, value) {
	let lang        = getLanguage();
	let parts       = key.split('.');
	let addToObject = (obj, keys, value) => {
		let o, copy = o = JSON.parse(JSON.stringify(obj));
		let lastKey = keys.pop();
		keys.forEach(key => o = o[key] = o[key] || {});
		o[lastKey] = value;
		return copy;
	};
	let new_entry   = addToObject(languages[lang], parts, value);
	languages[lang] = Object.assign(languages[lang], new_entry);

	syncTranslationWithStore(store);
	store.dispatch(loadTranslations({[lang]: languages[lang]}));
	store.dispatch(setLocale(lang));
}

let previousValue = {};
let currentValue  = {};
store.subscribe(() => {
	if (window.__MIRROR_STATE_UPDATE && window.__MIRROR_LISTENING) {
		currentValue = JSON.parse(JSON.stringify(store.getState().rootReducer));
		['borrower', 'coborrower', 'config', 'loan'].forEach(t => {
			let equal = _.isEqual(previousValue[t], currentValue[t]);
			if (!equal) {
				//Often, state update is sent before the click that updates it
				//and the click reverts it on the mirrorer side - so little timeout
				setTimeout(() => window.__MIRROR_STATE_UPDATE(t, currentValue[t]), 400);
			}

			// Should've been smarter... Didnt work
			// let diff = deepJsonDiff(previousValue[t], currentValue[t]);
			// if (diff) window.__MIRROR_STATE_UPDATE(t, diff);
		});
		previousValue = JSON.parse(JSON.stringify(store.getState().rootReducer));
	}
	saveState({
		borrower: store.getState().rootReducer.borrower,
		coborrower: store.getState().rootReducer.coborrower,
		config: store.getState().rootReducer.config,
		loan: store.getState().rootReducer.loan,
	});
});

export default store;
