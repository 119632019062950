import React from 'react';

import {Row, Col} from 'react-grid-system';
import Translate from "components/CustomTranslate";
import Odometer from 'react-odometerjs';
import {ValidatorForm} from 'react-material-ui-form-validator';
// import mutlog from "../Guarantees/mutlog.png";
// import afi_esca from "../Guarantees/afiesca.jpg";
// import mncap from "../Guarantees/mncap.png";
import './style.module.css';
import {isMobile} from "react-device-detect";
import FormButton from '../Form/FormButton';
import history from '../../history';
import Modal from "../Modal";
import {mapData} from "../../js/helpers/functions";
import InstallmentOffre from "../Installments/installment_offre";
import Installments from "../Installments/index";
import {getBackendUrl} from "../../js/helpers/functions";
import {productInsurers} from "../../js/helpers/data";
import BorrowerGuarantees from "../Guarantees/borrower_guarantees";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import BackButton from "../Form/BackButton";
import traitImage from './trait.svg';
import highFiveImage from './high_five.svg';
import peopleGroupImage from './people_group.svg';
import flyingManImage from './flying_man.svg';
import newLupaImage from './new_lupa.svg';
import StandardButton from "../Form/StandardButton";
import Price from "../Price";
import axios from "axios";
import {FaInfo} from "react-icons/all";

const api_url = getBackendUrl();

const I18n = require('react-redux-i18n').I18n;

class Offer extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			ibanModal: false,
			changed_premium_type: false,
			installmentModal: false,
			sendToProfession: false,
			signatureErrorModal: false,
			odometerInsuranceRateValue: (((this.props.loan.constant_premium ? this.props.config.calculation.total.insurance_rate : this.props.config.calculation.variable.insurance_rate) * 100).toFixed(3) + '1').replace('.', ',')
		};
	}

	async componentDidMount() {
		window.addEventListener("message", async event => {
			if (event && event.data && event.data.event && event.data.event === 'update-pricing') {
				await this.props.finalCalculation(mapData(Object.assign({}, this.props.borrower, {step: 'resume'}), this.props.coborrower, this.props.loan));
				this.setupPremium();
				if (this.props.loan.flow === 'mncap') {
					this.setState({sendToProfession: true});
				}
			}
		}, false);
		await this.props.updateBorrower('initial', false);
		// await this.props.finalCalculation(mapData(Object.assign({}, this.props.borrower, {step: 'resume'}), this.props.coborrower, this.props.loan));
		let bank = this.props.config.banks.filter(b => b.key === this.props.loan.bank);
		this.setState({bank});
		this.setupPremium();

	}

	setupPremium() {
		let premium;
		let totalCost;
		if (this.props.loan.constant_premium) {
			totalCost = this.props.config.calculation.total.insurance_cost ? this.props.config.calculation.total.insurance_cost : 0.01;
			premium   = this.props.config.calculation.total.premium ? this.props.config.calculation.total.premium : 0.01
		} else {
			premium   = this.props.config.calculation.variable.premium ? this.props.config.calculation.variable.premium : 0.01;
			totalCost = this.props.config.calculation.variable.insurance_cost ? this.props.config.calculation.variable.insurance_cost : 0.01;
		}
		this.updateNumbers(premium, totalCost);
	}

	updateNumbers(premium, totalCost) {
		this.setState({
			premium,
			odometerPremiumValue: (premium.toFixed(2) + '1').replace('.', ','),
			odometerTotalInsuranceValue: (totalCost.toFixed(2) + '1').replace('.', ',')
		});
	}

	modal() {
		return (
			<Modal
				dialogOpen={this.state.ibanModal}
				config={this.props.config}
				maxWidth={'md'}
				onClose={() => this.setState({ibanModal: false})}
			>
				<Row justify='center' style={{marginTop: '-10px'}}>
					<Col md={12} style={{color: '#000'}}>
						<h2 style={{textAlign: 'center'}}>{this.props.borrower.first_name},</h2>
						<p>Nous venons de vous adresser par mail, l'ensemble des documents précontractuels dont la Notice d'Information
							et votre FSI.</p>
						<p>Merci d'en prendre connaissance. </p>
						<p>Nous allons à présent vous soumettre pour signature les documents suivants :</p>
						<ul className={'documentsList'} style={{marginTop: '-10px'}}>
							<li><Translate value='step9.li1' dangerousHTML/></li>
							<li><Translate value='step9.li2' dangerousHTML/></li>
							<li><Translate value='step9.li3' dangerousHTML/></li>
							{this.props.loan.flow === 'afi-esca' && <li><Translate value='step9.li5' dangerousHTML/></li>}
							{this.props.loan.loan_status !== 'new' && <li><Translate value='step9.li4' dangerousHTML/></li>}
						</ul>
					</Col>
				</Row>
				<ValidatorForm
					onSubmit={async () => {
						let is_not_lemoine = !this.props.borrower.lemoine || this.props.borrower.lemoine.outcome === false;
						this.props.updateConfig('loading', true);
						this.props.updateConfig('show_document_loading_message', true);
						this.props.updateBorrower('initial', false);
						await this.props.finalCalculation(mapData(Object.assign({}, this.props.borrower, {step: 'resume'}), this.props.coborrower, this.props.loan))
						let templates = 'all';
						if (this.props.loan.flow === 'afi-esca') {
							await this.props.afiEsca(mapData(Object.assign({}, this.props.borrower, {
								step: 'offre',
								health_selection: false
							}), this.props.coborrower, this.props.loan));
							templates = ['QuestionnaireSportif', 'BPA'];
							if (is_not_lemoine) {
								templates.push('HealthQuestionnaireAfiesca');
							} else {
								templates.push('risk_declaration');
							}
							if (this.props.loan.loan_status !== 'new') {
								templates.push('SubstitutionMandate');
							}
						} else if (this.props.loan.flow === 'mncap') {
							await this.props.updateCustomer({borrower: {payment_iban: this.props.borrower.payment_iban, email: this.props.borrower.email}});
							templates = ['FsiMncap', 'DIAMncap', 'QuestionnaireSportif'];
							if (this.props.loan.loan_status !== 'new') {
								templates.push('SubstitutionMandate');
							}
						}
						await this.props.documents({
							flow: this.props.loan.flow,
							lemoine: !is_not_lemoine,
							templates
						});

						this.handleSubmit();
					}}>
					<Row>
						<Col md={12}>
							<FormButton id="signDocumentsButton" value='Accéder à mes documents'/>
						</Col>
					</Row>
				</ValidatorForm>
			</Modal>
		)
	}

	installmentModal() {
		return (
			<Modal
				title="Votre échéancier"
				dialogOpen={this.state.installmentModal}
				config={this.props.config}
				maxWidth={'sm'}
				onClose={() => this.setState({installmentModal: false})}
			>
				{isMobile ? this.installments() : this.installmentOffre()}
			</Modal>
		)
	}

	signatureErrorModal() {
		return (
			<Modal
				title="Oups!"
				dialogOpen={this.state.signatureErrorModal}
				config={this.props.config}
				onClose={() => this.setState({signatureErrorModal: false})}
			>
				<Row justify="center">
					<Col md={12} style={{width: '500px'}}>
						<Translate value="step8.signatureError"/>
					</Col>
				</Row>
				<Row justify="center" style={{marginTop: '30px'}}>
					<Col md={4}>
						<StandardButton
							onClick={() => {
								history.push('/profession');
							}}
							value={<Translate value="generic.continue"/>}/>
					</Col>
				</Row>


				{/*<Row justify="center" style={{marginTop: '30px'}}>*/}
				{/*	<p style={{margin: 12}}><Translate value={"header.help"} /></p>*/}
				{/*	<Col style={{textAlign: 'center', paddingTop: '9px'}}>*/}
				{/*		<FaPhoneAlt size={17}/><a href={'tel:' + I18n.t('header.sidebar.mobile')} style={{color: '#FE7A59', fontSize: '20px', fontWeight: '600'}}> {I18n.t('header.sidebar.mobile')}</a>*/}
				{/*	</Col>*/}
				{/*	<Col onClick={(e) => {*/}
				{/*		document.getElementById('chat-wrapper').style.display = 'block';*/}
				{/*		document.body.classList.add('chatOpened');*/}
				{/*	}} md={6}>*/}
				{/*		<StandardButton value={<span><BsFillChatFill/> <Translate value="header.sidebar.chat" dangerousHTML/></span>}>*/}

				{/*		</StandardButton>*/}
				{/*	</Col>*/}
				{/*</Row>*/}
			</Modal>
		)
	}

	async handleSubmit() {
		this.props.updateConfig('loading', true);
		this.props.updateConfig('show_document_loading_message', false);
		let borrower = this.props.config.isCoborrower ? this.props.coborrower : this.props.borrower;
		let sign     = await this.props.sign({borrower, documents: this.props.config.documents, send_mail: true});
		this.props.updateConfig('loading', false);
		if (sign.data.error && this.props.loan.flow === 'mncap') {
			this.setState({ibanModal: false})
			this.setState({signatureErrorModal: true});
		} else {
			this.props.updateConfig('loading', false);
			history.push('signing');
		}
	}


	installmentOffre() {
		return (
			<Row justify="center">
				<Col>
					<div style={{width: '510px', marginTop: '15px', marginRight: '17px'}}>
						{this.props.loan.flow === 'afi-esca' && <div className='installments-modal-text-wrapper'>
							<span><Translate value="step3.choose_your_payment_type"/> <span data-tip={I18n.t('step3.payment_type_info')}><FaInfo size={15}/></span> :</span>
							<RadioGroup
								className='radioGroup'
								name='question_three'
								style={{marginTop: 0}}
								value={this.props.loan.constant_premium}
								onChange={(event, value) => {
									this.setState({changed_premium_type: true});
									this.props.updateLoan('constant_premium', value === 'true');
									if (value === 'true') {
										this.updateNumbers(this.props.config.calculation.total.premium, this.props.config.calculation.total.insurance_cost);
									} else {
										this.updateNumbers(this.props.config.calculation.variable.premium, this.props.config.calculation.variable.insurance_cost);
									}
								}}>
								<FormControlLabel
									className='radioButton'
									value={true}
									control={<Radio required/>}
									label='Constante'
								/>
								<FormControlLabel
									className='radioButton'
									value={false}
									control={<Radio required/>}
									label='Variable'
								/>
							</RadioGroup>
						</div>}
						<InstallmentOffre type={this.props.loan.constant_premium ? 'constante' : 'variable'}
						                  selected='true'
						                  config={this.props.config}
						                  installments={this.props.loan.constant_premium ? this.props.config.calculation.installments : (this.props.config.calculation.variable ? this.props.config.calculation.variable.installments : [])}
						                  payment_period={this.props.config.calculation.payment_period}/>
					</div>
				</Col>
			</Row>
		)
	}

	installments() {
		return (
			<Row justify="center">
				<Col>
					<Installments type={this.props.loan.constant_premium === 'true' ? 'constante' : 'variable'}
					              selected='true'
					              config={this.props.config}
					              installments={this.props.loan.constant_premium === 'true' ? this.props.config.calculation.installments : (this.props.config.calculation.variable ? this.props.config.calculation.variable.installments : [])}
					              payment_period={this.props.config.calculation.payment_period}/>
				</Col>
			</Row>
		)
	}

	submitButton() {
		return (
			<Col>
				<FormButton onClick={async () => {
					if (['mncap', 'afi-esca'].indexOf(this.props.loan.flow) !== -1) {
						if (this.state.sendToProfession) {
							history.push('profession');
						} else {
							this.setState({ibanModal: true});
						}
					} else {
						this.props.updateConfig('loading', true);
						axios({
							method: 'post',
							url: api_url + '/calculate/push_to_insurer',
							data: mapData(Object.assign({}, this.props.borrower, {step: 'resume'}), this.props.coborrower, this.props.loan),
							withCredentials: true,
						}).then((res) => {
							console.log(res);
							// this.props.updateConfig('loading', false);
						});
						//Artificial loading as to allow as much time for the SSO button to be operational on next page
						setTimeout(() => {
							history.push('/recevoir');
						}, 3 * 1000);
					}
				}} value={this.props.config.calculation.health_selection || this.props.borrower.health_selection ? "Transmettre ma demande" : "J'en profite"}/>
			</Col>)
	}

	render() {
		// let logo;
		// let {bank_guarantees} = calculateGuarantees(this.props, all_equivalences);
		let eight_year_cost   = this.props.loan.constant_premium || this.props.loan.constant_premium === null ? this.props.config.calculation?.total?.eight_year_cost : this.props.config.calculation?.variable ? this.props.config.calculation?.variable?.eight_year_cost : this.props.config.calculation?.total?.eight_year_cost;
		eight_year_cost       = eight_year_cost ? (eight_year_cost.toFixed(2)).replace('.', ',') : 0;
		// switch (this.props.loan.flow) {
		// 	case 'mncap':
		// 		logo = mncap;
		// 		break;
		// 	case 'afi-esca':
		// 		logo = afi_esca;
		// 		break;
		// 	default:
		// 		logo = mutlog;
		// }
		return (
			<ValidatorForm onSubmit={() => {

			}}>
				{this.props.config.calculation.health_selection || this.props.borrower.health_selection ?
					<div>
						<Row justify='center' style={{textAlign: 'center', marginTop: '80px'}}>
							<Col md={8}>
								<p style={{fontWeight: '450', fontSize: '19px'}}>
									Votre situation nécessite un approfondissement de notre assureur. < br/> Merci de signer votre demande d'adhésion et votre questionnaire
									de
									santé pour que puissions lui transmettre. Il pourra revenir vers vous pour solliciter des éléments complémentaires d'information et vous
									communiquer
									son offre définitive.
								</p>
							</Col>
						</Row>
					</div> :
					<div>
						<Row justify='center' style={{textAlign: 'center'}}>
							<Col md={12}>
								{/*<h1 style={{fontSize: '1.7rem'}}>{this.props.borrower.first_name}{this.props.config.calculation.health_selection ? ', MNCAP a besoin d\'informations complémentaires. '*/}
								{/*	: ', voici notre offre :'}</h1>*/}
								<p><span style={{fontWeight: 700, color: '#292F44', letterSpacing: '0.56px', fontSize: '56px'}}>Et voilà</span><span
									style={{letterSpacing: '0.56px', fontWeight: 900, color: '#88BAC7', fontSize: '56px'}}> {this.props.config.theme.domain || 'wedou.fr'}</span></p>
								<p style={{color: '#707071', fontSize: '30px', fontWeight: 600}}>vous propose une meilleure assurance au juste prix de :</p>
							</Col>
						</Row>
						<Row justify="center">
							<Col md={8}>
								<Row justify="center">
									<Col md={3} style={{alignSelf: 'center', textAlign: 'center'}}>
										{/*TODO:theme*/}
										{/*<h3 style={{color: this.props.config.theme.primary}} className="pricing">*/}
										<Price value={this.state.odometerPremiumValue} periodicity='mois'/>

									</Col>
									{eight_year_cost !== 0 &&
										<>
										    <Col md={2.5} style={{alignSelf: 'center'}}>
										        <p style={{color: '#000000', fontSize: '22px'}}>avec un coût sur 8 ans</p>

										    </Col>
										    <Col md={3} style={{alignSelf: 'center'}}>
										        {/*TODO:theme*/}
										        {/*<h3 style={{color: this.props.config.theme.primary}} className="pricing">*/}
										        <span className="globalPricing">{eight_year_cost}€</span>
										        <img src={traitImage} className="traitImage"/>
										    </Col>
										</>
									}
								</Row>
							</Col>
						</Row>
						<Row justify="center" style={{textAlign: 'center'}}>
							<Col md={8}>
								<Row>
									<Col md={12} style={{fontSize: '21px', margin: '22px 0'}}>
										Vous pouvez consulter votre échéancier <strong style={{fontWeight: '700', cursor: 'pointer'}} onClick={() => {
											this.setState({installmentModal: true})
										}}>ici</strong>
									</Col>
								</Row>
								<Row>
									<Col md={5}>
										<span style={{fontSize: '35px', fontWeight: 500, color: '#292F44'}}>Coût global</span><br/>
										<span className="globalPricing" style={{fontSize: '34px', letterSpacing: '-2px'}}>
											<Odometer value={this.state.odometerTotalInsuranceValue} format='(ddd),ddd' duration={5500}/>
											<span> €</span>
										</span>
									</Col>
									<Col md={5}>
										<span style={{fontSize: '35px', fontWeight: 500, color: '#292F44'}}>Taux moyen</span><br/>
										<span className="globalPricing" style={{fontSize: '34px', letterSpacing: '-2px'}}>
											<Odometer value={this.state.odometerInsuranceRateValue} format='( ddd),ddddd' duration={5500}/>
											<span> %</span>
										</span>
									</Col>
								</Row>
							</Col>
						</Row>
						<Row justify="center">
							<Col md={6}>
								<img src={newLupaImage} style={isMobile ? {} : {width: '700px', marginTop: '100px'}}/>
							</Col>
							<Col md={6}>
								<BorrowerGuarantees finalOffer={true} config={this.props.config} flow={this.props.loan.flow} is_coborrower={false} loan={this.props.loan}
								                    borrower={this.props.borrower}
								                    translations={this.props.translations}
								                    guarantees={this.props.config.calculation.borrower_guarantees}/>
								<Row>
									<Col md={4}>
										{this.submitButton()}
									</Col>
								</Row>
							</Col>
						</Row>
						<Row>
							<Col md={12}>
								<span style={{color: '#000', fontSize: '40px', fontWeight: '900'}}> Le contrat est vraiment sûr :</span>
							</Col>
						</Row>
						<Row justify="center">
							<Col md={5.5} style={{textAlign: 'left', paddingTop: '27px'}}>
								<i className={`fa fa-check`} style={{color: '#44D18B', margin: '5px'}}/><span className="offerBenefits">Notre porteur de risque est : <b>{productInsurers[this.props.loan.flow]}</b></span> <br/>
								<i className={`fa fa-check`} style={{color: '#44D18B', margin: '5px'}}/><span className="offerBenefits">Et nous avons conçu notre offre avec l’aide des plus grands réassureurs ;</span><br/>
								<i className={`fa fa-check`} style={{color: '#44D18B', margin: '5px'}}/><span className="offerBenefits">Le contrat respecte les exigences de votre banque ;</span><br/>
								<i className={`fa fa-check`} style={{color: '#44D18B', margin: '5px'}}/><span className="offerBenefits">Vos garanties sont renforcée au regard de votre contrat actuel</span>
							</Col>
						</Row>
						<Row justify="center">
							<Col md={6}>
								<img src={highFiveImage}/>
							</Col>
							<Col md={6} style={{paddingTop: '50px'}}>
								<Row justify="left">
									<Col md={5} style={{textAlign: 'left', color: '#000', fontSize: '30px'}}>
										C’est ultra simple,
									</Col>
								</Row>
								<Row justify="left">
									<Col md={5} style={{textAlign: 'left', fontSize: '19px', color: '#000', paddingTop: '10px'}}>
										vous pouvez souscrire dès le prochain écran sans autre formalité, <span>{this.props.config.theme.domain || 'wedou.fr'}</span> s’occupe du reste
									</Col>
								</Row>
								<Row justify="left">
									<Col md={4} style={{textAlign: 'left', fontSize: '19px', color: '#000', paddingTop: '15px'}}>
										{this.submitButton()}
									</Col>
								</Row>
							</Col>
						</Row>
						<Row justify="center">
							<Col md={6}>
								<span style={{fontSize: isMobile ? '25px' : '39px', color: '#000'}}>Nos conseillers sont à votre écoute de 9h à 20h. <span style={{color: '#94C7D4'}}>Contactez-nous</span> </span>
							</Col>
						</Row>
						<Row justify="center" style={{textAlign: 'center', marginTop: '32px', fontSize: isMobile ? '17px' : '25px', color: '#000'}}>
							<Col md={4} xs={10} sm={10}>
								Des questions ?<br/>
								Besoin d’aide ?<br/>
								Appelez-nous!<br/>
								<span style={{color: '#FE7A59',}}>01 76 41 05 48</span>
							</Col>
							<Col md={4} xs={10} sm={10}>
								Vous pouvez <br/> aussi prendre <br/>rendez-vous :
							</Col>
						</Row>
						<Row justify="center" style={{textAlign: 'center', marginTop: '10px'}}>
							<Col md={2.5}>
								<StandardButton value="Prendre rendez-vous" onClick={() => {
									isMobile ? history.push('rendez-vous') : this.props.updateConfig('openCalendar', true);
								}}/>
							</Col>
							<Col md={1.5}>

							</Col>
							<Col md={2.5}>
								<StandardButton value={<Translate value="header.sidebar.chat" dangerousHTML/>} onClick={() => {
									document.getElementById('chat-wrapper').style.display = 'block';
									document.body.classList.add('chatOpened');
								}}/>
							</Col>
						</Row>
						<Row justify="center">
							<Col md={8}>
								<img src={peopleGroupImage}/>
							</Col>
						</Row>
						<Row justify="center">
							<Col md={8}>
								<span style={{fontSize: '35px', color: "#000"}}><span style={{color: '#A9D2DD'}}>{this.props.config.theme.domain || 'wedou.fr'}</span> s’occupe de tout :</span>
							</Col>
						</Row>
						<Row justify="center">
							<Col md={8} style={{fontSize: '22px', color: '#000', fontWeight: 0, fontFamily: 'Poppins', textAlign: 'center'}}>
								Votre pouvoir d’achat est notre priorité <br/> N’attendez pas l’échéance <br/> <strong>{this.props.config.theme.domain || 'wedou.fr'}</strong> gère pour vous les formalités auprès de votre banque.
							</Col>
						</Row>
						<Row justify="center">
							<Col md={6}>
								<img src={flyingManImage} style={isMobile ? {} : {height: '600px'}}/>
							</Col>
							<Col md={6} style={{paddingTop: isMobile ? '5px' : '50px'}}>
								<Row justify="center">
									<Col md={7} style={{textAlign: 'left', color: '#000', fontSize: '30px'}}>
										Votre pouvoir d’achat est notre priorité
									</Col>
								</Row>
								<Row justify="center">
									<Col md={7} style={{textAlign: 'left', fontFamily: 'Poppins-Light', fontSize: '21px', color: '#000', paddingTop: '10px'}}>
										L’association {this.props.config.theme.name || 'wedou'} se bat depuis 5 ans pour obtenir la fin du monopole bancaire et l’accès au juste prix de l’assureur emprunteur.
									</Col>
								</Row>
								<Row justify="center">
									<Col md={7} style={{textAlign: 'left', fontFamily: 'Poppins-Light', fontSize: '21px', color: '#000', paddingTop: '10px'}}>
										Nous avons obtenu pour vous le droit à la résiliation à tout moment et avons conçu pour vous un accès simple aux meilleures garanties, profitez-en !
									</Col>
								</Row>
							</Col>
						</Row>
					</div>}
				<Row justify="center" style={{marginTop: '7vh'}}>
					<Col md={4} xs={12} sm={12}>
						<BackButton flow={this.props.loan.flow}/>
					</Col>
					<Col md={4} sm={12} xs={12}>
						{this.submitButton()}
					</Col>
				</Row>

				{this.modal()}
				{this.installmentModal()}
				{this.signatureErrorModal()}
			</ValidatorForm>
		);
	}

}

export default Offer;
