import React from "react";
import {Switch as ReactSwitch} from 'react-router-dom';

window.applicationRoutes = window.applicationRoutes || [];

export default function Switch(props) {
	props.children.forEach(child => {
		if (child.props.path || (child.props.from && child.props.to)) {
			let path = child.props.path || child.props.from;
			if (window.applicationRoutes.indexOf(path) === -1) {
				window.applicationRoutes.push(path);
			}
		}
	});

	return <ReactSwitch {...props}>...{props.children}</ReactSwitch>
}
