import React from 'react';
import Shell from '../../components/Shell';
import {connect} from 'react-redux';
import {Container, Row, Col} from "react-grid-system";
import {LoanAmount, LoanDurationMonths} from "../../components/Form/StepsFields/step1";
import {CustomValidatorForm} from "../../components/Form";
import {BirthDate} from "../../components/Form/StepsFields/step2";
import moment from 'moment';
import {mapData} from "../../js/helpers/functions";
import Odometer from "react-odometerjs";
import style from "../../components/Offer/style.module.css";
import {Button} from "@material-ui/core";
import Switch from 'react-switch'

import Translate from "components/CustomTranslate";

class QuickPricing extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			submitted: false,
			checked: false,
			odometerPremium: 0.01,
			odometerInsuranceCost: 0.01,
			noResult: false
		}
	}

	componentDidMount() {
		this.props.updateLoan("loan_status", "new");
		this.props.updateLoan('contract_date', moment().add(1, 'days').format('DD/MM/YYYY'))
		this.props.updateLoan([0, 'interest_rate'], 0)
		this.props.updateLoan('bank', 'allianzbanque');
		this.props.updateLoan('project_type', 'investmentForRenting');
		this.props.updateLoan('bank_search', {
			"name": "Allianz banque",
			"key": "allianzbanque",
			"default_insurer": "allianz"
		});
	}

	getStep() {
		return 'quick_pricing';
	}

	lowestPricing() {
		if (Object.values(this.props.config.comparison.insurers).length > 0) {
			let cheapest_insurer = Object.values(this.props.config.comparison.insurers).reduce(function (prev, curr) {
				return prev.total.premium < curr.total.premium ? prev : curr;
			});
			let premium          = cheapest_insurer.total.premium;
			let insurance_cost   = cheapest_insurer.total.insurance_cost;
			this.setState({odometerPremium: (premium.toFixed(2) + '1').replace('.', ',')});
			this.setState({odometerInsuranceCost: (insurance_cost.toFixed(2) + '1').replace('.', ',')});
		} else {
			this.setState({noResult: true})
		}

	}

	render() {

		return (
			<Container>
				{!this.state.submitted ?
					<CustomValidatorForm onSubmit={() => {
						let data = mapData(this.props.borrower, this.props.coborrower, this.props.loan);
						this.props.compareInsurers(data).then((result) => {
							this.props.updateConfig('loading', false);
							if (result && result.data) {
								this.setState({submitted: true})
								this.lowestPricing();
							} else this.setState({dialogOpen: true});
						});
					}}>
						<Row justify='center' style={{paddingLeft: '10px', paddingRight: '10px'}}>
							<Col md={4.5}>
								<LoanAmount autoFocus update={this.props.updateLoan} contract_date={this.props.loan.contract_date} data={this.props.loan.loans} loan={0}
								            loan_status={this.props.loan.loan_status}/>
							</Col>
						</Row>
						<Row justify='center' style={{paddingLeft: '10px', paddingRight: '10px'}}>
							<Col md={4.5}>
								<LoanDurationMonths update={this.props.updateLoan} data={this.props.loan.loans} loan={0} loan_status={this.props.loan.loan_status}
								                    checkDuration={true} contract_date={this.props.loan.contract_date} flow={this.props.loan.flow}/>
							</Col>
						</Row>
						<Row justify='center' style={{paddingLeft: '10px', paddingRight: '10px'}}>
							<Col md={4.5}>
								<BirthDate
									id='borrower_birth_date'
									label="step1.birth_date"
									data={this.props.borrower} update={this.props.updateBorrower}/>
							</Col>
						</Row>
						{this.props.translations.locale === 'fr' &&
						<Row justify='center' style={{paddingLeft: '10px', paddingRight: '0'}}>
						    <Col md={4.5} style={{textAlign: 'left', marginBottom: '5px', fontSize: '13px'}}>
						        <Row justify='left'>
						            <Col md={6} sm={7.5} xs={7.5}>
						                <span style={{fontWeight: 500}}>Pour votre résidence</span><br/>
						            </Col>
						            <Col md={2} sm={2} xs={2} style={{marginTop: '-3px',paddingLeft: 0}}>
						                <Switch
						                    offColor="#7f97b0"
						                    checked={this.props.loan.project_type === 'mainResidence'}
						                    onColor="#2be806"
						                    checkedIcon=""
						                    uncheckedIcon=""
						                    height={23}
						                    width={48}
						                    onChange={(value) => {
						                        this.props.updateLoan('project_type', value ? 'mainResidence' : 'investmentForRenting');
						                    }}
						                />
						            </Col>
						        </Row>

						    </Col>
						</Row>}
						<Row justify='center' style={{paddingLeft: '10px', paddingRight: '10px'}}>
							<Col md={4.5}>
								<Button style={{
									width: '100%',
									fontFamily: 'Montserrat, sans-serif !important',
									color: '#fff',
									fontSize: '1.2rem',
									textTransform: 'none',
									borderColor: '#fff',
									borderStyle: 'solid',
									borderWidth: '1px',
									fontWeight: '500',
									backgroundColor: this.props.config.theme.secondary
								}}
								        type='submit'>
									<Translate value="generic.myEstimation"/>
								</Button>
							</Col>
						</Row>
					</CustomValidatorForm> :
					<div>
						{this.state.noResult ? <div>
							<Row justify='center' style={{marginTop: '25px'}}>
								<Col md={5} xs={9} style={{textAlign: 'left'}}>
										Nous n'avons pas de solution pour cette simulation. <br/> Êtes-vous sûr de ne pas avoir fait une erreur 🙂?
								</Col>
							</Row>
							<Row justify='center' style={{marginTop: '15px'}}>
								<Col md={4} xs={8} sm={8}>
									<Button className='formButton backButton' style={{color: this.props.config.theme.primary}} onClick={() => {
										this.setState({submitted: false, noResult: false})
									}}>Retour
									</Button>
								</Col>
							</Row>
						</div> :
							<div>
								<Row justify='center'>
									<Col md={5}>
										<h2 style={{textAlign: 'center'}}><Translate value="quickPricing.ourBestOffer"/></h2>
									</Col>
								</Row>
								<Row justify='center'>
									<Col md={5}>
										{this.props.translations.locale === 'fr' && <h3 style={{textAlign: 'center'}}><Translate value="step3.costGlobal"/>:</h3>}
										<h3 className={`${style.odometerWrapper}`} style={{textAlign: 'center', color: this.props.config.theme.primary || '#fff'}}>
											<Odometer value={this.state.odometerInsuranceCost} format='( ddd),ddd' duration={5500}/>
											<span><Translate value="generic.currencySymbol"/></span>
										</h3>
									</Col>
								</Row>
								<Row justify='center'>
									<Col md={5}>
										<h3 style={{textAlign: 'center'}}><Translate value="generic.monthlyPremium"/> :</h3>
										<h3 className={`${style.odometerWrapper}`} style={{textAlign: 'center', color: this.props.config.theme.primary || '#fff'}}>
											<Odometer value={this.state.odometerPremium} format='( ddd),ddd' duration={5500}/>
											<span><Translate value="generic.currencySymbol"/></span>
										</h3>
									</Col>
								</Row>
								<Row justify='center' style={{paddingLeft: '10px', paddingRight: '10px'}}>
									<Col md={5}>
										<Button className='formButton backButton' style={{color: this.props.config.theme.primary}} onClick={() => {
											window.parent.location = '/prets';
										}}>
											<Translate value="generic.iWantProfit"/></Button>
									</Col>
								</Row>
							</div>}
					</div>

				}
			</Container>
		)
	}

}

export default connect()(Shell(QuickPricing));
